import { HiOutlineChevronUp, HiOutlineChevronDown } from 'react-icons/hi';
import {FormattedMessage} from "react-intl";

/**
 * @module
 * @description This function creates the structure and the logic of Table Header Item
 * @param {text} label get label
 * @param {Function} currentColumn get current column
 * @param {Function} filterSort get filter sort
 * @param {Function} handleFilterSort get handle Filter Sort
 * @returns {JSX} Table Header Item
 */
function TableHeaderItem(props) {
  const { label, currentColumn, filterSort, handleFilterSort } = props;
  let orderIconJSX = ' ';
  if (filterSort.column === currentColumn || !filterSort.column) {
    orderIconJSX =
      filterSort.order === 'desc' ? (
        <HiOutlineChevronDown />
      ) : (
        <HiOutlineChevronUp />
      );
  }
  return (
    <div onClick={() => handleFilterSort(currentColumn)}>
      <span><FormattedMessage id="projects.label" defaultMessage="{columns}"
      values = {{columns: label}}/></span>
      {orderIconJSX}
    </div>
  );
}

export default TableHeaderItem;
