
function ArrowUpIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#3FAA58"
        d="M9.595 6.824a2.836 2.836 0 00-.033-.026V12a.562.562 0 11-1.124 0V6.798l-.033.026c-.242.194-.53.483-.957.914L6.4 8.796a.562.562 0 11-.8-.792l1.072-1.08c.398-.403.73-.738 1.028-.977.313-.251.641-.442 1.04-.493a2.048 2.048 0 01.52 0c.399.05.727.242 1.04.493.297.239.63.574 1.028.976L12.4 8.004a.563.563 0 01-.8.792l-1.048-1.058c-.427-.431-.715-.72-.957-.914z"
      ></path>
      <path
        fill="#3FAA58"
        fillRule="evenodd"
        d="M12.348 1.78c-.857-.093-1.937-.093-3.314-.093h-.068c-1.377 0-2.457 0-3.314.093-.874.095-1.582.292-2.187.731a4.313 4.313 0 00-.954.954c-.44.605-.636 1.313-.73 2.187-.094.857-.094 1.937-.093 3.314v.068c0 1.377 0 2.457.092 3.314.095.874.292 1.582.731 2.187.266.366.588.688.954.954.605.44 1.313.636 2.187.73.857.093 1.937.093 3.314.093h.068c1.377 0 2.457 0 3.314-.092.874-.095 1.582-.292 2.187-.731.366-.266.688-.588.954-.954.44-.605.636-1.313.73-2.187.093-.857.093-1.937.093-3.314v-.068c0-1.377 0-2.457-.092-3.314-.095-.874-.292-1.582-.731-2.187a4.313 4.313 0 00-.954-.954c-.605-.44-1.313-.636-2.187-.73zM4.126 3.421c.381-.277.87-.438 1.648-.522.788-.086 1.807-.087 3.226-.087s2.438.001 3.226.087c.779.084 1.267.245 1.648.522.27.197.508.435.705.705.277.381.438.87.522 1.648.086.788.086 1.807.086 3.226s0 2.438-.086 3.226c-.084.779-.245 1.267-.522 1.648-.197.27-.435.508-.705.705-.381.277-.87.438-1.648.522-.789.086-1.807.086-3.226.086s-2.438 0-3.226-.086c-.779-.084-1.267-.245-1.648-.522a3.187 3.187 0 01-.705-.705c-.277-.381-.438-.87-.522-1.648-.086-.789-.087-1.807-.087-3.226s.001-2.438.087-3.226c.084-.779.245-1.267.522-1.648.197-.27.435-.508.705-.705z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default ArrowUpIcon;