
function SmileFaceIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#3FAA58"
        d="M5.75 10.241a.563.563 0 10-1 .518c.299.575.854 1.205 1.571 1.693.723.491 1.643.86 2.679.86 1.036 0 1.956-.369 2.679-.86.717-.488 1.272-1.118 1.57-1.693a.563.563 0 00-.998-.518c-.202.39-.622.884-1.205 1.28-.577.393-1.282.666-2.046.666-.764 0-1.469-.273-2.046-.666-.583-.396-1.003-.89-1.205-1.28z"
      ></path>
      <path
        fill="#3FAA58"
        fillRule="evenodd"
        d="M9 .938a8.062 8.062 0 100 16.124A8.062 8.062 0 009 .937zM2.062 9a6.937 6.937 0 1113.875 0A6.937 6.937 0 012.063 9z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#3FAA58"
        d="M7.5 6A.75.75 0 116 6a.75.75 0 011.5 0zM12 6a.75.75 0 11-1.5 0A.75.75 0 0112 6z"
      ></path>
    </svg>
  );
}

export default SmileFaceIcon;
