import React, { useEffect, useState, useRef } from "react";
import Calendar from "../Calendar/Calendar";
import Tickler from "../Tickler/Tickler";
import "./scheduler.scss";
import DaysOffList from "../DaysOffList/DaysOffList";
import { useAuthContext } from "../../../context/AuthContext";
import { useSchedulerContext } from "../../../context/SchedulerContext";
import Header from "../Header/Header";
import { InformationIcon } from "../../../Icons";
import TimeCircle from "../../Icons/TimeCircleIcon";
import data from "../../../version.json";

// language switcher feature
import { FormattedMessage } from "react-intl";
import HolidaysIcon from "../../../Icons/HolidaysIcon";
import VacationsModal from "../Modal/VacationsModal";
import { getLocationsAndRegions } from "../services/getLocationsAndRegions";
import getUserByName from "../services/getUserByName";

/**
 * @module
 * @description This component displays the calendar, the list of days
 * off and the tickler
 * @returns {JSX} SchedulerLayout
 */

const moment = require("moment");

const SchedulerLayout = () => {
  const schedulerLayoutRef = useRef(null);
  const auth = useAuthContext();
  const scheduler = useSchedulerContext();
  let version = data;
  let currentVersion = version[version.length - 1];
  const [context, setContext] = useState();
  const [name, setName] = useState("name");
  const [filteredEvents, setFilteredEvents] = useState([...scheduler.companyEvents]);
  const [filteredEventsByRegion, setFilteredEventsByRegion] = useState([...scheduler.companyUserEventsByRegion]);
  const [filteredWorkedHours, setFilteredWorkedHours] = useState([...scheduler.workedHours]);
  const [filteredHolidays, setFilteredHolidays] = useState([...scheduler.personalHolidays]);
  const [filteredGlobalEvents, setFilteredGlobalEvents] = useState([...scheduler.companyGlobalEvents])
  const [filteredUserDayOff, setFilteredUserDayOff] = useState([...scheduler.userDaysOff])
  const [region, setRegion] = useState();
  const [local, setLocal] = useState()
  const [contextEnumerator, setContextEnumerator] = ([{
    Personal: "Personal",
    Global: "Global",
    Region: region ? region : auth.userInfo.region,
    Local: local ? local : auth.userInfo.location,
    Employee: name
  }]);
  const [availableDays, setAvailableDays] = useState()
  const [totalDays, setTotalDays] = useState()
  const [usedDays, setUsedDays] = useState(0)
  const [actualMonth, setActualMonth] = useState(parseInt(moment().format("M")));
  const [selectedMonth, setSelectedMonth] = useState(
    parseInt(moment().format("M"))
  );
  const [prevMonth, setPrevMonth] = useState(
    moment.utc(`${selectedMonth}`).subtract(1, "M").format("MMMM")
  );
  const [nextMonth, setNextMonth] = useState(
    moment.utc(`${selectedMonth}`).add(1, "M").format("MMMM")
  );

  const [permissions, setPermissions] = useState([]);
  const { user } = useAuthContext();
  const [isLocation, setIsLocation] = useState(false);
  const [isRegion, setIsRegion] = useState(false);

  useEffect(() => {
    scheduler.setSelectedContext('Personal')
    scheduler.setPersonalTickler(true)
    setPermissions([])
  }, [])

  useEffect(() => {
    schedulerLayoutRef.current.focus();
    scheduler.setSelectedMonth(parseInt(moment().format("M")));
    scheduler.setSelectedYear(parseInt(moment.utc().format("YYYY")));
    setContext(contextEnumerator.Personal);
  }, []);

  useEffect(() => {
    setSelectedMonth(actualMonth)
    scheduler.setSelectedMonth(actualMonth)
    setPermissions([])
  }, [context]);

  useEffect(async () => {
    let permissionsArray = [];
    let permissionsByLocation = [];
    let permission = user.permissions.userPermissions;

    if (context === 'Personal' || context === undefined || context === 'Global') {
      for (const permissionElement of permission) {
        if (permissionElement.locations && permissionElement.locations.name === auth.userInfo.location) {
          permissionsArray = [...permissionsArray, permissionElement.permission.name]
          setPermissions([...permissionsArray]);
        }
      }
    } else {
      let locationFound = [];
      let regionFound;
      const selectedUser = await getUserByName(name, auth.user.token);

      if (selectedUser) {
        const locationsAndRegions = await getLocationsAndRegions(auth.user.token);
        locationsAndRegions.forEach((region) => {
          const tempLocationFound = region.locations.filter((location) => location.id === selectedUser.companyLocationId)
          if (tempLocationFound.length > 0) {
            locationFound = [...tempLocationFound];
            regionFound = region;
          }
        });
        let tempPermissions = []
        for (const permissionElement of permission) {
          if (permissionElement.locations && permissionElement.locations.name === locationFound[0].name) {
            permissionsByLocation = [...permissionsByLocation, permissionElement.permission.name]
            tempPermissions = [...permissionsByLocation]
            setPermissions([...permissionsByLocation]);
          }
        }
        if (tempPermissions.length === 0) {
          for (const permissionElement of permission) {
            if (permissionElement.regions && permissionElement.regions && permissionElement.regions.name === regionFound.name) {
              permissionsByLocation = [...permissionsByLocation, permissionElement.permission.name]
              tempPermissions = [...permissionsByLocation];
              setPermissions([...tempPermissions])
            }
          }
          setPermissions([...tempPermissions])
        }
      } else {
        const locationsAndRegions = await getLocationsAndRegions(auth.user.token);
        locationsAndRegions.forEach((region) => {
          const tempLocationFound = region.locations.filter((location) => location.name === context)
          if (tempLocationFound.length > 0) {
            for (const permissionElement of permission) {
              if (permissionElement.locations && permissionElement.locations.name === tempLocationFound[0].name) {
                permissionsByLocation = [...permissionsByLocation, permissionElement.permission.name]
                setPermissions([...permissionsByLocation]);
              }
            }
          } else if (region.name === context) {
            for (const permissionElement of permission) {
              if (permissionElement.regions && permissionElement.regions.name === context) {
                permissionsByLocation = [...permissionsByLocation, permissionElement.permission.name]
                setPermissions([...permissionsByLocation])
              }
            }
          }
        });
      }
    }
  }, [context])

  useEffect(() => {
    //Si hay un evento global y un day off, se muestra el day off
    let tempEvents = scheduler.companyEvents.filter(e => {
      let eventDate = e.start.substring(0, 10);
      for (const element of scheduler.userDaysOff) {
        if (element.end === null) {
          if (element.start === eventDate) {
            return false;
          }
        } else if (element.start <= eventDate && element.end >= eventDate) {
          return false;
        }
      }
      return true;
    });

    //Si hay un holiday y un day off, se muestra el day off
    let tempHolidays = scheduler.personalHolidays.filter(e => {
      let eventDate = e.start;
      for (const element of scheduler.userDaysOff) {
        if (element.end === null) {
          if (element.start === eventDate) {
            return false;
          }
        } else if (element.start <= eventDate && element.end > eventDate) {
          return false;
        }
      }
      return true;
    });

    //Si hay un evento global y un holiday, se muestra el holiday
    tempEvents = tempEvents.filter(e => {
      let eventDate = e.start.substring(0, 10);
      for (const element of scheduler.personalHolidays) {
        if (element.start === eventDate) {
          return false;
        }
      }
      return true;
    });


    //Si hay un evento regional y un day off, se muestra el day off
    let tempEventsByRegion = scheduler.companyUserEventsByRegion.filter(e => {
      let eventDate = e.start.substring(0, 10);
      for (const element of scheduler.userDaysOff) {
        if (element.end === null) {
          if (element.start === eventDate) {
            return false;
          }
        } else if (element.start <= eventDate && element.end >= eventDate) {
          return false;
        }
      }
      return true;
    });

    //Si hay un evento regional y un holiday, se muestra el holiday
    tempEventsByRegion = tempEventsByRegion.filter(e => {
      let eventDate = e.start.substring(0, 10);
      for (const element of scheduler.personalHolidays) {
        if (element.start === eventDate) {
          return false;
        }
      }
      return true;
    });

    //Si hay hs cargadas pero un day off, se muestra el day off.
    let tempWorkedHours = scheduler.workedHours
    //Si hay hs cargadas pero un holiday, se muestra el holiday
    tempWorkedHours = tempWorkedHours.map(e => {
      let eventDate = e.start
      for (const element of scheduler.personalHolidays) {
        if (element.start === eventDate) {
          e.borderColor = 'transparent';
        }
      }
      return e;
    });

    //Si hay hs cargadas pero un evento global, se muestra el evento global
    tempWorkedHours = tempWorkedHours.map(e => {
      let eventDate = e.start
      for (const element of scheduler.companyEvents) {
        if (element.start.substring(0, 10) === eventDate) {
          e.borderColor = 'transparent'
        }
      }
      return e;
    });

    //Si hay hs cargadas pero un evento regional, se muestra el evento regional
    tempWorkedHours = tempWorkedHours.map(e => {
      let eventDate = e.start
      for (const element of scheduler.companyUserEventsByRegion) {
        if (element.start.substring(0, 10) === eventDate) {
          e.borderColor = 'transparent'
        }
      }
      return e;
    });

    //Si hay un evento en el contexto global y un holiday, se muestra el holiday
    let tempGlobalEvents = scheduler.companyGlobalEvents.filter(e => {
      let eventDate = e.start.substring(0, 10);
      for (const element of scheduler.personalHolidays) {
        if (element.start === eventDate) {
          return false;
        }
      }
      return true;
    });

    if (context === 'Personal') {
      if (permissions.includes('view-events')) {
        setFilteredEvents([...tempEvents]);
        setFilteredEventsByRegion([...tempEventsByRegion]);
        setFilteredHolidays([...tempHolidays]);
        setFilteredUserDayOff([...scheduler.userDaysOff])
      } else {
        setFilteredEvents([]);
        setFilteredEventsByRegion([]);
        setFilteredHolidays([]);
      }
      if (!permissions.includes('view-reported-hours')) {
        setFilteredWorkedHours([]);
      } else {
        setFilteredWorkedHours([...scheduler.workedHours])
      }
    }

    if (context === 'Global') {
      if (permissions.includes('view-events')) {
        setFilteredGlobalEvents([...tempGlobalEvents]);
      } else {
        setFilteredGlobalEvents([]);
      }
    }

    if (context !== 'name' && context !== 'Global' && context !== 'Personal') {
      let tempFilteredEventRegion = scheduler.companyUserEventsByRegion;
      let tempFilteredHoliday = scheduler.regionHolidays;
      let tempFilteredUserDayOff = scheduler.userDaysOff;
      tempFilteredEventRegion = tempFilteredEventRegion.filter(el => {
        let pop = true;
        for (const holiday of tempFilteredHoliday) {
          if (holiday.start === el.start) {
            pop = false;
          }
        }
        return pop;
      })
      setFilteredUserDayOff([...tempFilteredUserDayOff])
      setFilteredEventsByRegion([...tempFilteredEventRegion]);
      setFilteredHolidays([...tempFilteredHoliday])
    }

    if (contextEnumerator.Employee !== "name") {
      let tempFilteredWorked = scheduler.workedHours;
      let tempFilteredEventGlobal = scheduler.companyGlobalEvents;
      let tempFilteredEvent = scheduler.companyEvents;
      let tempFilteredEventRegion = scheduler.companyUserEventsByRegion;
      let tempFilteredHoliday = scheduler.personalHolidays;
      tempFilteredWorked = tempFilteredWorked.filter(el => !el.alertStr);
      setFilteredWorkedHours([...tempFilteredWorked]);

      tempFilteredEvent = tempFilteredEvent.filter(el => {
        let pop = true;
        for (const itemGlobal of tempFilteredEventGlobal) {
          if (itemGlobal.id === el.id) {
            pop = false;
          }
        }
        return pop;
      })

      tempFilteredEvent = tempFilteredEvent.filter(el => {
        let pop = true;
        for (const holiday of tempFilteredHoliday) {
          if (holiday.start === el.start) {
            pop = false;
          }
        }
        return pop;
      })

      tempFilteredEventGlobal = tempFilteredEventGlobal.filter(el => {
        let pop = true;
        for (const holiday of tempFilteredHoliday) {
          if (holiday.start === el.start) {
            pop = false;
          }
        }
        return pop;
      })

      tempFilteredEventRegion = tempFilteredEventRegion.filter(el => {
        let pop = true;
        for (const holiday of tempFilteredHoliday) {
          let start = el.start.split('T')[0];
          if (holiday.start === start) {
            pop = false;
          }
        }
        return pop;
      })

      setFilteredGlobalEvents([...tempFilteredEventGlobal]);
      setFilteredEvents([...tempFilteredEvent]);
      setFilteredEventsByRegion([...tempFilteredEventRegion]);
      setFilteredHolidays([...tempFilteredHoliday]);
    }
  }, [scheduler.companyEvents, scheduler.companyUserEventsByRegion, scheduler.userDaysOff, scheduler.workedHours, scheduler.personalHolidays, scheduler.timesheets, scheduler.companyGlobalEvents, context]);

  const handlePrevMonth = () => {
    setSelectedMonth(selectedMonth - 1);
    setPrevMonth(
      moment.utc(`${selectedMonth - 1}`)
        .subtract(1, "M")
        .format("MMMM")
    );
    setNextMonth(
      moment.utc(`${selectedMonth - 1}`)
        .add(1, "M")
        .format("MMMM")
    );
    if (selectedMonth === 1) {
      setSelectedMonth(12);
      setPrevMonth(moment.utc(`${12}`).subtract(1, "M").format("MMMM"));
      setNextMonth(moment.utc(`${12}`).add(1, "M").format("MMMM"));
    }
    scheduler.handlerPrevMonth();
  };

  const handleNextMonth = () => {
    setSelectedMonth(selectedMonth + 1);
    setPrevMonth(
      moment.utc(`${selectedMonth + 1}`)
        .subtract(1, "M")
        .format("MMMM")
    );
    setNextMonth(
      moment.utc(`${selectedMonth + 1}`)
        .add(1, "M")
        .format("MMMM")
    );
    if (selectedMonth === 12) {
      setSelectedMonth(1);
      setPrevMonth(moment.utc(`${1}`).subtract(1, "M").format("MMMM"));
      setNextMonth(moment.utc(`${1}`).add(1, "M").format("MMMM"));
    }
    scheduler.handlerNextMonth();
  };

  const handleUpdate = () => {
    scheduler.setTaskCounter(scheduler.taskCounter + 1);
  };

  const handleSchedulerFocus = () => {
    schedulerLayoutRef.current.focus();
  };

  const [isWheeling, setWheeling] = useState(false);

  const handleWheel = (e) => {
    setWheeling(true);
  };
  const handleSetWheeling = (isWheel) => {
    setWheeling(isWheel);
  };

  const handleKeyDown = (e) => {
    if (
      e.key === "ArrowUp" ||
      e.key === "ArrowDown" ||
      e.key === "ArrowRight" ||
      e.key === "ArrowLeft"
    ) {
      setWheeling(true);
    }
  };

  useEffect(() => {
    let permission = user.permissions.userPermissions;
    let permissionsArray = []
    for (const permissionElement of permission) {
      permissionsArray.push(permissionElement.permission.name);
    }
    setPermissions([...permissionsArray])
  }, [])

  return (
    <div
      className="scheduler-layout"
      tabIndex={0}
      onKeyDown={handleKeyDown}
      onWheel={(e) => handleWheel(e)}
      ref={schedulerLayoutRef}
    >
      <Header
        setLocal={setLocal}
        setRegion={setRegion}
        local={local}
        region={region}
        setContextEnumerator={setContextEnumerator}
        contextEnumerator={contextEnumerator}
        context={context}
        setContext={setContext}
        name={name}
        setName={setName}
        addTask={function () {
          handleUpdate();
        }}
        taskCount={scheduler.taskCounter}
      />
      <hr className="hrScheduler" />
      <div className="scheduler">
        <div className="calendar">
          <div className="myCalendar">
            {context === contextEnumerator.Personal ?
              permissions.includes('view-events') ?
                <Calendar
                  context={context}
                  isWheeling={isWheeling}
                  setWheeling={handleSetWheeling}
                  events={[
                    ...filteredWorkedHours,
                    ...filteredHolidays,
                    ...filteredUserDayOff,
                    ...filteredEvents,
                    ...filteredEventsByRegion,
                  ]}
                  name={name}
                  onClickNextMonth={handleNextMonth}
                  onClickPrevMonth={handlePrevMonth}
                  addTask={function () {
                    handleUpdate();
                  }}
                  taskCount={scheduler.taskCounter}
                  setSchedulerLayoutFocus={handleSchedulerFocus}
                  region={region}
                  local={local}
                /> :
                <Calendar
                  context={context}
                  isWheeling={isWheeling}
                  setWheeling={handleSetWheeling}
                  events={[
                    ...filteredWorkedHours,
                    ...filteredUserDayOff,
                  ]}
                  name={name}
                  onClickNextMonth={handleNextMonth}
                  onClickPrevMonth={handlePrevMonth}
                  addTask={function () {
                    handleUpdate();
                  }}
                  taskCount={scheduler.taskCounter}
                  setSchedulerLayoutFocus={handleSchedulerFocus}
                  region={region}
                  local={local}
                />
              : null}
            {contextEnumerator.Employee !== 'name' && context !== 'Personal' ? (
              permissions.includes('view-events') ? (
                <Calendar
                  context={context}
                  isWheeling={isWheeling}
                  setWheeling={handleSetWheeling}
                  events={[
                    ...filteredWorkedHours,
                    ...filteredHolidays,
                    ...filteredUserDayOff,
                    ...filteredEvents,
                    ...filteredEventsByRegion,
                    ...filteredGlobalEvents
                  ]}
                  name={name}
                  onClickNextMonth={handleNextMonth}
                  onClickPrevMonth={handlePrevMonth}
                  addTask={function () {
                    handleUpdate();
                  }}
                  taskCount={scheduler.taskCounter}
                  setSchedulerLayoutFocus={handleSchedulerFocus}
                  region={region}
                  local={local}
                />
              ) : (
                <Calendar
                  context={context}
                  isWheeling={isWheeling}
                  setWheeling={handleSetWheeling}
                  events={[
                    ...filteredWorkedHours,
                    ...filteredUserDayOff,
                  ]}
                  name={name}
                  onClickNextMonth={handleNextMonth}
                  onClickPrevMonth={handlePrevMonth}
                  addTask={function () {
                    handleUpdate();
                  }}
                  taskCount={scheduler.taskCounter}
                  setSchedulerLayoutFocus={handleSchedulerFocus}
                  region={region}
                  local={local}
                />
              )) : null}
            {context === contextEnumerator.Global ? (
              permissions.includes('view-events') ? (
                <Calendar
                  context={context}
                  isWheeling={isWheeling}
                  setWheeling={handleSetWheeling}
                  events={[
                    ...scheduler.globalHolidays,
                    ...filteredGlobalEvents,
                  ]}
                  name={name}
                  onClickNextMonth={handleNextMonth}
                  onClickPrevMonth={handlePrevMonth}
                  addTask={function () {
                    handleUpdate();
                  }}
                  taskCount={scheduler.taskCounter}
                  setSchedulerLayoutFocus={handleSchedulerFocus}
                  region={region}
                  local={local}
                />)
                : (<Calendar
                  context={context}
                  isWheeling={isWheeling}
                  setWheeling={handleSetWheeling}
                  events={[]}
                  name={name}
                  onClickNextMonth={handleNextMonth}
                  onClickPrevMonth={handlePrevMonth}
                  addTask={function () {
                    handleUpdate();
                  }}
                  taskCount={scheduler.taskCounter}
                  setSchedulerLayoutFocus={handleSchedulerFocus}
                  region={region}
                  local={local}
                />
                ))
              : null}
            {context === contextEnumerator.Region || context === region ? (
              permissions.includes('view-events') ? (
                <Calendar
                  context={context}
                  isWheeling={isWheeling}
                  setWheeling={handleSetWheeling}
                  events={[
                    ...scheduler.regionHolidays,
                    ...scheduler.companyEventsByRegion
                  ]}
                  name={name}
                  onClickNextMonth={handleNextMonth}
                  onClickPrevMonth={handlePrevMonth}
                  addTask={function () {
                    handleUpdate();
                  }}
                  taskCount={scheduler.taskCounter}
                  setSchedulerLayoutFocus={handleSchedulerFocus}
                  region={region}
                  local={local}
                />)
                : (
                  <Calendar
                    context={context}
                    isWheeling={isWheeling}
                    setWheeling={handleSetWheeling}
                    events={[]}
                    name={name}
                    onClickNextMonth={handleNextMonth}
                    onClickPrevMonth={handlePrevMonth}
                    addTask={function () {
                      handleUpdate();
                    }}
                    taskCount={scheduler.taskCounter}
                    setSchedulerLayoutFocus={handleSchedulerFocus}
                    region={region}
                    local={local}
                  />
                )
            ) : null}
            {context === contextEnumerator.Local || context === local ? (
              permissions.includes('view-events') ? (
                <Calendar
                  context={context}
                  isWheeling={isWheeling}
                  setWheeling={handleSetWheeling}
                  events={[
                    ...scheduler.localHolidays,
                    ...scheduler.companyEventsByRegion
                  ]}
                  onClickNextMonth={handleNextMonth}
                  onClickPrevMonth={handlePrevMonth}
                  addTask={function () {
                    handleUpdate();
                  }}
                  name={name}
                  taskCount={scheduler.taskCounter}
                  setSchedulerLayoutFocus={handleSchedulerFocus}
                  region={region}
                  local={local}
                />)
                : (
                  <Calendar
                    context={context}
                    isWheeling={isWheeling}
                    setWheeling={handleSetWheeling}
                    events={[]}
                    onClickNextMonth={handleNextMonth}
                    onClickPrevMonth={handlePrevMonth}
                    addTask={function () {
                      handleUpdate();
                    }}
                    name={name}
                    taskCount={scheduler.taskCounter}
                    setSchedulerLayoutFocus={handleSchedulerFocus}
                    region={region}
                    local={local}
                  />
                ))
              : null}
          </div>
          <div className="informationScheduler">
            <i>
              <InformationIcon />
              {/*<FormattedMessage
                id="calendar.information"
                defaultMessage="Select a day in the calendar to create events and more options"
                />*/}
              Select a day in the calendar to create events and more options
            </i>
          </div>
          <hr className="hrInfo" />
          <div className="myDaysOf">
            {context === "Global" ? (
              permissions.includes('view-events') ? (
                <DaysOffList
                  events={[
                    ...scheduler.globalHolidays,
                    ...scheduler.companyGlobalEvents
                  ]}
                  month={scheduler.selectedMonth}
                  year={scheduler.selectedYear}
                />
              ) : (
                <DaysOffList
                  events={[
                    ...scheduler.alerts,
                  ]}
                  month={scheduler.selectedMonth}
                  year={scheduler.selectedYear}
                />
              )) : null}
            {context === "Personal" || context === contextEnumerator.Employee ? (
              permissions.includes('view-events') ? (
                <DaysOffList
                  events={[
                    ...scheduler.personalHolidays,
                    ...filteredUserDayOff,
                    ...scheduler.companyUserEventsByRegion,
                    ...scheduler.companyEvents,
                  ]}
                  month={scheduler.selectedMonth}
                  year={scheduler.selectedYear}
                />
              ) : (
                <DaysOffList
                  events={[
                    ...filteredUserDayOff,
                  ]}
                  month={scheduler.selectedMonth}
                  year={scheduler.selectedYear}
                />
              )) : null}
            {context === auth.userInfo.region || context === region ? (
              permissions.includes('view-events') ? (
                <DaysOffList
                  events={[
                    ...scheduler.regionHolidays,
                    ...scheduler.companyEventsByRegion,
                  ]}
                  month={scheduler.selectedMonth}
                  year={scheduler.selectedYear}
                />
              ) : null) : null}
            {context === auth.userInfo.location || context === local ? (
              permissions.includes('view-events') ? (
                <DaysOffList
                  events={[
                    ...scheduler.localHolidays,
                    ...scheduler.companyEventsByRegion,
                  ]}
                  month={scheduler.selectedMonth}
                  year={scheduler.selectedYear}
                />
              ) : (
                <DaysOffList
                  events={[]}
                  month={scheduler.selectedMonth}
                  year={scheduler.selectedYear}
                />
              )) : null}
          </div>
          <hr />
          {context === contextEnumerator.Personal ||
            context === contextEnumerator.Employee ? (
            <>
              <div className="totalTimeReported">
                <div className="ico">
                  <TimeCircle />
                </div>
                <span>
                  {/*<FormattedMessage
                    id="calendar.total.month.time"
                    defaultMessage="Total hours reported within the month: "
                  />*/}
                  Total hours reported within the month:
                  <strong>
                    {scheduler.totalTimeInMonth.hours}h{" "}
                    {scheduler.totalTimeInMonth.minutes}m
                  </strong>
                </span>
              </div>
              <hr />
              <div className="remainingDaysOf">
                {permissions.includes('view-available-days-off') ? (
                  <p>
                    <HolidaysIcon /> You have used <b>{usedDays}</b> out of <b>{totalDays}</b>{" "}
                    vacation days (<b>{availableDays ? availableDays : 0}</b> remaining).
                    <br />
                    {/*Closest expiration date is <b>July '22</b>.*/}
                    <VacationsModal
                      buttonText={
                        <span
                          style={{
                            color: "#16448a",
                            textDecoration: "underline",
                          }}
                        >
                          {/*<FormattedMessage
                            id="scheduler.modal.vacation.footer"
                            defaultMessage="See more details"
                          />*/}
                          See more details
                        </span>
                      }
                      context={context}
                      name={name}
                      selectedUser={scheduler.selectedUser}
                      availableDays={availableDays}
                      setAvailableDays={setAvailableDays}
                      totalDays={totalDays}
                      setTotalDays={setTotalDays}
                      usedDays={usedDays}
                      setUsedDays={setUsedDays}
                    />
                  </p>
                ) : null}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="tickler">
          <Tickler
            prevMonth={prevMonth}
            nextMonth={nextMonth}
            handlePrevMonth={handlePrevMonth}
            handleNextMonth={handleNextMonth}
            context={context}
            items={scheduler.timesheets}
            alerts={scheduler.alerts}
            personalHolidays={scheduler.personalHolidays}
            globalHolidays={scheduler.globalHolidays}
            regionHolidays={scheduler.regionHolidays}
            localHolidays={scheduler.localHolidays}
            daysOff={scheduler.userDaysOff}
            eventsGlobal={scheduler.companyEvents}
            eventsRegion={scheduler.companyUserEventsByRegion}
            eventRegion2={scheduler.companyEventsByRegion}
            eventsGlobalContext={scheduler.companyGlobalEvents}
            selectedMonth={selectedMonth}
            refreshTask={function () {
              handleUpdate();
            }}
            name={name}
            local={local}
            region={region}
          />
        </div>
      </div>
      <div className="version">
        <p className="versionText">{currentVersion["Current version"]}</p>
      </div>
    </div>
  );
};

export default SchedulerLayout;
