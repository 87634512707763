import PropTypes from "prop-types";

/**
 * @description This component is used to shorten the burden of creating <a> tags.
 * @param children: JSX elements within the <Link> </Link> tags.
 * @param target: by default "_blank".
 * @param to: href value.
 * @param rel: by default "noreferrer"
 * @returns {JSX} WelcomeGlobal
 */

function Link({
  children,
  target = "_blank",
  to,
  rel = "noreferrer",
}) {
  return (
    <a href={to} rel={rel} target={target}>
      {children}
    </a>
  );
};

Link.propTypes = {
  children: PropTypes.any,
  target: PropTypes.string,
  to: PropTypes.string.isRequired,
  rel: PropTypes.string
};

export default Link;
