import { environment } from "../../config/environment";

const roleService = {

  getRoleByName: async (token, roleName) => {

    try {

      var myHeaders = new Headers();
      myHeaders.append("Authorization", token);

      var raw = "";

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      const response = await fetch(`${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/roles/?role=${roleName}"`, requestOptions)
        .then(response => response.json())
        .catch(error => console.log('error', error));

      if (response.status === 401) {

        throw Object.assign(new Error(), { code: 401, message: "Unauthorized" });

      };

      const data = await response;

      return data.data;

    }
    catch (error) {

      if (!error.code) error.code = 404

      if (!error.message) error.message = "Could not retrieve user information after logging in";

      throw error;

    };
  },
};

export { roleService };
