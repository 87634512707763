import Apps from "../Apps/Apps";
import React, {useContext} from "react";
import Logo from "../Logo/Logo";
import Notifications from "../Notification/Notifications";
import Profile from "../Profile/Profile";
import ClockComponent from "../Clock/ClockComponent";
import "./Topbar.scss";
import MyTicklerTopBar from "../../MyCalendar/Tickler/MyTicklerTopBar";
import { Context } from "../LanguagueSelector/Wrapper"

/**
 * @module
 * @description This function use different components and gather them into a single one called the topBar , that is used in the whole webpage..
 * @returns {JSX} Topbar
 */
const Topbar = () => {

  const context = useContext(Context);

  return (
    <div className="topbarContainer">
      <div className="leftContainer">
        {/* With the react tooltip you can change the tooltips of the icons. */}

        <Apps />
      </div>

      <div className="midContainer">
        <Logo />
      </div>

      <div className="rightContainer">
        {/*test button change language, don't remove it,
         It'll be implement in the future*/
        /* <button className="btnLang" onClick={() => switchLanguage()}>
          <FormattedMessage id="general_switch_language" />{' '}
        </button> */}

        {/* <div className="languageSelector">
          <select value={context.locale} onChange = {context.selectLang} >
            <option value="es-ES">Spanish</option>
            <option value="en-EN">English</option>
          </select>
        </div> */}
        
        <div className="clockIcon">
          < ClockComponent />
        </div>

        <div className="ticklerIcon">
          <MyTicklerTopBar />
        </div>

        <div className="profileIcon">
          <Profile />
        </div>

      </div>
    </div>
  );
};

export default Topbar;
