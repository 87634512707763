import axios from 'axios';
import PropTypes from 'prop-types';
import { environment } from '../../../config/environment';

/**
 * @function
 * @description This service calls the API in order to a motivational phrase from the BE.
 * @param {String} location: user's location as a queryParam.
 * @param {String} region: user's region as a queryParam.
 * @param {String} token: user's token as an authorization header.
 * @returns {Object} returns the current weather given the user's location and region.
 */

async function getWeatherInfo(location, region, token) {
  try {
    if (location) {
      if (location === 'Tepatitlan') location = 'Tepatitlan de Morelos';

      const response = await axios.get(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module0/company/locations/weather?locationName=${location}&regionName=${region}`,
        {
          headers: {
            Authorization: `${token ? token : ''}`,
          },
        }
      );

      return response.data.data;
    }
  } catch (error) {
    throw error.response || error.toJSON() || error;
  }
}

getWeatherInfo.propTypes = {
  location: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
};

export { getWeatherInfo };
