import React, { useState, useEffect } from 'react';
import { capitalLetters } from '../../utils/Projects/capitalLetters';
import './Players.scss';
import { PlayerFloatMenu } from '../shared/PlayerFloatMenu';
import AddPlayerModal from './AddPlayerModal';
import { getMoodsIcon } from '../../utils/getMoodsIcon';
import { environment } from '../../config/environment';
import ReactTooltip from "react-tooltip";

/**
 * @module
 * @description This function create the structure and the logic of the player
 * @param {string} playerInfo get info player
 * @param {string} left
 * @param {string} index
 * @param {string} blackWhite
 * @param {string} projectId  get project id
 * @param {string} fetchingData get fetching data
 * @returns {JSX} Player
 */

const Player = ({
  playerInfo,
  playerInfo: { photo, lastName, firstName, projectRole, id, moodId },
  left,
  index,
  blackWhite,
  projectId,
  refetch,
}) => {
  const [outline, setOutline] = useState('');
  const [floatMenuOpen, setFloatMenuOpen] = useState(false);
  const [playerModalOpen, setPlayerModalOpen] = useState(false);
  const [modalType, setModalType] = useState(''); // it could be to edit or relocate a player

  let userPhoto = `${environment.S3_ROOT}${environment.S3_BUCKET}/${environment.S3_FOLDER}/${photo}`;
  if (photo?.slice(0, 4) === 'http') {
    userPhoto = photo;
  }

  useEffect(() => {
    roleOutline();
  });

  /**
   * @description handle Float Menu
   */
  const handleFloatMenu = () => {
    setFloatMenuOpen(!floatMenuOpen);
    blackWhite(floatMenuOpen, playerInfo);
  };

  /**
   * @description role Outline
   */
  const roleOutline = () => {
    let roleLower = projectRole?.toLowerCase();
    let leadRole = roleLower?.includes('lead');
    if (projectRole === 'Project Management') {
      setOutline('2px solid #5ac674');
    } else if (leadRole) {
      setOutline('2px solid #171f5e');
    } else {
      setOutline('none');
    }
  };

  /**
   * @description handle Player Modal
   */
  const handlePlayerModal = (type) => {
    setModalType(type);
    setPlayerModalOpen(!playerModalOpen);
  };

  return (
    <>
      <AddPlayerModal
        type={modalType}
        show={playerModalOpen}
        handlePlayerModal={handlePlayerModal}
        playerId={id}
        projectId={projectId}
        callback={refetch}
      />
      <div
        className="foo"
        style={{
          zIndex: `${floatMenuOpen ? '100' : index}`,
          left: `${left}px`,
        }}
      >
        {!photo ? (
          <div
            className="capital-letters"
            style={{ border: `${outline}` }}
            onClick={handleFloatMenu}
            data-tip="Player"
            data-for="player"
          >
            {capitalLetters(firstName, lastName)}
          </div>
        ) : (
          <img
            src={userPhoto}
            alt="img-user"
            className="img-player"
            style={{ border: `${outline}` }}
            onClick={handleFloatMenu}
            data-tip="Player"
            data-for="player"
          />
        )}
        <ReactTooltip id="player" place="bottom" backgroundColor="#3FAA58"/>
        <div className="player-float">
          <PlayerFloatMenu
            floatMenuOpen={floatMenuOpen}
            handleFloatMenu={handleFloatMenu}
            firstname={firstName}
            lastname={lastName}
            playerId={playerInfo.id}
            refetch={refetch}
            projectId={projectId}
            handlePlayerModal={handlePlayerModal}
          />
        </div>
        <div className="player-mood" data-tip="Player's mood" data-for="player-mood">
          {getMoodsIcon(moodId)}
        </div>
        <ReactTooltip id="player-mood" place="bottom" backgroundColor="#3FAA58"/>
      </div>
    </>
  );
};

export default Player;
