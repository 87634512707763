import React from "react";
import docLogo from "../assets/icons/doc-icon.svg"
import jpgLogo from "../assets/icons/jpg-icon.svg"
import pdfogo from "../assets/icons/pdf-icon.svg"
import pptLogo from "../assets/icons/ppt-icon.svg"
import xlsLogo from "../assets/icons/xls-icon.svg"
import zipLogo from "../assets/icons/zip-icon.svg"
const FileIcon = ({ fileType }) => {
    const fileIcons = {
        "doc": docLogo,
        "jpg": jpgLogo,
        "pdf": pdfogo,
        "ppt": pptLogo,
        "xls": xlsLogo,
        "zip": zipLogo,
        "png": jpgLogo
    }

    return (
        <img src={fileIcons[fileType]} alt="Document Icon"></img>
    );
}

export default FileIcon;