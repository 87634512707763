import
  React, {useContext, useState, useEffect} from "react";
import TimeLogModal from "./TimeLogModal";
import HolidayModal from "./HolidayModal";
import DayOffModal from "./DayOffModal";
import EventModal from "./EventModal";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "../SchedulerLayout/scheduler.scss";
import "../PopOver/popOver.scss";
import ProjectsContext from "../../../context/ProjectsContext/ProjectsContext";
import {useAuthContext} from "../../../context/AuthContext";
import getUserByName from "../services/getUserByName";
import {getLocationsAndRegions} from "../services/getLocationsAndRegions";
import {useSchedulerContext} from "../../../context";

/**
 * @module
 * @description Modal container that displays the selected form
 * @param {Object} obj - An Object
 * @param {Object[]} obj.refreshTask - Function that increases the value of the timesheet counter by one
 * @param {Function} obj.count - Timesheet counter
 * @param {} obj.myOnClose
 * @param {string} obj.selectedDate
 * @param {} obj.buttonText
 * @returns {JSX} ModalContainer
 */
function ModalContainer({
  contexts,
  refreshTask,
  count,
  myOnClose,
  selectedDate,
  type,
  buttonText,
  modalDisplayed,
  popOn,
  firstBillingPeriodModal,
  name,
}) {
  const schedulerContext = useSchedulerContext();
  const { getUserProjectsByUserId, auth } = useContext(ProjectsContext);
  const [show, setShow] = useState(false);
  const [entryType, setEntryType] = useState("1");
  const { context, user } = useAuthContext();
  const [projects , setProjects] = useState([]);
  const [permissions,setPermissions] = useState([]);
  const authContext = useAuthContext();
  const availableModals = {
    "1": function (props) {
      return <TimeLogModal {...props} projectsDB={projects} />;
    },
    "2": function (props) {
      return <HolidayModal {...props} />;
    },
    "3": function (props) {
      return <DayOffModal {...props} />;
    },
    "4": function (props) {
      return <EventModal {...props} />;
    },
  };

  const handleClose = () => {
    if (popOn !== undefined) {
      modalDisplayed();
      popOn(false);
    }
    setShow(false);
    if (myOnClose) myOnClose();
  };

  const handleShow = async () => {
    if (popOn !== undefined) {
      modalDisplayed();
    }
    setShow(true);

    if(contexts === 'Personal'){
      let personalPermissions = [];
      personalPermissions = await getPersonalPermissions();
      if (personalPermissions.includes('log-hours')) {
        setEntryType("1");
      } else {
        setEntryType("3");
      }
      setPermissions(personalPermissions);
    }

    if(name === 'name' && contexts !== 'Personal' && name !== contexts && contexts !=='Global'){
      setEntryType("2");
    }

    if(contexts ==='Global' && name === 'name'){
      setEntryType("2");
    }

    if(contexts === name && name !== 'name')
    {
      let employeeContextPermissions = [];
      employeeContextPermissions = await getEmployeeContextPermissions();
      if (employeeContextPermissions.includes('log-hours')) {
        setEntryType("1");
      } else {
        setEntryType("3");
      }
      setPermissions(employeeContextPermissions);
    }
  };

  const handleChangeModalType = (e) => {
    setEntryType(e.target.value);
  };

  useEffect(()=>{
    const query = async ()=> {
      await getUserProjectsByUserId(auth.user.id).then(data =>{
        setProjects(data.data.data)
      });
    }
    query();
    return() => {
      setProjects([]);
    }
  },[])

  const getPersonalPermissions = () => {
    let permissionsArray = [];
    let permission = user.permissions.userPermissions;

    for (const permissionElement of permission) {
      if(permissionElement.locations && permissionElement.locations.name === auth.userInfo.location) {
        permissionsArray.push(permissionElement.permission.name);
      }
    }
    return [...permissionsArray];
  }

  const getEmployeeContextPermissions = async () => {
    let permissionsByLocation = [];
    let permission = user.permissions.userPermissions;

    let locationFound = [];
    let regionFound = [];
    const selectedUser = await getUserByName(name, auth.user.token);

    if(selectedUser) {
      const locationsAndRegions = await getLocationsAndRegions(auth.user.token);
      locationsAndRegions.forEach(region => {
        region.locations.forEach(location => {
          if(location.id === selectedUser.companyLocationId) {
            locationFound = location;
            regionFound = region;
          }
        })
      });
    }

    for (const permissionElement of permission) {
      if(permissionElement.locations && permissionElement.locations.name === locationFound.name) {
        permissionsByLocation.push(permissionElement.permission.name);
      } else if(permissionElement.regions && permissionElement.regions.name === regionFound.name) {
        permissionsByLocation.push(permissionElement.permission.name);
      }
    }
    return [...permissionsByLocation];
  }

  return (
    <>
      <button className="btn-addTime" onClick={handleShow}>
        {buttonText}
      </button>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="timesheetModal"
      >
      {availableModals[entryType]({
        refreshTask, count, selectedDate, handleClose, entryType, handleChangeModalType, contexts, firstBillingPeriodModal, permissions
      })}
      </Modal>
    </>
  );
}

export default ModalContainer;
