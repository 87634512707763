import { useContext, useEffect, useState } from "react";
import {
  ProjectHeader,
  Shortcuts,
  TeamAndRol,
  ProjectOverview,
  HowWeWork,
  LatestUpdates,
  ProjectFiles,
  ProjectDetail,
} from "../../components/Projects";
import "./ProjectById.scss";
import ProjectsContext from "../../context/ProjectsContext/ProjectsContext";
import { useParams } from "react-router-dom";
import data from "../../version.json";
import WhatIsNextProjects from "../../components/Projects/WhatIsNextProjects";
import {useAuthContext} from "../../context";

/**
 * @description Create the main structure for the individual project view
 * @returns {JSX} ProjectById
 */

//Layout definition
const ProjectById = () => {
  const [project, setProject] = useState({}); //data is restored here
  const [loading, setLoading] = useState(false); //implement a spinner
  let version = data;
  let currentVersion = version[version.length - 1];
  const { user } = useAuthContext();
  const auth = useAuthContext();
  const [permissions,setPermissions] = useState([]);
  const [filterSort, setFilterSort] = useState({
    column: "",
    order: "desc",
  });
  const [filterSearch, setFilterSearch] = useState("");
  const [page, setPage] = useState(1);
  const [archivedProject, setArchivedProject] = useState(false);
  let { id } = useParams();

  useEffect(() => {
    const regex = new RegExp(/^[0-9]*$/);
    const verify = regex.test(id);
    if (verify === false) {
      setLoading(true);
    }
  });

  useEffect(() => {
    setLoading(true);
    getProjectDetail(id)
      .then((res) => setProject(res.data.data), setLoading(false))
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const {
    getTeamAndRoles,
    // getProjectOverview,
    getProjectHeader,
    getLatestUpdates,
    getShortcuts,
    postShortcuts,
    getProjectDetail,
    getProjectsByUser
  } = useContext(ProjectsContext);

  useEffect(() => {
    let permission = user.permissions.userPermissions;
    let permissionsArray = [];
    for (const permissionElement of permission) {
      if(permissionElement.locations && permissionElement.locations.name === auth.userInfo.location) {
        permissionsArray.push(permissionElement.permission.name);
      }
    }
    setPermissions([...permissionsArray])
  },[]);

  useEffect(async () => {
    const filters = {
      archived: 1,
      st_onTrack: 1,
      st_inDanger: 1,
      st_atRisk: 1,
      st_outdated: 1,
      st_archived: 1,
      sortBy: filterSort.column,
      sortOrder: filterSort.order,
      page: page,
      search: filterSearch,
    };

    getProjectsByUser(filters)
      .then((response) => {
        const data = response?.data?.data?.data ? response.data.data.data : {};
        data.forEach(item => {
          if(item.projectId === project.id && item.projectIsArchived) {
            setArchivedProject(true);
          }else {
            setArchivedProject(false);
          }
        })
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [project])

  return (
    <>
      {loading ? (
        <h1>Page not found</h1>
      ) : (
        <div className="main-container">
          <header>
            <div className="main-header">
              <ProjectHeader
                getLatestUpdates={getLatestUpdates}
                getProjectHeader={getProjectHeader}
                id={id}
              />
            </div>
          </header>
          <div className="container-1-2">
            <div className="flex-1">
              <div className="project-overview">
                <ProjectOverview
                  id={id}
                  getProjectDetail={getProjectDetail}
                  archivedProject={archivedProject}
                />
              </div>
              <div className="how-we-work">
                <HowWeWork
                  id={id}
                  getProjectDetail={getProjectDetail}
                  archivedProject={archivedProject}
                />
              </div>
              {permissions.includes('view-roles') ?
              <div className="team-roles">
                <TeamAndRol id={id} getTeamAndRoles={getTeamAndRoles} archivedProject={archivedProject}/>
              </div>
              :null}
              {permissions.includes('view-public-files') ?
              <div className="project-files">
                <ProjectFiles id={id} archivedProject={archivedProject}/>
              </div> :null}
            </div>
            <div className="flex-2">
              {/*{permissions.includes('view-public-updates') ?*/}
              {/*<div className="latest-updates">*/}
              {/*  <LatestUpdates*/}
              {/*    id={id}*/}
              {/*    getLatestUpdates={getLatestUpdates}*/}
              {/*    getTeamAndRoles={getTeamAndRoles}*/}
              {/*    archivedProject={archivedProject}*/}
              {/*  />*/}
              {/*</div> : null}*/}
              <div className="Project-details">
                <ProjectDetail id={id} getProjectDetail={getProjectDetail} archivedProject={archivedProject}/>
              </div>
              <div className="Project-links">
                <Shortcuts id={id} getShortcuts={getShortcuts} postShortcuts={postShortcuts} archivedProject={archivedProject}/>
              </div>
              <div className="whatsNextProjects">
                <WhatIsNextProjects region={"global"}/>
              </div>
            </div>
          </div>
          <div className="version">
            <p className="versionText">{currentVersion["Current version"]}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectById;
