import React, { useState, useEffect } from 'react';
import WorkCard from './WorkCard';
import Modal from 'react-bootstrap/Modal';
import DayOffModal from '../Modal/DayOffModal';
import { DangerTriangleIcon } from '../../Icons';
import ModalContainer from '../Modal/ModalContainer';
import { FaSun } from 'react-icons/fa';
import { GiPartyPopper } from 'react-icons/gi';

// language switcher feature
import { FormattedMessage } from 'react-intl';
import {useAuthContext} from "../../../context";

const moment = require('moment');

/**
 * @module
 * @description this component shows a card with the user's timesheets
 * @param {Object} obj - An Object
 * @param {Object} obj.item
 * @param {Object[]} obj.yellowAlerts
 * @param {Function} obj.refreshTask - Function that increases the value of the timesheet counter by one
 * @param {boolean} obj.isTopTickler
 * @returns {JSX} DayCard
 */

const DayCard = ({ item, yellowAlerts, refreshTask, isTopTickler }) => {
  let [totalTime, setTotalTime] = useState(0);
  const [showHolidayModal, setShowHolidayModal] = useState(false);
  const [itemSortedByHour, setItemSortedByHour] = useState(item);
  const [permissions,setPermissions] = useState([]);
  const { user } = useAuthContext();

  const handleShowHolidayModal = () => {
    setShowHolidayModal(true);
  };

  const hideHolidayModal = () => {
    setShowHolidayModal(false);
  };

  useEffect(() => {
    let total = 0;
    if (item.descriptions) {
      item.descriptions.map((element) => {
        if (element.isFinished !== false) {
          total += element.dedicatedTime
            ? element.dedicatedTime
            : element.dedicatedHours * 3600;
        }
      });
      setTotalTime(total);
    }
  }, [item.descriptions]);

  // Convert from seconds to hours and minutes
  const time = totalTime / 3600;
  let dedicatedMinutes,
      dedicatedHours;
  if (time < 1) {
    dedicatedHours = 0;
    dedicatedMinutes = time * 60
  } else {
    dedicatedHours = Math.trunc(time);
    dedicatedMinutes = (time - dedicatedHours) * 60;
    dedicatedMinutes = Math.trunc(dedicatedMinutes);
  }

  useEffect(() => {
    if (item.descriptions) {
      let descriptionSorted = item.descriptions;
      descriptionSorted = descriptionSorted.map((task) => {
        task.startHour = task.startHour;
        return task;
      });

      descriptionSorted = descriptionSorted
        .sort((a, b) => {
          if (a.startHour < b.startHour) {
            return -1;
          }
          if (a.startHour > b.startHour) {
            return 1;
          }
          return 0;
        })
        .reverse();

      descriptionSorted = descriptionSorted.map((task) => {
        task.startHour = task.startHour;
        return task;
      });

      let lastProject;
      let isFirst = true;
      descriptionSorted = descriptionSorted
        .reverse()
        .map((task) => {
          if (isFirst) {
            lastProject = task.projectId;
            isFirst = false;
          } else {
            lastProject = task.projectId;
          }
          return task;
        })
        .reverse();

      const itemSorted = item;
      itemSorted.descriptions = descriptionSorted;
      setItemSortedByHour(itemSorted);
    }
  }, [item]);

  let elementDate = moment.utc(item.date ? item.date.toString().slice(0,10) : item.start).format(
    'YYYY-MM-DD'
  );
  let isAlert = yellowAlerts
    ? yellowAlerts.find((event) => event.start === elementDate)
    : null;
  item.alert = isAlert ? (
    <div className="day-alert">
      {isAlert.title.charAt(0) === 'H' ? (
        <div>
          <div>
            <span style={{ color: '#1a9ee3', fontSize: '18px' }}>
              {isAlert.type === 'Holiday' ? <GiPartyPopper /> : <FaSun />}
            </span>
            &nbsp;
            {isAlert.reason.indexOf("=") > 0? 'Happy ' + isAlert.reason.split('=')[1]: isAlert.reason}
          </div>
          <span style={{ fontSize: '16px' }}>
            <DangerTriangleIcon fillColor="#ef9325" />
          </span>
          {isAlert.title}
          {showHolidayModal &&
          elementDate >= moment.utc().subtract(30, 'd').format('YYYY-MM-DD') ? (
            <Modal
              show={showHolidayModal}
              onHide={hideHolidayModal}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              className="timesheetModal"
            >
              <DayOffModal
                handleClose={hideHolidayModal}
                selectedDate={elementDate}
              />
            </Modal>
          ) : null}
          {!isTopTickler ? (
            <button
              onClick={handleShowHolidayModal}
              className="holiday-move-btn"
            >
              request a Holiday Move
            </button>
          ) : null}
        </div>
      ) : (
        <>
          {/*<FormattedMessage id="alert.lesshours"
          defaultMessage="Reported hours do not complete your daily allocation" />*/}
          Reported hours do not complete your daily allocation
          &nbsp;
          {elementDate >= moment.utc().subtract(30, 'd').format('YYYY-MM-DD') &&
          !isTopTickler ? (
            <ModalContainer
              refreshTask={refreshTask}
              selectedDate={elementDate}
              buttonText={
                <span style={{ color: '#16448a', textDecoration: 'underline' }}>
                  {/*<FormattedMessage id="scheduler.tickler.add.hours" 
                  defaultMessage="Add missing entries now"/>*/}
                  Add missing entries now
                </span>
              }
            />
          ) : null}
        </>
      )}
    </div>
  ) : null;

  useEffect(()=>{
    let permission = user.permissions.userPermissions;
    let permissionsArray = []
    for (const permissionElement of permission) {
      permissionsArray.push(permissionElement.permission.name);
    }
    setPermissions([...permissionsArray])
  },[])

  return (
    <div className={`day-card ${item.alert ? 'missing-hours-day' : ''}`}>
      <div className="day-header">
        <div className="day-title">
          {moment.utc(item.date ? item.date.toString().slice(0,10) : item.start).format('ddd, DD')}
        </div>
        <div className="day-total">
          {/*<FormattedMessage id="txt.total.hours"
          defaultMessage="Total" />*/}
          Total
          {" " + dedicatedHours + "h" + ":" + dedicatedMinutes + "m"}
        </div>
      </div>
      {item.alert ? item.alert : null}

      {itemSortedByHour.descriptions ? (
        itemSortedByHour.descriptions.map((element) => {
          if (element.isFinished !== false) {
            return (
              permissions.includes('view-reported-hours') ?
                  <>
                    <WorkCard
                      description={element}
                      date={moment.utc(itemSortedByHour.date.toString().slice(0,10)).format('YYYY-MM-DD')}
                      refreshTask={refreshTask}
                      isTopTickler={isTopTickler ? isTopTickler : false}
                    />
                  </>
              : <> </>
            );
          }
        })
      ) : (
        <div>
          <span style={{ color: '#1a9ee3', fontSize: '18px' }}>
            {item.type === 'Holiday' ? <GiPartyPopper /> : <FaSun />}
          </span>
          &nbsp;
          {item.ticklerTitle!==null && item.ticklerTitle!==undefined && item.ticklerTitle.indexOf("=") > 0? item.ticklerTitle.split('=')[1]: (item.title=== null || item.title===undefined)?item.ticklerTitle: item.title}: {item.reason.toLowerCase()}
        </div>
      )}
      {(dedicatedHours == 8 && dedicatedMinutes > 30) || dedicatedHours >= 9 ? (
        <div className="exceededHoursWarning">
          <span style={{ fontSize: '16px', margin:'0 5px 0 5px'}}>
            <DangerTriangleIcon fillColor="#ef9325" />
          </span>
          <span style={{ fontSize: '15px'}}>
            {/*<FormattedMessage id="scheduler.tickler.hours.exceeded" defaultMessage="Reported hours exceed your daily allocation"/>*/}
            Reported hours exceed your daily allocation
          </span>
        </div>
      ) : null}
    </div>

  );
};

export default DayCard;
