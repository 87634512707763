import axios from "axios";
import { environment } from "../../../config/environment";

const moment = require("moment");

const userTimesheetsService = {
  modify: async (userTimesheetsData, userId, token) => {
    try {
      const data = await axios.put(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/users/${userId}/timesheet`,
        userTimesheetsData,
        {
          headers: {
            authorization: token,
          },
        }
      );
      return data;
    } catch (err) {
      throw (
        err.response || err.toJSON() || {
          success: false,
          message: "An error has occurred, please try again",
        }
      );
    }
  },
};

const editTimesheet = async (data, userId, token, id) => {
  try {
    let tags = undefined;

    if (data.tags?.length) {
      tags = data.tags.join(",");
    };
  
    let time = null;
    time = moment.utc(data.totalTime, "HH:mm:ss: A").diff(
      moment.utc().startOf("day"),
      "seconds"
    );
    const descriptionFormatted = {
      id: id,
      dedicatedHours:
        parseInt(data.workTimeEnd.slice(0, 2)) -
        parseInt(data.workTimeStart.slice(0, 2)),
      task: data.task,
      isHappy: data.feeling === "true" ? true : false,
      note: data.note || "No Comments",
      startHour: data.workTimeStart.length < 6 ? data.workTimeStart + ":00" : data.workTimeStart,
      endHour: data.workTimeEnd.length < 6 ? data.workTimeEnd + ":00" : data.workTimeEnd,
      isFinished: true,
      dedicatedTime: time,
    };
    if (tags) descriptionFormatted.tags = tags;
    return userTimesheetsService.modify(descriptionFormatted, userId, token);
  } catch (error) {
    throw ( error );
  }

};

export { editTimesheet };
