import React from "react";
/**
 * @description Function to create the email icon as a JSX component
 * @returns {JSX} EmailIcon
 */
const EmailIcon = () => {
  return (
    <svg
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.809.082C10.048 0 9.088 0 7.864 0H6.47C5.245 0 4.286 0 3.524.082c-.777.085-1.406.26-1.944.65a3.833 3.833 0 0 0-.848.848 2.92 2.92 0 0 0-.279.472c-.24.51-.349 1.103-.401 1.81C0 4.561 0 5.421 0 6.48v.05c0 1.224 0 2.183.082 2.945.085.777.26 1.406.65 1.944.237.325.523.611.848.848.538.39 1.167.565 1.944.65C4.286 13 5.245 13 6.47 13h1.394c1.224 0 2.184 0 2.945-.082.777-.085 1.407-.26 1.944-.65a3.83 3.83 0 0 0 .848-.848c.39-.538.566-1.167.65-1.944.082-.762.082-1.721.082-2.946v-.05c0-1.066 0-1.93-.053-2.634-.053-.71-.163-1.303-.41-1.816a2.906 2.906 0 0 0-.269-.45 3.833 3.833 0 0 0-.848-.848c-.537-.39-1.167-.565-1.944-.65zm-8.641 1.46c.339-.247.772-.39 1.464-.465C4.333 1 5.239 1 6.5 1h1.333c1.261 0 2.167 0 2.868.077.692.075 1.126.218 1.464.464.24.175.452.386.627.627l-1.265 1.264C10.407 4.554 9.6 5.358 8.905 5.888c-.684.522-1.201.731-1.738.731-.537 0-1.055-.209-1.739-.73-.695-.531-1.5-1.335-2.622-2.457L1.541 2.168c.175-.24.387-.452.627-.627zm11.023 1.64c.04.214.07.457.092.74.05.661.05 1.488.05 2.578 0 1.261 0 2.167-.076 2.868-.075.692-.219 1.125-.465 1.464-.175.24-.386.452-.627.627-.338.246-.772.39-1.464.464C10 12 9.094 12 7.833 12H6.5c-1.261 0-2.167 0-2.868-.077-.692-.075-1.125-.218-1.464-.464a2.835 2.835 0 0 1-.627-.627c-.246-.339-.39-.772-.464-1.464C1 8.667 1 7.761 1 6.5c0-1.082 0-1.905.05-2.565.02-.288.05-.536.092-.753l.984.985c1.088 1.088 1.941 1.94 2.696 2.516.773.59 1.501.936 2.345.936.843 0 1.572-.346 2.344-.936.755-.575 1.608-1.428 2.696-2.516l.984-.985z"
        fill="#B0B0B0"
      />
    </svg>
  );
};

export default EmailIcon;
