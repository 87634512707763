/**
 * Parses a date string form DB into a UTC date
 * @param {String} dateStringFromDB date like "YYYY-MM-DD"
 * @returns
 */
export default function (dateStringFromDB) {
  const data = dateStringFromDB.split("-")
  let utcDate = new Date(Date.UTC(data[0], data[1]-1, data[2]));
  const timezoneOffset = utcDate.getTimezoneOffset() * 60 * 1000 // Offset minutes, then parsed as seconds and miliseconds.
  utcDate = utcDate.getTime() + timezoneOffset;
  return new Date(utcDate)
}