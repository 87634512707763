/**
 * @description Function to create the Checked icon as a component
 * @returns {JSX} CheckedIcon
 */
function CheckedIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="#3FAA58"
        d="M10.62 5.372a.488.488 0 00-.728-.651L7.698 7.17c-.444.497-.744.83-1 1.045-.244.204-.387.25-.512.25s-.268-.046-.512-.25c-.256-.216-.555-.548-1-1.045l-.566-.632a.488.488 0 00-.728.652l.59.659c.415.462.76.848 1.075 1.113.334.28.694.479 1.141.479.447 0 .807-.198 1.14-.479.316-.265.662-.65 1.076-1.113l2.218-2.478z"
      ></path>
      <path
        fill="#3FAA58"
        fillRule="evenodd"
        d="M7 0a7 7 0 100 14A7 7 0 007 0zM.977 7a6.023 6.023 0 1112.046 0A6.023 6.023 0 01.977 7z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default CheckedIcon;
