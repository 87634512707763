export const sidebarTopbarTutorial = (name) => {
  let arreglo = [
    {
      father: {
        name: "",
        style: "",
      },
      querySelector: "",
      title: `Nice to e-meet you, ${name}`,
      subtitle: "I am your Blue Trail digital companion ",
      description:
        "I am here to help you stay in-touch with what is going on at the company, at your location, at your project(s), and to streamline the internal processes so you can focus on what\u00A0matters.\n\nNext, I will show you some key futures so you can know me\u00A0better...",
      styles: {
        backgroundColor: "red",
        zIndex: 99,
        width: "100%",
      },
      position: "center",
    },

    {
      father: {
        name: ".topbar",
        style: {
          zIndex: 52,
        },
      },
      querySelector: ".leftContainer :nth-child(1)",
      title: "I am the best, but we also use other internal tools",
      description:
        "On the top-left corner will find quick access to the other internal tools available to\u00A0you.",
      styles: {
        height: "70px",
        backgroundColor: "#171F5E",
        borderRadius: "8px",
        padding: "21px 35px 25px 35px",
        margin: "0px -15px",
        stroke: "white",
        strokeWidth: "0.5",
        zIndex: 99,
      },
      posistion: "left",
    },

    {
      father: {
        name: ".sidebar ",
        style: {
          zIndex: 51,
        },
      },
      querySelector: "#sideBarGlobalLocalContainer",
      title: "Stay tuned and in touch",
      description:
        "\nBe aware of what is going on globally and locally, and what is coming up\u00A0next.\n\nAlso, review the list of the Titans and managers; you can contact them at any moment if you have questions or\u00A0concerns.\n",
      styles: {
        backgroundColor: "#171F5E",
        padding: "0px 15px 0px 15px",
        borderRadius: "8px",
        marginLeft: "-15px",
        stroke: "white",
        strokeWidth: "1",
        zIndex: 99,
      },
      posistion: "left",
    },

    {
      father: {
        name: ".sidebar ",
        style: {
          zIndex: 51,
        },
      },
      querySelector: "#scheduler",
      title: "Plan your scheduler and log your working time",
      description:
        "Review your available time off and plan it ahead to ensure it is enjoyable for you and your\u00A0teammates.\n\nReport your worked time so we can invoice our clients properly and accurately, this is very important, take it\u00A0seriously!\n\nI will show you the Tickler in a minute, a tool to help you log your worked time with more\u00A0ease.\n",
      styles: {
        backgroundColor: "#171F5E",
        padding: "0px 15px 0px 15px",
        borderRadius: "8px",
        marginLeft: "-15px",
        stroke: "white",
        strokeWidth: "0.5",
        zIndex: 99,
      },
      posistion: "left",
    },

    {
      father: {
        name: ".sidebar",
        style: {
          zIndex: 51,
        },
      },
      querySelector: "#projects",
      title: "Know and keep up with your project(s)",
      description:
        "Here you can know more about your project(s) you have been assigned to, including how the team works, the technology stack, teammates, and many\u00A0more.\n\nProject updates will be posted frequently so you can keep up with your day-to-day\u00A0work.\n",
      styles: {
        backgroundColor: "#171F5E",
        padding: "0px 15px 0px 15px",
        borderRadius: "8px",
        marginLeft: "-15px",
        stroke: "white",
        strokeWidth: "0.5",
        zIndex: 99,
      },
      posistion: "left",
    },

    {
      father: {
        name: ".topbar",
        style: {
          zIndex: 51,
        },
      },
      querySelector: ".rightContainer :nth-child(1)",
      title: "Let me help you coordinate globally",
      description:
        "Our family is distributed across the globe, we interact daily with players in different parts of the world, with different\u00A0timezones.\n\nUse the World Clock to help you know others’ times so you can schedule and coordinate\u00A0easily.\n\n",
      styles: {
        backgroundColor: "#171F5E",
        borderRadius: "8px",
        padding: "15px 15px 15px 15px",
        margin: "0px -15px",
        strokeWidth: "1.5",
        stroke: "white",
        zIndex: 99,
        textAlign: "right",
      },
      posistion: "right",
    },

    {
      father: {
        name: ".topbar",
        style: {
          zIndex: 51,
        },
      },
      querySelector: "#tickler",
      title: "Master your time logs without the pain",
      description:
        "Tick your time log automatically, register working time as you go instead of letting it pile\u00A0up.\n\nIt’s simple: start the counter when you start to work on a task; stop it when you\u00A0finish.\n\n",
      styles: {
        backgroundColor: "#171F5E",
        borderRadius: "8px",
        padding: "15px 15px 15px 15px",
        margin: "0px -15px",
        stroke: "white",
        strokeWidth: "1.5",
        zIndex: 99,
      },
      posistion: "right",
    },

    {
      father: {
        name: ".sidebar",
        style: {
          zIndex: 51,
        },
      },
      querySelector: ".box-container",
      title: "I also want to be empowered, help me!",
      description:
        "You can send feedback and suggestions about your experience with me at any\u00A0time.",
      styles: {
        backgroundColor: "#171F5E",
        padding: "15px 15px 15px 15px",
        borderRadius: "8px",
        marginLeft: "-15px",
        stroke: "white",
        strokeWidth: "0.5",
        zIndex: 99,
      },
      posistion: "left",
    },

    {
      father: {
        name: ".topbar",
        style: {
          zIndex: 51,
        },
      },
      querySelector: "#profile",
      title: "Last but not least... you, the star!",
      description:
        "Here you can check and update your personal information and preferences to shape my behaviour to your\u00A0convenience.\n\n",
      styles: {
        background: "#171F5E",
        borderRadius: "8px",
        padding: "10px",
        margin: "-10px",
        stroke: "white",
        strokeWidth: "1",
        zIndex: 99,
      },

      posistion: "right",
    },
  ];

  return arreglo;
};
