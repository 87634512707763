import axios from "axios";
import { environment } from "../../../config/environment";

const getUserTimesheets = (fromDate, toDate, id, token) => {
  return axios
    .get(
      `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/users/${id}/timesheet?fromDate=${fromDate}&toDate=${toDate}`,
      {
        headers: {
          authorization: token,
        },
      }
    )
    .then((result) => {
      const { timesheets } = result.data.data;
      return timesheets;
    })
    .catch(error => {
      throw error.response || error.toJSON() || error
    });
};

export default getUserTimesheets;