/**
 * @description Function to create the Wiki icon as a JSX component
 * @returns {JSX} WikiIcon
 */
const WikiIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.566 3.72a.465.465 0 0 0 0 .931h4.961a.465.465 0 1 0 0-.93H3.566zM3.566 6.202a.465.465 0 0 0 0 .93h4.961a.465.465 0 1 0 0-.93H3.566zM3.566 8.682a.465.465 0 0 0 0 .93h4.961a.465.465 0 1 0 0-.93H3.566z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.815.077C8.106 0 7.214 0 6.075 0h-.057C4.88 0 3.987 0 3.278.077 2.556.155 1.97.317 1.47.68 1.167.901.9 1.167.68 1.47c-.363.5-.526 1.086-.604 1.808C0 3.987 0 4.88 0 6.018v1.297c0 1.139 0 2.032.077 2.74.078.723.24 1.308.604 1.808.22.303.486.57.789.79.5.363 1.086.525 1.808.604.709.076 1.601.076 2.74.076h.057c1.138 0 2.031 0 2.74-.076.722-.079 1.308-.241 1.808-.605.303-.22.57-.486.789-.789.363-.5.526-1.085.604-1.808.077-.708.077-1.601.077-2.74V6.018c0-1.138 0-2.031-.077-2.74-.078-.722-.24-1.308-.604-1.808a3.566 3.566 0 0 0-.789-.789c-.5-.363-1.086-.526-1.808-.604zM2.017 1.434c.315-.23.718-.363 1.362-.432C4.03.93 4.873.93 6.047.93c1.173 0 2.015 0 2.667.072.644.07 1.047.203 1.362.432.224.162.42.359.583.583.23.315.363.718.432 1.362.071.652.072 1.494.072 2.668v1.24c0 1.173 0 2.015-.072 2.668-.07.643-.203 1.047-.432 1.362a2.63 2.63 0 0 1-.583.583c-.315.229-.718.362-1.362.432-.652.07-1.494.071-2.667.071-1.174 0-2.016 0-2.668-.071-.644-.07-1.047-.203-1.362-.432a2.633 2.633 0 0 1-.583-.583c-.23-.315-.363-.719-.432-1.362C.93 9.302.93 8.46.93 7.287v-1.24c0-1.174 0-2.016.072-2.668.07-.644.203-1.047.432-1.362.162-.224.359-.42.583-.583z"
        fill="currentColor"
      />
    </svg>
  );
};

export default WikiIcon;
