function Icon({fillColor}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill={fillColor}
    >
      <path fill-rule="evenodd" 
      clip-rule="evenodd" 
      d="M4.438 7.875c1.99-3.446 2.984-5.169 4.283-5.747a4.375 4.375 0 0 1 3.559 0c1.298.578 2.293 2.301 4.282 5.747 1.99 3.446 2.984 5.169 2.836 6.582a4.375 4.375 0 0 1-1.78 3.082c-1.15.836-3.14.836-7.118.836-3.979 0-5.968 0-7.118-.836a4.375 4.375 0 0 1-1.78-3.082c-.148-1.413.847-3.136 2.836-6.582zM10.5 5.469c.363 0 .656.294.656.656v5.25a.656.656 0 0 1-1.312 0v-5.25c0-.362.294-.656.656-.656zm0 9.406a.875.875 0 1 0 0-1.75.875.875 0 0 0 0 1.75z" 
      fill={fillColor ? fillColor : "#ff0101"} />
    </svg>
  );
}

export default Icon;