import {useIntl} from "react-intl";
import LocationBadge from './LocationBadge';
import React, {useState} from "react";
import Select from "react-select";


export default function SelectAllowedLocation({selectLocations, addLocations, locationsBadge, countries, deleteBadge}){
  const intl = useIntl();
  const [location, setLocation] = useState([]);
  const addLocation =()=>{
    addLocations({name:location.name})
  }

  return(
    <div className="projects-list select-role-container" style={{'width':'100%'}}>
      <h3 className="title-container location-allowed-title">
        Locations allowed
      </h3>
      <div className="project-list-search location-section" style={{'justifyContent':'unset'}}>
        <div className="search-project" style={{'display':'flex', 'width':'350px'}}>
          <div className="employee-list" style={{'display':'flex', 'width':'500px', 'margin': '0  0 20px 0'}}>
            <Select
              id="select"
              options={selectLocations}
              getOptionLabel={(selectLocations) => selectLocations.name}
              getOptionValue={(selectLocations) => selectLocations.id}
              onChange={e=>setLocation(e)}
              placeholder={"Search location"}
              maxMenuHeight={190}
              styles={{
                control:(baseStyles, state)=>({
                  ...baseStyles,
                  marginTop:'15px',
                  minWidth: '255px',
                }),
                menu:(baseStyles, )=>({
                  ...baseStyles,
                  minWidth: '255px'
                })
              }}
            />
          </div>
          <button className="btn-add" onClick={addLocation}>ADD</button>
        </div>
        <LocationBadge locations={locationsBadge} deleteBadge={deleteBadge}/>
      </div>
    </div>
  );
}