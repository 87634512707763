import React from "react";

/**
 * @description BTSLogo without the text as an icon
 * @returns {JSX} BTSLogo
 */
function BTSLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="38"
      fill="none"
      viewBox="0 0 38 38"
    >
      <path
        fill="#171F5E"
        d="M18.88 38c10.426 0 18.878-8.507 18.878-19S29.306 0 18.88 0C8.452 0 0 8.507 0 19s8.452 19 18.88 19z"
      ></path>
      <path
        fill="#86E29E"
        d="M18.88 37.208c3.599.017 7.122-1.038 10.123-3.029 3.001-1.99 5.345-4.828 6.733-8.154a18.233 18.233 0 00-3.889-19.871A18.16 18.16 0 0012.021 2.17a18.189 18.189 0 00-8.173 6.706A18.226 18.226 0 00.778 19a18.184 18.184 0 005.286 12.857 18.134 18.134 0 0012.815 5.351zm0 .792a18.96 18.96 0 01-13.418-5.565A19.013 19.013 0 01-.095 19c0-5.04 2-9.872 5.557-13.435A18.96 18.96 0 0118.88 0c10.433 0 18.866 8.508 18.866 19s-8.433 19-18.866 19z"
      ></path>
      <path
        fill="#5AC775"
        d="M18.88 32c7.132 0 12.915-5.82 12.915-13S26.011 6 18.878 6 5.964 11.82 5.964 19s5.782 13 12.915 13z"
      ></path>
      <path
        fill="#171F5E"
        d="M26.48 9.481s-.528 3.03-14.208 7.067c0 0-3.586 1.23-.79 3.167 0 0 15.827 6.89 17.31 7.917 0 0-4.12 5.225-10.324 4.222A37.888 37.888 0 006.68 22.958s-4.978-4.594 2.043-7.125c7.02-2.53 11.74-2.185 17.424-6.86"
      ></path>
      <path
        fill="#3FAA58"
        d="M18.88 31.231a12.203 12.203 0 006.802-2.032 12.228 12.228 0 004.525-5.476 12.247 12.247 0 00-2.609-13.35A12.21 12.21 0 0014.28 7.694a12.218 12.218 0 00-5.492 4.504A12.243 12.243 0 006.725 19a12.214 12.214 0 003.547 8.637 12.18 12.18 0 008.607 3.594zm0 .768a12.968 12.968 0 01-7.231-2.157 12.995 12.995 0 01-4.812-5.82 13.016 13.016 0 012.77-14.19 12.976 12.976 0 0114.155-2.848A12.985 12.985 0 0129.6 11.77 13.012 13.012 0 0131.792 19a12.977 12.977 0 01-3.769 9.177 12.94 12.94 0 01-9.144 3.822z"
      ></path>
    </svg>
  );
}

export default BTSLogo;
