import "./PlayersMoodTable.scss";
import { MehFaceIcon, SmileFaceIcon, SadFaceIcon } from "../Icons";
import { useContext, useEffect, useState } from "react";
import ProjectsContext from "../../context/ProjectsContext/ProjectsContext";
import { capitalLetters } from "../../utils/Projects/capitalLetters";
import { Mood } from "./Mood";
import { environment } from "../../config/environment";
import { useIntl } from "react-intl";

export const PlayersMoodTable = ({
  players,
  handleClickMood,
  iconMood,
  playerId,
}) => {
  const [moodOptions, setMoodOptions] = useState([]);
  const [moodBg, setMoodBg] = useState(false);
  const { getMoodOptions, auth } = useContext(ProjectsContext);
  const intl = useIntl();
  useEffect(() => {
    getMoodOptions()
      .then(({ data: { data } }) => setMoodOptions(data))
      .catch((err) => {
      });
  }, []);

  const getMoodIcon = (moodId) => {
    const icons = {
      1: <SmileFaceIcon />,
      5: <SadFaceIcon />,
      3: <MehFaceIcon />,
    };

    return icons[moodId] || null;
  };

  return (
    <div className="players-mood-table">
      <h5>Players' mood</h5>
      <table>
        <thead>
          <tr>
            <td>Name</td>
            <td>Role</td>
            <td>Mood*</td>
          </tr>
        </thead>
        <tbody>
          {players.map((player) => {
            let userPhoto = `${environment.S3_ROOT}${environment.S3_BUCKET}/${environment.S3_FOLDER}/${player.photo}`;
            if (player.photo?.slice(0, 4) === "http") {
              userPhoto = player.photo;
            }
            return (
              <tr className="player-row" key={player.userId || player.id}>
                <td className="name-column">
                  {!player.photo ? (
                    <div className="capital-letters">
                      <p>{capitalLetters(player.firstName, player.lastName)}</p>
                    </div>
                  ) : (
                    <img
                      src={userPhoto}
                      alt="player-img"
                      className="player-img"
                    />
                  )}
                  <span>{`${player.firstName} ${player.lastName}`}</span>
                </td>
                <td className="role-column">{player.projectRole}</td>
                <td className="mood-column">
                  <div className="mood-icon">
                    {moodOptions.map((mood, idx) => {
                      return (
                        <span key={idx}>
                          <Mood
                            mood={mood}
                            handleClickMood={handleClickMood}
                            getMoodIcon={getMoodIcon}
                            player={player}
                            iconMood={iconMood}
                            playerId={playerId}
                          />
                        </span>
                      );
                    })}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
