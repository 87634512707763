import MyCalendar from '../../components/MyCalendar/MyCalendar';
import { TabTitle } from '../../utils/DinamicTitle';

const Scheduler = () => {
  TabTitle("MyBTS - Scheduler")
    return (
      <MyCalendar />
    )
  };
  
export default Scheduler;
  
