import { MehFaceIcon, SadFaceIcon, SmileFaceIcon, OutdatedIcon } from "../components/Icons";

/**
 * Get the icon status by a mood Id
 * @param  {Number} mood Id mood Id
 */
export const getMoodsIcon = (moodId) => {
  const iconMatrix = {
    1: <SmileFaceIcon />,
    2: <SmileFaceIcon />,
    3: <MehFaceIcon />,
    4: <SadFaceIcon />,
    5: <SadFaceIcon />,
  };
  return iconMatrix[moodId] || <OutdatedIcon />;
};
