import axios from 'axios';
import PropTypes from 'prop-types';
import { environment } from '../../../config/environment';

/**
 * @module
 * @description This service calls the API in order to retrieve global news, regional news or news by ID.
 * @param {String} type: type of news to be retrieved (whether local or global).
 * @param {Object} context: user's context.
 * @param {Number} id: piece of news ID.
 * @returns {Array} Array of news (whether global, regional or only one)
 */

async function getNews(type, context, id) {
  try {
    const objNews = {
      local: `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module0/news/${context?.userInfo?.region}`,
      global: `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module0/news/global`,
      individual: `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module0/news/article/${id}`,
    };

    let response = null;

    if (typeof id === 'number') {
      response = await axios.get(objNews['individual'], {
        headers: {
          Authorization: context?.user?.token,
        },
      });
    } else if (!id && type) {
      response = await axios.get(objNews[type], {
        headers: {
          Authorization: context?.user?.token,
        },
      });
    }

    return response.data.data;
  } catch (error) {
    throw error.response || error.toJSON() || error;
  }
}

/**
 * @description This service calls the Api in order to delete a specific
 * piece of news using its ID
 * @param {Number} id :piece of news ID.
 * @returns {Object} request: Object that contains the information if the call
 * to the API was successful or not
 */

async function deleteNew(id, token) {
  try {
    const request = await axios.delete(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module0/news/article/${id}`,
        {
            headers: {
                Authorization: token,
            },
        }
    );
    return request;
  } catch (error) {
    throw error.response || error.toJSON() || error;
  }
}

getNews.propTypes = {
  type: PropTypes.string,
  context: PropTypes.object.isRequired,
  id: PropTypes.number,
};

deleteNew.propTypes = {
  id: PropTypes.number,
};

export { getNews, deleteNew };
