import React from "react";

function TemporarySupport({temporary}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="14"
      fill="none"
      viewBox="0 0 13 14"
      style={{filter: `opacity(${temporary})`}}
    >
      <path
        fill="#000"
        d="M3.812 4.444a.48.48 0 00-.482.477.48.48 0 00.482.476h5.14a.48.48 0 00.482-.476.48.48 0 00-.482-.477h-5.14zm5.461 4.31a.433.433 0 00-.44.426v1.098c0 .502.425.897.933.897h.493c.237 0 .44-.184.44-.426a.433.433 0 00-.44-.426h-.493c-.036 0-.053-.026-.053-.045V9.18a.433.433 0 00-.44-.426z"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M6.382 0c-1.562 0-2.85 1.159-3.03 2.653a3.09 3.09 0 00-.71.219 3.682 3.682 0 00-1.575 1.355c-.232.358-.369.76-.477 1.248-.105.48-.19 1.076-.296 1.836l-.006.042C.151 8.331.043 9.096.01 9.711c-.033.625.007 1.146.198 1.625a3.664 3.664 0 001.626 1.845c.453.252.97.363 1.601.417.622.052 1.403.052 2.402.052H7.66c.489.224 1.036.35 1.613.35C11.325 14 13 12.41 13 10.435c0-.504-.109-.982-.306-1.416a54.208 54.208 0 00-.218-1.666l-.006-.042c-.107-.76-.191-1.357-.297-1.836-.108-.489-.245-.89-.476-1.248a3.681 3.681 0 00-1.575-1.355 3.088 3.088 0 00-.71-.219C9.231 1.16 7.943 0 6.382 0zm5.162 7.608a2.034 2.034 0 01-.026-.146c-.11-.784-.19-1.345-.287-1.784-.095-.434-.2-.713-.347-.939A2.721 2.721 0 009.72 3.737c-.214-.096-.464-.158-.818-.195-.475-.05-1.089-.05-1.998-.05H5.86c-.91 0-1.524 0-1.998.05-.354.037-.605.099-.818.195A2.721 2.721 0 001.879 4.74c-.145.226-.251.505-.347.939-.097.44-.176 1-.286 1.784-.141 1.005-.243 1.73-.273 2.3-.03.564.014.93.132 1.225.23.578.654 1.06 1.202 1.364.28.156.64.25 1.21.298.576.049 1.316.05 2.343.05h.533a3.455 3.455 0 01-.846-2.264c0-1.975 1.674-3.564 3.726-3.564a3.82 3.82 0 012.27.737zM5.83 2.54c-.58 0-1.068 0-1.485.016A2.084 2.084 0 016.382.952c.993 0 1.824.686 2.036 1.604a40.924 40.924 0 00-1.485-.016H5.83zm.594 7.895c0-1.492 1.269-2.713 2.848-2.713 1.58 0 2.848 1.221 2.848 2.713s-1.269 2.713-2.848 2.713-2.848-1.22-2.848-2.713z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default TemporarySupport;