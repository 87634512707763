import React, { useState, useEffect } from "react";
import "../SchedulerLayout/scheduler.scss";
import "./MyTicklerStyles.scss";
import TimeLogModal from "../Modal/TimeLogModal";
import Modal from "react-bootstrap/Modal";
import { useSchedulerContext } from "../../../context/SchedulerContext/SchedulerContext";
import { useAuthContext } from "../../../context/AuthContext";
import { BsArrowClockwise, BsFillTrashFill, BsPencil } from "react-icons/bs";
import { deleteTimesheet } from "../services/deleteTimesheet";
import { hoursConverter } from "../services/hoursConverter";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import ReactTooltip from "react-tooltip";
//import { DialogBox } from "../../shared/DialogBox";
import Swal from "sweetalert2";

/**
 * @module
 * @description this component displays the buttons to autofill, edit and delete tasks on tickler
 * @param {Object} description
 * @returns {JSX} EditTask
 */

export default function EditTask({ description, date, refreshTask }) {
  const intl = useIntl();
  const [showEditTask, setShowEditTask] = useState(false);
  const [showAutofillTask, setShowAutofillTask] = useState(false);
  const [taskData, setTaskData] = useState();
  // Contexts
  const authContext = useAuthContext();
  const schedulerContext = useSchedulerContext();

  useEffect(() => {
    setTaskData(
      schedulerContext.timesheets.filter(
        (task) => task.descriptions[0].timesheetId === description.timesheetId
      )
    );
  }, []);

  const handleEditClick = () => {
    setShowEditTask(true);
  };
  //This code is the autofill feature in the scheduler tickler:
  
  // const handleAutofillClick = () => {
  //   setShowAutofillTask(true);
  // };

  // const handleDeleteClick = async () => {
  //   const data = description;
  //   data.date = taskData[0].date;
  //   await deleteTimesheet(
  //     authContext.user.id,
  //     authContext.user.token,
  //     data.id)
  //     .then(res => { refreshTask(); })
  //     .catch(error => {
  //       checkForInvalidToken(error, authContext, intl);
  //     });
  // };

  const handleDeleteClick = () => {
    const data = description;
    data.date = taskData[0].date;
    Swal.fire({
      title: "Are you sure you want to delete this entry?",
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonText: `${intl.formatMessage({ id: "button.confirm", defaultMessage: "Yes, I want" })}`,
      customClass: {
        title: "main-dialog",
        htmlContainer: "secondary-dialog",
        actions: "actions-container",
        confirmButton: "secondary-action-button",
        cancelButton: "cancel-delete-button",
      },
    }).then(async (response) => {
      if (response.isConfirmed) {
        const result = await deleteTimesheet(
          authContext.user.id,
          authContext.user.token,
          data.id
        );
        if (result) {
          refreshTask();
        };
      }
    }).catch(error => {
      console.log(error)
    });
  };

  const handleClose = () => {
    setShowEditTask(false);
    setShowAutofillTask(false);
  };

  return (
    <>
      {showEditTask ? (
        <Modal
          show={showEditTask}
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="timesheetModal"
        >
          <TimeLogModal
            handleClose={handleClose}
            refreshTask={refreshTask}
            isHappy={description.isHappy}
            notes={description.note}
            startHour={description.startHour}
            endHour={description.endHour}
            projectId={description.projectId}
            taskDescription={description.task}
            selectedDate={taskData[0].date}
            isEditing={true}
            totalTime={description.dedicatedTime}
            taskId={description.id}
            tags={description.tags}
          />
        </Modal>
      ) : (
        <></>
      )}
      {showAutofillTask ? (
        <Modal
          show={showAutofillTask}
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="timesheetModal"
        >
          <Modal.Header closeButton className="modalHeader">
            {/*<FormattedMessage
              id="modal_title_add_time_log"
              defaultMessage="Add time log entry"
            />*/}
            Add time log entry
          </Modal.Header>
          <TimeLogModal
            handleClose={handleClose}
            refreshTask={refreshTask}
            isHappy={description.isHappy}
            notes={description.note}
            startHour={hoursConverter.timeFromUTCtoMyTimeZone(
              description.startHour
            )}
            endHour={hoursConverter.timeFromUTCtoMyTimeZone(
              description.endHour
            )}
            projectId={description.projectId}
            taskDescription={description.task}
            selectedDate={moment().format("YYYY-MM-DD")}
            isEditing={false}
            taskId={description.id}
            tags={description.tags}
          />
        </Modal>
      ) : (
        <></>
      )}
      <div className="btn-container">
        {/* <button onClick={() => DialogBox(deleteConfirmation, handleDeleteClick)} data-tip={intl.formatMessage({ id: "delete.task", defaultMessage: "Delete" })} data-for="deleteTask">
          <BsFillTrashFill />
        </button> */}
        <button onClick={handleDeleteClick} data-tip={intl.formatMessage({ id: "delete.task", defaultMessage: "Delete" })} data-for="deleteTask">
          <BsFillTrashFill />
        </button>
        <ReactTooltip id="deleteTask" place="bottom" backgroundColor="#3FAA58" />

        <button onClick={handleEditClick} data-tip={intl.formatMessage({ id: "edit.task", defaultMessage: "Edit" })} data-for="editTask">
          <BsPencil />
        </button>
        <ReactTooltip id="editTask" place="bottom" backgroundColor="#3FAA58" />

        {/* this is the button of the autofill feature: 

        <button onClick={handleAutofillClick} data-tip={intl.formatMessage({ id: "autofill.task", defaultMessage: "Autofill" })} data-for="autofillTask">
          <BsArrowClockwise />
        </button>
        <ReactTooltip id="autofillTask" place="bottom" backgroundColor="#3FAA58" /> */}
      </div>
    </>
  );
}
