import React, { useEffect, useState } from "react";
import DayCard from "./DayCard";
import ModalContainer from "../Modal/ModalContainer";
import { DangerTriangleIcon } from "../../Icons";
import "moment/locale/en-gb";
// language switcher feature
import { FormattedMessage, FormattedDate } from "react-intl";
import { useAuthContext } from "../../../context";
import getUserByName from "../services/getUserByName";
import { getLocationsAndRegions } from "../services/getLocationsAndRegions";

const moment = require("moment");

/**
 * @module
 * @description This component displays the cards for the
 * user's worked projects
 * @param {Object} obj - An Object
 * @param {Object[]} obj.items - User's timesheets
 * @param {Object[]} obj.alerts - Hours worked alerts
 * @param {Function} obj.refreshTask - Function that increases the value of the timesheet counter by one
 * @param {Object[]} obj.holidays - User's holidays
 * @param {Object[]} obj.eventsGlobal - User's global events
 * @param {Object[]} obj.eventsRegion - User's region events
 * @param {Object[]} obj.daysOff - User's days off
 * @returns {JSX} Tickler
 */
const Tickler = ({ eventRegion2, context, items, alerts, refreshTask, personalHolidays, globalHolidays, regionHolidays, localHolidays, eventsGlobal, eventsGlobalContext, eventsRegion, daysOff, selectedMonth, prevMonth, nextMonth, handlePrevMonth, handleNextMonth, name, region, local }) => {

  let redAlerts = alerts.filter((event) => event.alertStr === "⚠");
  let yellowAlerts = alerts?.filter((event) => event.alertStr !== "⚠");
  const { user } = useAuthContext();
  const auth = useAuthContext();
  const [renderItems, setRenderItems] = useState();
  const [contextEnumerator, setContextEnumerator] = ([{
    Personal: "Personal",
    Global: "Global",
    Region: region ? region : auth.userInfo.region,
    Local: local ? local : auth.userInfo.location,
    Employee: name
  }]);

  useEffect(async () => {
    let isLocation;
    let isRegion;
    let newPersonalHolidays = personalHolidays ? personalHolidays.map((holiday) => {
      let isAlert = yellowAlerts.find(
        (alert) => alert.type === "Holiday" && alert.start === holiday.start
      );
      if (!isAlert) {
        return holiday;
      } else {
        return {};
      }
    })
      : [];

    let newGlobalHolidays = globalHolidays
      ? globalHolidays.map((holiday) => {
        let isAlert = yellowAlerts.find(
          (alert) => alert.type === "Holiday" && alert.start === holiday.start
        );
        if (!isAlert) {
          return holiday;
        } else {
          return {};
        }
      })
      : [];

    let newLocalHolidays = localHolidays
      ? localHolidays.map((holiday) => {
        let isAlert = yellowAlerts.find(
          (alert) => alert.type === "Holiday" && alert.start === holiday.start
        );
        if (!isAlert) {
          return holiday;
        } else {
          return {};
        }
      })
      : [];

    let newRegionHolidays = regionHolidays
      ? regionHolidays.map((holiday) => {
        let isAlert = yellowAlerts.find(
          (alert) => alert.type === "Holiday" && alert.start === holiday.start
        );
        if (!isAlert) {
          return holiday;
        } else {
          return {};
        }
      })
      : [];

    let newGlobalEvents = eventsGlobal
      ? eventsGlobal.map((event) => {
        let isAlert = yellowAlerts.find(
          (alert) => alert.type === "Event" && alert.start === event.start
        );
        if (!isAlert) {
          return event;
        } else {
          return {};
        }
      })
      : [];

    let newGlobalContextEvents = eventsGlobalContext
      ? eventsGlobalContext.map((event) => {
        let isAlert = yellowAlerts.find(
          (alert) => alert.type === "Event" && alert.start === event.start
        );
        if (!isAlert) {
          return event;
        } else {
          return {};
        }
      })
      : [];

    let newRegionEvents = eventsRegion
      ? eventsRegion.map((event) => {
        let isAlert = yellowAlerts.find(
          (alert) => alert.type === "Event" && alert.start === event.start
        );
        if (!isAlert) {
          return event;
        } else {
          return {};
        }
      })
      : [];

    let newDaysOff = daysOff
      ? daysOff.map((dayOff) => {
        let isAlert = yellowAlerts.find(
          (alert) => alert.type === "Day off" && alert.start === dayOff.start
        );
        if (!isAlert) {
          return dayOff;
        } else {
          return dayOff;
        }
      })
      : [];

    let permission = user.permissions.userPermissions;
    let permissionsArray = [];
    let permissionsByLocation = [];
    if (context === 'Personal' || context === 'Global') {
      for (const permissionElement of permission) {
        if (permissionElement.locations && permissionElement.locations.name === auth.userInfo.location) {
          permissionsArray.push(permissionElement.permission.name);
        }
      }
    } else if (context === name) {
      let locationFound = [];
      let regionFound;
      const selectedUser = await getUserByName(name, auth.user.token);
      if (selectedUser) {
        const locationsAndRegions = await getLocationsAndRegions(auth.user.token);
        locationsAndRegions.forEach((region) => {
          const tempLocationFound = region.locations.filter((location) => location.id === selectedUser.companyLocationId)
          if (tempLocationFound.length > 0) {
            locationFound = [...locationFound, ...tempLocationFound];
            regionFound = region;
          }
        });
      }
      for (const permissionElement of user.permissions.userPermissions) {
        if (permissionElement.locations && permissionElement.locations.name === locationFound[0].name) {
          permissionsByLocation.push(permissionElement.permission.name);
        }
      }
      if (permissionsByLocation.length === 0) {
        for (const permissionElement of user.permissions.userPermissions) {
          if (permissionElement.regions && permissionElement.regions.name === regionFound.name) {
            permissionsByLocation.push(permissionElement.permission.name);
          }
        }
      }
    } else {
      let regionToFind = context;
      const locationsAndRegions = await getLocationsAndRegions(auth.user.token);

      //for location
      locationsAndRegions.forEach((region) => {
        const tempLocationFound = region.locations.filter(location => {
          return location.name === regionToFind
        })

        if (tempLocationFound.length > 0) {
          for (const permissionElement of user.permissions.userPermissions) {
            if (permissionElement.locations && permissionElement.locations.name === tempLocationFound[0].name) {
              permissionsByLocation.push(permissionElement.permission.name);
              isLocation = (true);
              isRegion = (false);
            }
          }
        }
      });
      //for region
      if (permissionsByLocation.length === 0) {
        for (const permissionElement of user.permissions.userPermissions) {
          if (permissionElement.regions && permissionElement.regions.name === regionToFind) {
            permissionsByLocation.push(permissionElement.permission.name);
            isRegion = (true);
            isLocation = (false);
          }
        }
      }
    }

    if (context === "Personal" || context === undefined) {
      if (!permissionsArray.includes('view-reported-hours') && permissionsArray.includes('view-events')) {
        items = [...newPersonalHolidays, ...newDaysOff, ...newGlobalEvents, ...newRegionEvents];
      } else if (permissionsArray.includes('view-reported-hours') && !permissionsArray.includes('view-events')) {
        items = [...items, ...newDaysOff];
      } else if (permissionsArray.includes('view-reported-hours') && permissionsArray.includes('view-events')) {
        items = [...items, ...newPersonalHolidays, ...newDaysOff, ...newGlobalEvents, ...newRegionEvents];
      } else {
        items = [...newDaysOff];
      }
    }

    if (context === name) {
      if (!permissionsByLocation.includes('view-reported-hours') && permissionsByLocation.includes('view-events')) {
        items = [...newPersonalHolidays, ...newDaysOff, ...newGlobalEvents, ...newRegionEvents];
      } else if (permissionsByLocation.includes('view-reported-hours') && !permissionsByLocation.includes('view-events')) {
        items = [...items, ...newDaysOff];
      } else if (permissionsByLocation.includes('view-reported-hours') && permissionsByLocation.includes('view-events')) {
        items = [...items, ...newPersonalHolidays, ...newDaysOff, ...newGlobalEvents, ...newRegionEvents];
      } else {
        items = [];
      }
    }

    if (context === "Global") {
      if (permissionsArray.includes('view-events')) {
        items = [...newGlobalHolidays, ...newGlobalContextEvents];
      } else {
        items = [];
      }
    }
    if ((context === contextEnumerator.Local || isLocation) && !isRegion && context !== 'Personal' && context !== name && context !== 'Global') {
      if (permissionsByLocation.includes('view-events')) {
        items = [...newLocalHolidays];
      } else {
        items = [];
      }
    }

    if ((context === contextEnumerator.Region || isRegion) && !isLocation && context !== name && context !== 'Personal' && context !== 'Global') {
      if (context === auth.userInfo.region) {
        if (permissionsByLocation.includes('view-events')) {
          items = [...newRegionHolidays, ...newRegionEvents];
        } else {
          items = [];
        }
      } else {
        if (permissionsByLocation.includes('view-events')) {
          items = [...newRegionHolidays, ...eventRegion2];
        } else {
          items = [];
        }
      }
    } 

    items.sort((a, b) => {
      if (
        (a.date ? moment(a.date).format("YYYY-MM-DD") : a.start) >
        (b.date ? moment(b.date).format("YYYY-MM-DD") : b.start)
      )
        return 1;
      if (
        (a.date ? moment(a.date).format("YYYY-MM-DD") : a.start) <
        (b.date ? moment(b.date).format("YYYY-MM-DD") : b.start)
      )
        return -1;
      return 0;
    });
    items = items.reverse();

    items = items.filter(el => {
      if (el.start) {
        return parseInt(moment(el.start).format("M")) === selectedMonth
      } else {
        return parseInt(moment(el.date).format("M")) === selectedMonth
      }
    })
    setRenderItems(items);
    return () => {
      isLocation = "";
      isRegion = "";
      newPersonalHolidays = {};
      newGlobalHolidays = {};
      newLocalHolidays = {};
      newRegionHolidays = {};
      newGlobalEvents = {};
      newGlobalContextEvents = {};
      newRegionEvents = {};
      newDaysOff = {};
      permissionsArray = [];
      permissionsByLocation = [];
      setRenderItems([])
    }
  }, [eventsGlobal, alerts, personalHolidays, globalHolidays, localHolidays, regionHolidays, eventsGlobal, eventsGlobalContext, eventsRegion, daysOff, context]);

  if (renderItems) {
    return (
      <div className="timeline">
        <div className="first-and-last-tickler first-tickler">
          <span onClick={handleNextMonth}>
            {/*<FormattedMessage
              id="scheduler.tickler.go.to"
              defaultMessage=" Go to "
            />*/}
            {nextMonth} ↑
          </span>
        </div>
        <div className="red-alerts">
          {redAlerts ? (
            redAlerts.map((event) => {
              let elementDate = moment(event.start).format("YYYY-MM-DD");
              return (
                <div className="day-card red-card">
                  <div className="day-header">
                    <div className="day-title">
                      {moment(event.start).format("ddd, DD")}
                    </div>
                  </div>
                  <div>
                    <span style={{ fontSize: "16px" }}>
                      <DangerTriangleIcon fillColor="#ff0101" />{" "}
                    </span>
                    {/*<FormattedMessage
                      id="alert.noreport"
                      defaultMessage="Ops... you haven't reported hours this day, please add them."
                    />*/}
                    &nbsp;
                    {elementDate >=
                      moment().subtract(1, "M").format("YYYY-MM-DD") ? (
                      <ModalContainer
                        contexts={context}
                        refreshTask={refreshTask}
                        selectedDate={moment(event.start).format("YYYY-MM-DD")}
                        buttonText={
                          <span
                            style={{
                              color: "#16448a",
                              textDecoration: "underline",
                            }}
                          >
                            {/*<FormattedMessage
                              id="scheduler.tickler.add.hours"
                              defaultMessage="Add missing entries now"
                          />*/}
                            Add missing entries now
                          </span>
                        }
                      />
                    ) : null}
                  </div>
                </div>
              );
            })
          ) : (
            <h3>
              {/*<FormattedMessage id="no_alerts" />*/}
              No alerts
            </h3>
          )}
        </div>
        {renderItems.map((element) => {
          if (
            element.descriptions &&
            element.descriptions.length === 1 &&
            element.descriptions[0].isFinished === false
          ) {
            return null;
          } else {
            if (element.start || element.descriptions)
              return (
                <DayCard
                  item={element}
                  key={element.id}
                  yellowAlerts={yellowAlerts}
                  refreshTask={refreshTask}
                  isTopTickler={false}
                />
              );
          }
        })}
        <div className="first-and-last-tickler last-tickler">
          <span onClick={handlePrevMonth}>
            {/*<FormattedMessage
              id="scheduler.tickler.go.to"
              defaultMessage=" Go to "
            />*/}
            Go to
            {prevMonth} ↓
          </span>
        </div>
      </div>
    );
  } else {
    return (
      <div className="timeline">
        <div className="first-and-last-tickler first-tickler">
          {/*<FormattedMessage id="next_month_sign" defaultMessage=" Next month "/>*/}
          Next month
          {selectedMonth},
          <span onClick={handleNextMonth}>
            {/*<FormattedMessage id="scheduler.tickler.go.to"
                              defaultMessage=" Go to " />*/}
            Go to
            {nextMonth} ↑
          </span>
        </div>
        <div>
          <h3>
            {/*<FormattedMessage id="no_timesheets_found" defaultMessage=" No timesheets found " />*/}
            No timesheets found
          </h3>
        </div>
        <div className="first-and-last-tickler last-tickler">
          {/*<FormattedMessage id="prev_month_sign" defaultMessage=" Previous month "/>*/}
          Previous month
          {selectedMonth},
          <span onClick={handlePrevMonth}>
            {/*<FormattedMessage id="scheduler.tickler.go.to"
                              defaultMessage=" Go to " />*/}
            Go to
            {prevMonth} ↓
          </span>
        </div>
      </div>
    );
  }
};

export default Tickler;
