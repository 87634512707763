import { React, useState } from 'react';
import { NavLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import FeedbackModal from '../FeedbackModal/FeedbackModal';

export default function SidebarItem({ id, path, icon, title, children }) {
    const [className, setClassname] = useState("text-hidden");
    if (children) {
        return (
            <ul id={id}>
                {children.map((child) =>
                    <li key={child.id}>
                        <SidebarItem {...child} />
                    </li>
                )}
            </ul>
        );
    } else if(title==="feedback"){
        return(
            <div className='box-container'>
                <FeedbackModal/>
            </div>
        );
    }else {
        return (
            <div className='sidebarNavItem'>
                <NavLink
                    to={path}
                    className="sidebarNavIcon"
                    activeclassname="active"
                    data-tip
                    data-for={title}
                >
                    <div
                        className="box-items"
                        onMouseEnter={() => setClassname("bubble-box-active")}
                        onMouseLeave={() => setClassname("bubble-box-desactive")}
                    >
                        {icon}
                    </div>

                </NavLink>
                <ReactTooltip
                    id={title}
                    place="right"
                    backgroundColor="#3FAA58"
                >
                    {title}
                </ReactTooltip>
            </div>
        );
    }
}