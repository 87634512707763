const DaylightSavingTime = (timezone) => {
  let isDaylightSaving = null;
  let actualDate = new Date();
  let x = actualDate.getMonth() + 1;
  let actualDaylightSaving = null;
  switch (timezone) {
    case "UTC+9":
      isDaylightSaving = false;
      break;
    case "UTC+5:30":
      isDaylightSaving = false;
      break;
    case "UTC-3":
      isDaylightSaving = false;
      break;
    case "UTC-4":
      isDaylightSaving = false;
      break;
    case "UTC+3":
      isDaylightSaving = false;
      break;
    case "UTC+2":
      isDaylightSaving = true;
      if (x === 10) {
        actualDaylightSaving = true;
      } else if (x === 5) {
        actualDaylightSaving = false;
      }
      break;
    case "UTC+1":
      isDaylightSaving = false;
      break;
    case "UTC-0":
      isDaylightSaving = false;
      break;
    case "UTC-5":
      isDaylightSaving = true;
      if (x === 4) {
        actualDaylightSaving = true;
      } else if (x === 11) {
        actualDaylightSaving = false;
      }
      break;
    case "UTC-6":
      isDaylightSaving = true;
      if (x === 4) {
        actualDaylightSaving = true;
      } else if (x === 11) {
        actualDaylightSaving = false;
      }
      break;
    case "UTC-7":
      isDaylightSaving = true;
      if (x === 3) {
        actualDaylightSaving = true;
      } else if (x === 11) {
        actualDaylightSaving = false;
      }
      break;
    case "UTC-8":
      isDaylightSaving = false;
      break;
    default:
      isDaylightSaving = false;
      break;
  }
  return isDaylightSaving;
};

export default DaylightSavingTime;
