
function LockIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
    >
      <circle cx="11" cy="11" r="11" fill="#D92A2A"></circle>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M11 10.846c-1.012 0-1.833.943-1.833 2.105 0 .964.564 1.776 1.333 2.026v2.567c0 .317.224.575.5.575s.5-.258.5-.575v-2.567c.77-.25 1.333-1.062 1.333-2.026 0-1.162-.82-2.105-1.833-2.105zm-.833 2.105c0-.528.373-.956.833-.956.46 0 .833.428.833.956 0 .529-.373.957-.833.957-.46 0-.833-.428-.833-.957z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M11 3.957c-2.117 0-3.833 1.97-3.833 4.401v.4l-.018.007c-1.167.436-2.082 1.486-2.461 2.826-.188.664-.188 1.461-.188 2.768v.247c0 1.306 0 2.103.188 2.768.379 1.34 1.294 2.39 2.46 2.826.58.215 1.273.215 2.41.215h2.883c1.138 0 1.832 0 2.41-.215 1.167-.436 2.082-1.486 2.461-2.826.188-.665.188-1.461.188-2.768v-.248c0-1.306 0-2.103-.188-2.767-.379-1.34-1.294-2.39-2.46-2.826l-.019-.006v-.4c0-2.431-1.716-4.402-3.833-4.402zM9.61 8.55c-.585 0-1.053 0-1.443.025v-.217c0-1.796 1.268-3.253 2.833-3.253 1.565 0 2.833 1.457 2.833 3.253v.217c-.39-.025-.857-.025-1.444-.025H9.611zM7.75 9.777c.398-.077.935-.079 1.917-.079h2.666c.982 0 1.519.002 1.917.08.11.02.206.047.292.08.863.321 1.54 1.098 1.82 2.088.132.468.138 1.067.138 2.536 0 1.47-.006 2.069-.139 2.537-.28.99-.956 1.767-1.819 2.089-.408.152-.929.159-2.209.159H9.667c-1.28 0-1.801-.007-2.21-.16-.862-.321-1.538-1.098-1.818-2.088-.133-.468-.139-1.067-.139-2.537 0-1.469.006-2.068.139-2.536.28-.99.956-1.767 1.819-2.089a1.83 1.83 0 01.292-.08z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default LockIcon;