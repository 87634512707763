/**
 * @description Function to create the Apps icon as a jsx component
 * @returns {JSX} AppsIcon
 */
import PropTypes from "prop-types";

const AppsIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 30 30"
      stroke={color}
      strokeWidth="0.5"
      fill="none"
    >
      <path
        d="M6.6 15A2.8 2.8 0 1 1 1 15a2.8 2.8 0 0 1 5.6 0zM17.8 15a2.8 2.8 0 1 1-5.6 0 2.8 2.8 0 0 1 5.6 0zM29 15a2.8 2.8 0 1 1-5.6 0 2.8 2.8 0 0 1 5.6 0zM6.6 3.8a2.8 2.8 0 1 1-5.6 0 2.8 2.8 0 0 1 5.6 0zM17.8 3.8a2.8 2.8 0 1 1-5.6 0 2.8 2.8 0 0 1 5.6 0zM29 3.8a2.8 2.8 0 1 1-5.6 0 2.8 2.8 0 0 1 5.6 0zM6.6 26.2a2.8 2.8 0 1 1-5.6 0 2.8 2.8 0 0 1 5.6 0zM17.8 26.2a2.8 2.8 0 1 1-5.6 0 2.8 2.8 0 0 1 5.6 0zM29 26.2a2.8 2.8 0 1 1-5.6 0 2.8 2.8 0 0 1 5.6 0z"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};

AppsIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default AppsIcon;
