import scss from "./Clock.module.scss";
import { LocationIcon } from "../../Icons";
import { useState, useEffect } from "react";
import { useAuthContext } from "../../../context/AuthContext/AuthContext";
import PropTypes from "prop-types";
import { getCurrentEpoch } from "../../../utils/date";
import moment from "react-moment";
import "moment-timezone";
import Slider from "./Slider";
import SunIcon from "../../Icons/Sun";
import DaylightSavingTime from "./DaylightSavingTime";
import {FormattedMessage} from "react-intl";
// IMPORTANT: CHECK if timezone is supported
const timezones = [
  { timezone: "America/Los_Angeles", name: "UTC-8" },
  { timezone: "US/Arizona", name: "UTC-7" },
  { timezone: "America/Cancun", name: "UTC-6" },
  { timezone: "America/Lima", name: "UTC-5" },
  { timezone: "America/La_Paz", name: "UTC-4" },
  { timezone: "America/Rosario", name: "UTC-3" },
  { timezone: "GB", name: "UTC-0" },
  { timezone: "Europe/Madrid", name: "UTC+1" },
  { timezone: "Europe/Helsinki", name: "UTC+2" },
  { timezone: "Europe/Istanbul", name: "UTC+3" },
  { timezone: "Asia/Colombo", name: "UTC+5:30" },
  { timezone: "Japan", name: "UTC+9" },
];

/**
 * @module
 * @description This component displays the clocks from different timezones in order to see the difference between the user timezone and the timezones from other regions.
 * @returns {JSX} Clock
 */
function Clock() {
  const context = useAuthContext();
  const [sliderValue, setSliderValue] = useState(0);
  const [restart, setRestart] = useState(false);
  const info = context?.userInfo;
  const [date, setDate] = useState(new Date());
  const stepValue = (v) => Math.round(v / 10) * 10;
  const step = stepValue(sliderValue * 100 * 4.8) / 10;
  let dstart = new Date(),
    dmid = new Date(),
    dend = new Date(),
    slider_time = new Date();

  dstart.setHours(0, 0, 0, 0);
  dmid.setHours(12, 0, 0, 0);

  let seconds = (date.valueOf() - dstart.valueOf()) / 1000,
    minutes = seconds / 60;

  // Updates clock every sec
  useEffect(() => {
    const interval = setInterval(() => setDate(new Date()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, [date]);

  // 15 minutes cycle and association with slider.
  if (step === 0) {
    slider_time = dstart.valueOf();
  } else if (step === 48) {
    slider_time = dmid.valueOf();
  } else if (step === 96) {
    slider_time = dstart.valueOf();
  } else {
    slider_time = dstart.valueOf() + step * 900000;
  }

  function changeHandler(value) {
    setSliderValue(+value);
  }

  function reset(value) {
    setRestart(value);
  }

  const UTC = date
    .toLocaleDateString([], {
      day: "2-digit",
      timeZoneName: "shortOffset",
    })
    .substring(3)
    .replace("GMT", "UTC");

  //Filter to acces the specific location and timezone where the user is located
  const zone = timezones.filter((zone) => zone.name === UTC);

  return (
    <div
      className={scss.clock_container}
      //onWheel={(e) => setSliderValue((prev) => +prev + e.deltaY / 10000)} 
    >
      <div className={scss.clock_principal}>
        <div className={scss.circularClockIcon}>
          <Slider
            time={minutes}
            sliderValue={sliderValue}
            changeHandler={changeHandler}
            restart={restart}
            reset={reset}
          />
        </div>

        <div className={scss.clockLocation}>{/*<FormattedMessage id="topbar.clock.local.time" defaultMessage="Your local time" />*/}Your local time</div>
        <div className={`${scss.main_clock}`}>
          {date.getHours() + ":" + String(date.getMinutes()).padStart(2, "0")}
          <div className={scss.main_clocktz}>
            {UTC}
            <br />
            {Intl.DateTimeFormat().resolvedOptions().timeZone}
          </div>
        </div>

        <div className={scss.main_clocktext}>
        {/*<FormattedMessage id="topbar.clock.instructions" defaultMessage="Use the circular scroll to see times along the day." />*/}
          Use the circular scroll to see times along the day.
          <span
            onClick={(e) => {
              setSliderValue(0);
              setRestart(true);
            }}
          >
            {/*<FormattedMessage id="topbar.clock.reset" defaultMessage="Reset it" />*/}
            Reset it
          </span>
          <br />
          <br />
          <SunIcon />{/*<FormattedMessage id="topbar.clock.daylight" defaultMessage=" Daylight saving time in order" />*/}
          Daylight saving time in order
        </div>
      </div>

      <div className={scss.clock_grid}>
        {timezones.map((tz) => (
          <MiniClock
            date={date}
            key={tz.timezone}
            timezone={tz.timezone}
            name={tz.name}
            offset={slider_time}
          />
        ))}
      </div>
    </div>
  );
}

// this here only for prototype TODO: move
/**
 * @description This function creates a single clock with the current information of specific timezone
 * @param {String} timezone
 * @param {number} offset
 * @param {Object} date
 * @param {String} name
 * @returns {JSX} MiniClock
 */
function MiniClock({ timezone, offset, date, name }) {
  const dateWithOffset = new Date(offset);
  
  // hour:minute format
  const localDateWithOffset = dateWithOffset.toLocaleTimeString("en-US", {
    timeZone: timezone,
    hour: "2-digit",
    minute: "2-digit",
  });

  const timeDif = (date.getTimezoneOffset() - getOffset(timezone)) / 60;

  const min = Math.abs((timeDif % 1) * 60);
  const hour = parseInt(timeDif);
  let tzDelta = `${hour >= 0 ? `+${hour}` : hour}${min ? `:${min}` : ""}h`;
  let regions = "";
  if (min + hour === 0) tzDelta = <LocationIcon />;
  //Switch to choose the locations
  switch (name) {
    case "UTC+9":
      regions = ["South Korea", "Japan"];
      break;
    case "UTC+5:30":
      regions = ["India", "Sri Lanka"];
      break;
    case "UTC+3":
      regions = ["Turkey", "Kenya"];
      break;
    case "UTC+2":
      regions = ["Eastern Europe", "Greece"];
      break;
    case "UTC+1":
      regions = ["Central Europe", "Spain"];
      break;
      case "UTC-0":
        regions = ["Western Europe", "United Kingdom"];
      break;
      case "UTC-3":
        regions = ["Buenos Aires", "Montevideo"];
      break;
      case "UTC-4":
        regions = ["Sucre", "San Juan"];
      break;
      case "UTC-5":
        regions = ["East Time", "Lima"];
      break;
      case "UTC-6":
        regions = ["Central Time", "Jalisco"];
      break;
      case "UTC-7":
        regions = ["Mountain Time", "Arizona"];
      break;
      case "UTC-8":
        regions = ["Pacific Time", "California"];
      break;
    default:
      regions = "Region not found";
  }

  const daylightSaving = DaylightSavingTime(name);

  return (
    <div className={scss.miniclock_container}>
      <div className={scss.clock_time}>{localDateWithOffset}</div>
      <div className={scss.locationName}>{name}</div>
      <div className={scss.clockRegions}>
        {regions[0]}
        {daylightSaving ? <SunIcon /> : <></>}
        <br />
        {regions[1]}
      </div>
      <div className={scss.delta}>{tzDelta}</div>
    </div>
  );
}
/**
 * @description This function is used to calculate the differnce between the user current time in other timezones, in order to know whats the current user time on different timezones
 * @param {String} timeZone
 * @returns {number} Result
 */
function getOffset(timeZone) {
  // date as current date
  const date = new Date();
  // Abuse the Intl API to get a local ISO 8601 string for a given time zone.
  let iso = date
    .toLocaleString("en-CA", { timeZone, hour12: false })
    .replace(", ", "T");

  // Include the milliseconds from the original timestamp
  iso += "." + date.getMilliseconds().toString().padStart(3, "0");

  // Lie to the Date object constructor that it's a UTC time.
  const lie = new Date(iso + "Z");

  // Return the difference in timestamps, as minutes
  // Positive values are West of GMT, opposite of ISO 8601
  // this matches the output of `Date.getTimeZoneOffset`
  let result = -(lie - date) / 60 / 1000;
  // Fix for +-12 timezone dif,
  if (Number.isNaN(result)) {
    const isoFix = iso.replace("T24:", "T00:");
    const lieFix = new Date(isoFix + "Z");
    result = -(lieFix - date) / 60 / 1000;
  }
  return result;
}

MiniClock.propTypes = {
  timezone: PropTypes.string.isRequired,
  offset: PropTypes.number.isRequired,
  date: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};

getOffset.propTypes = {
  timeZone: PropTypes.string.isRequired,
};
export default Clock;
