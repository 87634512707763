import "./SearchProject.scss";
import { FaSearch } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

/**
 * @module
 * @description This function creates the structure and the logic of Search Project
 * @param {Function} searchValueRef get search Value Ref
 * @param {Function} onSearch get on Search
 * @param {array} projectsList get projects list
 * @param {Function} clearSearch get clear Search
 * @param {array} projectsFiltered get filtered projects
 * @param {Function} setProjectFiltered set filtered projects
 * @returns {JSX} SearchProject
 */
const SearchProject = ({
  searchValueRef,
  onSearch,
  projectsList,
  clearSearch,
  projectsFiltered,
  setProjectsFiltered
}) => {
  let intl = useIntl();
  const [inputValue, setInputValue] = useState(false);
  const [isFiltered, setIsFiltered] = useState("no filter");

  const playersIncludes = (players, search) => {
    let response = false;
    players.forEach(item => {
      if(item.lastName.toLowerCase().includes(search) || item.firstName.toLowerCase().includes(search)){
        response = true;
      }
    });
    return response;
  }

  const handleInputValue = (e) => {
    if (e !== "") {
      setInputValue(true);
      const search = e.toLowerCase();
      let tempProjectsFiltered =[];

      projectsList.forEach(item => {
        const players =  item.players;
        if(item.clientName.toLowerCase().includes(search) || item.projectName.toLowerCase().includes(search) ||
          playersIncludes(players, search.toLowerCase())){
          tempProjectsFiltered.push(item);
        }
        setProjectsFiltered(tempProjectsFiltered);
        setIsFiltered("filtered")
      });
    }else {
      setInputValue(false);
      setProjectsFiltered(projectsList);
      setIsFiltered("no filter");
    }
  };

  return (
    <div className="search-project">
      <div className="search-bar">
        <form onSubmit={onSearch}>
          <FaSearch />
          <input
            type="text"
            placeholder={intl.formatMessage({
              id: "projects.header.input.placeholder",
              defaultMessage: "Search by project, client and/ or players",
            })}
            className="text-field"
            name="search"
            ref={searchValueRef}
            onChange={(e) => {
              handleInputValue(e.target.value);
            }}
          />
        </form>
        {inputValue ? (
          <button
            type="button"
            className="btnclose"
            onClick={() => clearSearch(setInputValue(false))}
          >
            <IoMdClose />
          </button>
        ) : (
          <></>
        )}
      </div>
      <p>
        {/*<FormattedMessage id ="projects.header.input.data" defaultMessage = "{projects} results, {filter}"
        values = {{projects: projectsFiltered.length,
        filter: isFiltered}} />*/}
        {projectsFiltered.length} results, {isFiltered}
      </p>
    </div>
  );
};

export default SearchProject;
