import Moment from "react-moment";
import "moment-timezone";
import { datesFilter } from "./daysOffFilters";
import BlackCalendarIcon from "../../Icons/BlackCalendarIcon";
import moment from "moment";
import { BsCalendar4 } from "react-icons/bs";

// language switcher feature
import { FormattedMessage } from "react-intl";
import { useEffect, useState } from "react";

/**
 * @module
 * @description This component displays the list of days off
 * @param {Object} obj - An Object
 * @param {Object[]} obj.events - Array of objects containing days events
 * @param {Number} obj.month - Selected month
 * @param {Number} obj.year - Selected year
 * @returns {JSX} DaysOffList
 */

const DaysOffList = ({ events, month, year }) => {
  let filteredEvents = datesFilter(events, month, year);
  const [eventsNoRepeated, setEventsNoRepeated] = useState([])
  const colorToTitle = (color) => {
    switch (color) {
      case "#1c2bc0":
        return "Country holiday";
      case "#785549":
        return "Company event: ";
      case "#FFB5A7":
        return "Maternity leave";
      case "#4B5D83":
        return "Paternity leave";
      case "rgb(214, 210, 210, 1)":
        return "Vacations";
      case "#8d2ca7":
        return "Illness";
      case "rgb(193, 251, 164, 1)":
        return "Study";
      case "#b0b0b0":
        return "Others";
      case "#8F9DCE":
        return "Paid time off";
      default:
        return "Day off or holiday"/*<FormattedMessage id = "day.off.holiday" defaultMessage = "Day off or holiday"/>;*/
    }
  };

  const filterRepeated = () => {
    let noRepeated = [];
    let noRepeatTemp = [];

    noRepeatTemp = filteredEvents.filter(ele => {
      if (ele.borderColor === 'transparent') {
        return false;
      }
      return true;
    })

    noRepeatTemp.map((element) => {
      if (element.type) {

        if (element.ticklerTitle !== undefined && element.ticklerTitle !== null && element.ticklerTitle !== '') {
          if (element.ticklerTitle.indexOf("=") > 0) {
            element.ticklerTitle = element.title;
          } else {
            element.title = element.ticklerTitle;
          }
        }

        if (element.title !== undefined && element.title !== null && element.title !== '') {
          if (element.title.indexOf("=") > 0) {
            element.title = element.ticklerTitle;
          } else {
            element.ticklerTitle = element.title;
          }
        }
      }
      if (element.textColor !== "#ef9325") {
        let found = events.find(
          (event) =>
            event.start === element.start && event.textColor === "#ef9325" && event.borderColor !== 'transparent'
        );
        if (found) {
          noRepeated.push(found);
        } else {
          noRepeated.push(element);
        }
      }
    });

    noRepeated.sort((a, b) => {
      if (a.start > b.start) return -1;
      if (a.start < b.start) return 1;
      return 0;
    });

    return noRepeated;
  }

  useEffect(() => {
    setEventsNoRepeated(filterRepeated())
  }, [events])

  return (
    <div className="daysOffList">
      {eventsNoRepeated.map((event) => {
        return (
          <div className="dayOffElement" key={event.id}>
            <div className="imgCalendar">
              <div className="ico">
                <BsCalendar4 />
              </div>
              <div className="textIco">
                <Moment format="DD">{event.start}</Moment>
              </div>
            </div>
            <div
              className="eventDescription"
              style={{ color: event.textColor }}
            >
              {event.end && " to "}{" "}
              {event.end && (
                <Moment format="DD">
                  {moment(event.end)
                    .subtract(1, "day")
                    .format("YYYY-MM-DDThh:mm:ss")}
                </Moment>
              )}{" "}
              {event.ticklerTitle && event.type !== 'Event' ?
                event.ticklerTitle.indexOf("=") > 0 ?
                  event.ticklerTitle.split('=')[1]
                  : event.ticklerTitle
                : event.title ?
                  event.title.indexOf("=") > 0 ?
                    event.title.split('=')[1]
                    : event.title
                  : ''
              }
              {event.alertStr === "⚠" ? (
                <FormattedMessage
                  id="calendar.no.hours"
                  defaultMessage="⚠ No hours reported"
                />
              ) : event.textColor === "#ef9325" &&
                event.borderColor === "#ef9325" ? (
                <FormattedMessage
                  id="calendar.missing.hours"
                  defaultMessage="⚠ Missing hours"
                />
              ) :
                event.status === "accepted" ? (
                  ` (approved)`
                ) : event.status === "pending" ? (
                  ` (pending approval)`
                ) :
                  event.type === 'Event' ? ' ' + colorToTitle(event.textColor) : ''
              }
              {
                event.type === "Event" && event.title ?
                    `${event.title.indexOf("=") > 0 ? event.title.split("=")[1]: event.title} `
                    +`${!event.wholeDay ? event.startHour + "-" + event.endHour : ""}`
                  :null
              }
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DaysOffList;
