import AppRouter from "./config/routes";
import "./App.css";
import { AuthProvider } from "./context/AuthContext";
import { SessionValidator } from "./context/AuthContext/SessionValidation";
import { ProjectsProvider } from "./context/ProjectsContext/ProjectsContext";
import { SchedulerProvider } from "./context/SchedulerContext";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

library.add(fas);

function App() {
  return (
      <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <SessionValidator>
              <ProjectsProvider>
                <SchedulerProvider>
                  <BrowserRouter>
                    <AppRouter />
                  </BrowserRouter>
                </SchedulerProvider>
              </ProjectsProvider>
            </SessionValidator>
          </AuthProvider>
      </QueryClientProvider>
  );
}

export default App;
