import scss from './Field.module.scss';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

/**
 * @module
 * @description This component renders a dropdown list to be used in the signUp page.
 * @param {String} props.name: HTML attribute to be put in this component.
 * @param {Function} props.liftState: callback function to lift up this component's state.
 * @param {String} props.currentValue: current value of the parent's state according to this component.
 * @param {Array} props.positionList: list of company positions retrieved from the API.
 * @returns {JSX} a dropdown list containing the company's positions.
 */

const Position = (props) => {
  const [error, setError] = useState('');
  const handler = (event) => {
    props.liftState(event);
  };

  const positionFilteredAndOrdered = () => {
    let posFilteredAndOrdered = props.positionList
      .filter(item => item.companyFieldId === props.company_field_id)
      .sort((itemA, itemB) => {
        if(itemA.name < itemB.name) {
          return -1;
        }
        if(itemA.name > itemB.name) {
          return 1;
        }
        return 0;
      });
    return posFilteredAndOrdered;
  }

  return (
    <>
      {props.positionList.length === 0 ? (
        error ? (
          <p>{error}</p>
        ) : (
          <p>Loading...</p>
        )
      ) : (
        <div className={scss.field}>
          <label>What is your position?</label>
          <select
            onChange={(event) => handler(event)}
            name={props.name}
            value={props.currentValue}
          >
            <>
              <option value="" hidden>
                Select your company position
              </option>
              {positionFilteredAndOrdered().map((item) => (
                <option key={item.id} value={item.name}>
                  {item.name}
                </option>
              ))}
            </>
          </select>
        </div>
      )}
    </>
  );
};

Position.propTypes = {
  name: PropTypes.string,
  liftState: PropTypes.func,
  currentValue: PropTypes.string,
  fieldList: PropTypes.array,
};

export default Position;
