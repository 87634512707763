import axios from "axios";
import { environment } from "../../config/environment";

const getAllLocation = async (userToken) => {
  let locations = await axios
    .get(`${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/company/locations`, {
      headers: {
        authorization: userToken,
      },
    })
    .then((result) => result)
    .catch((error) => {
      throw error.response || error.toJSON() || error
    });
  locations = await locations.data.data;


  return locations;
};

export { getAllLocation };
