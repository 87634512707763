import "./PlayerFloatMenu.scss";
import { useRef, useState, useContext } from "react";
import { useOutsideAlerter } from "../../hooks";
import FloatMenu from "../shared/FloatMenu";
import { DialogBox } from "./DialogBox";
import Swal from "sweetalert2";
import ProjectsContext from "../../context/ProjectsContext/ProjectsContext";
import {useIntl} from "react-intl";


export const PlayerFloatMenu = ({
  floatMenuOpen,
  handleFloatMenu,
  firstname,
  lastname,
  projectId,
  playerId,
  refetch,
  handlePlayerModal,
}) => {
  const [expandMenu, setExpandMenu] = useState(false);
  const floatMenu = useRef(null);
  const floatMenu2 = useRef(null);
  let intl = useIntl();

  const { removePlayers, inactivePlayer, auth } = useContext(ProjectsContext);

  const removeConfirmation = {
    principalText: "Are you sure you want to remove this allocation?",
    secondaryText:
      "The user will no longer be able to see or report hours in this project",
    confirmButtonText: `${intl.formatMessage({id : "button.confirm", defaultMessage : "Yes, I want"})}`,
    //confirm: `${firstname} has been removed!`,
  };

  // const inactiveConfirmation = {
  //   principalText: "Are you sure you want to inactive the user?",
  //   secondaryText: "Access to the entire system will be revoked",
  //   confirmButtonText: `${intl.formatMessage({id : "button.confirm", defaultMessage : "Yes, I want"})}`,
  //   //confirm: `${firstname} has been disabled`,
  // };

  // the next hook is used to detect if user clicks outside the menu, then it's closed
  useOutsideAlerter(floatMenu, handleFloatMenu, floatMenuOpen);

  const handleExpandMenu = () => {
    setExpandMenu(!expandMenu);
  };

  const setTrueMenu = () => {
    setExpandMenu(true);
  };

  useOutsideAlerter(floatMenu, handleExpandMenu, expandMenu);

  const removePlayer = async () => {
    await removePlayers(projectId, playerId)
      .then(() => {
        Swal.fire(`${firstname} has been removed!`, "", "success");
        refetch();
      })
      .catch(async (err) => {
        Swal.fire("Something went wrong", "Try again later", "error");
        console.error(err);
      });
  };

  // const inactive = async () => {
  //   await inactivePlayer(playerId)
  //     .then(() => {
  //       Swal.fire(`${firstname} has been disabled`, "", "success");
  //       refetch();
  //     })
  //     .catch( async (err) => {
  //       await Swal.fire("Something went wrong", "Try again later", "error");
  //       console.error(err);
  //     });
  // };

  const getMenuOptions = () => [
    //need to set as an argument the id project
    {
      text: "View profile",
      action: () => console.log("add status update"),
    },
    {
      text: "Edit assignment",
      action: () => handlePlayerModal("relocate"),
      addDividerAfter: true,
    },
    {
      text: "Remove from project",
      action: () => DialogBox(removeConfirmation, removePlayer),
    },
  ];

  return (
    <div
      ref={floatMenu}
      className={`player-menu ${floatMenuOpen ? "opened" : "closed"}`}
    >
      <div className="float-container" ref={floatMenu2}>
        <p>{`${firstname} ${lastname}`}</p>
        <button className="btn-player" onClick={handleExpandMenu}>
          ...
        </button>
      </div>
      <div
        className="expansive-menu"
        style={{ display: `${expandMenu ? "block" : "none"}` }}
      >
        <FloatMenu
          handleFloatMenu={setTrueMenu}
          floatMenuOpen={expandMenu}
          bottomRight
          menuOptions={getMenuOptions()} // here it's simulating that it's passing a project id, it'll be inside a map loop
        />
      </div>
    </div>
  );
};
