import React from "react";
import { environment } from "../../../config/environment";
import EmailIcon from "../../../Icons/EmailIcon";
import ChatIcon from "../../../Icons/ChatIcon";
import scss from "./Managers.module.scss";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import {useIntl} from "react-intl";
/**
 * @module
 * @description This function creates an individual manager card and  can be different depending on what type of manager it is
 * @param {Object} item
 * @param {String} type
 * @returns {JSX} Manager
 */
const Manager = ({ item, type }) => {
  let intl = useIntl();
  const { email, firstName, lastName, position, photo, regionName } = item;
  let userPhoto = `${environment.S3_ROOT}${environment.S3_BUCKET}/${environment.S3_FOLDER}/${photo}`;
  if (photo?.slice(0, 4) === "http") {
    userPhoto = photo;
  }

  return (
    <div className={scss.ManagerContainer}>
      <div className={scss.ManagerPhoto}>
        <figure>
          <img src={userPhoto} alt="Manager" />
        </figure>
      </div>
      <div className={scss.ManagerInfo}>
        <h3 className="manager-name">
          {firstName} {lastName}
        </h3>

        {type === "titan" ? (
          <h4 className="manager-name">{regionName}</h4>
        ) : (
          <h4 className="manager-name">{position}</h4>
        )}
        <div className={scss.Icons}>
          <span
            onClick={() =>
              window.open(`mailto:${email}?subject=Greetings ${firstName}!`)
            }
            data-tip={intl.formatMessage({id: "send.message", defaultMessage: "Send message"})}
            data-for="message"
          >
            <ChatIcon />
            <ReactTooltip id="message" place="bottom" backgroundColor="#3FAA58" />
          </span> &nbsp;&nbsp;
          <span
            onClick={() =>
              window.open(`mailto:${email}?subject=Greetings ${firstName}!`)
            }
            data-tip={intl.formatMessage({id: "send.email", defaultMessage: "Send email"})}
            data-for="email"
          >
            <EmailIcon />
            <ReactTooltip id="email" place="bottom" backgroundColor="#3FAA58" />
          </span>
        </div>
      </div>
    </div>
  );
};

Manager.propTypes = {
  item: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default Manager;
