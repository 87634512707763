import React from "react";
import ReactTooltip from "react-tooltip";

function PartialTimeIcon() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        version="1"
        viewBox="0 0 156 312"
        data-tip="Allocation type"
        data-for="allocation-type"
      >
        <path
          d="M2 2844l3-269 90-7c234-18 485-141 648-317 419-455 346-1160-158-1523-129-92-312-160-488-180l-99-11 4-263C3 130 8 9 11 5c10-9 209 11 305 30 195 40 422 139 591 258 112 79 281 248 360 360 374 531 386 1233 28 1767-270 403-712 661-1178 687l-117 6 2-269zm675-231c151-115 234-188 239-210 13-50-38-103-83-86-39 14-284 218-290 241-11 44 25 89 74 91 6 1 33-16 60-36z"
          transform="matrix(.1 0 0 -.1 0 312)"
        ></path>
        <path
          d="M0 2265c0-140 2-165 15-165 8 0 24-9 35-20 18-18 20-33 20-185 0-91 3-165 6-165 4 0 29 20 55 45 31 29 57 45 74 45 35 0 65-33 65-73 0-29-15-48-118-150-66-64-126-117-135-117-16 0-17-34-17-396V687l82 6c375 28 698 319 774 696 25 125 16 307-21 421-102 319-352 543-675 604-45 9-100 16-121 16H0v-165z"
          transform="matrix(.1 0 0 -.1 0 312)"
        ></path>
      </svg>
      <ReactTooltip id="allocation-type" place="bottom" backgroundColor="#3FAA58"/>
    </>
  );
}

export default PartialTimeIcon;
