import axios from "axios";
import { environment } from "../../../config/environment";
import postUserTags from "./postUserTags";;

const ticklerServices = {
  put: async (userTimesheetsData, userId, token) => {
    try {
      const data = await axios.put(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/users/${userId}/tickler`,
        userTimesheetsData,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return data;
    } catch (err) {
      throw (
        err.response || err.toJSON() || {
          success: false,
          message: "An error has occurred, please try again",
        }
      );
    }
  },
  post: async (ticklerTask, userId, token) => {
    try {
      const data = await axios.post(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/users/${userId}/tickler`,
        ticklerTask,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return data;
    } catch (err) {
      throw (
        err.response || err.toJSON() || err
      );
    };
  },
  get: async (userId, date, token) => {
    try {
      const data = await axios.get(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/users/${userId}/tickler?date=${date}`,
        {
          headers: {
            authorization: token,
          },
        }
      );
      return data;
    } catch (err) {
      throw (
        err.response || err.toJSON() || err
      );
    };
  }
};

const postTicklerTask = async (data, userId, token) => {
  let tags = undefined;

  if (data.tags?.length) {
    tags = data.tags.join(",");
  };

  if (tags) await postUserTags(userId, token, { tags })
    .then((r) => console.log(r))
    .catch((error) => {
      throw error.response || error.toJSON() || error
    });

  return await ticklerServices.post(data, userId, token);
};

const getTicklerTask = async (date, userId, token) => {
  return await ticklerServices.get(userId, date, token);
};

const finalizeTicklerTask = async (data, userId, token) => {
  return await ticklerServices.put(data, userId, token);
};

export { getTicklerTask, postTicklerTask, finalizeTicklerTask };
