import React, { useEffect, useState } from "react";
import axios from "axios";
import { environment } from "../../config/environment";
import { useAuthContext } from "../../context/AuthContext";
import { GlobalComponent, MyLocation } from "../../components/Icons/index";
import SingleNews from "./SingleNews";
import css from "./NewsById.module.scss";
import scss from "./News.module.scss";
import moment from "moment";
import { Breadcrumb } from "react-bootstrap";
import { Link, Navigate, useParams } from "react-router-dom";
import { ActionButton } from "../../components/shared";
import { useNavigate } from "react-router-dom";
import AddNews from "./AddNews/AddNews";
import data from "../../version.json";

function News() {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [images, setImages] = useState([]);
  const navigate = useNavigate();
  let version = data;
  let currentVersion = version[version.length - 1];
  const { user } = useAuthContext();
  let permissionsAr = [];
  const [permiss, setPermiss] = useState([])
  const auth = useAuthContext();
  const { region } = useParams();
  const context = useAuthContext();

  const trimDescription = (description) => {
    const length = 120;
    return description.substring(0, length);
  };

  useEffect(() => {
    let isMounted = true;
    (function () {
      const objNews = {
        local: `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module0/news/${context?.userInfo?.region}`,
        global: `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module0/news/global`,
        individual: `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module0/news/article/:id`,
      };

      let filterNews=[];
      let tempImage=[];
      let tempLoading=true;
      const response = axios
        .get(objNews[region === "global" ? "global" : "local"], {
          headers: {
            Authorization: context.user.token,
          },
        })
        .then((response) => {
          axios
            .get(objNews.local, {
              headers: {
                Authorization: context.user.token,
              },
            })
            .then((response) => {

              if (isMounted) {
                filterNews =[ ...response.data.data]
                let array = [];
                for (let x = 0; x < response.data.data.length; x++) {
                  array[x] = response.data.data[x].noticeId;
                }
                tempImage=[ ...array];
                tempLoading=false;
              }
            });
          if (isMounted) {
            if(region === 'global') {
              filterNews =[...filterNews, ...response.data.data.filter(item => item.isGlobal)]
            }else {
              filterNews = [...filterNews, ...response.data.data.filter(item => !item.isGlobal)]
            }
            setNews(filterNews);
            let array = [];
            for (let x = 0; x < response.data.data.length; x++) {
              array[x] = response.data.data[x].noticeId;
            }
            tempLoading=[...tempLoading, ...array]
            setImages(tempLoading);
            setLoading(false);
          }
        })
        .catch((error) => {
          setError(error.message);
          setLoading(false);
        });

      if(region==='global'){
        axios
          .get(objNews.local, {
            headers: {
              Authorization: context.user.token,
            },
          })
         .then((response) => {
           if (isMounted) {
             filterNews =[...filterNews, ...response.data.data.filter(item => item.isGlobal)];
             let tempNews = [];
             filterNews.forEach((item)=>{
               let push = true;
               for (const itemElement of tempNews) {
                 if(itemElement.noticeId !== item.noticeId){
                   push =  true;
                 }else{
                   push = false;
                   break;
                 }
               }
               if(push){
                 tempNews.push(item);
               }
             })
             filterNews =tempNews;

             filterNews = filterNews.sort(function (a, b) {
               if (a.datePublished < b.datePublished) {
                 return 1;
               }
               if (a.datePublished > b.datePublished) {
                 return -1;
               }
               // a must be equal to b
               return 0;
             });
             setNews([...filterNews]);
             let array = [];
             for (let x = 0; x < response.data.data.length; x++) {
               array[x] = response.data.data[x].noticeId;
             }
             tempImage=[...tempImage, ...array];
             setImages([...tempImage])
             tempLoading=false;
           }
         })
      }

    })();
    return () => (isMounted = false);
  }, []);

  useEffect(() => {}, [images]);

  const type = region !== "global" ? "local" : "global";
  const assets = {
    global: {
      icons: <GlobalComponent />,
      text: "Global",
      page: "/",
      path: "/news/region/global",
    },
    local: {
      icons: <MyLocation />,
      text: context?.userInfo?.region,
      page: "/location",
      path: `/news/region/${context?.userInfo?.region}`,
    },
  };

  useEffect(()=>{
    let permissions = user.permissions.userPermissions;
    let redirect = true;
    for (const permission of permissions) {
      if(permission.permission.name === 'view-news'){
        redirect = false;
      }
    }
    if(redirect){
      navigate("/")
    }
  },[])

  useEffect(()=>{
    permissionsAr = user.permissions.userPermissions;
    let permissionsArray = [];
    for (const permissionElement of permissionsAr) {
      if(permissionElement.locations && permissionElement.locations.name === auth.userInfo.location) {
        permissionsArray.push(permissionElement.permission.name);
      }
    }
    setPermiss([...permissionsArray])
  },[])

  return (
    <div className={scss.container}>
      <div className={scss.top}>
        <Breadcrumb className={scss.breadCrumb}>
          <Breadcrumb.Item>
            <div className={scss.global}>
              <div className={scss.icon}>{assets[type].icons}</div>
              <Link className={scss.newsLayout} to={assets[type].page}>
                {assets[type].text}
              </Link>
            </div>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link className={scss.news} to={assets[type].path}>
              News
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        {region === "global" && permiss.includes('create-global-news') ?
          <ActionButton
            secondary="tertiary"
            type="submit"
            onClick={() => navigate("/news/addNews/global", { replace: true })}
          >
            Add news
          </ActionButton> : null
        }
        {region === "local" && permiss.includes('create-local-news') ?
          <ActionButton
            secondary="tertiary"
            type="submit"
            onClick={() => navigate("/news/addNews/local", { replace: true })}
          >
            Add news
          </ActionButton> : null
        }

      </div>
      <div className={scss.header}>
        <div className={scss.p1}>
          {news.length} New news in the last 365 days
        </div>
        <div className={scss.p2}>Date</div>
        <div className={scss.p3}>Publisher</div>
        <p></p>
      </div>
      <div className={scss.cards}>
        {news.map((e) => {
          return (
            <SingleNews
              title={e.title}
              resume={trimDescription(e.shortDescription).concat("...")}
              authorPhoto={e.authorPhoto}
              date={moment(e.datePublished).format("MMM DD YY")}
              description={e.description}
              author={e.authorName}
              noticeId={e.noticeId}
              region={region}
              e={e}
              key={e.noticeId}
            />
          );
        })}
      </div>
      <div className={css.version}>
        <p className={css.versionText}>{currentVersion["Current version"]}</p>
      </div>
    </div>
  );
}

export default News;
