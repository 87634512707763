import React from "react";

function Record() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      fill="none"
      viewBox="0 0 13 13"
    >
      <path
        fill="#FF0101"
        d="M7.882 1.971C6.727 1.313 5.831.801 5.102.469 4.371.136 3.736-.05 3.12.012a3.49 3.49 0 00-2.452 1.4C.3 1.91.147 2.55.073 3.342 0 4.133 0 5.156 0 6.473v.054c0 1.317 0 2.34.073 3.13.074.792.227 1.433.594 1.931a3.49 3.49 0 002.452 1.4c.617.064 1.252-.124 1.983-.457.729-.332 1.625-.844 2.78-1.502l.047-.027c1.155-.658 2.052-1.17 2.707-1.627.657-.458 1.141-.908 1.395-1.472a3.412 3.412 0 000-2.807c-.254-.563-.738-1.013-1.395-1.471-.655-.457-1.552-.969-2.707-1.627l-.047-.027z"
      ></path>
    </svg>
  );
}

export default Record;
