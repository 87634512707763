import { useState, useContext } from "react";
import { LockIcon } from "../Icons";
import moment from "moment";
import { getFileIcon } from "../../utils/getFileIcon";
import { FloatMenu } from "../shared";
import { DialogBox } from "../shared/DialogBox";
import ProjectsContext from "../../context/ProjectsContext/ProjectsContext";
import fileDownload from "js-file-download";
import {useIntl} from "react-intl";

export const File = ({ file, callback }) => {
  const [floatMenuOpen, setFloatMenuOpen] = useState(false);
  const { deleteFileTotally, getFile, changeFilePrivacity, auth } =
    useContext(ProjectsContext);
  let intl = useIntl();

  const deleteConfirmation = {
    principalText: "Are you sure you want to delete the file?",
    secondaryText: "File will be deleted completely",
    confirmButtonText: `${intl.formatMessage({id : "button.confirm", defaultMessage : "Yes, I want"})}`,
    confirm: `The file has been removed!`,
  };

  const handleFloatMenu = () => {
    setFloatMenuOpen(!floatMenuOpen);
  };

  const deleteFile = () => {
    const payload = {
      file: file.pfFileName,
    };
    deleteFileTotally(payload)
      .then((res) => callback())
      .catch((err) => {
      });
  };

  const downloadFile = () => {
    getFile(file.pfFileName)
      .then((res) => fileDownload(res.data, file.pfFileName))
      .catch((err) => {
      });
  };

  const privacity = () => {
    changeFilePrivacity(file.pfId)
      .then((res) => callback())
      .catch((err) => {
      });
  };

  const getMenuOptionsActive = () => [
    {
      text: "Download",
      action: () => downloadFile(),
    },
    {
      text: file.pfIsConfidential ? "Make public" : "Make private",
      action: () => privacity(),
    },
    {
      text: "Delete",
      action: () => DialogBox(deleteConfirmation, deleteFile),
    },
  ];
  return (
    <>
      <div className="file-icons" onClick={handleFloatMenu}>
        {getFileIcon(file.pfFileName)}
        {file.pfIsConfidential && <LockIcon />}
        <div className="doc-menu">
          <FloatMenu
            handleFloatMenu={handleFloatMenu}
            floatMenuOpen={floatMenuOpen}
            menuOptions={getMenuOptionsActive()}
            bottomRight
          />
        </div>
      </div>

      <div className="file-description">
        <p> {file.pfFileName} </p>
        <p>
          by {file.firstName} {file.lastName} on{" "}
          {moment(file.pfCreatedAt).format("MM/DD/yyyy")}
        </p>
      </div>
    </>
  );
};
