import React from "react";

function EditIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="14"
      fill="none"
      viewBox="0 0 11 14"
    >
      <path
        fill="#171F5E"
        fillRule="evenodd"
        d="M8.552 1.06C7.945.7 7.438.401 7.005.22c-.458-.193-.914-.29-1.4-.158-.485.134-.832.452-1.134.853-.286.38-.579.9-.93 1.522L.515 7.806c-.203.36-.37.654-.453.97-.012.045-.023.091-.031.137-.068.37-.013.743.058 1.213l.019.127c.13.873.236 1.592.398 2.138.168.57.428 1.065.938 1.367.51.301 1.057.284 1.624.149.542-.13 1.203-.395 2.006-.716l.117-.047c.433-.173.775-.31 1.054-.555.279-.246.462-.572.694-.984l3.026-5.37c.35-.622.643-1.14.822-1.585.188-.469.283-.936.153-1.433-.13-.498-.44-.853-.832-1.163-.371-.293-.878-.593-1.486-.952l-.07-.042zm.086 5.131l-4.395-2.6L1.57 8.335c-.292.516-.362.656-.388.8-.027.145-.011.301.077.891.138.93.234 1.564.368 2.017.129.438.262.593.402.675.14.083.338.124.772.02.45-.107 1.034-.34 1.89-.682.542-.217.682-.281.79-.377.11-.096.193-.228.484-.745l2.673-4.743zm.584-1.04c.22-.396.378-.7.481-.957.138-.345.143-.524.106-.667-.037-.142-.13-.295-.417-.523-.301-.238-.738-.498-1.39-.884-.652-.385-1.09-.643-1.443-.791-.336-.142-.511-.147-.65-.109-.14.039-.289.133-.51.427-.167.221-.344.513-.57.906l4.393 2.598z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default EditIcon;
