import React from "react";
/**
 * @description Function to create the email icon as a JSX component
 * @returns {JSX} EmailIcon
 */

const InformationIcon = () => {
  return (
    <svg
      width="15.5"
      height="15.5"
      viewBox="0 0 15.5 15.5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M0 7.875c0-3.281 0-4.922.836-6.072.27-.371.596-.698.967-.967C2.953 0 4.594 0 7.875 0s4.921 0 6.072.836c.371.27.698.596.968.967.835 1.15.835 2.791.835 6.072s0 4.921-.835 6.072c-.27.371-.597.698-.968.967-1.15.836-2.791.836-6.072.836s-4.922 0-6.072-.836a4.374 4.374 0 0 1-.967-.967C0 12.797 0 11.156 0 7.875zm7.875-1.531c.362 0 .656.294.656.656v5.25a.656.656 0 0 1-1.312 0V7c0-.362.294-.656.656-.656zm0-1.094a.875.875 0 1 0 0-1.75.875.875 0 0 0 0 1.75z" fill="#217DD1"/>
    </svg>
  );
};

export default InformationIcon;
