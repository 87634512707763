import { useState, useEffect } from "react";
import {
  Link,
  Navigate,
  useParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import css from "./NewsById.module.scss";
import { Breadcrumb } from "react-bootstrap";
import { GlobalComponent, MyLocation } from "../../components/Icons/index";
import { useAuthContext } from "../../context/AuthContext/AuthContext";
import { getNews } from "../../components/Core/services/news";
import { environment } from "../../config/environment";
import moment from "moment";
import { ActionButton } from "../../components/shared";
import updateNews from "../../components/Core/services/updateNews";
import FilesDropzone from "./FilesDropzone";
import { getImage, ImagePath } from "../../components/Core/services/images";
import axios from "axios";
import EditComponent from "../../components/Icons/EditComponent";
import data from "../../version.json";
import { useIntl } from "react-intl";
import css2 from "./AddNews/AddNews.module.scss";

//Layout definition

const NewsById = () => {
  // id from url for future request to API
  const { id } = useParams();
  const context = useAuthContext();
  const { user } = useAuthContext();
  const auth = useAuthContext();
  const location = useLocation();
  let version = data;
  let currentVersion = version[version.length - 1];
  const [isEdit, setIsEdit] = useState(location.state);
  const [article, setArticle] = useState(location.state);
  const navigate = useNavigate();
  const [input, setInput] = useState(true);
  const [isActive, setActive] = useState(false);
  const [box, setBox] = useState(false);
  const [title, setTitle] = useState(false);
  const [updateTitle, setUpdateTitle] = useState(article.title);
  const [updateText, setUpdateText] = useState(article.text);
  const [files, setFiles] = useState([]);
  const [imagePath, setImagePath] = useState("");
  const [image, setImage] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [imageName, setImageName] = useState("");
  const [selectedFile, setSelectedFile] = useState({
    file: [],
  });
  const [imgUrl, setImgUrl] = useState("");
  const intl = useIntl();

  const cantEdit = () => {
    let permissions = user.permissions.userPermissions;
    let canEdit = false;
    for (const permissionElement of permissions) {
      if(permissionElement.locations && permissionElement.locations.name === auth.userInfo.location) {
        if(permissionElement.permission.name === 'update-news'){
          canEdit = true;
        }
      }
    }
    return canEdit;
  }

  useEffect(() => {
    async function getData(isMounted) {
      try {
        const response = await getNews("", context, id);
        if (isMounted) {
          let notAWSPicture = response.authorPhoto;

          let userPhoto = `${environment.S3_ROOT}${environment.S3_BUCKET}/${environment.S3_FOLDER}/${response.authorPhoto}`;
          if (response.authorPhoto?.slice(0, 4) === "http") {
            userPhoto = notAWSPicture;
          }
          response.authorPhoto = userPhoto;
          setUpdateTitle(response.title)
          setUpdateText(response.description)
          setArticle(response);
        }
      } catch (error) {
        // Error handling
      }
    }
    let isMounted = true;
    // If no article was passed by useLocation make API call
    if (!article?.id) {
      // API call
      getData(isMounted);
    }

    return () => (isMounted = false);
  }, [article, location.state]);

  useEffect(async () => {
    if (id === "global") {
      setTimeout(() => {
        console.log("Loading Image...");
      }, 5000);
    } else {
      try {
        const photo = await getImage(id, context?.user?.token);
        setImagePath(photo);
        const photoLink = await ImagePath(id, context?.user?.token);
        setImage(photoLink);
      } catch (error) {}
    }
  });

  useEffect(() => {
    if (isEdit && isEdit.isEdit === true) {
      setBox(true);
      setInput(!input);
      setActive(true);
      setTitle(true);
    }
  }, [location.state]);

  useEffect(() => {
    setUpdateText(article.description);
    setUpdateTitle(article.title);
    setImageName(article.portrait)
  },[article]);

  const handleChange = async (e, type) => {
    if (imageName === null || imageName === "") {
      setImageName(image);
    }
    if (type === "title") {
      setUpdateTitle(e.target.value);
      if (updateText === "" || updateText === null) {
        setUpdateText(article.description);
      }
    } else if (type === "text") {
      setUpdateText(e.target.value);
      if (updateTitle === "" || updateTitle === null) {
        setUpdateTitle(article.title);
      }
    }
  };

  useEffect(async () => {
    try {
      let photo = await getImage(id, auth?.user?.token);
      photo=photo.replace('/assets/newsPhotos', '/newsFiles')
      photo=photo.split("?")[0]
      setImgUrl(photo);
    }
    catch (error) {
      console.log(error);
    } 

  });

  const handleSubmit = async () => {
    try {
      const response = await updateNews(
        updateText,
        updateTitle,
        context.userInfo.region,
        id,
        article.type,
        imageName,
        context.user.id,
        user.token
      );

      if (type === "global") {
        navigate("/news/region/global", { replace: false });
      } else if (type === "local") {
        navigate("/news/region/local", { replace: false });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const splitImgPath = (path) => {
      let photo = path.replace('/assets/newsPhotos', '/newsFiles')
      photo = photo.split("?")[0]
      return photo;
  }

  const postFile = async (bodyFormData) => {
    const response = await axios
      .post(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module0/news/image`,
        bodyFormData,
        {
          headers: {
            Authorization: context?.user?.token,
          },
        }
      )

      .catch((err) => {
        throw err.response || err.toJSON() || err;
      });
    return response;
  };

  const handleImagePreview = (e) =>{
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  }

  const onUpload = (e) => {
    handleImagePreview(e);
    let bodyFormData = new FormData();
    bodyFormData.append("file", selectedFile.file);
    setImageName(`dev/assets/newsPhotos/${selectedFile?.file?.name}`);
    setTimeout(() => {
      postFile(bodyFormData)
        .then((res) => {
          setSelectedFile(null);
        })
        .catch((err) => {});
    }, 4000);
  };

  // If id is not valid go to main page
  if (!isNumeric(id)) return <Navigate to="/" />;

  // If null article is consider as local
  let hasArticle = !article ? false : true;
  let type;
  if(hasArticle){
    type= article.type !== "global" ? "local" : "global";
  }

  // Checking for server-side photos or others.
  let notAWSPicture = article?.authorPhoto;
  let userPhoto = `${environment.S3_ROOT}${environment.S3_BUCKET}/${environment.S3_FOLDER}/${article?.authorPhoto}`;

  if (article?.authorPhoto?.slice(0, 4) === "http") {
    userPhoto = notAWSPicture;
  }
  if(hasArticle){
    article.authorPhoto = userPhoto;
  }

  const marginX = input ? '0px': imagePreview !== null ? '25px' : '135px';
  const inputStyle = imagePreview !== null ? {marginLeft:'-150px', marginTop:'180px', zIndex:'2', position:'relative'}: { left:'-140px',marginTop:'180px', zIndex:'2', position:'relative'}
  const assets = {
    global: {
      icon: <GlobalComponent />,
      text: "Global",
      page: "/",
      path: "/news/region/global",
    },
    local: {
      icon: <MyLocation />,
      text: context?.userInfo?.region,
      page: "/location",
      path: `/news/region/${context?.userInfo?.region}`,
    },
  };

  return (
    <div className={css.container}>
      <div className={css.top}>
        <Breadcrumb className={css.breadCrumb}>
          <Breadcrumb.Item>
            <div className={css.global}>
              { hasArticle ? <>
                <div className={css.icon}>{assets[type].icon}</div>
                <Link className={css.newsLayout} to={assets[type].path}>
                  {assets[type].text}
                </Link>
                </>:null
              }
            </div>
          </Breadcrumb.Item>
          { hasArticle ?
          <Breadcrumb.Item>
            <Link className={css.newsLayout} to={assets[type].path}>
              News
            </Link>
          </Breadcrumb.Item>: null}
          <Breadcrumb.Item active>
            {title ? (
              <input
                type="text"
                className="edittitle"
                defaultValue={article.title}
                onChange={(e) => handleChange(e, "title")}
              ></input>
            ) : (
              <>
              { hasArticle ?
                article.title: ''}
                {input ? (
                  <></>
                ) : (
                  <div
                    onClick={() => {
                      setActive(!isActive);
                      setTitle(!title);
                    }}
                    className={css.editTitle}
                  >
                    <EditComponent />
                  </div>
                )}
              </>
            )}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className={css.bottom}>
        <div className={css.section1}>
          <div className={css.articlePicture}>
            <figure>
              <div className={css2.input}>
                <div className="element">
                  {imagePreview !== null ? <img src ={imagePreview} style={ {marginLeft: marginX, marginTop:'-30px', height:'150px', width:'120px'}}/>
                    : null}
                  {imagePath !== null && imagePreview===null ? <img src ={splitImgPath(imagePath)} style={ {marginLeft:marginX, marginTop:'-30px', height:'150px', width:'120px'}}/>
                    : null}
                </div>
              {input ? (
                true
              ) : (
                <div
                  onClick={() => setActive(!isActive)}
                  className={css.componentedit}
                >
                  {imagePreview != null ? <EditComponent style={{position:'relative', zIndex:'5', top:'0px',left:'-70px'}}/> :
                    <EditComponent style={{position:'relative', zIndex:'5', top:'0px',left:'-75px'}}/>}
                </div>
              )}
              {input ? (
                true
              ) : (
                <div onClick={() => setActive(!isActive)}>
                  <input
                    style={inputStyle}
                    type="file"
                    className={css.inputfile}
                    onInput={(e) => {
                      setSelectedFile({
                        ...selectedFile,
                        file: e.target.files[0],
                      });
                    }}
                    onChange={(e) => {
                      onUpload(e);
                    }}
                  ></input>
                </div>
              )}
              </div>
            </figure>
          </div>
        </div>
        <div className={css.section2}>
          <div className={css.postedBy}>
            Published by:
            {hasArticle ?
              <>
            <svg
              className={css.profilePicture}
              alt="profilePic"
              src={article.authorPhoto}
              style={{ backgroundImage: `url(${article.authorPhoto})` }}
            ></svg>
            <em className={css.author}>{article.authorName}</em>
              </>: null}
            {input && cantEdit()? (
              <ActionButton
                secondary="tertiary"
                type="submit"
                onClick={() => {
                  setInput(!input);
                }}
              >
                Edit Content
              </ActionButton>
            ) : (
              <div></div>
            )}
            {hasArticle ?
            <div className={css.postedOn}>
              {moment(article.datePublished).format("MMMM DD Y")}
              {input ? (
                true
              ) : (
                <div
                  onClick={() => {
                    setActive(!isActive);
                    setBox(!box);
                    setInput(false);
                  }}
                  className={css.editContent}
                >
                  <EditComponent />
                </div>
              )}
            </div> : null}
          </div>

          {box ? (
            <div className={css.articleText}>
              <textarea
                onChange={(e) => handleChange(e, "text")}
                defaultValue={article.description}
              ></textarea>
            </div>
          ) : hasArticle?
            <div className={css.articleText}>{article.description}</div>:null
          }
          {/* {files.length > 0 && (
            <div className="card">
              <ul className="list-group list-group-flush">
                {files.map((file, index) => (
                  <li className="list-group-item" key={index}>
                    <a href={file.pfFileLink}>{file.pfFileName}</a>
                  </li>
                ))}
              </ul>
            </div>
          )} */}
          {!input ? (
            <FilesDropzone id={id} />
          ) : (
            <div></div>
          )}
          {input ? (
            <div></div>
          ) : (
            <div>
              <ActionButton
                secondary="tertiary"
                type="submit"
                onClick={() => setInput(!input)}
              >
                Close
              </ActionButton>
              <ActionButton
                secondary="tertiary"
                type="submit"
                onClick={() => handleSubmit()}
              >
                Save
              </ActionButton>
            </div>
          )}
        </div>
      </div>

      <div className={css.version}>
        <p className={css.versionText}>{currentVersion["Current version"]}</p>
      </div>
    </div>
  );
};

function isNumeric(str) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  );
}

export default NewsById;
