import React, { useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import "./Dropzone.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faPlayCircle } from "@fortawesome/free-solid-svg-icons";

/**
 * baseStyle
 * @type {{flex: number,
 * display: string,
 * flexDirection: string,
 * alignItems: string,
 * fontSize: string,
 * padding: string,
 * borderWidth: number,
 * borderRadius: number,
 * borderColor: string,
 * borderStyle: string,
 * backgroundColor: string,
 * color: string,
 * outline: string,
 * transition: string}}
 */

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  fontSize: "1.5rem",
  padding: "90px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  //   position: "absolute"
};

/**
 * activeStyle
 * @type {{
 * borderColor: string
 * }}
 */
const activeStyle = {
  borderColor: "#2196f3",
};
/**
 * acceptStyle
 * @type {{
 * borderColor: string
 * }}
 */
const acceptStyle = {
  borderColor: "#00e676",
};
/**
 * rejectStyle
 * @type {{
 * borderColor: string
 * }}
 */
const rejectStyle = {
  borderColor: "#ff1744",
};

/**
 * @description This function create the Dropzone
 * @param {any} children get data
 * @returns {JSX} Dropzone
 */

function Dropzone({ children }) {
  const [files, setFiles] = useState([]);

  // when the file is accepted the following func'll be executed
  const onDropAccepted = (acceptedFiles) => {
    setFiles(acceptedFiles[0]);
  };

  const onDropRejected = (res) => {
    const error = res[0]?.errors[0]?.code;
    console.log(error);
  };

  const {
    getRootProps,
    acceptedFiles,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: [
      "application/vnd.ms-excel",
      "application/vnd.ms-excel",
      "application/vnd.ms-excel",
      "application/vnd.ms-excel.sheet.macroEnabled.12",
      "application/msexcel",
      "application/x-msexcel",
      "application/x-ms-excel",
      "application/x-excel",
      "application/x-dos_ms_excel",
      "application/xls",
      "application/x-xls",
      "application/x-msi",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/pdf",
      "application/vnd.ms-powerpoint",
      "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/msword",
      "application/vnd.ms-word.document.macroEnabled.12",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "image/jpeg",
      "image/jpg",
      "image/png",
    ],
    noClick: true,
    maxFiles: 1,
    maxSize: 37000000,
    onDropAccepted,
    onDropRejected,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const onRemoveVideo = () => {
    setFiles([]);
  };

  return (
    <div
      className="projects-dropzone"
      {...getRootProps()}
    >
      {children}
      <div className="box">
        <input {...getInputProps()} />
        {isDragActive && (
          <p className="text-center">Drop your file on the box</p>
        )}
        {files.length && <div></div>}
      </div>
    </div>
  );
}
export default Dropzone;
