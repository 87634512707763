import { createContext, useContext, useState } from "react";
import { userAuthServices } from "../../services/Login/userAuthServices";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import btsLogo from "../../assets/BTS-Logo/bts-logo.svg";
import PropTypes from "prop-types";
import useLocalStorage from "../../hooks/useLocalStorage";
const AuthContext = createContext();

/**
 * @description This function creates a react context that stores different information and functions that are used across all the application
 * this is made in order to avoid prop drilling through the components
 * @param {Element} children
 * @returns {JSX} AuthProvider
 */

export function AuthProvider({ children }) {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem("userInfo")));

  const MySwal = withReactContent(Swal);

  useLocalStorage(user, setUser, "user", userInfo, setUserInfo, "userInfo");

  const googleLogin = async (tokenId) => {
    localStorage.setItem('googleToken', tokenId)
    try {
      const userBasicInfo = await userAuthServices.login(tokenId);

      const userProfileInfo = await userAuthServices.userInfo(
        userBasicInfo.data.id,
        userBasicInfo.data.token
      );

      if (!userBasicInfo.isSignedUp) {
        localStorage.setItem("user", JSON.stringify(userBasicInfo.data));
        setUser(userBasicInfo.data);

        // Sending a need to sign up message.
        MySwal.fire({
          title: "We need to finish your registration process",
          text: "Please, wait a moment",
          imageUrl: btsLogo,
          imageWidth: 150,
          imageHeight: 150,
          imageAlt: "BTS Logo",
          backdrop: `
          rgba(0,0,0,0.9)`,
          timer: 2000,
          showConfirmButton: false,
        });
      } else {
        localStorage.setItem("user", JSON.stringify(userBasicInfo.data));
        localStorage.setItem("userInfo", JSON.stringify(userProfileInfo.data));
        setUser(userBasicInfo.data);
        setUserInfo(userProfileInfo.data);

        // Sending an OK message.
        MySwal.fire({
          title: "Setting up your session",
          text: "Your session is being secured, please hold on for a moment...",
          imageUrl: btsLogo,
          imageWidth: 150,
          imageHeight: 150,
          imageAlt: "BTS Logo",
          backdrop: `
          rgba(0,0,0,0.9)`,
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      MySwal.fire({
        title: "Couldn't log in",
        text: "Please, try again or contact an administrator",
        icon: "error",
        timer: 3000,
      });
    }
  };

  const googleSignUp = async (body, onSuccSignUp: () => {}) => {
    try {
      const userProfileInfo = await userAuthServices.signup(body, user?.token);
      localStorage.setItem("userInfo", JSON.stringify(userProfileInfo.data));
      setUserInfo(userProfileInfo.data);
      onSuccSignUp();
    } catch (error) {
      MySwal.fire({
        title: "Oops!",
        text: error.message,
        icon: "error",
        imageWidth: 150,
        imageHeight: 150,
        imageAlt: "BTS Logo",
        backdrop: `
        rgba(0,0,0,0.9)`,
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const logout = async (withMessage = true) => {
      // Sending a goodbye message.
      if (withMessage)
          await MySwal.fire({
              title: "Logged out",
              text: "We hope to see you soon!",
              imageUrl: btsLogo,
              imageWidth: 150,
              imageHeight: 150,
              imageAlt: "BTS Logo",
              backdrop: `
        rgba(0,0,0,0.9)`,
              timer: 2000,
              showConfirmButton: false,
          });
      localStorage.removeItem("user");
      localStorage.removeItem("userInfo");
      setUser(null);
      setUserInfo(null);
  };

  // TODO: Modularize
  return (
    <AuthContext.Provider
      value={{ user, userInfo, setUserInfo, logout, googleLogin, googleSignUp }}
    >
      {children}
    </AuthContext.Provider>
  );
}

AuthContext.propTypes = {
  children: PropTypes.element.isRequired,
};

/**
 * @description Creates the custom hook to use the AuthContext
 * @returns {Function} useAuthContext
 */

export function useAuthContext() {
  const context = useContext(AuthContext);
  if (context == null) throw new Error("Missing AuthProvider");
  return context;
}
