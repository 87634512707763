import { Modal, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState, useContext } from "react";
import DatePicker from "react-datepicker";
import { CalendarIcon } from "../../Icons";
import "./EditProjectDetailModal.scss";
import "react-datepicker/dist/react-datepicker.css";
import ActionButton from "../shared/ActionButton";
import moment from "moment";
import ProjectsContext from "../../context/ProjectsContext/ProjectsContext";
import Swal from "sweetalert2";
import CloseIcon from "../Icons/CloseIcon";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { getCurrentEpoch } from "../../utils/date";

/**
 * @module
 * @description This function creates the structure and the logic of the add/edit a project
 * @param {Boolean} show to see if the modal is open or not
 * @param {Function} handleProjectModal to control the modal
 * @returns {JSX} AddPlayerModal
 */

const EditProjectDetailModal = ({ show, handleProjectModal, project }) => {
  const [clientsList, setClientsList] = useState([]);
  const [loading, setLoading] = useState(false);

  const { editProjectById , auth } = useContext(ProjectsContext);
  const intl = useIntl();
  const navigate = useNavigate();

  /**
   * initialValues
   * @type {{
   * category: string,
   * contractType: string,
   * otherContractType: string,
   * previousNotices: string,
   * startDate: date,
   * estimatedEndDate: date
   * }}
   */
  const initialValues = {
    category: project.projectCategory,
    contractType: project.contractType,
    otherContractType: project.otherContractType,
    previousNotices: project.previousNotice,
    startDate: new Date(project.startDate),
    estimatedEndDate: new Date(project.endDate),
  };

  useEffect(() => {
    //data to fill the clients list until the endpoint is ready
    setClientsList([
      { label: "BTS", value: "100" },
      { label: "Nike", value: "101" },
      { label: "Samsung", value: "102" },
      { label: "Najib", value: "103" },
      { label: "Lenovo", value: "104" },
      { label: "Panasonic", value: "105" },
    ]);
  }, []);

  const EditProjectSchema = Yup.object({
    category: Yup.object({
      label: Yup.string(),
      value: Yup.string()
    }),
    contractType: Yup.object({
      label: Yup.string(),
      value: Yup.string()
    }),
    otherContractType: Yup.string()
    .when('contractType',{
      is: (contractType) => contractType.value === "Other",
      then: Yup.string().required("Please enter a specification"),
      otherwise: Yup.string().nullable()
    }),
    previousNotices: Yup.number(),
    startDate: Yup.date(),
    estimatedEndDate: Yup.date()
      .when('startDate',
        (startDate, EditProjectSchema2) => {
          const minDate = new Date()
          minDate.setDate(startDate.getDate() + 1)
          return EditProjectSchema2.min(minDate)
        })
  });

  const formik = useFormik({
    initialValues,
    validationSchema: EditProjectSchema,
    onSubmit: (values) => {
      const formatedStartDate = getCurrentEpoch(values.startDate);
      const formatedEndDate = getCurrentEpoch(values.estimatedEndDate);
      const payload = {
        projectCategory: values.category.label,
        contractType: values.contractType.label,
        otherContractType: values.contractType.label === "Other" ? values.otherContractType : null,
        previousNotices: values.previousNotices,
        startDate: moment(formatedStartDate).format("YYYY-MM-DD"),
        endDate: values.estimatedEndDate
          ? moment(formatedEndDate).format("YYYY-MM-DD")
          : values.estimatedEndDate,
      };

      setLoading(true);

      editProjectById(project.id, payload)
        .then(({ data: { status, data } }) => {
          setLoading(false);

          if (status === "success") {
            Swal.fire("The project was edited successfully!", "", "success");
            handleProjectModal();
            navigate(`/projects/${project.id}`);
          }
        })
        .catch((err) => {
          setLoading(false);
          Swal.fire("Something went wrong", "Try again later", "error");
          console.error(err);
        });
    },
  });

  const onHide = () => {
    formik.resetForm();
    handleProjectModal();
  };

  const categoryOptions = [
    { label: "External Project", value: "External Project" },
    { label: "Internal Project", value: "Internal Project" },
    { label: "Internal Initiative", value: "Internal Initiative" },
    { label: "Internal Group/Sector", value: "Internal Group/Sector" },
  ];

  const contractOptions = [
    { label: "Long-term", value: "Long-term" },
    { label: "Short-term", value: "Short-term" },
    { label: "Fixed-time", value: "Fixed-time" },
    { label: "Other", value: "Other" },
  ];

  const {
    category,
    contractType,
    otherContractType,
    previousNotices,
    startDate,
    estimatedEndDate,
  } = formik.values;

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        centered
        size="lg"
        className="edit-project-modal"
        backdrop={"static"}
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title className="modal-title">Edit project details</Modal.Title>
          <button
            type="button"
            className="close-icon"
            aria-label="Close"
            onClick={onHide}
          >
            <CloseIcon />
          </button>
        </Modal.Header>
        <Modal.Body>
          <form className="edit-project-form">
            <div className="form-field">
              <label htmlFor="category">Category*</label>
              <Select
                id="category"
                value={category}
                name="category"
                onChange={(selected) => {
                  formik.setFieldValue("category", selected);
                }}
                onBlur={formik.handleBlur}
                className="select-client"
                placeholder={project.projectCategory}
                options={categoryOptions}
                // defaultInputValue={project.projectCategory}
              />

              {formik.touched.category && formik.errors.category ? (
                <div className="formik-error">
                  <small>{formik.errors.category}</small>
                </div>
              ) : null}
            </div>

            <div className="form-field">
              <label htmlFor="contract-type">Contract type*</label>
              <Select
                id="contract-type"
                value={contractType}
                name="contractType"
                onChange={(selected) => {
                  formik.setFieldValue("contractType", selected);
                }}
                onBlur={formik.handleBlur}
                className="select-client"
                options={contractOptions}
                placeholder={project.contractType}
              />

              {formik.values.contractType.value === "Other" && (
                <div className="form-field">
                  <label className="other-contract-type">Other*</label>
                  <textarea
                    id="other-contract-type"
                    value={otherContractType}
                    name="otherContractType"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="form-control"
                    placeholder="Please specify"
                  />
                  {formik.touched.otherContractType &&
                  formik.errors.otherContractType ? (
                    <div className="formik-error">
                      <small>{formik.errors.otherContractType}</small>
                    </div>
                  ) : null}
                </div>
              )}

              {formik.touched.contractType && formik.errors.contractType ? (
                <div className="formik-error">
                  <small>{formik.errors.contractType}</small>
                </div>
              ) : null}
            </div>

            {/* <label htmlFor="previous-notice"> */}
            {formik.values.contractType.value === "Long-term" ? (
              <>
                <div className="form-field"></div>
                <div className="form-field">
                  <label htmlFor="previous-notices"></label>
                  <span>Previous notices*</span>
                  <div className="icon-outside-input">
                    <input
                      type="number"
                      id="previous-notices"
                      min="0"
                      max="100"
                      value={previousNotices}
                      name="previousNotices"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="form-control"
                      placeholder="0"
                    />
                    <span>days</span>
                  </div>
                  {formik.touched.previousNotices &&
                  formik.errors.previousNotices ? (
                    <div className="formik-error">
                      <small>{formik.errors.previousNotices}</small>
                    </div>
                  ) : null}
                </div>
              </>
            ) : (
              <></>
            )}

            {/* </label> */}

            <div className="form-field">
              <label htmlFor="start-date">Start date*</label>
              <div className="input-with-icon">
                <label>
                  <DatePicker
                    selected={startDate}
                    name="start-date"
                    onBlur={formik.handleBlur}
                    id="start-date"
                    placeholderText="Select a date"
                    dateFormat="yyyy-MM-dd"
                    onChange={(date) => formik.setFieldValue("startDate", date)}
                    className="form-control"
                    minDate={moment().subtract(10, "d").toDate()}
                  />
                  <CalendarIcon />
                </label>
              </div>
              {formik.touched.startDate && formik.errors.startDate ? (
                <div className="formik-error">
                  <small>{formik.errors.startDate}</small>
                </div>
              ) : null}
            </div>

            <div className="form-field">
              <label htmlFor="estimated-end-date">Estimated end date</label>
              <div className="input-with-icon">
                <label>
                  <DatePicker
                    selected={estimatedEndDate}
                    name="estimated-end-date"
                    onBlur={formik.handleBlur}
                    id="estimated-end-date"
                    placeholderText="Select a date"
                    dateFormat="yyyy-MM-dd"
                    onChange={(date) =>
                      formik.setFieldValue("estimatedEndDate", date)
                    }
                    className="form-control"
                    minDate={moment().toDate()}
                  />
                  <CalendarIcon />
                </label>
              </div>
              {formik.touched.estimatedEndDate &&
              formik.errors.estimatedEndDate ? (
                <div className="formik-error">
                  <small>{formik.errors.estimatedEndDate}</small>
                </div>
              ) : null}
            </div>
          </form>
        </Modal.Body>
        <div className="footer-btn">
          <Modal.Footer>
            <div>
              <ActionButton secondary onClick={onHide}>
                Cancel
              </ActionButton>
              <ActionButton disabled={loading} onClick={formik.submitForm}>
                {loading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Add"
                )}
              </ActionButton>
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};

export default EditProjectDetailModal;
