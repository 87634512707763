import React from "react";
import FileIcon from "../../Icons/FileIcon";
const FilePreview = ({ file }) => {
    return (
        <>
            <FileIcon fileType={file.pfFileName.split('.')[1]}></FileIcon>
            <a style={{ "textDecoration": "none" }} href={file.pfFileLink}>&#8195;{file.pfFileName}</a>
        </>
    );
}

export default FilePreview;