import axios from 'axios';
import PropTypes from 'prop-types';
import { environment } from '../../../config/environment';

/**
 * @module
 * @description This service calls the API in order to retrieve global managers, local managers,
 * titans or local titans depending on the user's information (location).
 * @param {String} type: type of manager to be retrieved (local, global, titan or localTitan).
 * @param {Object} context: user's context.
 * @returns {Array} Array of titans, local titans, managers or local managers.
 */

async function getManagers(type, context, setManagers, setLoading, setError) {
  try {
    const obj = {
      local: `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module0/company/managers?locationName=${context?.userInfo?.location}&regionName=${context?.userInfo?.region}`,
      global: `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module0/company/managers`,
      titan: `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module0/titans`,
      localTitan: `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module0/titans?regionName=${context?.userInfo?.region}`,
    };

    const response = await axios.get(obj[type], {
      headers: {
        Authorization: context.user.token,
      },
    });

    if (response.status !== 200) {
      throw Object.assign(new Error(), {
        code: response.status,
        message: `Couldn't retrieve ${type}`,
      });
    }

    return response.data.data;
  } catch (error) {
    throw error.response || error.toJSON() || error;
  }
}

getManagers.propTypes = {
  type: PropTypes.string.isRequired,
  context: PropTypes.object.isRequired,
};

export default getManagers;
