import { environment } from "../../config/environment";

const userAuthServices = {

  signup:  async (body, token) => {
    const options = {
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      method: "POST",
    };
    
    try {
      const response = await fetch(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module0/login/signup`,
        options
      );
      if (response.status !== 201) {

        if (response.status === 401) {

          throw Object.assign(new Error(), {
            code: 401,
            message: "Unauthorised",
          });
          
        }
        else if (response.status === 403) {

          throw Object.assign(new Error(), {
            code: 403,
            message: "User has already signed up",
          });

        }
        else {

          throw Object.assign(new Error(), {
            code: 500,
            message: "Unexpected error, please try again or contact an administrator",
          });

        }
      }

      const data = await response.json();

      return data;
    } catch (error) {
      if (!error.code) {
        error.code = 500;
        error.message = "Internal server error";
      }

      throw error;
    }
  },
  
  login: async (tokenId) => {

    const options = {
      body: JSON.stringify({ tokenId }),
      headers: { "Content-Type": "application/json" },
      method: "POST",
    };

    try {
      let isSignedUp = null;
      let basicData = null;
      const response = await fetch(`${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module0/login/redirect`, options);
      
      if (response.status === 401) {
        
        throw Object.assign(new Error(), { code: 401, message: "Unauthorised" });

      };

      // Checking with BE if the user has already signed up. This is done by receiving a 403 if a sign up is needed.
      if (response.status === 403) isSignedUp = false;
      else isSignedUp = true;

      basicData = await response.json();

      const responseData = {
       isSignedUp: isSignedUp,
       data: basicData.data,
      };
      
      return responseData;

    } 
    catch (error) {

      if (!error.code) error.code = 404

      if (!error.message) error.message = "Could not log in, server did not send the expected credentials";
      
      throw error;

    };
  },

  userInfo: async (userId, token) => {
    
    const options = {
      headers: {
        Authorization: `${token}`,
      },
      method: "GET",
    };

    try {

      const response = await fetch(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module0/users/${userId}/profile`, options);

      if (response.status === 401) {
        
        throw Object.assign(new Error(), { code: 401, message: "Unauthorized" });

      };

      const data = await response.json();

      return data;

    } 
    catch (error) {
      
      if (!error.code) error.code = 404

      if (!error.message) error.message = "Could not retrieve user information after logging in";

      throw error;

    };
  },
};

export { userAuthServices };
