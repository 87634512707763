import React from "react";

function AllocationDelete() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#2D264B"
        fillRule="evenodd"
        d="M8 .833A3.167 3.167 0 004.833 4v.167H2.666a.5.5 0 100 1h10.667a.5.5 0 000-1h-2.167V4A3.167 3.167 0 008 .833zm0 1c1.196 0 2.166.97 2.166 2.167v.167H5.833V4c0-1.197.97-2.167 2.167-2.167z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#2D264B"
        d="M3.83 5.953a.5.5 0 10-.995.094c.063.663.177 1.48.323 2.53l.188 1.348c.18 1.29.281 2.022.501 2.621.41 1.115 1.14 2.01 2.08 2.406.511.215 1.095.215 1.964.215h.217c.87 0 1.453 0 1.964-.215.94-.397 1.67-1.29 2.08-2.406.22-.6.322-1.33.502-2.62l.188-1.35c.146-1.05.26-1.866.322-2.529a.5.5 0 00-.995-.094c-.06.638-.171 1.434-.32 2.498l-.175 1.256c-.193 1.392-.282 2.009-.46 2.494-.344.936-.916 1.57-1.531 1.83-.3.126-.664.136-1.683.136-1.02 0-1.383-.01-1.683-.136-.615-.26-1.187-.894-1.531-1.83-.178-.485-.267-1.102-.46-2.494L4.15 8.451a66.694 66.694 0 01-.32-2.498z"
      ></path>
      <path
        fill="#2D264B"
        d="M7.167 6.667a.5.5 0 10-1 0V12a.5.5 0 101 0V6.667zm2.666 0a.5.5 0 00-1 0V12a.5.5 0 101 0V6.667z"
      ></path>
    </svg>
  );
}

export default AllocationDelete;