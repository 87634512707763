import React from "react";

function BillableIcon({billable}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      style={{filter: `grayscale(${billable})`}}
    >
      <path
        fill="#FF0101"
        fillRule="evenodd"
        d="M8.5 3.333a.5.5 0 00-1 0v.206c-.218.054-.432.14-.635.257-1.815 1.048-1.584 3.737.382 4.46l.253.093v3.13a1.466 1.466 0 01-.875-.68l-.25-.433a.5.5 0 00-.866.5l.25.433A2.467 2.467 0 007.5 12.502v.165a.5.5 0 001 0v-.206c.218-.054.432-.14.635-.257 1.815-1.048 1.584-3.737-.382-4.46L8.5 7.651v-3.13c.357.1.675.334.875.68l.25.434a.5.5 0 10.866-.5l-.25-.434A2.467 2.467 0 008.5 3.498v-.165zm-1.135 1.33c.044-.026.09-.05.135-.07V7.28c-1.085-.479-1.186-2.01-.135-2.618zM8.5 11.406V8.72c1.085.479 1.186 2.01.135 2.618-.044.025-.09.048-.135.069z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#FF0101"
        fillRule="evenodd"
        d="M10.976 1.582C10.214 1.5 9.255 1.5 8.03 1.5h-.06c-1.225 0-2.184 0-2.946.082-.777.085-1.406.26-1.944.65a3.833 3.833 0 00-.848.848c-.39.538-.565 1.167-.65 1.944C1.5 5.786 1.5 6.745 1.5 7.97v.06c0 1.225 0 2.184.082 2.946.085.777.26 1.406.65 1.944.237.325.523.611.848.848.538.39 1.167.565 1.944.65.762.082 1.721.082 2.946.082h.06c1.225 0 2.184 0 2.946-.082.777-.085 1.406-.26 1.944-.65a3.83 3.83 0 00.848-.848c.39-.538.565-1.167.65-1.944.082-.762.082-1.721.082-2.946v-.06c0-1.225 0-2.184-.082-2.946-.085-.777-.26-1.406-.65-1.944a3.831 3.831 0 00-.848-.848c-.538-.39-1.167-.565-1.944-.65zm-7.308 1.46c.339-.247.772-.39 1.464-.465C5.833 2.5 6.739 2.5 8 2.5s2.167 0 2.868.077c.692.075 1.125.218 1.464.464.24.175.452.386.627.627.246.339.39.772.464 1.464.076.701.077 1.607.077 2.868s0 2.167-.077 2.868c-.075.692-.218 1.125-.464 1.464-.175.24-.386.452-.627.627-.339.246-.772.39-1.464.464-.701.076-1.607.077-2.868.077s-2.167 0-2.868-.077c-.692-.075-1.125-.218-1.464-.464a2.835 2.835 0 01-.627-.627c-.246-.339-.39-.772-.464-1.464C2.5 10.167 2.5 9.261 2.5 8s0-2.167.077-2.868c.075-.692.218-1.125.464-1.464.175-.24.386-.452.627-.627z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default BillableIcon;