import './Players.scss';
import '../../index.css';
import { useState, useEffect } from 'react';
import { Player } from './index';
import { paintingBlack } from '../../utils/Projects/paintingBlack';

/**
 * @module
 * @description This function creates the structure and the logic of information of players
 * @param {string} members get info members
 * @param {number} position get position
 * @param {number} projectId get project Id
 * @param {Function} fetchingData get fetching Data
 * @returns {JSX} Players
 */

const Players = ({ members, position, projectId, refetch, moodId }) => {
  const [players, setPlayers] = useState([]);

  var index = players.length + 1;
  var left = -30;

  useEffect(() => {
    members.map((item) => (item.index = position));
    setPlayers(members);
  }, [members, position]);

  return (
    <>
      <div className="players">
        <div className="players-container" id={position}>
          {players.map((player, idx) => {
            index--;
            left += 30;
            return (
              idx <= 10 && (
                <div key={player.id} className="ok" id={player.id}>
                  <Player
                    playerInfo={player}
                    index={index}
                    left={left}
                    blackWhite={paintingBlack}
                    status={player.status}
                    projectId={projectId}
                    refetch={refetch}
                    moodId={moodId}
                  />
                </div>
              )
            );
          })}
          {members.length > 10 ? (
            <div className="total">+{members.length - 10}</div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default Players;
