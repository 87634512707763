import axios from "axios";
import { environment } from "../../../config/environment";

const editVacations = async (userId, token, body) => {
  try {
    const data = await axios.put(
      `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/users/${userId}/vacations`,
      body,
      {
        headers: {
          authorization: token,
        },
      }
    );
    return data;
  } catch (err) {
    throw (
      err.response || err.toJSON() || {
        success: false,
        message: "An error has occurred, please try again",
      }
    );
  }
};

export { editVacations };