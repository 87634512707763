import scss from './Field.module.scss';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

/**
 * @module
 * @description This component renders a dropdown list to be used in the signUp page.
 * @param {String} props.name: HTML attribute to be put in this component.
 * @param {Function} props.liftState: callback function to lift up this component's state.
 * @param {String} props.currentValue: current value of the parent's state according to this component.
 * @param {Array} props.locationList: company's location list retrieved from the API.
 * @returns {JSX} a dropdown list containing the company's locations.
 */

const Locations = (props) => {
  const [error, setError] = useState('');

  const handler = (event) => {
    props.liftState(event);
  };
  return (
    <>
      {props.locationList.length === 0 ? (
        error ? (
          <p>{error}</p>
        ) : (
          <p>Loading...</p>
        )
      ) : (
        <div className={scss.field}>
          <label>Where do you live?</label>
          <select
            onChange={(event) => handler(event)}
            name={props.name}
            value={props.currentValue}
          >
            <>
              <option value="" hidden>
                Select your location
              </option>
              {props.locationList.map((item) => (
                <option key={item.id} value={item.name}>
                  {item.name}
                </option>
              ))}
            </>
          </select>
        </div>
      )}
    </>
  );
};

Locations.propTypes = {
  'props.name': PropTypes.string,
  'props.liftState': PropTypes.func,
  'props.currentValue': PropTypes.string,
  'props.locationList': PropTypes.array,
};

export default Locations;
