 /**
 * @description This function takes a list of events and filters 
 * them according to the month and year provided as parameters 
 * @param events
 * @param {Number} month
 * @param {Number} year

 * @returns {Function} datesFilter
 */


export const datesFilter = (events, month, year) => {
  let selectMonth = month;
  if (selectMonth < 10) {
    selectMonth = "0" + selectMonth;
  }
  const startOfMonth = `${year}-${selectMonth}-01`;
  const endOfMonth = `${year}-${selectMonth}-31`;
  let filteredList = events.filter(
    (event) => (event.start.split("T")[0] >= startOfMonth && event.start.split("T")[0] <= endOfMonth) ||
      (event.start.split("T")[0] < startOfMonth && event.end >= startOfMonth)
  );
  return filteredList;
};
