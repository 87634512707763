import { useState, useEffect, useContext } from "react";
import { Spinner, Modal, Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  // faFlag,
} from "@fortawesome/free-solid-svg-icons";
import {
  MehFaceIcon,
  SmileFaceIcon,
  SadFaceIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  ArrowLeftIcon,
  InfoSquareIcon,
  OutdatedIcon,
} from '../Icons';
import { ActionButton } from '../shared';
import { faCheckCircle, faFlag } from '@fortawesome/free-regular-svg-icons';
import './ProjectUpdateModal.scss';
import ProjectsContext from '../../context/ProjectsContext/ProjectsContext';
import Swal from 'sweetalert2';
import { PlayersMoodTable } from './PlayersMoodTable';
import { useIntl } from 'react-intl';

const initialValues = {
  projectStatus: 1,
  teamMood: 1,
  clientSatisfaction: 1,
  publicOverview: "",
  internalOverview: "",

  //updates for specific area (optionals)
  clientUpdate: "",
  clientStatus: 1,

  scheduleUpdate: "",
  scheduleStatus: 1,

  scopeUpdate: "",
  scopeStatus: 1,

  teamUpdate: "",
  teamStatus: 1,

  issuesUpdate: "",
  issuesStatus: 1,

  risksUpdate: "",
  risksStatus: 1,
};

/**
 * @module
 * @description This function creates the structure and the logic of Project Update Modal
 * @param {function} show get show
 * @param {Function} handleProjectUpdateModal get handle Project Update Modal
 * @param {Function} projectId get project Id
 * @param {Function} callback get callback
 * @param {Function} players get Players
 * @returns {JSX} Project Update Modal
 */

const ProjectUpdateModal = ({
  show,
  handleProjectUpdateModal,
  projectId,
  callback,
  players,
}) => {
  const [moodOptions, setMoodOptions] = useState([]);
  const [satisfactionOptions, setSatisfactionOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [playersMood, setPlayersMood] = useState([]);
  const [resMood, setResMood] = useState([]);
  const [iconMood, setIconMood] = useState(null);
  const [playerId, setPlayerId] = useState(null);

  const payloadMood = {
    users: playersMood,
  };

  const {
    getProjectStatusOptions,
    getMoodOptions,
    setProjectUpdate,
    getSatisfactionOptions,
    setPlayersMoodPM,
    auth,
  } = useContext(ProjectsContext);

  const intl = useIntl();

  useEffect(() => {
    setPlayersMood([]);
    getProjectStatusOptions()
      .then(({ data: { data } }) => setStatusOptions(data))
      .catch((err) => {
      });

    getMoodOptions()
      .then(({ data: { data } }) => setMoodOptions(data))
      .catch((err) => {
      });

    getSatisfactionOptions()
      .then(({ data: { data } }) => setSatisfactionOptions(data))
      .catch((err) => {
      });
  }, []);

  const handleClickMood = (moodId, playerId, setTest, test) => {
    setIconMood(moodId);
    setPlayerId(playerId);
    setTest(!test);

    if (!test) {
      const moodBody = {
        user_id: playerId,
        mood_id: moodId,
      };

      const moodIndex = playersMood.findIndex(
        (player) => player.user_id === playerId
      );
      if (moodIndex >= 0) {
        const moodsArray = playersMood;
        moodsArray[moodIndex] = moodBody;
        setPlayersMood(moodsArray);
      } else {
        setPlayersMood([...playersMood, moodBody]);
      }
    }
    if (test) {
      setPlayersMood(
        playersMood.filter((player) => player.user_id !== playerId)
      );
    }
    //else {
    // const moodBody = {};
    // const moodIndex = playersMood.findIndex(
    //   (player) => player.user_id === playerId
    // );
    // if (moodIndex >= 0) {
    //   const moodsArray = playersMood;
    //   moodsArray[moodIndex] = moodBody;
    //   setPlayersMood(moodsArray);
    // } else {
    //   setPlayersMood([...playersMood, moodBody]);
    // }
    // const result = playersMood.filter(item => {item.userId !== playerId);
    // // console.log(result)
    // playersMood.forEach((item, idx) => {
    //   if(item.userId === playerId) {
    //     playersMood.splice(idx, 1);
    //   }
    // })
    // // console.log(playersMood)
  };

  Yup.addMethod(
    Yup.string,
    "internalOverviewMinLength",
    function (errorMessage) {
      return this.test(`test-text-box-length`, errorMessage, function (value) {
        const { path, createError } = this;

        return (
          (value && value.trim().length > 4) ||
          createError({
            path,
            message: intl.formatMessage({
              id: "project.update.modal.overview.min",
              defaultMessage: "Overview must have at least 5 characters",
            }),
          })
        );
      });
    }
  );

  Yup.addMethod(
    Yup.string,
    "internalOverviewMaxLength",
    function (errorMessage) {
      return this.test(`test-text-box-length`, errorMessage, function (value) {
        const { path, createError } = this;

        return (
          (value && value.trim().length < 251) ||
          createError({
            path,
            message: intl.formatMessage({
              id: "project.update.modal.overview.max",
              defaultMessage: "Overview must have 250 characters as maximum",
            }),
          })
        );
      });
    }
  );

  Yup.addMethod(Yup.string, "publicOverviewMinLength", function (errorMessage) {
    return this.test(`test-text-box-length`, errorMessage, function (value) {
      const { path, createError } = this;

      return (
        (value && value.trim().length > 4) ||
        createError({
          path,
          message: intl.formatMessage({
            id: "project.update.modal.overview.min",
            defaultMessage: "Overview must have at least 5 characters",
          }),
        })
      );
    });
  });

  Yup.addMethod(Yup.string, "publicOverviewMaxLength", function (errorMessage) {
    return this.test(`test-text-box-length`, errorMessage, function (value) {
      const { path, createError } = this;

      return (
        (value && value.trim().length < 251) ||
        createError({
          path,
          message: intl.formatMessage({
            id: "project.update.modal.overview.max",
            defaultMessage: "Overview must have 250 characters as maximum",
          }),
        })
      );
    });
  });

  const AddProjectUpdateSchema = Yup.object().shape({
    // projectStatus: Yup.number().required("Please select a project status"),
    // teamMood: Yup.number().required("Please select a team mood"),
    // clientSatisfaction: Yup.number().required(
    //   "Please select client satisfaction status"
    // ),
    publicOverview: Yup.string()
      .required(
        intl.formatMessage({
          id: "project.update.modal.public.overview.error",
          defaultMessage: "Please enter a public overview",
        })
      )
      .publicOverviewMinLength()
      .publicOverviewMaxLength(),
    internalOverview: Yup.string()
      .required(
        intl.formatMessage({
          id: "project.update.modal.internal.overview.error",
          defaultMessage: "Please enter a internal overview",
        })
      )
      .internalOverviewMinLength()
      .internalOverviewMaxLength(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: AddProjectUpdateSchema,
    onSubmit: (values) => {
      const payload = {
        project_id: Number(projectId),
        projectStatusId: values.projectStatus,
        teamMoodId: values.teamMood,
        clientSatisfactionId: values.clientSatisfaction,
        public_overview: values.publicOverview,
        internal_overview: values.internalOverview,
        client_area_status_id: values.clientStatus,
        client_area_description: values.clientUpdate,
        schedule_area_status_id: values.scheduleStatus,
        schedule_area_description: values.scheduleUpdate,
        scope_area_status_id: values.scopeStatus,
        scope_area_description: values.scopeStatus,
        team_area_status_id: values.teamStatus,
        team_area_description: values.teamUpdate,
        issues_area_status_id: values.issuesStatus,
        issues_area_description: values.issuesUpdate,
        risks_area_status_id: values.risksStatus,
        risks_area_description: values.risksUpdate,
      };

      setLoading(true);

      setProjectUpdate(projectId, payload)
        .then(({ data }) => {
          setLoading(false);
          if (playersMood.length) {
            setPlayersMoodPM(payloadMood, data.data.projectStatusId)
              .then(({ data }) => {
                setResMood(data?.data);
              })
              .catch((err) => {
                console.error(err);
              });
          }
          if (data.status === "success") {
            Swal.fire(
              "The project status was updated successfully!",
              "",
              "success"
            );
            handleProjectUpdateModal();
            callback({ success: true });
            resetForm();
            setPlayersMood([]);
          }
        })
        .catch((err) => {
          setLoading(false);
          Swal.fire('Something went wrong', 'Try again later', 'error');
          console.error(err);
          callback({ success: false });
        });
    },
  });

  const onHide = () => {
    handleProjectUpdateModal();
  };

  // get the status icon by id
  const getStatusIcon = (statusId) => {
    const icons = {
      1: (
        <FontAwesomeIcon
          className={getStatusClasses(statusId)}
          icon={faCheckCircle}
        />
      ),
      2: (
        <FontAwesomeIcon className={getStatusClasses(statusId)} icon={faFlag} />
      ),
      3: (
        <FontAwesomeIcon
          className={getStatusClasses(statusId)}
          icon={faExclamationTriangle}
        />
      ),
      4: <OutdatedIcon />,
    };

    return icons[statusId] || null;
  };

  // get the mood icon by id
  const getMoodIcon = (moodId) => {
    const icons = {
      1: <SmileFaceIcon />,
      5: <SadFaceIcon />,
      3: <MehFaceIcon />,
    };

    return icons[moodId] || null;
  };

  // get the statisfaction icon by id
  const getSatisfactionIcon = (satisfactionIcon) => {
    const icons = {
      1: <ArrowUpIcon />,
      2: <ArrowLeftIcon />,
      3: <ArrowDownIcon />,
    };

    return icons[satisfactionIcon] || null;
  };

  // get the classes by id to add styles
  const getStatusClasses = (statusId) => {
    const icons = {
      1: "onTrack",
      3: "atRisk",
      2: "inDanger",
    };

    return icons[statusId];
  };

  const {
    projectStatus,
    teamMood,
    clientSatisfaction,
    publicOverview,
    internalOverview,
    clientUpdate,
    scheduleUpdate,
    clientStatus,
    scheduleStatus,
    scopeStatus,
    teamStatus,
    issuesStatus,
    risksStatus,
    scopeUpdate,
    teamUpdate,
    issuesUpdate,
    risksUpdate,
  } = formik.values;

  const specificAreasFields = [
    {
      name: "Client",
      controller: clientUpdate,
      controllerName: "clientUpdate",
      status: clientStatus,
      statusName: "clientStatus",
    },
    {
      name: "Schedule",
      controller: scheduleUpdate,
      controllerName: "scheduleUpdate",
      status: scheduleStatus,
      statusName: "scheduleStatus",
    },
    {
      name: "Scope",
      controller: scopeUpdate,
      controllerName: "scopeUpdate",
      status: scopeStatus,
      statusName: "scopeStatus",
    },
    {
      name: "Team",
      controller: teamUpdate,
      controllerName: "teamUpdate",
      status: teamStatus,
      statusName: "teamStatus",
    },
    {
      name: "Issues / Blocks",
      controller: issuesUpdate,
      controllerName: "issuesUpdate",
      status: issuesStatus,
      statusName: "issuesStatus",
    },
    {
      name: "Risks",
      controller: risksUpdate,
      controllerName: "risksUpdate",
      status: risksStatus,
      statusName: "risksStatus",
    },
  ];

  const resetForm = () => {
    formik.resetForm();
  };

  return (
    <Modal
      className="update-status-modal"
      show={show}
      onHide={onHide}
      centered
      size="lg"
    >
      <Modal.Header>
        <Modal.Title>Add project update</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="addProjectUpdateForm">
          <div className="statusSelects">
            <div className="formField">
              <label htmlFor="projectStatus">Project status*</label>
              <div className="selectStatusWithIcon">
                {getStatusIcon(projectStatus)}
                <Form.Select
                  type="select"
                  className="form-control"
                  id="projectStatus"
                  value={projectStatus}
                  name="projectStatus"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  {statusOptions.map((status) => (
                    <option key={status.statusId} value={status.statusId}>
                      {status.statusTitle}
                    </option>
                  ))}
                </Form.Select>
              </div>

              {formik.touched.projectStatus && formik.errors.projectStatus ? (
                <div className="formikError">
                  <small>{formik.errors.projectStatus}</small>
                </div>
              ) : null}
            </div>

            <div className="formField">
              <label htmlFor="teamMood">Team mood*</label>
              <div className="selectStatusWithIcon">
                {getMoodIcon(teamMood)}

                <Form.Select
                  type="select"
                  className="form-control"
                  id="teamMood"
                  value={teamMood}
                  name="teamMood"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  {moodOptions.map((mood) => (
                    <option key={mood.moodId} value={mood.moodId}>
                      {mood.moodTitle}
                    </option>
                  ))}
                </Form.Select>
              </div>

              {formik.touched.teamMood && formik.errors.teamMood ? (
                <div className="formikError">
                  <small>{formik.errors.teamMood}</small>
                </div>
              ) : null}
            </div>
            <div className="formField">
              <label htmlFor="teamMood">Client satisfaction*</label>
              <div className="selectStatusWithIcon">
                {getSatisfactionIcon(clientSatisfaction)}

                <Form.Select
                  type="select"
                  className="form-control"
                  id="clientSatisfaction"
                  value={clientSatisfaction}
                  name="clientSatisfaction"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  {satisfactionOptions.map((satisfaction) => (
                    <option
                      key={satisfaction.satisfactionId}
                      value={satisfaction.satisfactionId}
                    >
                      {satisfaction.satisfactionTitle}
                    </option>
                  ))}
                </Form.Select>
              </div>

              {formik.touched.clientSatisfaction &&
              formik.errors.clientSatisfaction ? (
                <div className="formikError">
                  <small>{formik.errors.clientSatisfaction}</small>
                </div>
              ) : null}
            </div>
          </div>

          <div className="divider"></div>

          <div className="overviews">
            <div className="formField">
              <label htmlFor="publicOverview">Public overview*</label>
              <textarea
                rows="6"
                type="text"
                id="publicOverview"
                value={publicOverview}
                name="publicOverview"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control"
                placeholder="Write a short update... 2 sentences recommended"
              ></textarea>
              {formik.touched.publicOverview && formik.errors.publicOverview ? (
                <div className="formikError">
                  <small>{formik.errors.publicOverview}</small>
                </div>
              ) : null}
              <div className="overview-aclaration">
                <InfoSquareIcon />
                <small>This text will be visible to all team members</small>
              </div>
            </div>

            <div className="formField">
              <label htmlFor="internalOverview">Internal overview*</label>
              <textarea
                rows="6"
                type="text"
                id="internalOverview"
                value={internalOverview}
                name="internalOverview"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="form-control"
                placeholder="Write a short update... 2 sentences recommended"
              ></textarea>
              {formik.touched.internalOverview &&
              formik.errors.internalOverview ? (
                <div className="formikError">
                  <small>{formik.errors.internalOverview}</small>
                </div>
              ) : null}
              <div className="overview-aclaration">
                <InfoSquareIcon />
                <small>This text will be visible only to managers</small>
              </div>
            </div>
          </div>

          <div className="divider"></div>
          <PlayersMoodTable
            players={players}
            handleClickMood={handleClickMood}
            iconMood={iconMood}
            playerId={playerId}
          />
          <div className="divider"></div>

          <h5>Updates for specific areas</h5>
          <div className="specificAreas">
            {specificAreasFields.map((area) => (
              <div key={area.controllerName} className="updater">
                <Form.Select
                  type="select"
                  className={`form-control ${getStatusClasses(area.status)}`}
                  id={area.statusName}
                  value={area.status}
                  name={area.statusName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option className="onTrack" value="1">
                    &#xf05d;
                  </option>
                  <option className="inDanger" value="2">
                    {/* &#xf024; */}
                    &#xf11d;
                  </option>
                  <option className="atRisk" value="3">
                    &#xf071;
                  </option>
                </Form.Select>
                <label htmlFor={area.controllerName}> {area.name} </label>
                <div>
                  <textarea
                    rows="6"
                    type="text"
                    id={area.controllerName}
                    value={area.controller}
                    name={area.controllerName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="form-control"
                    placeholder="Write something"
                  ></textarea>
                </div>
              </div>
            ))}
          </div>
        </form>
      </Modal.Body>
      <div className="footer-btn">
        <div className="submission-aclaration">
          <InfoSquareIcon />
          <small>Reports will be editable only for 24hs after submission</small>
        </div>
        <div>
          <ActionButton secondary onClick={onHide}>
            Cancel
          </ActionButton>
          <ActionButton disabled={loading} onClick={formik.submitForm}>
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Add"
            )}
          </ActionButton>
        </div>
      </div>
    </Modal>
  );
};

export default ProjectUpdateModal;
