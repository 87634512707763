/**
 * @description Function to create Onboard icon as a JSX component
 * @returns {JSX} Onboard
 */
function OnboardIcon() {
  return (
    <svg
      width="74"
      height="74"
      viewBox="0 0 74 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M67.057 0.28272H6.93806C3.25898 0.28272 0.282227 3.25947 0.282227 6.93855V67.0616C0.282227 70.7365 3.25898 73.7215 6.93806 73.7215H67.0611C70.7361 73.7215 73.721 70.7406 73.721 67.0616V6.93855C73.721 6.06381 73.5486 5.19765 73.2136 4.38959C72.8786 3.58153 72.3876 2.84744 71.7686 2.22928C71.1497 1.61112 70.415 1.12103 69.6066 0.78702C68.7981 0.453013 67.9317 0.281647 67.057 0.28272ZM24.8231 14.5621C27.2608 14.5621 29.3066 16.4895 29.3066 18.964C29.3066 21.4793 27.2608 23.374 24.8231 23.374V23.4638C22.3567 23.4638 20.3722 21.4793 20.3722 19.0211C20.3722 16.5507 22.3567 14.5621 24.8231 14.5621ZM54.8519 56.8328C50.3112 56.8328 48.1103 54.1296 48.1103 50.4383C48.1103 49.3766 48.2451 48.2619 48.5758 47.1226L50.7318 39.3398C51.0095 38.4905 51.083 37.6861 51.083 36.9674C51.083 34.4561 49.5517 32.9494 47.1181 32.9494C44.0106 32.9494 41.9731 35.1666 40.9114 39.4746L36.6933 56.4041H29.2984L30.6336 51.0835C28.4531 54.6482 25.4437 56.8491 21.7197 56.8491C17.2199 56.8491 15.117 54.2685 15.117 50.3811C15.1349 49.2668 15.2662 48.1572 15.509 47.0696L18.8818 33.3251H13.6633L15.2476 27.4859H27.8039L22.8386 47.1022C22.5119 48.364 22.3894 49.3848 22.3894 50.1157C22.3894 51.3693 22.9937 51.7327 23.9615 51.9614C24.5576 52.0839 29.2494 52.0063 31.7933 46.3427L35.0518 33.3251H29.768L31.3646 27.5921H42.7448L41.2789 34.1948C43.2552 30.5239 47.2079 27 51.1034 27C55.2357 27 58.678 29.9726 58.678 35.6199C58.678 37.2532 58.4207 38.8702 57.943 40.4382L55.8196 48.0251C55.6448 48.6816 55.5421 49.3552 55.5134 50.0341C55.5134 51.3652 56.0687 52.0104 57.0161 52.0104C57.9879 52.0104 59.2742 51.2713 60.687 47.2247L63.578 48.3354C61.8875 54.3501 58.7515 56.8328 54.8519 56.8328Z"
        fill="#E6126B"
      />
    </svg>
  );
}

export default OnboardIcon;
