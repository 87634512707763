import React from "react";

function Tutorial() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.6"
      height="16"
      fill="none"
      viewBox="1.5 0 16 19"
    >
      <path
        fill="#9C9C9C"
        d="M6.698 5.487a1.339 1.339 0 112.277 1.305l-.613.7A3.487 3.487 0 007.5 9.79v.21a.5.5 0 101 0v-.21c0-.603.218-1.184.615-1.638l.613-.7c.728-.832.774-2.06.11-2.943-1.131-1.51-3.493-1.127-4.09.663l-.223.67a.5.5 0 00.949.317l.224-.67z"
      ></path>
      <path
        fill="#9C9C9C"
        fillRule="evenodd"
        d="M8 .833a7.167 7.167 0 100 14.334A7.167 7.167 0 008 .833zM1.833 8a6.167 6.167 0 1112.333 0A6.167 6.167 0 011.833 8z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#9C9C9C"
        d="M8.666 12a.667.667 0 11-1.333 0 .667.667 0 011.333 0z"
      ></path>
    </svg>
  );
}

export default Tutorial;