import React, { useState, useEffect, forwardRef } from "react";
import moment from "moment";
import { default as BootstrapForm } from "react-bootstrap/Form";
import { Modal, Row, Col, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./EventModal.scss";
import "../PopOver/popOver.scss";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useAuthContext } from "../../../context/AuthContext";
import { useSchedulerContext } from "../../../context/SchedulerContext/SchedulerContext";
// language swichter feature
import { FormattedMessage, useIntl } from "react-intl";
import ActionButton from "../../shared/ActionButton";
import CloseIcon from "../../Icons/CloseIcon";
import CustomDatePicker from "../../../utils/CustomDatePicker";
import Swal from "sweetalert2";
import {eventServiceDataFormat} from "../services/post.events";
import {hoursConverter} from "../services/hoursConverter";
//import ClockIcon from "../../Icons/ClockIcon";
//import Input from '@mui/material/Input';
//import InputAdornment from '@mui/material/InputAdornment';

/**
 * @module
 * @description This component displays the events modal
 * @param {string} selectedDate
 * @param {string} startHour
 * @param {string} endHour
 * @param {Function} handleClose
 * @returns {JSX} EventModal
 */

const {
  getLocationsAndRegions,
} = require("../services/getLocationsAndRegions");

const EventModal = ({
  contexts,
  selectedDate,
  count,
  refreshTask,
  startHour,
  endHour,
  handleClose,
  entryType,
  handleChangeModalType,
}) => {
  const auth = useAuthContext();
  const [regions, setRegion] = useState([]);
  const [startDate, setStartDate] = useState(moment(selectedDate).toDate());
  const [startTime, setStartTime] = useState(startHour ? startHour : 0);
  const [endTime, setEndTime] = useState(endHour ? endHour : 0);
  const [timeframeInvalid, setTimeframeInvalid] = useState(false);
  const [allDay, setAllDay] = useState(false);
  const [globalVisible, setGlobalVisible] = useState(false);
  const contextEnum = {
    Global: "Global",
    Region: auth.userInfo.region,
    Local: auth.userInfo.location,
  };
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const schedulerContext = useSchedulerContext();

  const NewEventSchema = Yup.object().shape({
    globalVisible: Yup.boolean(),
    context: Yup.string()
      .when("globalVisible", {
        is: (globalVisible) => globalVisible?.value === false,
        then: Yup.string()
          .required(
            intl.formatMessage({
              id: "modal.required.field",
              defaultMessage: "This is a required field"
            }))
      }),
    date: Yup.date().required(),
    allDay: Yup.boolean(),
    workTimeStart: Yup.string()
      .when("allDay", {
        is: (allDay) => allDay?.value === false,
        then: Yup.string().required(intl.formatMessage({
          id: "modal.required.field.short",
          defaultMessage: "Required"
      }))
    }),
    //if the option 'all day' is not selected, these field must be required
    workTimeEnd: Yup.string()
      .when("allDay", {
        is: (allDay) => allDay?.value === false,
        then: Yup.string().required(intl.formatMessage({
          id: "modal.required.field.short",
          defaultMessage: "Required"
      }))
    }),
    title: Yup.string()
      .required(intl.formatMessage({
        id: "modal.required.field",
        defaultMessage: "This is a required field"
      })),
    description: Yup.string()
      .required(
        intl.formatMessage({
          id: "modal.required.field",
          defaultMessage: "This is a required field",
        })
      )
      .min(
        5,
        intl.formatMessage({
          id: "modal.required.characters.min",
          defaultMessage: "Description must be at least 5 characters",
        })
      )
      .max(
        120,
        intl.formatMessage({
          id: "modal.required.characters.max",
          defaultMessage: "Description must have as maximum 120 characters",
        })
      ),
  });


  useEffect(() => {
    let mounted = true;
    getLocationsAndRegions(auth.user.token).then((items) => {
      if (mounted) {
        setRegion(items);
      }
    });
    return () => (mounted = false);
  }, []);

  return (

    <div className="event-modal">
      <Modal.Header>
        <Modal.Title closeButton className="modal-title">
          {/*<FormattedMessage
            id="scheduler.modal.entry.event"
            defaultMessage="Add event entry"
          />*/}
          Add event entry
        </Modal.Title>
        <button
          type="button"
          className="close-icon"
          aria-label="Close"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </Modal.Header>

      <div className="event-modal-body">
        <div className="form-group">
          <label htmlFor="type">
            {/*<FormattedMessage
              id="scheduler.modal.entry.type"
              defaultMessage="Entry type"
            />*/}
            Entry type
          </label>
          <Col md={6}>
            <BootstrapForm.Select
              size="sm"
              className="timesheetSelect"
              value={entryType}
              onChange={handleChangeModalType}
              id="entryTypeSelect"
            >
              {/* EXPORT THESE VALUES FOR A GLOBAL CONTEXT ARRAY*/}
              <option value={2}>
                {intl.formatMessage({
                  id: "modal.entry.type.option2",
                  defaultMessage: "Holidays",
                })}
              </option>
              <option value={4}>
                {intl.formatMessage({
                  id: "modal.entry.type.option4",
                  defaultMessage: "Event",
                })}
              </option>
            </BootstrapForm.Select>
          </Col>
        </div>
      </div>

      <hr className="hrModal"></hr>
      <Formik
        initialValues={{
          context: "",
          globalVisible: globalVisible,
          date: selectedDate || moment.utc().format("YYYY-MM-DD"+"T00:00:00"),
          workTimeStart: startTime,
          workTimeEnd: endTime,
          title: "",
          description: "",
          allDay: allDay,
        }}
        validationSchema={NewEventSchema}
        onSubmit={async (values, actions) => {
          if(!values.allDay) {
            values.workTimeStart = hoursConverter.timeToUTC(values.workTimeStart + ":00");
            values.workTimeEnd = hoursConverter.timeToUTC(values.workTimeEnd + ":00");
          } else {
            values.workTimeStart = "00:00";
            values.workTimeEnd = "00:00";
          }

          try {
            const response = await eventServiceDataFormat(
              values,
              auth.user.id,
              auth.user.token
            );
            if (response) {
              refreshTask(count + 1);
              Swal.fire(
                intl.formatMessage({
                  id: "modal.event.swal.success",
                  defaultMessage: "A new event was added successfully!",
                }),
                "",
                "success"
              );
              handleClose();
              schedulerContext.getEventsByRegion();
              schedulerContext.getGlobalEvents();
              schedulerContext.getEvents();
            }
            actions.setSubmitting(false);
          } catch (error) {
            Swal.fire(
              intl.formatMessage({
                id: "modal.holidays.swal.error.description",
                defaultMessage: "Something went wrong: ",
              }) + error.data?.message,
              intl.formatMessage({
                id: "modal.error.footer",
                defaultMessage: "Please, try again later",
              }),
              "error"
            );
            console.log(error);
          }
        }}
        validate={(values) => {
          let errors = {};

          if(!values.globalVisible) {
            if(values.context === "") {
              errors.context = "Context is required"
            }
          }
          if(!values.allDay) {
            if (
              moment
                .utc(values.workTimeStart, "HH:mm A")
                .isBefore(moment.utc(values.workTimeEnd, "HH:mm A"))
            ) {
              setTimeframeInvalid(false);
            } else {
              setTimeframeInvalid(true);
              errors.workTimeStart = "Invalid range";
              errors.workTimeEnd = "Invalid range";
            }
          } else {
            values.workTimeStart = 0;
            values.workTimeEnd = 0;
          }
          if (values.globalVisible) {
            values.context = '';
          }
          return errors;
        }}
      >
        {({ touched, errors, values }) => (
          <Form >
            <>
              <Modal.Body>
                <Row className="event-modal-body g-0" >
                  <Col md={6}>
                    <div className="form-group">
                      {values.globalVisible === false ? (
                      <label htmlFor="context">
                        {/*<FormattedMessage
                          id="modal.event.context"
                          defaultMessage="Context*"
                        />*/}
                        Context*
                      </label>
                      ) : (<label></label>)}
                      <div className="checkBoxAndComponentOnModal">
                      {values.globalVisible === false ? (
                      <div className="select-context-field">
                        <Field
                          as="select"
                          component="select"
                          name="context"
                          className={`form-control form-select ${
                            errors.context ? "is-invalid" : ""
                          }`}
                        >
                          {contexts === contextEnum.Global ? (
                            <>
                              <option disabled value="">
                                {intl.formatMessage({
                                  id: "modal.event.context.select",
                                  defaultMessage: "Select context",
                                })}
                              </option>
                              {regions.map((regionItem) => {
                                return (
                                  <>
                                    <option
                                      key={regionItem.id}
                                      value={regionItem.id}
                                      label={"Region: " + regionItem.name}
                                      className="cntx"
                                    />
                                  </>
                                );                           
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                          {contexts === contextEnum.Region ? (
                            <>
                              <option value="">
                                {intl.formatMessage({
                                  id: "modal.event.context.select",
                                  defaultMessage: "Select context",
                                })}
                              </option>
                              {regions
                                .filter(
                                  (regionItem) =>
                                    regionItem.name === contextEnum.Region
                                )
                                .map((regionItem) =>
                                  regionItem.locations.map((locations) => (
                                    <option
                                      key={locations.id}
                                      value={locations.id}
                                      label={`Local: ${locations.name}`}
                                      className="cntx"
                                    />
                                  ))
                                )}
                            </>
                          ) : (
                            <></>
                          )}
                          {contexts === contextEnum.Local ? (
                            <>
                              {regions
                                .filter(
                                  (regionItem) =>
                                    regionItem.name === contextEnum.Region
                                )
                                .map((regionItem) =>
                                  regionItem.locations.map((locations) =>
                                    locations.name === contextEnum.Local ? (
                                      <option
                                        key={locations.id}
                                        value={locations.id}
                                        label={`Local: ${locations.name}`}
                                        className="cntx"
                                      />
                                    ) : (
                                      <></>
                                    )
                                  )
                                )}
                            </>
                          ) : (
                            <></>
                          )}
                        </Field>
                        <ErrorMessage
                          component="div"
                          name="context"
                          className="invalid-feedback"
                        />
                        </div>
                        ) : (
                            <div style={{"width":"12.5rem"}}></div>)}
                        <div className="eventModalCheckbox">
                          <label htmlFor="context" for="cb1" id="txt">
                            <Field type="checkbox" name="globalVisible" id="cb1" />
                            <span className="text-checkbox">
                              {
                                intl.formatMessage({
                                  id: "modal.event.globally.visible",
                                  defaultMessage: "Global visible"
                                })
                              }
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col xs={6}>
                    <div className="form-group date-container">
                      <label htmlFor="date">
                        {/*<FormattedMessage
                          id="modal.holidays.date"
                          defaultMessage="Date*"
                        />*/}
                        Date*
                      </label>
                      <div className="checkBoxAndComponentOnModal">
                        <DatePicker
                          wrapperClassName="datePicker"
                          dateFormat="yyyy-MM-dd"
                          onChange={(date) => setStartDate(date)}
                          showDisabledMonthNavigation
                          selected={startDate}
                          customInput={<CustomDatePicker />}
                        />
                        <div className="eventModalCheckbox">
                          <label htmlFor="date" for="cb2" id="txt">
                            <Field type="checkbox" name="allDay" id="cb2" />
                          <span className="text-checkbox">
                              {
                                intl.formatMessage({
                                  id: "modal.event.all.day",
                                  defaultMessage: "All day"
                                })
                              }
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                {values.allDay === false ? (
                  <>
                  <hr className="hrModal" />
                  <Row className="event-modal-body g-0">
                    <label htmlFor="timeTitle">
                      {/*<FormattedMessage
                        id="modal.event.time.title"
                        defaultMessage="Duration"
                      />*/}
                      Duration
                    </label>
                    <Col xs={5}>
                      <div className="form-group d-flex align-items-end timeRangeInput">
                        <label
                          htmlFor="workTimeStart"
                          className="labelTimeframes"
                        >
                          {/*<FormattedMessage
                            id="modal.time.log.date.from"
                            defaultMessage="From*"
                          />*/}
                          From*
                        </label>
                        &nbsp;
                        <Field
                          type="time"
                          name="workTimeStart"
                          className={`timeframesField form-control ${errors.workTimeStart ? "is-invalid error" : ""
                          }`}
                        />
                      </div>
                    </Col>
                    <Col xs={5}>
                      <div className="form-group d-flex align-items-end timeRangeInput">
                        <label htmlFor="workTimeEnd" className="labelTimeframes">
                          {/*<FormattedMessage
                            id="modal.time.log.date.to"
                            defaultMessage="To*"
                        />*/}
                          To*
                        </label>
                        &nbsp;
                        <Field
                          type="time"
                          name="workTimeEnd"
                          min={startTime}
                          className={`timeframesField form-control ${
                            errors.workTimeEnd ? "is-invalid error" : ""
                          }`}
                        />
                      </div>
                    </Col>
                  </Row>
                    <div>
                      <small className="validity">
                        {" "}
                        {timeframeInvalid ? (
                          <div>
                            {/*<FormattedMessage
                              id="modal.time.log.date.invalid.interval"
                              defaultMessage="Invalid time interval"
                            />*/}
                            Invalid time interval
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </small>
                    </div>
                  </>
                  ) : (<></>)}
                <hr className="hrModal" />
                <Row className="event-modal-body g-0">
                  <Col>
                    <div className="form-group">
                      <label htmlFor="titleHoliday">
                        {/*<FormattedMessage
                          id="modal.holidays.title"
                          defaultMessage="Title*"
                        />*/}
                        Title*
                      </label>
                      <Field
                        type="text"
                        name="title"
                        id="titleHoliday"
                        placeholder={intl.formatMessage({
                          id: "modal.event.title.placeholder",
                          defaultMessage: "Event's name",
                        })}
                        className={`form-control ${touched.title && errors.title ? "is-invalid" : ""
                          }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="title"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="descriptionField">
                  <Col>
                    <div>
                      <label htmlFor="description">
                        {/*<FormattedMessage
                          id="modal.event.description"
                          defaultMessage="Description*"
                        />*/}
                        Description
                      </label>
                      <Field
                        as="textarea"
                        name="description"
                        placeholder={intl.formatMessage({
                          id: "modal.event.description.placeholder",
                          defaultMessage: "Add this event's description",
                        })}
                        className={`form-control ${touched.description && errors.description
                          ? "is-invalid"
                          : ""
                          }`}
                        id="description"
                      />
                      <ErrorMessage
                        component="div"
                        name="description"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <div>
                  <ActionButton secondary onClick={handleClose}>
                    {/*<FormattedMessage
                      id="button.cancel"
                      defaultMessage="Cancel"
                    />*/}
                    Cancel
                  </ActionButton>
                  <ActionButton disabled={loading} onClick={Formik.submitForm}>
                    {loading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      /*<FormattedMessage
                        id="button.add"
                        defaultMessage="Add"
                    />*/
                      "Add"
                    )}
                  </ActionButton>
                </div>
              </Modal.Footer>
            </>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EventModal;
