import { environment } from "../../config/environment";

  const permissionsByUserRoleLocation = async (token, userId, locationId, roleId, permissions, assignedLocationsRegions) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", token);
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        "userId": userId,
        "roleId": roleId,
        "locationId": locationId,
        "permissions":permissions,
        "assignedLocationsRegions":assignedLocationsRegions
      });
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      const savedPermissions = await fetch(`${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/permissions/saveDefaultRolePermissionLocation`, requestOptions)
        .then(response => response.json())
        .catch(error => console.log('error', error));

      if(savedPermissions.status ==='success') return true
      else return false;
    }
    catch (error) {

      if (!error.code) error.code = 404

      if (!error.message) error.message = "Could not save permissions";

      throw error;

    };
};

const permissionsByUserRoleLocationRegion = async (token, userId, locations, regionId, roleId, permissions, assignedLocationsRegions) => {

  try {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      "userId": userId,
      "roleId": roleId,
      "regionId": regionId,
      "locations": locations,
      "permissions":permissions,
      "assignedLocationsRegions":assignedLocationsRegions
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    const savedPermissions =await fetch(`${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/permissions/saveDefaultRolePermissionRegion`, requestOptions)
      .then(response => response.json())
      .catch(error => console.log('error', error));

    if(savedPermissions.status ==='success') return true
    else return false;
  }
  catch (error) {

    if (!error.code) error.code = 404

    if (!error.message) error.message = "Could not save permissions";

    throw error;

  };
};
export { permissionsByUserRoleLocation, permissionsByUserRoleLocationRegion };
