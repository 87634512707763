import {
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowUpIcon,
  OutdatedIcon
} from "../components/Icons";

/**
 * Get the icon status by a satisfaction Id
 * @param  {Number} satisfactionId satisfaction Id
 */
export const getSatisfactionIcon = (satisfactionId) => {
  const iconMatrix = {
    1: <ArrowUpIcon />,
    2: <ArrowLeftIcon />,
    3: <ArrowDownIcon />,
  };

  return iconMatrix[satisfactionId] || <OutdatedIcon />;
};
