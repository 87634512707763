import React from "react";

function Library() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="14"
      fill="none"
      viewBox="0 0 12 14"
    >
      <path
        fill="#9C9C9C"
        d="M6.154 5.385c0-.255.206-.462.461-.462h1.847a.462.462 0 110 .923H6.615a.462.462 0 01-.461-.461zM3.077 7.846c0-.255.207-.461.461-.461h4.924a.462.462 0 110 .923H3.538a.462.462 0 01-.461-.462zM3.077 10.308c0-.255.207-.462.461-.462h4.924a.462.462 0 010 .923H3.538a.462.462 0 01-.461-.461z"
      ></path>
      <path
        fill="#9C9C9C"
        fillRule="evenodd"
        d="M8.481.052c.662.051 1.214.158 1.69.398.13.066.253.14.37.226.3.218.565.482.783.783.36.496.522 1.077.6 1.794.076.703.076 1.589.076 2.72v1.286c0 1.13 0 2.016-.076 2.719-.078.717-.24 1.298-.6 1.794-.218.3-.482.565-.783.783-.496.36-1.077.522-1.794.6-.703.076-1.589.076-2.72.076h-.055c-1.13 0-2.016 0-2.719-.076-.717-.078-1.298-.24-1.794-.6a3.54 3.54 0 01-.783-.783c-.36-.496-.522-1.077-.6-1.794C0 9.275 0 8.388 0 7.258V5.973c0-1.13 0-2.016.076-2.719.078-.717.24-1.298.6-1.794.218-.3.482-.565.783-.783.226-.165.471-.289.743-.382.448-.155.965-.225 1.573-.26C4.381 0 5.11 0 5.987 0h.032c1 0 1.807 0 2.462.052zm-.072.92C7.794.923 7.023.923 6 .923c-.893 0-1.596 0-2.173.033-.2.011-.382.026-.548.046.512.506.924.896 1.29 1.19.494.395.868.582 1.246.642.27.042.547.042.818 0 .368-.059.73-.236 1.204-.61.362-.284.767-.665 1.27-1.161-.2-.04-.43-.07-.698-.091zm1.622.475C9.4 2.077 8.878 2.58 8.408 2.95c-.538.424-1.047.703-1.63.795-.367.058-.74.058-1.108 0-.6-.095-1.12-.387-1.677-.832-.498-.399-1.057-.948-1.749-1.638a1.76 1.76 0 00-.243.148A2.615 2.615 0 001.423 2c-.228.313-.36.713-.43 1.352C.924 4 .924 4.836.924 6v1.23c0 1.165 0 2 .07 2.648.07.639.202 1.039.43 1.352.16.222.356.417.578.578.313.227.713.36 1.352.429.647.07 1.483.07 2.647.07 1.164 0 2 0 2.647-.07.64-.07 1.04-.202 1.352-.429.222-.161.417-.356.578-.578.228-.313.36-.713.43-1.352.07-.647.07-1.483.07-2.647V6c0-1.164 0-2-.07-2.647-.07-.64-.202-1.04-.43-1.352a2.616 2.616 0 00-.546-.554z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Library;
