import React from "react";

function SunIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="#9C9C9C"
        d="M7.437.583a.438.438 0 00-.875 0V1.75a.437.437 0 10.875 0V.583zM2.772 2.154a.438.438 0 00-.619.618l.825.825a.437.437 0 10.619-.619l-.825-.824zM11.847 2.772a.437.437 0 10-.62-.618l-.824.824a.437.437 0 10.619.62l.825-.826zM7 3.063a3.938 3.938 0 100 7.875 3.938 3.938 0 000-7.875zM.583 6.563a.438.438 0 000 .875H1.75a.437.437 0 100-.875H.583zM12.25 6.563a.438.438 0 000 .875h1.167a.438.438 0 000-.875H12.25zM3.597 11.022a.437.437 0 10-.619-.619l-.825.825a.437.437 0 10.619.619l.825-.825zM11.022 10.403a.438.438 0 00-.62.619l.826.825a.438.438 0 00.619-.619l-.825-.825zM7.437 12.25a.437.437 0 10-.875 0v1.167a.437.437 0 10.875 0V12.25z"
      ></path>
    </svg>
  );
}

export default SunIcon;
