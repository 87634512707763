function ArrowLeftIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#F9A526"
        d="M6.824 8.405a2.836 2.836 0 00-.026.033H12a.562.562 0 110 1.124H6.798l.026.033c.194.242.483.53.914.957l1.058 1.049a.563.563 0 11-.792.798l-1.08-1.07c-.403-.4-.738-.732-.977-1.03-.251-.312-.442-.64-.493-1.04a2.048 2.048 0 010-.518c.05-.4.242-.728.493-1.04.239-.298.574-.63.976-1.03l1.081-1.07a.562.562 0 11.792.799L7.738 7.448c-.431.427-.72.715-.914.957z"
      ></path>
      <path
        fill="#F9A526"
        fillRule="evenodd"
        d="M1.78 5.652c-.093.857-.093 1.937-.093 3.314v.068c0 1.377 0 2.457.093 3.314.095.874.292 1.582.731 2.187.266.366.588.688.954.954.605.44 1.313.636 2.187.73.857.093 1.937.093 3.314.093h.068c1.377 0 2.457 0 3.314-.092.874-.095 1.582-.292 2.187-.731.366-.266.688-.588.954-.954.44-.605.636-1.313.73-2.187.093-.857.093-1.937.093-3.314v-.068c0-1.377 0-2.457-.092-3.314-.095-.874-.292-1.582-.731-2.187a4.313 4.313 0 00-.954-.954c-.605-.44-1.313-.636-2.187-.73-.857-.094-1.937-.094-3.314-.093h-.068c-1.377 0-2.457 0-3.314.092-.874.095-1.582.292-2.187.731a4.313 4.313 0 00-.954.954c-.44.605-.636 1.313-.73 2.187zm1.641 8.222c-.277-.381-.438-.87-.522-1.648-.086-.789-.087-1.807-.087-3.226s.001-2.438.087-3.226c.084-.779.245-1.267.522-1.648.197-.27.435-.508.705-.705.381-.277.87-.438 1.648-.522.788-.086 1.807-.087 3.226-.087s2.438.001 3.226.087c.779.084 1.267.245 1.648.522.27.197.508.435.705.705.277.381.438.87.522 1.648.086.788.086 1.807.086 3.226s0 2.438-.086 3.226c-.084.779-.245 1.267-.522 1.648-.197.27-.435.508-.705.705-.381.277-.87.438-1.648.522-.789.086-1.807.086-3.226.086s-2.438 0-3.226-.086c-.779-.084-1.267-.245-1.648-.522a3.187 3.187 0 01-.705-.705z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default ArrowLeftIcon;
    