import getUsersByRegion from "../../components/MyCalendar/services/getUserByRegion";
import getUsersByLocation from "../../components/MyCalendar/services/getUserByLocation";
import {getLocationsAndRegions} from "../../components/MyCalendar/services/getLocationsAndRegions";

export default async function getEmployees(auth){

  let regions = [];
  let locations=[];

  const getLocationsRegions = async () => {
    try {
      const response = await getLocationsAndRegions(auth.user.token);
      if (response) {
        let locationsArr = [];
        let regionsArr = response.map((element) => {
          locationsArr = [...locationsArr, ...element.locations];
          return {
            id: element.id,
            name: element.name,
          };
        });
        regions = regionsArr;
        locations = locationsArr;

      }
    } catch (error) {
      console.error(error);
    }
  };

  await getLocationsRegions();
  let res = [];

    if (auth.user && auth.user.token) {
      for (const region of regions) {
        let regionId = region.id;
        try {
          const result1 = await getUsersByRegion(regionId, auth.user.token);
          for (const user of result1) {
            if (!res.find((elem) => elem.id === user.id)) {
              if (user.id !== auth.user.id) res.push(user);
            }
          }
        } catch (error) {
          console.error(error);
        }
      }

      for (const location of locations) {
        let locationId = location.id;
        try {
          const res2 = await getUsersByLocation(locationId, auth.user.token);
          for (const user of res2) {
            if (!res.find((elem) => elem.id === user.id)) {
              if (user.id !== auth.user.id) res.push(user);
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
    return await res;

}