import React, {useState} from "react";
import {IntlProvider} from "react-intl";
import Spanish from "../../../i18n/es-ES.json"
import English from "../../../i18n/en-EN.json"

export const Context = React.createContext();

//Con esto consigues que por defecto ponga el idioma del navegador en la pagina
const local = navigator.language;

let lang;
if (local === "es-ES") {
  lang = Spanish;
} else {
  lang = English;
}

const Wrapper = (props) => {

    const [locale, setLocale] = useState(local);
    const [messages, setMessages] = useState(lang)

    function selectLang(e) {
        const newLocale = e.target.value;
        setLocale(newLocale);
        if (newLocale === "es-ES"){
            setMessages(Spanish);
        } else {
            setMessages(English);
        }
    }

    return(
        <Context.Provider value = {{locale, selectLang}}>
            {/* Esta es para el selector <IntlProvider messages = {messages} locale = {locale}> */}
            <IntlProvider messages = {messages} locale = {locale}>
                {props.children}
            </IntlProvider>
        </Context.Provider>
    );
}

export default Wrapper;