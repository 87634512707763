import { useEffect } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */

// ref is the HTML element to be controlled.
// action is the callback to be executed when user clicks outside the ref
// listening is used to know if the pop-up/menu is open or not
function useOutsideAlerter(ref, action, listening) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target) && listening) {
        action();

        // alert("You clicked outside of me!");
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, listening]);
}

export default useOutsideAlerter;
