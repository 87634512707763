import moment from "moment";
import "./popOver.scss";
import ModalContainer from "../Modal/ModalContainer";
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

// language switcher feature
import { useIntl } from "react-intl";
import { useAuthContext } from "../../../context/AuthContext";
import getUserByName from "../services/getUserByName";
import {getLocationsAndRegions} from "../services/getLocationsAndRegions";

/**
 * @module
 * @description this Component that renders a pop over with options
 * on every day in the calendar
 * @param {Style} left, top
 * @param {Date} date
 * @param refreshTaskPoP
 * @param countPoP
 * @param closePop
 * @returns {JSX} PopOverOptions
 */

let useClickOutside = (handler) => {
  let domNode = useRef();

  useEffect(() => {
    let maybeHandler = (event) => {
      if (!domNode.current.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", maybeHandler);

    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};
export default function PopOverOptions({
  context,
  leftP,
  topP,
  date,
  refreshTaskPoP,
  countPoP,
  closePop,
  show,
  popOverClick,
  firstBillingPeriod,
  name,
  local,
  region
}) {
  const auth = useAuthContext();
  const [currentDate, setCurrentDate] = useState();
  const contextEnum = {
    Personal: "Personal",
    Global: "Global",
    Region: auth.userInfo.region,
    Local: auth.userInfo.location,
    Employee: "name"
  };

  const selectedDate = moment.utc(date).format("DD");
  const selectedMonth = moment.utc(date).format("MM");
  const currentMonth = moment.utc().format("MM");
  const previousMonth = moment
    .utc(moment.utc().subtract(1, "month"))
    .format("MM");
  const lastDayOfMonth = moment.utc().endOf("month").format("YYYY-MM-DD");
  const [permissions,setPermissions] = useState([]);
  const { user } = useAuthContext();
  const intl = useIntl();

  useEffect(() => {
    setCurrentDate(moment.utc().format("YYYY-MM-DD"));
  });

  let domNode = useClickOutside(() => {
    closePop();
  });


  useEffect(async ()=>{
    let permissionsArray = [];
    let permission = user.permissions.userPermissions;
    if(context === 'Personal' || context === 'Global') {
      for (const permissionElement of permission) {
        if(permissionElement.locations && permissionElement.locations.name === auth.userInfo.location) {
          permissionsArray.push(permissionElement.permission.name);
        }
      }
      setPermissions([...permissionsArray]);
    }else {
      let locationFound =[];
      let regionFound;
      let permissionsByLocation = [];
      const selectedUser = await getUserByName(name, auth.user.token);
      if(selectedUser) {
        const locationsAndRegions = await getLocationsAndRegions(auth.user.token);
        locationsAndRegions.forEach((region) => {
          const tempLocationFound = region.locations.filter((location) => location.id === selectedUser.companyLocationId)
          if(tempLocationFound.length > 0) {
            locationFound = [...locationFound, ...tempLocationFound];
            regionFound = region;
          }
        });
      }else if(context !=='Personal' && context !== 'Global' && context !== name){
        user.permissions.userPermissions.forEach(element =>{
          if(element.regions && element.regions.name === context){
            permissionsByLocation.push(element.permission.name);
          }else if(element.locations && element.locations.name === context){
            permissionsByLocation.push(element.permission.name);
          }
        })
      }

      if(locationFound.length >0){
        for (const permissionElement of user.permissions.userPermissions) {
          if(permissionElement.locations && permissionElement.locations.name === locationFound[0].name) {
            permissionsByLocation.push(permissionElement.permission.name);
          }
        }
      }
      if(locationFound.length >0) {
        if (permissionsByLocation.length === 0) {
          for (const permissionElement of user.permissions.userPermissions) {
            if (permissionElement.regions && permissionElement.regions && permissionElement.regions.name === regionFound.name) {
              permissionsByLocation.push(permissionElement.permission.name);
            }
          }
        }
      }
      setPermissions([...permissionsByLocation]);
    }
  },[])

  return (
    <>
      <div
        className="pop-over"
        style={{
          position: "fixed",
          left: leftP + 45,
          top: topP,
          display: "flex",
          zIndex: 1,
        }}
        ref={domNode}
      >
        {context === contextEnum.Personal || (name === context && context !== contextEnum.Employee && context !== contextEnum.Global)? (
          firstBillingPeriod ? (
            currentDate < date ? (
              selectedMonth === currentMonth && selectedDate < 16 ? (
                <>
                  <div className="triangle-right-up"></div>
                  <div className="square">
                    <div className="options">
                      {permissions.includes('log-hours')?
                        <>
                          <div className="pop-button">
                            <ModalContainer
                              contexts={context}
                              selectedDate={date}
                              refreshTask={refreshTaskPoP}
                              count={countPoP}
                              myOnClose={closePop}
                              type={"1"}
                              buttonText={intl.formatMessage({
                                id: "pop.up.time.add",
                                defaultMessage: "Add worked hours",
                              })}
                              modalDisplayed={popOverClick}
                              popOn={show}
                              firstBillingPeriodModal={firstBillingPeriod}
                              name={name}
                            />
                          </div>
                        </>:null}
                          <div className="pop-button">
                            <ModalContainer
                              contexts={context}
                              selectedDate={date}
                              refreshTask={refreshTaskPoP}
                              count={countPoP}
                              myOnClose={closePop}
                              type={"3"}
                              buttonText={intl.formatMessage({
                                id: "pop.up.illness.time.add",
                                defaultMessage: "Request time off",
                              })}
                              modalDisplayed={popOverClick}
                              popOn={show}
                              firstBillingPeriodModal={firstBillingPeriod}
                              name={name}
                            />
                          </div>
                    </div>
                  </div>
                </>
              ) : (
                <> </>
              )
            ) : (selectedMonth === previousMonth && selectedDate > 15) ||
              selectedMonth === currentMonth ? (
              <>
                <div className="triangle-right-up"></div>
                <div className="square">
                  <div className="options">
                    {permissions.includes('log-hours')?
                      <>
                        <div className="pop-button">
                          <ModalContainer
                            contexts={context}
                            selectedDate={date}
                            refreshTask={refreshTaskPoP}
                            count={countPoP}
                            myOnClose={closePop}
                            type={"1"}
                            buttonText={intl.formatMessage({
                              id: "pop.up.time.add",
                              defaultMessage: "Add worked hours",
                            })}
                            modalDisplayed={popOverClick}
                            popOn={show}
                            firstBillingPeriodModal={firstBillingPeriod}
                            name={name}
                          />
                        </div>
                      </>:null}
                        <div className="pop-button">
                          <ModalContainer
                            contexts={context}
                            selectedDate={date}
                            refreshTask={refreshTaskPoP}
                            count={countPoP}
                            myOnClose={closePop}
                            type={"3"}
                            buttonText={intl.formatMessage({
                              id: "pop.up.illness.time.add",
                              defaultMessage: "Add illness time off",
                            })}
                            modalDisplayed={popOverClick}
                            popOn={show}
                            firstBillingPeriodModal={firstBillingPeriod}
                            name={name}
                          />
                        </div>
                  </div>
                </div>
              </>
            ) : (
              <> </>
            )
          ) : currentDate < date && date <= lastDayOfMonth ? (
            <>
              <div className="triangle-right-up"></div>
              <div className="square">
                <div className="options">
                  {permissions.includes('log-hours')?
                    <>
                      <div className="pop-button">
                        <ModalContainer
                          contexts={context}
                          selectedDate={date}
                          refreshTask={refreshTaskPoP}
                          count={countPoP}
                          myOnClose={closePop}
                          type={"1"}
                          buttonText={intl.formatMessage({
                            id: "pop.up.time.add",
                            defaultMessage: "Add worked hours",
                          })}
                          modalDisplayed={popOverClick}
                          popOn={show}
                          firstBillingPeriodModal={firstBillingPeriod}
                          name={name}
                        />
                      </div>
                    </>:null}
                      <div className="pop-button">
                        <ModalContainer
                          contexts={context}
                          selectedDate={date}
                          refreshTask={refreshTaskPoP}
                          count={countPoP}
                          myOnClose={closePop}
                          type={"3"}
                          buttonText={intl.formatMessage({
                            id: "pop.up.illness.time.add",
                            defaultMessage: "Request time off",
                          })}
                          modalDisplayed={popOverClick}
                          popOn={show}
                          firstBillingPeriodModal={firstBillingPeriod}
                          name={name}
                        />
                      </div>
                </div>
              </div>
            </>
          ) : selectedMonth === currentMonth ? (
            <>
              <div className="triangle-right-up"></div>
              <div className="square">
                <div className="options">
                  {permissions.includes('log-hours')?
                    <>
                      <div className="pop-button">
                        <ModalContainer
                          contexts={context}
                          selectedDate={date}
                          refreshTask={refreshTaskPoP}
                          count={countPoP}
                          myOnClose={closePop}
                          type={"1"}
                          buttonText={intl.formatMessage({
                            id: "pop.up.time.add",
                            defaultMessage: "Add worked hours",
                          })}
                          modalDisplayed={popOverClick}
                          popOn={show}
                          firstBillingPeriodModal={firstBillingPeriod}
                          name={name}
                        />
                      </div>
                    </>:null}
                    <div className="pop-button">
                      <ModalContainer
                        contexts={context}
                        selectedDate={date}
                        refreshTask={refreshTaskPoP}
                        count={countPoP}
                        myOnClose={closePop}
                        type={"3"}
                        buttonText={intl.formatMessage({
                          id: "pop.up.illness.time.add",
                          defaultMessage: "Add illness time off",
                        })}
                        modalDisplayed={popOverClick}
                        popOn={show}
                        firstBillingPeriodModal={firstBillingPeriod}
                        name={name}
                      />
                    </div>
                </div>
              </div>
            </>
          ) : null
        ) : currentDate <= date ? (
          context === 'Global'?
            permissions.includes('create-global-events')?
              <>
                <div className="triangle-right-up"></div>
                <div className="square">
                  <div className="options">
                    <div className="pop-button">
                      <ModalContainer
                        contexts={context}
                        selectedDate={date}
                        refreshTask={refreshTaskPoP}
                        count={countPoP}
                        myOnClose={closePop}
                        type={"2"}
                        buttonText={intl.formatMessage({
                          id: "pop.up.holiday.add",
                          defaultMessage: "Add a holiday",
                        })}
                        modalDisplayed={popOverClick}
                        popOn={show}
                        name={name}
                      />
                    </div>
                    <div className="pop-button">
                      <ModalContainer
                        contexts={context}
                        selectedDate={date}
                        refreshTask={refreshTaskPoP}
                        count={countPoP}
                        myOnClose={closePop}
                        type={"4"}
                        buttonText={intl.formatMessage({
                          id: "pop.up.other.add",
                          defaultMessage: "Add other event",
                        })}
                        modalDisplayed={popOverClick}
                        popOn={show}
                        name={name}
                      />
                    </div>
                  </div>
                </div>
              </>
              : null
            : null
        ) :null}
        {context === region || context === local?
          permissions.includes('create-local-events')?
            <>
              <div className="triangle-right-up"></div>
              <div className="square">
                <div className="options">
                  <div className="pop-button">
                    <ModalContainer
                      contexts={context}
                      selectedDate={date}
                      refreshTask={refreshTaskPoP}
                      count={countPoP}
                      myOnClose={closePop}
                      type={"2"}
                      buttonText={intl.formatMessage({
                        id: "pop.up.holiday.add",
                        defaultMessage: "Add a holiday",
                      })}
                      modalDisplayed={popOverClick}
                      popOn={show}
                      name={name}
                    />
                  </div>
                  <div className="pop-button">
                    <ModalContainer
                      contexts={context}
                      selectedDate={date}
                      refreshTask={refreshTaskPoP}
                      count={countPoP}
                      myOnClose={closePop}
                      type={"4"}
                      buttonText={intl.formatMessage({
                        id: "pop.up.other.add",
                        defaultMessage: "Add other event",
                      })}
                      modalDisplayed={popOverClick}
                      popOn={show}
                      name={name}
                    />
                  </div>
                </div>
              </div>
            </>
            : null
        :null}
      </div>
    </>
  );
}

PopOverOptions.propTypes = {
  currentDate: PropTypes.string,
};
