import "./ProjectsHappeningNext.scss";
import CalendarWithDateIcon from "../Icons/CalendarWithDateIcon";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSchedulerContext } from "../../context/SchedulerContext";
import { getCalendarHolidays } from "../MyCalendar/services/get.calendar.holidays";
import { useAuthContext } from "../../context/AuthContext";
import { getLocationsAndRegions } from "../MyCalendar/services/getLocationsAndRegions";

/**
 * @module
 * @description This function create the structure and the logic of the Happening Next
 * @returns {JSX} Happening next
 */

const HappeningNext = (props) => {
  //example info
  
  let region = props.region;
  const context = useSchedulerContext();
  const user = useAuthContext();
  const [holiday, setHoliday] = useState([]);

  let infoNext = [];

  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let selectedYear, selectedMonth, initDate, finishDate, endMonth;

  useEffect(() => {
    let regions;

    let currentDate = new Date();
    selectedMonth = currentDate.getMonth() + 1;
    selectedYear = currentDate.getFullYear();
    endMonth = selectedMonth + 1;
    if (selectedMonth >= 10) {
      initDate = selectedYear + "-" + selectedMonth + "-" + "01";
      finishDate = selectedYear + "-" + endMonth + "-" + "30";
    } else {
      initDate = selectedYear + "-0" + selectedMonth + "-" + "01";
      finishDate = selectedYear + "-0" + selectedMonth + "-" + "30";
    }

    let position, locationCity;
    const promise = new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(getLocations());
      }, 50);
    }).then((data) => getHoliday());
    async function getLocations() {
      let locations = await getLocationsAndRegions();

      for (let x in locations) {
        if (locations[x].name === user.userInfo.region) {
          regions = locations[x].id;
          position = x;
        }
      }

      for (let y in locations[position].locations) {
        if (locations[position].locations[y].name === user.userInfo.location) {
          locationCity = locations[position].locations[y].id;
        }
      }
    }


    async function getHoliday() {
      let holidays;
      if (region === "global") {
        holidays = await getCalendarHolidays(
          initDate,
          finishDate,
          locationCity,
          regions,
          user.user.token,
          true
        );
      } else if (region === "local") {
        holidays = await getCalendarHolidays(
          initDate,
          finishDate,
          locationCity,
          regions,
          user.user.token,
          false
        );
      }

      setHoliday(holidays);
    }
  }, []);
  var now;
  let data;
  if (holiday.length <= 0) {
  } else {
    for (let x = 0; x < holiday.length; x++) {
      now = new Date(holiday[x].start);

      // We ALWAYS need both UTCMonth and UTCDate , given the fact that we have different timezones for 
      // a particular FE.
      data = months[now.getUTCMonth()] + " " + now.getUTCDate();
      infoNext.push({
        title: holiday[x]?.title,
        info: holiday[x]?.reason,
        date: data,
      });
      now = 0;
      data = 0;
    }
  }

  return (
    <div className="happeningNextElementContainer">
      <table className="hn-table">
        <tbody>
          {infoNext.map((info) => (
            <tr key={info.info}>
              <div className="happeningNextElement">
                <td>
                  <CalendarWithDateIcon date={info.date} />
                </td>

                <td>
                  <b>{info.title}</b> <br />
                  {info.info}
                </td>
              </div>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    
    
  );
};

export default HappeningNext;
