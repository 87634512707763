import axios from "axios";
import "./Projects2.scss";
import {useState, useRef, useEffect} from "react";
import { useInfiniteQuery } from "react-query";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import { environment } from "../../config/environment";
import { useAuthContext } from "../../context/AuthContext/AuthContext";
import ProjectsList from "../../components/Projects/ProjectsList";
import { TabTitle } from "../../utils/DinamicTitle";
import data from "../../version.json";
import { useIntl } from "react-intl";
import {Redirect, useNavigate} from "react-router-dom";

let version = data;
let currentVersion = version[version.length - 1];
const Version = (
  <div className="version">
    <p className="versionText">{currentVersion["Current version"]}</p>
  </div>
);

const Projects2 = () => {
  TabTitle("MyBTS - My projects");
  const { user } = useAuthContext();
  const auth = useAuthContext();
  const intl = useIntl();
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState([]);

  const [filterSort, setFilterSort] = useState({
    column: "",
    order: "asc",
  });
  const [filterSearch, setFilterSearch] = useState("");
  const [filterStatusType, setFilterStatusType] = useState({
    st_onTrack: true,
    st_inDanger: true,
    st_atRisk: true,
    st_outdated: true,
    st_archived: true,
  });
  const [firstRender, setFirstRender] = useState(true);

  const filters = {
    archived: 1,
    st_onTrack: 1,
    st_inDanger: 1,
    st_atRisk: 1,
    st_outdated: 1,
    sortBy: filterSort.column,
    sortOrder: filterSort.order,
  };

  const getQueryParamsFromFilters = (filters) => {
    let queryParamsString = "?";
    let firstParam = true;
    for (const key in filters) {
      const value = filters[key];
      let currentParam = `${key}=${value}`;
      if (!firstParam) {
        currentParam = "&" + currentParam;
      }
      firstParam = false;
      queryParamsString = queryParamsString + currentParam;
    }
    return queryParamsString;
  };

  const getProjectsByUser = async ({ pageParam = 1 }) => {
    const queryParams = getQueryParamsFromFilters(filters);
    const response = await axios
      .get(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module2/projects/user/${user.id}${queryParams}&page=${pageParam}&search=${filterSearch}`,
        {
          headers: {
            Authorization: user?.token,
          },
        }
      )
      .catch(async (err) => {});
    return response.data.data;
  };

  const useFetchingData = () => {
    return useInfiniteQuery(
      [
        "projects",
        {
          sortBy: filterSort.column,
          sortOrder: filterSort.order,
          search: filterSearch,
        },
      ],
      getProjectsByUser,
      {
        getNextPageParam: (lastPage, _pages) => {
          if (lastPage.currentPage < lastPage.totalPages) {
            return lastPage.currentPage + 1;
          } else {
            return undefined;
          }
        },
        keepPreviousData: true,
      }
    );
  };

  const {
    status,
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useFetchingData();

  const loadMoreButtonRef = useRef();

  useIntersectionObserver({
    target: loadMoreButtonRef,
    onIntersect: fetchNextPage,
    enabled: !!hasNextPage,
  });

  const handleFilterSort = (selectedColumn) => {
    const newFilterSort = { ...filterSort };
    if (selectedColumn === filterSort.column) {
      newFilterSort.order = filterSort.order === "desc" ? "asc" : "desc";
    } else {
      newFilterSort.column = selectedColumn;
      newFilterSort.order = "desc";
    }
    setFilterSort(newFilterSort);
  };

  useEffect(()=>{
    let permissions = user.permissions.userPermissions;
    let redirect = true;
    let permissionsArray = [];
    for (const permissionElement of permissions) {
      if(permissionElement.locations && permissionElement.locations.name === auth.userInfo.location) {
        if(permissionElement.permission.name === 'view-list-projects' || permissionElement.permission.name === 'view-projects'){
          redirect = false;
        }
        permissionsArray.push(permissionElement.permission.name);
      }
    }
    setPermissions([...permissionsArray])
    if(redirect){
      navigate("/")
    }
  },[])

  return (
    <div>
      {status === "loading" ? (
        <p>Loading...</p>
      ) : status === "error" ? (
        <span>Error: {error.message}</span>
      ) : (
        <>
          <div className="main-projects">
            <div className="projects">
              {permissions.includes('view-list-projects')?
                <ProjectsList
                  projects={data.pages}
                  handleFilterSort={handleFilterSort}
                  filterSort={filterSort}
                  setFilterSearch={setFilterSearch}
                  filterStatusType={filterStatusType}
                  setFilterStatusType={setFilterStatusType}
                  setFirstRender={setFirstRender}
                  firstRender={firstRender}
                  refetch={refetch}
                ></ProjectsList>
              : null}
              <div>
                <button
                  ref={loadMoreButtonRef}
                  onClick={() => fetchNextPage()}
                  disabled={!hasNextPage || isFetchingNextPage}
                >
                  {isFetchingNextPage
                    ? "Loading more..."
                    : hasNextPage
                    ? "Load Newer"
                    : "Nothing more to load"}
                </button>
              </div>
              {Version}
              <div>
                {isFetching && !isFetchingNextPage ? "Fetching..." : null}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Projects2;
