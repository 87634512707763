import OnboardIcon from "../../Icons/OnboardIcon";
import VideoIcon from "../../Icons/VideoIcon";
import scss from "./Video.module.scss";

/**
 * @description Renders two boxes that portray a onboarding video as well as a link to a wiki.
 * @returns {JSX} A container with two frames portraying photos and links below embedded into descriptions.
 */

const Video = () => {
  const data = [
    {
      src: 1,
      className: scss.ceo,
      ref: "",
      title: "Welcome by our CEO Rosalba",
      icon: <VideoIcon />,
    },
    {
      src: 2,
      className: scss.ceo,
      ref: "",
      title: "Country onboarding",
      icon: <OnboardIcon />,
    },
    {
      src: 3,
      className: scss.wiki,
      ref: "",
      title: "Get started page in our Wiki",
      icon: "",
    },
  ];
  return (
    <div className={scss.container}>
      <div className={scss.video}>
        {data.map((item) => (
          <div className={scss.video_container} key={item.src}>
            <div className={item.className}>{item.icon}</div>
            <p className={scss.box_text}>
              <a href={item.ref} target="_blank" rel="noreferrer">
                {item.title}
              </a>
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Video;
