import { DOCIcon, PDFIcon } from "../components/Icons";

const icons = {
  doc: <DOCIcon />,
  pdf: <PDFIcon />,
};

export function getFileIcon(filename) {
  // this func returns the icon of a file by its name

  const extension = filename.split(".").pop();

  return icons[extension] || <DOCIcon />;
}
