
function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#217DD1"
        fillRule="evenodd"
        d="M0 7.875c0-3.281 0-4.922.836-6.072a4.37 4.37 0 01.967-.967C2.953 0 4.594 0 7.875 0s4.921 0 6.072.836c.371.27.698.596.968.967.835 1.15.835 2.791.835 6.072s0 4.921-.835 6.072a4.4 4.4 0 01-.968.968c-1.15.835-2.791.835-6.072.835s-4.922 0-6.072-.835a4.377 4.377 0 01-.967-.968C0 12.797 0 11.156 0 7.875zm7.875-1.531c.362 0 .656.294.656.656v5.25a.656.656 0 01-1.312 0V7c0-.362.294-.656.656-.656zm0-1.094a.875.875 0 100-1.75.875.875 0 000 1.75z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;