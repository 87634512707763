import "./Layout.scss";
import React, { useLayoutEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useAuthContext } from "../../../context/AuthContext";
import Sidebar from "../Sidebar/Sidebar";
import Topbar from "../Topbar/Topbar";
import Tutorial from "../../Core/FirstTutorial/FirstTutorial";
import { getTutorialsCompleted } from "../services/tutorials";
import { useIntl } from "react-intl";

/**
 * @module
 * @description this function creates the layout of the main components of the whole application and defines the dimensions of each one. Sidebar, top bar, output.
 * @returns {JSX} Layaout
 */
const Layout = () => {
  const auth = useAuthContext();
  const intl = useIntl();
  const [tutorials, setTutorials] = useState();
  const [complete, setComplete] = useState(0);
  const [err, setErr] = useState(false);

  /**
   * This useLayout effect verify if the user has completed or not the sidebarTopbar
   * tutorial.
   *  */
  useLayoutEffect(() => {
    let isMounted = true;
    if (complete == 0 && localStorage.getItem("sidebarTopbarTutorial")) {
      localStorage.removeItem("sidebarTopbarTutorial");
    }

    if (
      localStorage.getItem("sidebarTopbarTutorial") == 0 ||
      localStorage.getItem("sidebarTopbarTutorial") == null
    ) {
      (async function () {
        try {
          if (auth.user) {
            let tutorialsArray = await getTutorialsCompleted(auth.userInfo.id, auth?.user?.token);
            tutorialsArray = tutorialsArray.filter(
              (tuto) => tuto.tutorialName == "sidebarTopbarTutorial"
            );
            setComplete(tutorialsArray.length);
            localStorage.setItem("sidebarTopbarTutorial", complete);
            if (isMounted) {
              setTutorials(tutorialsArray);
            }
          }
        } catch (error) {
          setErr(error?.message);
        }
      })();
    } else {
      setComplete(localStorage.getItem("sidebarTopbarTutorial"));
    }
    return () => (isMounted = false);
  }, [auth, complete]);

  if (!auth.user) {
    return (
      <>
        Redirecting to log in page...
        <Outlet />
      </>
    );
  }

  return (
    <div className="mainContainer">
      {complete > 0 ? <></> : <Tutorial />}

      <div className="topbar">
        <Topbar />
      </div>

      <div className="secondaryContainer">
        <Sidebar />

        <div className="contentContainer">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
