import Managers from "../../components/Core/Managers/Managers";
import WelcomeGlobal from "../../components/Core/Welcome/WelcomeGlobal";
import WhatIsGoingOn from "../../components/Core/WhatIsGoingOn/WhatIsGoingOn";
import css from "./Home.module.scss";
import WhatIsNext from "../../components/Core/WhatIsNext/WhatIsNext";
import { TabTitle } from "../../utils/DinamicTitle";
import data from "../../version.json";
import { useIntl } from "react-intl";

const Home = () => {
  let intl = useIntl();
  let version = data;
  let currentVersion = version[version.length - 1];

  TabTitle("MyBTS - Global home");
  return (
    <>
      <div className={css.container}>
        <div className={css.top}>
          <WelcomeGlobal />
        </div>
        <div className={css.bottom}>
          <div className={css.bTop}>
            <div className={css.whatIsGoingOn}>
              <WhatIsGoingOn region="global" />
            </div>
            <div className={css.whatIsNext}>
              <WhatIsNext region={"global"} />
            </div>
          </div>
          <div className={css.bBottom}>
            <div className={css.gmanagers}>
              <Managers
                type="global"
                title={intl.formatMessage({
                  id: "global.managers",
                  defaultMessage: "Global Managers",
                })}
              />
            </div>
            <div className={css.gTitans}>
              <Managers
                type="titan"
                title={intl.formatMessage({
                  id: "global.titans",
                  defaultMessage: "Global Titans",
                })}
              />
            </div>
            <div className={css.version}>
              <p className={css.versionText}>{currentVersion["Current version"]}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
