import css from "../Home/Home.module.scss";
import React from "react";
import WelcomeLocal from "../../components/Core/Welcome/WelcomeLocal";
import Managers from "../../components/Core/Managers/Managers";
import WhatIsGoingOn from "../../components/Core/WhatIsGoingOn/WhatIsGoingOn";
import { useAuthContext } from "../../context/AuthContext";
import WhatIsNext from "../../components/Core/WhatIsNext/WhatIsNext";
import { TabTitle } from "../../utils/DinamicTitle";
import data from "../../version.json";
import { useIntl } from "react-intl";

function Location() {
  let intl = useIntl();
  let version = data;
  let currentVersion = version[version.length - 1];
  const context = useAuthContext();

  TabTitle("MyBTS - Local home");
  return (
    <>
      <div className={css.container}>
        <div className={css.top}>
          <WelcomeLocal />
        </div>

        <div className={css.bottom}>
          <div className={css.bTop}>
            <div className={css.whatIsGoingOn}>
              <WhatIsGoingOn region={"local"} />
            </div>
            <div className={css.whatIsNext}>
              <WhatIsNext region={"local"} />
            </div>
          </div>
          <div className={css.bBottom}>
            <div className={css.gmanagers}>
              <Managers
                type="local"
                title={intl.formatMessage({
                  id: "local.managers",
                  defaultMessage: "Local Managers",
                })}
              />
            </div>
            <div className={css.gTitans}>
              <Managers
                type="localTitan"
                title={intl.formatMessage({
                  id: "local.titans",
                  defaultMessage: "Local Titans",
                })}
              />
            </div>
          </div>
        </div>
        <div className={css.version}>
          <p className={css.versionText}>{currentVersion["Current version"]}</p>
        </div>
      </div>
    </>
  );
}

export default Location;
