import "./ProjectDetail.scss";
import moment from "moment";
import { useEffect, useState } from "react";
import { BiEditAlt } from "react-icons/bi";
import { useContext } from "react";
import ProjectsContext from "../../context/ProjectsContext/ProjectsContext";
import { FormattedMessage, useIntl } from "react-intl";
import ReactTooltip from "react-tooltip";
import { Button } from "react-bootstrap";
import EditProjectDetailModal from "./EditProjectDetailModal";
import {useAuthContext} from "../../context";

/**
 * @module
 * @description This function creates the structure and the logic of Project Detail
 * @param {string} getProjectDetail get Project Detail
 * @param {number} id get id
 * @returns {JSX} Project Detail
 */

const ProjectDetail = ({ getProjectDetail, id, archivedProject }) => {
  const [project, setProject] = useState(""); //data is stored here
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { auth } = useContext(ProjectsContext);
  const intl = useIntl();
  const { user } = useAuthContext();
  const authContext = useAuthContext();
  const [permissions,setPermissions] = useState([]);

  useEffect(() => {
    setLoading(true);
    getProjectDetail(id)
      .then((res) => setProject(res.data.data), setLoading(false))
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleProjectModal = () => {
    setIsEditing(!isEditing);
  };

  useEffect(()=>{
    let permission = user.permissions.userPermissions;
    let permissionsArray = []
    for (const permissionElement of permission) {
      if (permissionElement.locations && permissionElement.locations.name === authContext.userInfo.location) {
        permissionsArray.push(permissionElement.permission.name);
      }
    }
    setPermissions([...permissionsArray])
  },[])

  return (
    <>
      {" "}
      {loading ? (
        <div> Loading... </div>
      ) : (
        <div className="projectConteiner">
          <h2>
            {/*<FormattedMessage
              id="project.by.id.details"
              defaultMessage="Project details"
            />*/}
            Project details
            {permissions.includes('update-projects') && !archivedProject?
            <div className="edit">
              <button
                data-tip={intl.formatMessage({
                  id: "edit.task",
                  defaultMessage: "Edit",
                })}
                data-for="editProjectDetails"
                onClick={handleProjectModal}
              >
                <BiEditAlt />
              </button>
              <ReactTooltip
                id="editProjectDetails"
                place="bottom"
                backgroundColor="#3FAA58"
              />
            </div>:null}
          </h2>
          {isEditing ? (
            <>
              <EditProjectDetailModal
                show={isEditing}
                handleProjectModal={handleProjectModal}
                project={project}
              />
              <div>
                <p>
                  Category: <span>{project.projectCategory}</span>
                </p>
                <p>
                  Since:{" "}
                  <span>{moment(project.startDate).format("YYYY-MM-DD")}</span>
                </p>
                <p>
                  Contract type: <span>{project.contractType}</span>
                  {project.contractType === "Long-term" ? (
                    <span> {project.previousNotices} day/s notice</span>
                  ) : (
                    ""
                  )}
                  {project.contractType === "Other" ? (
                    <span> - {project.otherContractType} </span>
                  ) : (
                    ""
                  )}
                </p>
                <p>
                  Estimated end date:{" "}
                  <span>{moment(project.endDate).format("YYYY-MM-DD")}</span>
                </p>
                {project.deletedAt ? (
                  <p>
                    Archived date:{" "}
                    <span>
                      {moment(project.deletedAt).format("YYYY-MM-DD")}
                    </span>
                  </p>
                ) : (
                  ""
                )}
              </div>
            </>
          ) : (
            <div>
              <p>
                Category: <span>{project.projectCategory}</span>
              </p>
              <p>
                Since:{" "}
                <span>{moment(project.startDate).format("YYYY-MM-DD")}</span>
              </p>
              <p>
                Contract type: <span>{project.contractType}</span>
                {project.contractType === "Long-term" ? (
                  <span>{project.previousNotices} day/s notice</span>
                ) : (
                  ""
                )}
                {project.contractType === "Other" ? (
                  <span> - {project.otherContractType} </span>
                ) : (
                  ""
                )}
              </p>
              <p>
                Estimated end date:{" "}
                <span>{moment(project.endDate).format("YYYY-MM-DD")}</span>
              </p>
              {project.deletedAt ? (
                <p>
                  Archived date:{" "}
                  <span>{moment(project.deletedAt).format("YYYY-MM-DD")}</span>
                </p>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      )}{" "}
    </>
  );
};

export default ProjectDetail;
