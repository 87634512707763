import GrayCalendarIcon from "../../Icons/GrayCalendarIcon";
import ModalContainer from "../Modal/ModalContainer";
import Context from "../Context/Context";
import {useEffect, useState} from "react";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// language switcher feature
import { FormattedMessage } from "react-intl";
import { useAuthContext } from "../../../context/AuthContext";
import moment from "moment";
import {getLocationsAndRegions} from "../../../services/RoleAccessControl/getUserLocation";
import getUserByName from "../services/getUserByName";

/**
 * @module
 * @description this component displays the calendar icon and the
 * add timesheet component
 * @param addTask
 * @param  taskCount
 * @returns {JSX} Header
 */

const Header = (props) => {
  const auth = useAuthContext();
  const { addTask, setAddTask } = props;
  const { taskCount, setTaskCount } = props;
  const { context, setContext } = props;
  const { name, setName } = props;
  const [firstBillingPeriod, setFirstBillingPeriod] = useState(false);
  const [permissions,setPermissions] = useState([]);
  const { user } = useAuthContext();
  const [locations, setLocations] = useState([]);
  const [regions, setRegions] = useState([]);
  const {setContextEnumerator} = props;
  const {contextEnumerator} = props;
  const {local} =props;
  const {region} = props;
  const {setLocal} =props;
  const {setRegion} = props;

  useEffect(() => {
    if(moment().format("D") < 16) {
      setFirstBillingPeriod(true);
    };
    return () => {
      setFirstBillingPeriod(false);
    }
  },[]);

  useEffect(()=>{
    async function setUp() {
    let permissionsArray = [];
    let permission = user.permissions.userPermissions;
    if(context === 'Personal' || context === 'Global') {
      for (const permissionElement of permission) {
        if(permissionElement.locations && permissionElement.locations.name === auth.userInfo.location) {
          permissionsArray.push(permissionElement.permission.name);
        }
      }
      setPermissions([...permissionsArray]);
    }else {
      let locationFound =[];
      let regionFound;
      let permissionsByLocation = [];
      const selectedUser = await getUserByName(name, auth.user.token);
      if(selectedUser) {
        const locationsAndRegions = await getLocationsAndRegions(auth.user.token);
        locationsAndRegions.forEach((region) => {
          const tempLocationFound = region.locations.filter((location) => location.id === selectedUser.companyLocationId)
          if(tempLocationFound.length > 0) {
            locationFound = [...locationFound, ...tempLocationFound];
            regionFound = region;
          }
        });
      }else if(context !=='Personal' && context !== 'Global' && context !== name){
        user.permissions.userPermissions.forEach(element =>{
          if(element.regions && element.regions.name === context){
            permissionsByLocation.push(element.permission.name);
          }else if(element.locations && element.locations.name === context){
            permissionsByLocation.push(element.permission.name);
          }
        })
      }

      if(locationFound.length >0){
        for (const permissionElement of user.permissions.userPermissions) {
          if(permissionElement.locations && permissionElement.locations.name === locationFound[0].name) {
            permissionsByLocation.push(permissionElement.permission.name);
          }
        }
      }
      if(locationFound.length >0) {
        if (permissionsByLocation.length === 0) {
          for (const permissionElement of user.permissions.userPermissions) {
            if (permissionElement.regions && permissionElement.regions && permissionElement.regions.name === regionFound.name) {
              permissionsByLocation.push(permissionElement.permission.name);
            }
          }
        }
      }
      setPermissions([...permissionsByLocation]);
    }
    let userLocations= await getLocationsAndRegions(auth.user.token, auth.userInfo.id);
    let userRegions = userLocations.filter(item => !item.id);
    userLocations = userLocations.filter(item => item.id);
    setLocations([...userLocations])
    setRegions([...userRegions])
    }
    setUp();
    return () => {
      setPermissions([]);
      setLocations([]);
      setRegions([]);
    }
  },[auth.user.token, auth.userInfo.id, auth.userInfo.location, context, name, user.permissions.userPermissions])

  return (
    <>
      <div className="scheduler-header">
        <div className="styleDiv">
          <GrayCalendarIcon />
          <h4>
            {/*<FormattedMessage
              id="scheduler.header.left.txt"
              defaultMessage="Scheduler and Log"
            />*/}
            Scheduler and Log
          </h4>
        </div>

        <div className="styleDiv1">
          <h5 id="context-title">
            {/*<FormattedMessage
              id="scheduler.header.context"
              defaultMessage="Context: "
            />*/}
            Context
          </h5>
          <h5>
            &nbsp;
            { context === name ? <FontAwesomeIcon icon={['fas', 'user']}/> : <></> }
            { context === "Personal" ? <FontAwesomeIcon icon={['fas', 'user']}/> : <></> }
            { context === "Global" ? <FontAwesomeIcon icon={['fas', 'globe']}/> : <></> }
            { context === region ? (
              <FontAwesomeIcon icon={['fas', 'city']}></FontAwesomeIcon>
            ) : (
              <></>
            )}
            { context === local ? <FontAwesomeIcon icon={['fas', 'home']}/> : <></> }
            &nbsp;
            { context }
            { context === "Personal" ? " (you)" : <></> }
          </h5>
          <Context
            setLocal={setLocal}
            setRegion={setRegion}
            local={local}
            region={region}
            contextEnumerator={contextEnumerator}
            setContextEnumerator={setContextEnumerator}
            context={context}
            setContext={setContext}
            name={name}
            setName={setName}
            locations={locations}
            regions={regions}
          />
        </div>
        {context === 'Global' ?
          permissions.includes('create-global-events')?
          <ModalContainer
            contexts={context}
            name={name}
            refreshTask={addTask}
            count={taskCount}
            buttonText={"Add entry manually"
              /*<FormattedMessage
                id="scheduler.header.add.entry"
                defaultMessage="Add entry manually"
              />*/
            }
            firstBillingPeriodModal={firstBillingPeriod}
          /> : <div style={{minWidth:'150px'}}></div>
            : null
        }
        {context === region || context === local ?
          permissions.includes('create-local-events')?
          <ModalContainer
            contexts={context}
            name={name}
            refreshTask={addTask}
            count={taskCount}
            buttonText={"Add entry manually"
              /*<FormattedMessage
                id="scheduler.header.add.entry"
                defaultMessage="Add entry manually"
              />*/
            }
            firstBillingPeriodModal={firstBillingPeriod}
          />: <div style={{minWidth:'150px'}}></div>
            : null
        }
        {context === 'Personal' || context === name ?
          <ModalContainer
            contexts={context}
            name={name}
            refreshTask={addTask}
            count={taskCount}
            buttonText={"Add entry manually"
              /*<FormattedMessage
                id="scheduler.header.add.entry"
                defaultMessage="Add entry manually"
              />*/
            }
            firstBillingPeriodModal={firstBillingPeriod}
          /> : null}
      </div>
    </>
  );
};

export default Header;
