import axios from "axios";
import { environment } from "../../config/environment";

const getAllLocationsAndRegions = async (userToken) => {
  let locations = await axios
    .get(`${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/company/locations`, {
      headers: {
        authorization: userToken,
      },
    })
    .then((result) => result)
    .catch((error) => {
      throw error.response || error.toJSON() || error
    });
  locations = locations.data.data;

  let regions = await axios
    .get(`${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/company/regions`, {
      headers: {
        authorization: userToken,
      },
    })
    .then((result) => result)
    .catch((error) => {
      throw error.response || error.toJSON() || error
    });

  const regionsAndLocations = [...regions.data.data, ...locations];

  return regionsAndLocations;
};

export { getAllLocationsAndRegions };
