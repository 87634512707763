import React from "react";
/**
 * @description Function to create settings icon as a JSX component
 * @returns {JSX} SettingIcon
 */
function SettingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      fill="currentcolor"
      viewBox="1 0 14 18"
    >
      <path
        //fill="#9C9C9C"
        fillRule="evenodd"
        d="M7 5.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5zM5.5 8a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z"
        clipRule="evenodd"
      ></path>
      <path
        //fill="#9C9C9C"
        fillRule="evenodd"
        d="M9.276 2.155c-.653-2.29-3.899-2.29-4.553 0a1.367 1.367 0 01-1.647.951C.766 2.526-.857 5.338.8 7.049c.513.53.513 1.372 0 1.902-1.657 1.711-.034 4.522 2.276 3.943a1.367 1.367 0 011.647.951c.654 2.29 3.9 2.29 4.553 0 .203-.71.932-1.13 1.648-.95 2.31.578 3.933-2.233 2.276-3.944a1.367 1.367 0 010-1.902c1.657-1.711.034-4.522-2.276-3.943a1.367 1.367 0 01-1.648-.951zm-3.591.274c.378-1.322 2.252-1.322 2.63 0a2.367 2.367 0 002.852 1.647c1.334-.334 2.271 1.29 1.315 2.277a2.367 2.367 0 000 3.294c.957.988.02 2.612-1.315 2.277a2.367 2.367 0 00-2.852 1.647c-.378 1.323-2.252 1.323-2.63 0a2.367 2.367 0 00-2.852-1.647C1.499 12.26.56 10.635 1.518 9.647a2.367 2.367 0 000-3.294c-.957-.988-.02-2.611 1.315-2.277a2.367 2.367 0 002.852-1.647z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default SettingIcon;