import React from "react";
/**
 * @description This function returns an Icon used to show the users timezone.
 * @returns {JSX} LocationIcon
 */
function LocationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="13"
      fill="none"
      viewBox="0 0 10 13"
    >
      <path
        fill="#38B872"
        fillRule="evenodd"
        d="M4.777 3.276a2.047 2.047 0 100 4.095 2.047 2.047 0 000-4.095zM3.55 5.323a1.228 1.228 0 112.457 0 1.228 1.228 0 01-2.457 0z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#38B872"
        fillRule="evenodd"
        d="M4.777 0C2.111 0 0 2.316 0 5.11c0 .895.445 1.95 1.005 2.94.569 1.006 1.295 2.01 1.932 2.822l.021.027c.31.397.576.734.832.968.28.254.59.42.987.42.398 0 .707-.166.987-.42.257-.234.522-.571.833-.968l.02-.027c.638-.812 1.364-1.816 1.933-2.822.56-.99 1.005-2.045 1.005-2.94C9.555 2.316 7.444 0 4.777 0zM.82 5.11c0-2.399 1.8-4.291 3.958-4.291 2.159 0 3.959 1.892 3.959 4.29 0 .67-.35 1.566-.9 2.538-.54.956-1.238 1.924-1.863 2.72-.338.43-.56.712-.76.893-.181.165-.302.209-.436.209-.133 0-.254-.044-.436-.209-.2-.18-.422-.462-.76-.893-.624-.796-1.323-1.764-1.863-2.72-.55-.972-.899-1.868-.899-2.537z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default LocationIcon;
