import { environment } from "../../config/environment";

const  getLocationsByRegionName= async (token, region) => {

  try {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    const data = await fetch(`${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/permissions/locationsByRegionName/` + region, requestOptions)
      .then(response => response.json())
      .catch(error => console.log('error', error));

    return data.data;
  } catch (e) {
    console.error(e)
  }

}


const  getLocationsAndRegions= async (token, userId) => {

  try {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    const response = await fetch(`${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/company/locations/assignedLocationsAndRegions/` + userId, requestOptions)
      .then(response => response.json())
      .catch(error => console.log('error', error));
    return response.data;
  } catch (e) {
    console.error(e)
  }

}

export { getLocationsByRegionName, getLocationsAndRegions };
