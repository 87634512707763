/**
 * @description Function to create the Recruitment icon as a JSX component
 * @returns {JSX} RecruitmentIcon
 */
const RecruitmentIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.357 2.453a.404.404 0 0 0-.126-.798A3.099 3.099 0 0 0 1.655 4.23a.404.404 0 0 0 .798.126 2.29 2.29 0 0 1 1.904-1.904z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.254 0a5.254 5.254 0 1 0 0 10.508A5.254 5.254 0 0 0 5.254 0zM.808 5.254a4.446 4.446 0 1 1 8.892 0 4.446 4.446 0 0 1-8.892 0z"
        fill="currentColor"
      />
      <path
        d="M9.851 9.28a.404.404 0 1 0-.571.571l2.155 2.156a.404.404 0 1 0 .572-.572L9.85 9.28z"
        fill="currentColor"
      />
    </svg>
  );
};

export default RecruitmentIcon;
