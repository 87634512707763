import React, { useState, useEffect, useContext } from "react";
import Dropdown from "./Dropdown2";
import "../SchedulerLayout/scheduler.scss";
import "./MyTicklerStyles.scss";
import MyTickler from "./MyTickler";
import MyTask from "./MyTask";
import { useAuthContext } from "../../../context/AuthContext";
import { useSchedulerContext } from "../../../context/SchedulerContext";
import { CalendarIcon } from "../../Icons";
import WarningTimeIcon from "../../Icons/WarningTimeIcon"
import ReactTooltip from "react-tooltip";
import Record from "../../Icons/Record";
import { getTicklerTask } from "../services/userTicklerServices";
import { projectService } from "../services/get.user.projects";
import ProjectsContext from "../../../context/ProjectsContext/ProjectsContext";

/**
 * @module
 * @description this component displays the tickler dropdown from the topbar
 * @returns {JSX} MyTicklerTopBar
 */

const MyTicklerTopBar = () => {
  const [showTickler, setShowTickler] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const [outsideClick, setOutsideClick] = useState(false);
  const [time, setTime] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  const [startHour, setStartHour] = useState("00:00");
  const startHourSplit = startHour.split(":");
  const startHourMs =
    (+startHourSplit[0] * 3600 + +startHourSplit[1] * 60) * 1000;
  const now = new Date();
  const nowHourMs = (now.getHours() * 3600 + now.getMinutes() * 60) * 1000;
  const msDiff = nowHourMs - startHourMs;
  const { getUserProjectsByUserId } = useContext(ProjectsContext);

  // Current date
  let date = new Date();
  let year = date.getFullYear(),
    month = String(date.getMonth() + 1).padStart(2, "0"),
    day = String(date.getDate()).padStart(2, "0"),
    joined = [year, month, day].join("-");
  // Projects
  const [projects, setProjects] = useState([]);
  const [projectsDB, setProjectsDB] = useState([]);
  // Current unfinished task in localStorage
  const [unfinishedTask, setUnfinishedTask] = useState(null);
  const [localStorageChanged, setLocalStorageChanged] = useState(false);

  // Contexts
  const auth = useAuthContext();
  const scheduler = useSchedulerContext();

  const handleShowTickler = (tickler) => setShowTickler(tickler);

  const handleStartTimer = () => {
    setIsActive(true);
    setIsPaused(false);
  };

  const handleStopTimer = () => {
    setIsActive(false);
    setIsPaused(true);
    setTime(0);
  };

  const hideDropdown = () => {
    setShowDropdown(false);
    setOutsideClick(true);
  };

  // Retrieving user projects.
  useEffect(() => {
    projectService
      .getProjects(auth.user.id, auth.user.token)
      .then((items) => setProjects(items))
      .catch((error) => {});
  }, []);

  // This function automatically sets "tickler" localStorage property into
  // unfinishedTask state.
  const onTicklerStorageUpdate = (e) => {
    const { key, newValue } = e;
    if (key === "tickler") {
      setUnfinishedTask(JSON.parse(newValue));
    }
  };

  // This lets us hear everytime there is a change in localStorage.
  // This will affect OTHER TABS instead of the one we are currently interacting with.
  // That is to say, this will propagate a phenomena into other tabs.
  useEffect(() => {
    window.addEventListener("storage", onTicklerStorageUpdate);
    return () => {
      window.removeEventListener("storage", onTicklerStorageUpdate);
    };
  }, []);

  // Retrieving unfinished task if it exists, and setting it in localStorage.
  // If there is no unfinished task in the backend, then localStorage "tickler" gets emptied.
  useEffect(() => {
    getTicklerTask(joined, auth.user.id, auth.user.token)
      .then((response) => {
        localStorage.setItem(
          "tickler",
          JSON.stringify({
            id: response.data.data[0].descriptions[0].id,
            task: response.data.data[0].descriptions[0].task,
            startHour: response.data.data[0].descriptions[0].startHour,
            projectId: response.data.data[0].descriptions[0].projectId,
            projectName: response.data.data[0].descriptions[0].projectName,
            tags: response.data.data[0].descriptions[0]?.tags,
          })
        );
      })
      .catch((error) => {
        if (error?.status === 404) {
          // Cleaning already finished task in Tickler
          localStorage.removeItem("tickler");
        }
      });
  }, []);

  // Reloading unfinished task everytime localStorage changes.
  // A child should mark that local storage HAS changed so as to make this work.
  useEffect(() => {
    setUnfinishedTask(JSON.parse(localStorage.getItem("tickler")));
    setLocalStorageChanged(false);
  }, [localStorageChanged]);

  // To load tickler (if it has got a pending task active) for every tab,
  // or after relogging in.
  useEffect(() => {
    if (unfinishedTask !== null) {
      setStartHour(unfinishedTask.startHour);
      handleStartTimer();
      handleShowTickler(false);
    } else if (unfinishedTask === null) {
      handleStopTimer();
      handleShowTickler(true);
    }
  }, [unfinishedTask]);

  useEffect(() => {
    const query = async () => {
      await getUserProjectsByUserId(auth.user.id).then((data) => {
        setProjectsDB(data.data.data);
      });
    };
    query();
  }, []);

  //Run the timer
  useEffect(() => {
    let interval = null;

    if (isActive && isPaused === false) {
      if (startHourMs === nowHourMs) {
        interval = setInterval(() => {
          setTime((time) => time + 10);
        }, 10);
      } else {
        setTime(msDiff);
        interval = setInterval(() => {
          setTime((time) => time + 10);
        }, 10);
      }
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isActive, isPaused]);

  // Get the timesheets
  useEffect(() => {
    if (auth.user && showDropdown === true)
      scheduler.setTaskCounter(scheduler.taskCounter + 1);
  }, [showDropdown]);

  useEffect(() => {
    const myTimer = setTimeout(() => setOutsideClick(false), 100);

    return () => clearTimeout(myTimer);
  }, [outsideClick]);

  return (
    <>
      <div
        id="tickler"
        onClick={!outsideClick ? () => setShowDropdown(!showDropdown) : null}
        data-tip="Tickler"
        data-for="tickler"
      >
        {isActive ? (
          time < 30600000 ? (
            <div className="record">
              <Record />
            </div>
          ) : (
            <div className="record">
              <WarningTimeIcon fillColor="#ef9325" />
            </div>
          )
        ) : (
          <div></div>
        )}
        <CalendarIcon color={showDropdown ? "#5AC775" : ""} />
        <ReactTooltip id="tickler" place="bottom" backgroundColor="#3FAA58" />
      </div>

      {showDropdown ? (
        <div className="ticklerContainer">
          <Dropdown hideDropdown={hideDropdown} showDropdown={showDropdown}>
            {showTickler === true ? (
              <MyTickler
                timesheets={scheduler.timesheets}
                changeTickler={() => handleShowTickler(false)}
                changed={scheduler.setTaskCounter}
                handleStartTimer={handleStartTimer}
                handleStartHourChange={setStartHour}
                projects={projects}
                currentDate={joined}
                setLocalStorageChanged={setLocalStorageChanged}
                setShowDropdown={setShowDropdown}
                projectsDB={projectsDB}
              />
            ) : (
              <MyTask
                changeTickler={() => handleShowTickler(true)}
                changed={scheduler.setTaskCounter}
                time={time}
                handleStopTimer={() => handleStopTimer()}
                projects={projects}
                currentDate={joined}
                currentTask={unfinishedTask}
                setLocalStorageChanged={setLocalStorageChanged}
                setShowDropdown={setShowDropdown}
              />
            )}
          </Dropdown>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default MyTicklerTopBar;
