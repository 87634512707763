import React from "react";
import ReactTooltip from "react-tooltip";

function FullTimeIcon() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        fill="none"
        viewBox="0 0 22 22"
        data-tip="Allocation type"
        data-for="allocation-type"
      >
        <circle cx="11" cy="11" r="11" fill="#313131"></circle>
        <path
          fill="#fff"
          d="M6.98 4.224a.5.5 0 00-.626-.781L4.688 4.776a.5.5 0 10.625.781l1.666-1.333zm8.666-.781a.5.5 0 10-.625.78l1.667 1.334a.5.5 0 00.625-.78l-1.667-1.334zM11.5 7.667a.5.5 0 00-1 0V11c0 .475.575.713.91.377l1.358-1.357a.5.5 0 10-.707-.707l-.56.56V7.668z"
        ></path>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M11 3.833a7.167 7.167 0 100 14.334 7.167 7.167 0 000-14.334zM4.833 11a6.167 6.167 0 1112.334 0 6.167 6.167 0 01-12.334 0z"
          clipRule="evenodd"
        ></path>
      </svg>
      <ReactTooltip id="allocation-type" place="bottom" backgroundColor="#3FAA58"/>
    </>

  );
}

export default FullTimeIcon;