import React, { useEffect, useState } from "react";
import axios from "axios";
import { environment } from "../../../config/environment";
import { useAuthContext } from "../../../context/AuthContext";
import Message from "../../Icons/Message";
import "./FeedbackModal.scss";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import btsLogo from "../../../assets/BTS-Logo/bts-logo.svg";
import ReactTooltip from "react-tooltip";
import {FormattedMessage, useIntl} from "react-intl";

const FeedbackModal = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [userInfo, setUserInfo] = useState("");
  const [textMessage, setTextmessage] = useState("");
  const [contextualInfo, setContextualInfo] = useState("");
  const [userMail, setUserMail] = useState("");
  const context = useAuthContext();
  const MySwal = withReactContent(Swal);
  const intl = useIntl();

  useEffect(() => {
    const getUserInfo = () => {
      let mail = context?.userInfo?.email;
      let name = context?.userInfo?.firstName.concat(
        context?.userInfo?.lastName,
        "-",
        mail
      );
      let ua = JSON.stringify(navigator.userAgent);
      let date = Date.now();
      let time = new Date(date).toString();


      setUserMail(mail);
      setContextualInfo(ua + time);
      setUserInfo(name);
    };
    getUserInfo();
  });
  const hideDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleChange = (e) => {
    setTextmessage(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (textMessage === "" || textMessage === null) {
      MySwal.fire({
        title: "Error",
        text: "Your feedback message is empty",
        imageUrl: btsLogo,
        imageWidth: 150,
        imageHeight: 150,
        imageAlt: "BTS Logo",
        backdrop: `
        rgba(0,0,0,0.9)`,
        timer: 2000,
        showConfirmButton: false,
      });
    } else {
      const body = {
        userInfo: userInfo,
        message: textMessage,
        contextualInformation: contextualInfo,
      };
      try {
        const response = await axios.post(
          `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module0/reports/feedback?email=${userMail}`,
          body,
          {
            headers: {
              authorization: `${context.user.token}`,
            },
          }
        );
        setTextmessage("");
        setUserInfo("");
        setContextualInfo("");
        if (response.status !== 200) {
          if (response.status === 401) {
            throw Object.assign(new Error(), {
              code: 401,
              message: "Unauthorized",
            });
          } else {
            throw Object.assign(new Error(), {
              code: 500,
              message:
                "Unexpected error, please try again or contact an administrator",
            });
          }
        }

        MySwal.fire({
          title: "Success",
          text: "Your message was send succesfully!",
          imageUrl: btsLogo,
          imageWidth: 150,
          imageHeight: 150,
          imageAlt: "BTS Logo",
          backdrop: `
        rgba(0,0,0,0.9)`,
          timer: 2000,
          showConfirmButton: false,
        });
        return response;
      } catch (err) {
        console.log(err);
        throw (
          err.response || {
            success: false,
            message: "An error has ocurred, please try again",
          }
        );
      }
    }
  };
  return (
    <div className="content-container">
      <div
        onClick={() => hideDropdown()}
        className="icon-container"
        data-tip={intl.formatMessage({id : "sidebar.feedback", defaultMessage : "Feedback"})}
        data-for="feedback"
      >
        <Message />
      </div>
      <ReactTooltip id="feedback" place="right" backgroundColor="#3FAA58" />
      {showDropdown && (
        <form className="feedback-container">
          <div className="title">
            <label>{/*<FormattedMessage id="sidebar.feedback.help.us"
    defaultMessage = "Help us to improve more"/>*/}Help us to improve more</label>
          </div>

          <textarea
            placeholder={intl.formatMessage({id : "sidebar.feedback.comments", defaultMessage : "Add your comments here..."})}
            className="text-area"
            onChange={(e) => handleChange(e)}
            value={textMessage}
          ></textarea>
          <div className="buttons">
            <button className="btn-cancel" onClick={() => hideDropdown()}>
            {/*<FormattedMessage id="button.cancel"
    defaultMessage = "Cancel"/>*/}
            Cancel
            </button>
            <button
              className="btn"
              type="submit"
              onClick={(e) => handleSubmit(e)}
            >
              {/*<FormattedMessage id="button.send"
    defaultMessage = "Send"/>*/}
            Send
            </button>
          </div>
        </form>
      )}
    </div>
  );
};
export default FeedbackModal;
