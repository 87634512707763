/**
 * @description Function to create the Platform icon as a JSX component
 * @returns {JSX} PlatformIcon
 */
const PlatformIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.12"
      viewBox="0.5 0 15 15"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.142.002a3.311 3.311 0 0 0-.243 0c-.534.02-1.015.201-1.542.504C4.843.8 4.252 1.23 3.5 1.776l-.04.03c-.751.544-1.343.974-1.782 1.372-.45.408-.772.81-.955 1.311a3.31 3.31 0 0 0-.075.23C.502 5.235.526 5.748.65 6.343c.121.58.347 1.275.634 2.158l.015.048c.287.882.513 1.578.756 2.118.249.554.53.984.951 1.314.064.05.13.097.197.142.423.285.894.421 1.461.49.604.073 1.359.073 2.327.073h.057c.968 0 1.723 0 2.327-.073.567-.069 1.038-.205 1.461-.49.067-.045.133-.092.197-.142.42-.33.702-.76.951-1.314.243-.54.469-1.236.756-2.118l.015-.048c.287-.883.513-1.578.634-2.158.125-.595.149-1.108.002-1.622a3.282 3.282 0 0 0-.075-.231c-.183-.502-.505-.903-.955-1.311-.439-.398-1.03-.828-1.781-1.373l-.04-.03C9.79 1.23 9.197.8 8.683.505 8.157.204 7.676.023 7.142.003zM6.93.865c.06-.002.12-.002.18 0 .342.013.684.126 1.143.39.47.269 1.023.67 1.799 1.234.775.564 1.329.966 1.73 1.329.392.356.605.646.723.968.02.056.04.113.056.17.094.33.091.69-.017 1.21-.111.528-.322 1.18-.618 2.09-.296.912-.508 1.563-.73 2.056-.217.484-.426.776-.696.988a1.904 1.904 0 0 1-.745.367v-1.55a2.735 2.735 0 1 0-5.47 0v1.55a1.904 1.904 0 0 1-.746-.367c-.27-.212-.48-.504-.696-.988-.221-.493-.434-1.144-.73-2.055-.296-.912-.507-1.563-.618-2.092-.108-.519-.11-.878-.017-1.208.017-.058.035-.115.056-.171.118-.322.331-.612.724-.968.4-.363.954-.765 1.73-1.329.775-.563 1.329-.965 1.798-1.234.46-.264.8-.377 1.144-.39zm.09 7.381c1.033 0 1.871.838 1.871 1.872v1.671c-.486.032-1.086.032-1.871.032-.786 0-1.386 0-1.872-.032v-1.671c0-1.034.838-1.872 1.872-1.872z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PlatformIcon;
