import React, { useState } from 'react';
import { useAuthContext } from '../../../context/AuthContext';
import { useSchedulerContext } from '../../../context/SchedulerContext';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../SchedulerLayout/scheduler.scss';
import TaskTimer from './TaskTimer';
import PropTypes from 'prop-types';
import { deleteTimesheet } from '../services/deleteTimesheet';
import TagsInput from "../../../utils/TagsInput";
import Swal from 'sweetalert2';

// language swichter feature
import { FormattedMessage, useIntl } from "react-intl";
import { finalizeTicklerTask } from '../services/userTicklerServices';

/**
 * @description this component shows the form and saves the new timesheet
 * @module
 * @param {Function} changeTickler
 * @param {Object} changed
 * @param {Function} handleStopTimer
 * @param {Object} time

 * @returns {JSX} MyTask
 */

const MyTask = ({ changeTickler, changed, handleStopTimer, time, projects, currentTask, setLocalStorageChanged, setShowDropdown }) => {
  const auth = useAuthContext();
  const schedulerContext = useSchedulerContext();
  const moment = require('moment');
  const [tags, setTags] = useState([]);
  const intl = useIntl();

  const projectsOptions = projects.map((project, key) => (
    <option value={project.id} key={project.id}>
      {project.name}
    </option>
  ));

  const removeItem = async () => {
    handleStopTimer();
    changeTickler();
    localStorage.removeItem('tickler');
    setShowDropdown(false);
  };

  const cancelTask = async () => {
    try {
      const result = await deleteTimesheet(
        auth.user.id,
        auth.user.token,
        currentTask.id
      );
      if (result) {
        schedulerContext.setTaskCounter(schedulerContext.taskCounter + 1);
        Swal.fire({
          toast: true,
          icon: "warning",
          title: intl.formatMessage({
            id: "tickler.task.cancel",
            defaultMessage: "Task cancelled"
          }),
          timer: 2000,
          position: "top",
          showConfirmButton: false
        })
        removeItem();
      }
    }
    catch (error) {
      Swal.fire({
        toast: true,
        icon: "error",
        title: `${intl.formatMessage({
          id: "tickler.task.error",
          defaultMessage: "Something happened: "
        })}${error?.data.message}`,
        timer: 2000,
        position: "top",
        showConfirmButton: false
      })
      removeItem();
    };
  };

  const finishTask = async (values) => {
    const [hours, minutes] = currentTask.startHour.split(':');
    const finishTime = moment().format('HH:mm:ss');

    const totalTime = moment(moment(finishTime, "HH:mm A"))
    .subtract(hours, "hours")
    .subtract(minutes, "minutes")
    .format("hh:mm A")

    let time = null;
    time = moment.utc(totalTime, "HH:mm:ss: A").diff(
      moment.utc().startOf("day"),
      "seconds"
    );

    const totalHours = parseInt(finishTime.slice(0, 2)) -
      parseInt(currentTask.startHour.slice(0, 2))

    const timesheetData = {
      id: currentTask.id,
      endHour: finishTime,
      dedicatedTime: time,
      dedicatedHours: totalHours,
      isFinished: true,
    };

    // Adding optional properties.
    if (values.note) timesheetData.note = values.note;
    if (tags.length) timesheetData.tags = tags.join(",");

    try {
      const response = await finalizeTicklerTask(
        timesheetData,
        auth.user.id,
        auth.user.token
      );
      if (response) {
        changed(schedulerContext.taskCounter + 1);
        Swal.fire({
          toast: true,
          icon: "success",
          title: intl.formatMessage({
            id: "tickler.task.end",
            defaultMessage: "Task finalized"
          }),
          timer: 2000,
          position: "top",
          showConfirmButton: false
        });
        removeItem();
        setLocalStorageChanged(false);
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        toast: true,
        icon: "error",
        title: `${intl.formatMessage({
          id: "tickler.task.error",
          defaultMessage: "Something happened: "
        })}${error?.data.message}`,
        timer: 2000,
        position: "top",
        showConfirmButton: false
      })
    };
  };

  return (
    <>
      <Formik
        initialValues={{ note: '', tags: '' }}
        onSubmit={(values, actions)=>{
          finishTask(values, actions);
        }}
      >
        {({ touched, errors, isSubmitting }) => (
          <Form>
            <Row>
              <TaskTimer time={time} />
            </Row>

            <Row>
              <Col md={12}>
                <Field
                  type="text"
                  name="task"
                  value={currentTask?.task}
                  className={`form-control ${errors.task ? 'is-invalid' : ''}`}
                />

                <ErrorMessage
                  component="div"
                  name="task"
                  className="invalid-feedback"
                />
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <Field
                  name="project"
                  as="select"
                  className={`form-control ${
                    errors.project ? 'is-invalid' : ''
                  }`}
                >
                  <option defaultValue={currentTask?.projectId}>{currentTask?.projectName}</option>
                  {projectsOptions}
                </Field>

                <ErrorMessage
                  component="div"
                  name="project"
                  className="invalid-feedback"
                />
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <Field
                  as="textarea"
                  name="note"
                  id="note-field"
                  placeholder={intl.formatMessage({
                    id: "modal.time.log.notes.placeholder",
                    defaultMessage: "Additional notes",
                  })}
                  className="textArea"
                />
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                  <TagsInput
                    allTags={tags}
                    setAllTags={setTags}
                  />
              </Col>

              <ErrorMessage
                component="div"
                name="task"
                className="invalid-feedback"
              />
            </Row>

            <Button id="finishTask" variant="primary" type="submit">
              {/*<FormattedMessage id="button.finish" 
              defaultMessage="Finish"/>*/}
              Finish
            </Button>

            <Button variant="danger" type="button" onClick={cancelTask}>
              {/*<FormattedMessage id="button.delete" 
              defaultMessage="Delete"/>*/}
              Delete
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

MyTask.propTypes = {
  task: PropTypes.string,
  note: PropTypes.string,
  tags: PropTypes.string,
  project: PropTypes.string,
};

export default MyTask;
