import axios from "axios";
import { environment } from "../../../config/environment";

const postUserVacations = (id, token, body) => {
  return axios
    .post(
      `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/users/${id}/vacations`,
      body,
      {
        headers: {
          authorization: token,
        },
      }
    )
    .then((result) =>  {
      return result.data
    })
    .catch((err)=>{
      throw err.response || err.toJSON() || err;
    });
};

export default postUserVacations;