import React, { useEffect, useState } from 'react';
import { hoursConverter } from '../services/hoursConverter';
import EditTask from './EditTask';
import './MyTicklerStyles.scss';

// language switcher feature
import { FormattedMessage } from 'react-intl';

const moment = require('moment');

/**
 * @module
 * @description this component shows a card of the user's worked hours
 * @param {String} description
 * @returns {JSX} WorkCard
 */

const WorkCard = ({ description, date, refreshTask, isTopTickler }) => {
  const [startHour, setStartHour] = useState();
  const [endHour, setEndHour] = useState();
  
  // Convert from seconds to hours and minutes
  const time = description.dedicatedTime / 3600;
  let dedicatedMinutes,
      dedicatedHours;
  if (time < 1) {
    dedicatedHours = 0;
    dedicatedMinutes = time * 60
  } else {
    dedicatedHours = Math.trunc(time);
    dedicatedMinutes = (time - dedicatedHours) * 60;
    dedicatedMinutes = Math.trunc(dedicatedMinutes);
  }

  useEffect(() => {
    let startHourDescription = description.startHour
      ? moment.utc(
          description.startHour,
          'HH:mm A'
        ).format('hh:mm A')
      : '';
    let endHourDescription = description.endHour
      ? moment.utc(
          description.endHour,
          'HH:mm A'
        ).format('hh:mm A')
      : '';
    const amPm = startHourDescription.split(' ')[1];
    let modifiedHour;
    if (amPm === 'AM' && startHourDescription.split(':')[0] === '12') {
      modifiedHour = '00';
      startHourDescription =
        startHourDescription !== ''
          ? modifiedHour + ':' + startHourDescription.split(':')[1]
          : '';
    }
    if (amPm === 'AM' && endHourDescription.split(':')[0] === '12') {
      modifiedHour = '00';
      endHourDescription =
        endHourDescription !== ''
          ? modifiedHour + ':' + endHourDescription.split(':')[1]
          : '';
    }
    if (amPm !== 'AM' && endHourDescription.split(':')[0] !== '12') {
      modifiedHour = endHourDescription.split(':')[0];
      endHourDescription =
        startHourDescription !== ''
          ? modifiedHour + ':' + endHourDescription.split(':')[1]
          : '';
    }
    if (amPm !== 'AM' && startHourDescription.split(':')[0] !== '12') {
      modifiedHour = startHourDescription.split(':')[0];
      startHourDescription =
        startHourDescription !== ''
          ? modifiedHour + ':' + startHourDescription.split(':')[1]
          : '';
    }

    setStartHour(startHourDescription);
    setEndHour(endHourDescription);
  });
  return (
    <div className="work-card">
      <div className="work-card-task">
        <div className="left">{description.task + ' '}</div>
        {!isTopTickler ? (
          <div className="edit-task-container">
            <EditTask
              description={description}
              date={date}
              refreshTask={refreshTask}
            />
          </div>
        ) : null}
      </div>
      <div className="work-card-description">
        <div className="left">
          <strong>{description.projectName}</strong>
        </div>
        <div className="rightdescription">
          <div>
            {startHour} → {endHour} | {/*<FormattedMessage id="txt.total.hours" defaultMessage="Total"/>*/}{'Total '}
            {dedicatedHours + "h:" + dedicatedMinutes + "m"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkCard;
