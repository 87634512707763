import axios from "axios";
import { environment } from "../../../config/environment";

const getUserVacations = (id, token) => {
  return axios
    .get(
      `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/users/${id}/vacations`,
      {
        headers: {
          authorization: token,
        },
      }
    )
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      throw err.response || err.toJSON() || err;
    });
};

export default getUserVacations;