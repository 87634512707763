import React, { useMemo, useState, useContext, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import "./Dropzone.scss";
import { AddSquareIcon, CloseIcon } from "../Icons";
import { getFileIcon } from "../../utils/getFileIcon";
import "./ProjectFiles.scss";
import { ActionButton } from "../shared";
import { Spinner } from "react-bootstrap";
import ProjectsContext from "../../context/ProjectsContext/ProjectsContext";
import { File } from "./File";
import { useAuthContext } from "../../context/AuthContext";
import { FormattedMessage, useIntl } from "react-intl";

/**
 * @description active Style
 */
const activeStyle = {
  borderColor: "#2196f3",
};

/**
 * @description acceptStyle
 */
const acceptStyle = {
  borderColor: "#00e676",
};

/**
 * @description rejectStyle
 */
const rejectStyle = {
  borderColor: "#ff1744",
};

/**
 * @module
 * @description This function creates the structure and the logic of Project Files
 * @param {number} id get player id
 * @returns {JSX} Project Files
 */

const ProjectFiles = ({ id, archivedProject }) => {
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileRejected, setFileRejected] = useState(false);
  const [files, setFiles] = useState([]);
  const [headersPost, setHeadersPost] = useState({});
  const [manyFiles, setManyFiles] = useState(false);

  const { getProjectFiles, postFile, auth } = useContext(ProjectsContext);
  const { user } = useAuthContext();
  const intl = useIntl();

  useEffect(() => {
    getFiles();
  }, []);

  const getFiles = () => {
    const regex = new RegExp(/^[0-9]*$/);
    const verify = regex.test(id);
    if (verify === true) {
      getProjectFiles(id)
        .then((res) => {
          if (res.status === 200) setFiles(res?.data?.data);
          else setFiles([]);
        })
        .catch((err) => {
          if (err.status === 404) setFiles([]);
          else {
          };
        });
    } else {
      console.error("Bad request");
    }
  };

  const onDropAccepted = (acceptedFile) => {
    acceptedFile[0].pfId = filesToUpload.length + 1;
    setHeadersPost({
      headers: {
        Authorization: user?.token,
        projectID: id,
        pfOwnerId: user.id,
        pfFileName: acceptedFile[0].name,
        pfFileLink: `dev/projectFiles/${acceptedFile[0].path}`,
        pfIsConfidential: false,
      },
    });
    setFilesToUpload([acceptedFile]);
  };

  /**
   * @description on Drop Rejected
   */
  const onDropRejected = (res) => {
    const error = res[0]?.errors[0]?.code;
    setFileRejected(true);
    setTimeout(() => {
      setFileRejected(false);
    }, 5000);
  };

  const {
    getRootProps,
    acceptedFiles,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    accept: [
      "application/vnd.ms-excel",
      "application/vnd.ms-excel",
      "application/vnd.ms-excel",
      "application/vnd.ms-excel.sheet.macroEnabled.12",
      "application/msexcel",
      "application/x-msexcel",
      "application/x-ms-excel",
      "application/x-excel",
      "application/x-dos_ms_excel",
      "application/xls",
      "application/x-xls",
      "application/x-msi",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/pdf",
      "application/vnd.ms-powerpoint",
      "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/msword",
      "application/vnd.ms-word.document.macroEnabled.12",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "image/jpeg",
      "image/jpg",
      "image/png",
    ],
    noClick: true,
    maxFiles: 1,
    maxSize: 37000000,
    onDropAccepted,
    onDropRejected,
  });

  /**
   * @description style
   */
  const style = useMemo(
    () => ({
      // ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  /**
   * @description on Remove File
   */
  const onRemoveFile = (fileId) => {
    const filtered = filesToUpload.filter((fil) => fil.pfId !== fileId);
    setFilesToUpload(filtered);
  };

  /**
   * @description on Remove All Files
   */
  const onRemoveAllFiles = () => {
    setFilesToUpload([]);
    setManyFiles(false);
  };

  /**
   * @description on Upload
   */
  const onUpload = () => {
    setLoading(true);
    if (filesToUpload.length !== 1) {
      setLoading(false);
      setManyFiles(true);
    } else {
      setManyFiles(false);
      let bodyFormData = new FormData();
      bodyFormData.append("file", filesToUpload[0][0]);
      setTimeout(() => {
        postFile(headersPost, bodyFormData)
          .then((res) => {
            setFilesToUpload([]);
            getFiles();
            setLoading(false);
          })
          .catch((err) => {
          });
      }, 4000);
    }
  };

  return (
    <div className="project-files-container">
      <div className="section-header">
        <h2>
          {/*<FormattedMessage id="project.by.id.files" defaultMessage="Files"/>*/}
          Files
          {" "}
          {!archivedProject ? 
          <>
          <span onClick={open} style={{ cursor: "pointer" }}>
            <AddSquareIcon />
          </span>{" "}
          <span>
            {/*<FormattedMessage id="project.by.id.files.drag.and.drop" defaultMessage="(Drag and drop available)"/>*/}
            {"(Drag and drop available)"}
            </span>
          </>:null}
        </h2>
      </div>

      {isDragReject && (
        <p className="file-rejected">
          The file types supported are: .xls - .pdf - .doc - .xlsx - .ppt - .png
          - .jpeg
        </p>
      )}

      {/* DROPZONE */}
      <div className={`projects-dropzone`} {...getRootProps({ style })}>
        {!filesToUpload.length && !isDragActive && (
          <div className="files-list">
            {files.map((file, idx) => (
              <div key={idx}>
                <File file={file} callback={getFiles} />
              </div>
            ))}
          </div>
        )}
        <div
          className="box"
          style={{
            minHeight: !filesToUpload.length && !files.length ? `1px` : "0",
          }}
        >
          <input {...getInputProps()} />
          {isDragActive && !filesToUpload.length && (
            <div className={`isDragActive ${isDragReject && "reject"}`}>
              <p>
                {isDragReject
                  ? "This allow just 1 file at a time"
                  : "Drop your file on the box"}
              </p>
            </div>
          )}
        </div>
        {!filesToUpload.length ? (
          ""
        ) : (
          <div>
            {filesToUpload[0].map((file, idx) => (
              <div className="to-upload-list" key={idx}>
                <div className="file-ready">
                  {getFileIcon(file.path)}
                  <p> {file.name} </p>
                </div>
                <span onClick={() => onRemoveFile(file.pfId)}>
                  <CloseIcon />
                </span>
              </div>
            ))}
            <div className="action-btn">
              <ActionButton onClick={onUpload} disabled={loading}>
                {loading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Upload"
                )}
              </ActionButton>
              <ActionButton onClick={onRemoveAllFiles} secondary>
                Cancel
              </ActionButton>
            </div>
          </div>
        )}
        <div
          className="many-files"
          style={{ display: manyFiles ? "block" : "none" }}
        >
          Please, add the files one at the time
        </div>
      </div>
    </div>
  );
};

export default ProjectFiles;
