import {Link, useNavigate} from 'react-router-dom';
import {useAuthContext} from "../../context";
import {useIntl} from "react-intl";
import {useEffect, useState} from "react";

/**
 * @module
 * @description This function create the structure and the logic of the Information of the project
 * @param {Function} project get data project
 * @returns {JSX} Info Project
 */

const InfoProject = ({
  project: { projectName, projectKey, clientName, projectId },
}) => {
  const imgURL =
    'https://pbs.twimg.com/profile_images/1395764701620015107/rioTM8Ld_400x400.jpg';
  const { user } = useAuthContext();
  const auth = useAuthContext();
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    let permissions = user.permissions.userPermissions;
    let redirect = true;
    let permissionsArray = [];
    for (const permissionElement of permissions) {
      if(permissionElement.locations && permissionElement.locations.name === auth.userInfo.location) {
        permissionsArray.push(permissionElement.permission.name);
      }
    }
    setPermissions([...permissionsArray]);
  },[])

  return (
    <>
      <img src={imgURL} className="img-project" alt="project" />
      <div className="name-project">
        {permissions.includes('view-projects')?
          <Link to={`/projects/${projectId}`} style={{ textDecoration: 'none' }}>
            <p className="title-project">{projectName}</p>
          </Link> :
        <div>
          <p className="title-project">{projectName}</p>
        </div>}
        <p className="description-project">
          <b>{`#${projectKey}`}</b>
          {` - ${clientName}`}
        </p>
      </div>
    </>
  );
};

export default InfoProject;
