import React, {useContext, useEffect, useState} from "react";
import Select from "react-select";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {useIntl} from "react-intl";
import ProjectsContext from "../../context/ProjectsContext/ProjectsContext";
import {useNavigate} from "react-router-dom";
import {useAuthContext} from "../../context";

export default function SearchEmployee({employees, employee, selectEmployee}){
  const [displayEmployee, setDisplayEmployee] = useState(employee);
  const intl = useIntl();
  const MySwal = withReactContent(Swal);
  const { inactivePlayer } = useContext(ProjectsContext);
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const auth = useAuthContext();
  const [permissions, setPermissions] = useState([])

  const handleChangeEmployee = (emp) =>{
    setDisplayEmployee(emp)
    selectEmployee(emp);
  }

  const removeFromCompany = async ()=>{
    try {
      MySwal.fire({
        title: "Are you sure you want to remove the user?",
        text: "Access to the entire system will be revoked",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: `${intl.formatMessage({ id: "button.confirm", defaultMessage: "Yes, I want" })}`,
        customClass: {
          title: "main-dialog",
          htmlContainer: "secondary-dialog",
          actions: "actions-container",
        },
        confirmButtonColor:"#E42B02",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await inactivePlayer(displayEmployee.id);
          Swal.fire(`The user has been disabled`, "", "success");
          navigate("/roles-and-permissions");
        }
      });
    } catch (error) {
      await Swal.fire("Something went wrong", "Try again later", "error");
      console.error(error);
    }
  }

  useEffect(()=>{
    let permissionsAr =  user.permissions.userPermissions;
    let permissionsArray = [];
    for (const permissionElement of permissionsAr) {
      if(permissionElement.locations && permissionElement.locations.name === auth.userInfo.location) {
        permissionsArray.push(permissionElement.permission.name);
      }
    }
    setPermissions([...permissionsArray])
  },[])

    return(
    <header>
      <div className="flex-header">
        <h1 className="title-container role-access-title">
          Role access control for
        </h1>
        <div className="employee-list" style={{'marginTop':'12px', 'width':'30%'}}>
          <Select
            id="select"
            value={displayEmployee}
            options={employees}
            getOptionLabel={(employees) => employees.firstName + " " + employees.lastName}
            getOptionValue={(employees) => employees.id}
            onChange={handleChangeEmployee}
            placeholder={"Search employee"}
            maxMenuHeight={190}
            width={253}
            styles={{
              control:(baseStyles, state)=>({
                ...baseStyles,
                marginTop:'15px',
                minWidth: '253px',
              }),
              menu:(baseStyles, )=>({
                ...baseStyles,
                minWidth: '253px'
              })
            }}
          />
        </div>
        <div className='btn-container'  style={{'marginTop':'25px', 'width':'180px', 'marginRight':'3%', 'marginLeft':'22%'}}>
          {displayEmployee && permissions.includes('remove-from-company')?
            <button className="btn-remove" onClick={removeFromCompany}>Remove from BTS</button> :
            <button className="btn-disabled" disabled onClick={removeFromCompany}>Remove from BTS</button>}
        </div>
      </div>
    </header>
  );
}