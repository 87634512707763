import React from "react";
import PropTypes from "prop-types";
/**
 *
 *@description This component returns the Icon for the Clock component.
 * @returns {JSX} ClockIcon
 */
function ClockIcon({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20,75"
      fill="#B0B0B0"
      viewBox="0 0 22 23"
    >
      <path
        fill={color}
        d="M11.75 6.75a.75.75 0 00-1.5 0h1.5zm1.902 3.53a.75.75 0 10-1.061-1.06l1.06 1.06zM1.532 2.414a.75.75 0 10.937 1.172L1.53 2.414zm3.437-.828A.75.75 0 104.03.414l.938 1.172zm14.563 2a.75.75 0 10.936-1.172l-.936 1.172zM17.968.414a.75.75 0 00-.936 1.172l.936-1.172zM10.25 6.75v5h1.5v-5h-1.5zm2.341 2.47l-2.035 2.035 1.06 1.06 2.036-2.035-1.061-1.06zm7.659 2.53A9.25 9.25 0 0111 21v1.5c5.937 0 10.75-4.813 10.75-10.75h-1.5zM11 21a9.25 9.25 0 01-9.25-9.25H.25C.25 17.687 5.063 22.5 11 22.5V21zm-9.25-9.25A9.25 9.25 0 0111 2.5V1C5.063 1 .25 5.813.25 11.75h1.5zM11 2.5a9.25 9.25 0 019.25 9.25h1.5C21.75 5.813 16.937 1 11 1v1.5zM2.469 3.586l2.5-2L4.03.414l-2.5 2 .938 1.172zm18-1.172l-2.5-2-.938 1.172 2.5 2 .938-1.172zm-9.914 8.841a.7.7 0 011.195.495h-1.5a.8.8 0 001.366.566l-1.06-1.06z"
      ></path>
    </svg>
  );
}

ClockIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default ClockIcon;
