import { environment } from "../../config/environment";

const getPermissions = {

  permissionsByLocation: async (token, userId, locationName, userRole) => {

    try {

      var myHeaders = new Headers();
      myHeaders.append("Authorization", token);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "userId": userId,
        "locationName": locationName,
        "userRole": userRole
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      const response = fetch(`${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/users/permissions`, requestOptions)
        .then(response => response.json())
        .catch(error => console.log('error', error));

      if (response.status === 401) {

        throw Object.assign(new Error(), { code: 401, message: "Unauthorized" });

      };

      const data = await response;

      return data.data;

    }
    catch (error) {

      if (!error.code) error.code = 404

      if (!error.message) error.message = "Could not retrieve user information after logging in";

      throw error;

    };
  },

  permissionCategories: async (token) => {

    try {

      var myHeaders = new Headers();
      myHeaders.append("Authorization", token);

      var raw = "";

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      const response = fetch(`${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/permissions/categories`, requestOptions)
        .then(response => response.json())
        .catch(error => console.log('error', error));

      if (response.status === 401) {

        throw Object.assign(new Error(), { code: 401, message: "Unauthorized" });

      };

      const data = await response;

      return data;

    }
    catch (error) {

      if (!error.code) error.code = 404

      if (!error.message) error.message = "Could not retrieve user information after logging in";

      throw error;

    };
  },

  getAllPermissions: async (token) => {

    try {

      var myHeaders = new Headers();
      myHeaders.append("Authorization", token);

      var raw = "";

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      const response = fetch(`${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/permissions/permissions`, requestOptions)
        .then(response => response.json())
        .catch(error => console.log('error', error));

      if (response.status === 401) {

        throw Object.assign(new Error(), { code: 401, message: "Unauthorized" });

      };

      const data = await response;

      return data;

    }
    catch (error) {

      if (!error.code) error.code = 404

      if (!error.message) error.message = "Could not retrieve user information after logging in";

      throw error;

    };
  },
  getPermissionsByRegion: async (token, userId, regionName, roleName) => {

    try {

      var myHeaders = new Headers();
      myHeaders.append("Authorization", token);

      var raw = "";

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      const response = await fetch(`${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/permissionsRegion/permissionsByRegion/`+userId+'/'+regionName+'/'+roleName, requestOptions)
        .then(response => response.json())
        .catch(error => console.log('error', error));

      if (response.status === 401) {

        throw Object.assign(new Error(), { code: 401, message: "Unauthorized" });

      };

      const data = await response;
      return data.data;

    }
    catch (error) {

      if (!error.code) error.code = 404

      if (!error.message) error.message = "Could not retrieve user information after logging in";

      throw error;

    };
  },

  getPermissionsByDefault: async (token) => {

    try {

      var myHeaders = new Headers();
      myHeaders.append("Authorization", token);

      var raw = "";

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      const response = fetch(`${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/permissions/permissionsByDefault`, requestOptions)
        .then(response => response.json())
        .catch(error => console.log('error', error));

      if (response.status === 401) {

        throw Object.assign(new Error(), { code: 401, message: "Unauthorized" });

      };

      const data = await response;
      return data.data;

    }
    catch (error) {

      if (!error.code) error.code = 404

      if (!error.message) error.message = "Could not retrieve user information after logging in";

      throw error;

    };
  },
  getDefaultPermissionsByUserRole: async (token) => {

    try {

      var myHeaders = new Headers();
      myHeaders.append("Authorization", token);

      var raw = "";

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      const response = fetch(`${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/permissions/permissionsByDefault`, requestOptions)
        .then(response => response.json())
        .catch(error => console.log('error', error));

      if (response.status === 401) {

        throw Object.assign(new Error(), { code: 401, message: "Unauthorized" });

      };

      const data = await response;
      return data.data;

    }
    catch (error) {

      if (!error.code) error.code = 404

      if (!error.message) error.message = "Could not retrieve user information after logging in";

      throw error;

    };
  },

  getDefaultPermissionsByRoleName: async (token, role) => {

    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", token);
      //myHeaders.append('role', role)
      var raw = "";

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      const response = await fetch(`${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/permissions/defaultPermissionsByName?role=${role}`, requestOptions)
        .then(response => response.json())
        .catch(error => console.log('error', error));

      if (response.status === 401) {

        throw Object.assign(new Error(), { code: 401, message: "Unauthorized" });

      };

      const data = await response;
      return data.data;

    }
    catch (error) {

      if (!error.code) error.code = 404

      if (!error.message) error.message = "Could not retrieve user information after logging in";

      throw error;

    };
  },
  getUserRoleLocationPermissions: async (token, userId, roleId, locationName) => {

    try {
      var myHeadersLocation = new Headers();
      myHeadersLocation.append("Authorization", token);
      myHeadersLocation.append("locationName", locationName);

      var requestOptionsLocation = {
        method: 'GET',
        headers: myHeadersLocation,
        redirect: 'follow'
      };
      const location = await fetch(`${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module0/company/locations/locationByName`, requestOptionsLocation)
        .then(response =>response.json())
        .catch(error => console.log('error', error));

      var myHeaders = new Headers();
      myHeaders.append("Authorization", token);
      myHeaders.append("userId", userId);
      myHeaders.append("roleId", roleId);
      myHeaders.append("locationId", location.data.id);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      const response = await fetch(`${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/permissions/userRoleLocationPermissions`, requestOptions)
        .then(response => response.json())
        .catch(error => console.log('error', error));

      if (response.status === 401) {

        throw Object.assign(new Error(), { code: 401, message: "Unauthorized" });

      };

      const data = await response.data;
      return data;

    }
    catch (error) {

      if (!error.code) error.code = 404

      if (!error.message) error.message = "Could not retrieve user information after logging in";

      throw error;

    };
  },
    getUserRoleLocationRegionPermissions: async (token, userId, roleId, regionName) => {

    try {
      var myHeadersLocation = new Headers();
      myHeadersLocation.append("Authorization", token);
      myHeadersLocation.append("regionName", regionName);

      var requestOptionsLocation = {
        method: 'GET',
        headers: myHeadersLocation,
        redirect: 'follow'
      };
      const region = await fetch(`${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module0/company/locations/regionByName`, requestOptionsLocation)
        .then(response =>response.json())
        .catch(error => console.log('error', error));


      var myHeaders = new Headers();
      myHeaders.append("Authorization", token);
      myHeaders.append("userId", userId);
      myHeaders.append("roleId", roleId);
      myHeaders.append("regionId", region.data.id);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      const response = await fetch(`${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/permissions/userRoleLocationRegionPermissions`, requestOptions)
        .then(response => response.json())
        .catch(error => console.log('error', error));

      if (response.status === 401) {

        throw Object.assign(new Error(), { code: 401, message: "Unauthorized" });

      };

      const data = await response.data;
      return data;

    }
    catch (error) {

      if (!error.code) error.code = 404

      if (!error.message) error.message = "Could not retrieve user information after logging in";

      throw error;

    };
  },
};

export { getPermissions };
