/**
 * @description Function to create Video icon as a JSX component
 * @returns {JSX} Video
 */
function VideoIcon() {
  return (
    <svg
      width="85"
      height="70"
      viewBox="0 0 85 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M84.0998 59.5V10.5C84.0998 5.10995 79.6898 0.699951 74.2998 0.699951H10.5998C5.2098 0.699951 0.799805 5.10995 0.799805 10.5V59.5C0.799805 64.89 5.2098 69.3 10.5998 69.3H74.2998C79.6898 69.3 84.0998 64.89 84.0998 59.5ZM30.1998 54.6V15.4L59.5998 35L30.1998 54.6Z"
        fill="#E92D12"
      />
    </svg>
  );
}

export default VideoIcon;
