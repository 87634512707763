import React, { useMemo, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useIntl } from "react-intl";
import { useAuthContext } from "../../context/AuthContext";
import { environment } from "../../config/environment";
import "./FilesDropzone.scss";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import FileIcon from "../../Icons/FileIcon";
import FilePreview from "./FilePreview";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "57px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#ABABAB",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  marginBottom: "10px",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function FilesDropzone({id, save, region}) {
  const navigate = useNavigate();
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [onSave, setOnSave] = useState(save);
  const { user } = useAuthContext();
  const auth = useAuthContext();
  const intl = useIntl();

  useEffect(() => {
    getFiles();
  }, []);

  useEffect(()=>{
    if(save !== undefined && save !== null){
      onUpload();
      navigate(`/news/region/${region}`, { replace: true });
    }
  }, [save]);

  const postFile = async (bodyFormData) => {
    const response = await axios
      .post(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module0/news/files`,
        bodyFormData,
        {
          headers: {
            Authorization: user?.token,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .catch((err) => {
        throw err.response || err.toJSON() || err;
      });
    return response;
  };

  const getNewsFiles = async (id) => {
    const newsId = id;
    //logic to get the id
    try {
      const response = await axios.get(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module0/news/${newsId}/files`,
        {
          //Add this path to the environment file
          headers: {
            //User token from the local storage
            Authorization: user?.token,
          },
        }
      );
      return response;
    } catch (err) {
      throw err;
    }
  };
  const getFiles = () => {
    getNewsFiles(id)
      .then((res) => {
        console.log(res)
        if (res.status === 200) setFiles(res?.data?.data);
        else setFiles([]);
      })
      .catch((err) => {
        if (err.status === 404) setFiles([]);
        else {
        }
      });
  };

  const {
    fileRejections,
    getRootProps,
    getInputProps,
    open,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    maxFiles: 0,
    accept: {
      "image/*": [".jpeg", ".jpg", ".png"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/pdf": [".pdf"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
    onDrop: (acceptedFiles) => {
      if (acceptedFiles) {
        if (filesToUpload.length > 0) {
          acceptedFiles[0].pfId = filesToUpload.length + 1;
          setFilesToUpload([...filesToUpload, acceptedFiles]);
        } else {
          acceptedFiles[0].pfId = filesToUpload.length + 1;
          filesToUpload.push(acceptedFiles);
          setFilesToUpload([acceptedFiles]);
        }
      } else {
        setFilesToUpload([]);
      }
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const acceptedFileItems = filesToUpload.map((file) => {
    return file.map((doc) => {
      return (
        <li key={doc.path}>
          {doc.name} - {doc.size} bytes
        </li>
      );
    });
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <li key={file.path}>
        {file.path} - {file.size} bytes
        <ul>
          {errors.map((e) => {
            return <li key={e.code}>{e.code}</li>;
          })}
        </ul>
      </li>
    );
  });

  const onUpload = () => {
    setLoading(true);
    if (filesToUpload.length !== 1) {
      setLoading(false);
      for (let i = 0; i < filesToUpload.length; i++) {
        let bodyFormData = new FormData();
        bodyFormData.append("file", filesToUpload[i][0]);
        bodyFormData.append("pfNewsId", id);
        bodyFormData.append("pfOwnerId", user.id);
        bodyFormData.append("pfFileName", filesToUpload[i][0].name);
        bodyFormData.append(
          "pfFileLink",
          `dev/newsFiles/${filesToUpload[i][0].path}`
        );
        bodyFormData.append("pfIsConfidential", false);
        postFile(bodyFormData)
          .then((res) => {
            setFilesToUpload([]);
            getFiles();
            setLoading(false);
          })
          .catch((err) => {console.log(err)});
      }
    } else if (filesToUpload.length === 1) {
      let bodyFormData = new FormData();
      bodyFormData.append("file", filesToUpload[0][0]);
      bodyFormData.append("pfNewsId", id);
      bodyFormData.append("pfOwnerId", user.id);
      bodyFormData.append("pfFileName", filesToUpload[0][0].name);
      bodyFormData.append(
        "pfFileLink",
        `dev/newsFiles/${filesToUpload[0][0].path}`
      );
      bodyFormData.append("pfIsConfidential", false);
      postFile(bodyFormData)
        .then((res) => {
          setFilesToUpload([]);
          getFiles();
          setLoading(false);
        })
        .catch((err) => {console.log(err)});
    }
  };

  const openDialog = (e) => {
    e.preventDefault();
    open();
  };

  return (
    <>
      {files.length > 0 && (
        <div className="card">
          <ul className="list-group list-group-flush">
            {files.map((file, index) => (
              <li className="list-group-item" key={index}>
                <FilePreview file = {file}></FilePreview>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="container">
        <h6 id="dropzoneTitle">Attach Document(s)</h6>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop files here</p>
          <p align="center">or</p>
          <a href="/" onClick={openDialog}>
            click to select files
          </a>
        </div>
        <small>
          Accept file Types: .doc, .pdf, .xlsx, .jpeg, .jpg and .png
        </small>
        {window.location.pathname === '/news/addNews/global' || window.location.pathname === '/news/addNews/local' ? null :  <button id="uploadFiles" onClick={onUpload}>
          Save
        </button>}
        <aside>
          <h4>Accepted Files</h4>
          <ul>{acceptedFileItems}</ul>
          <h4>Rejected Files</h4>
          <ul>{fileRejectionItems}</ul>
        </aside>
      </div>
    </>
  );
}

export default FilesDropzone;
