import "./Dropdown.scss";
import React, { useRef } from "react";
import { useOutsideAlerter } from "../../hooks";
import PropTypes from "prop-types";
/**
 * @description This functions creates a container called dropwdown with the purpose of store different items inside of it , and keep the same design across all of them (of the container OFC)
 * @param {Function} hideDropdown
 * @param {Boolean} showDropdown
 * @param {Object} children
 * @returns {JSX} Dropdown
 */
const Dropdown = ({ hideDropdown, showDropdown, children, id }) => {
  const floatMenu = useRef(null);

  // Hook used to detect if user clicks outside of the menu, if true, the menu is closed
  useOutsideAlerter(floatMenu, hideDropdown, showDropdown);

  return (
    <>
    <div id={id} className="dropdown-container-m0" ref={floatMenu}>
      <ul>{children}</ul>
    </div>
  </>
  );
};

/**
 * @description This function allows to all the items inside the dropdown to behave as a list.
 * @param {Object} children
 * @returns {JSX} Item
 */
const Item = ({ children }) => {
  return <li>{children}</li>;
};

Dropdown.propTypes = {
  id: PropTypes.element,
  childen: PropTypes.element,
  hideDropdown: PropTypes.func.isRequired,
  showDropdown: PropTypes.bool.isRequired,
};

Item.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]),
};

Dropdown.Item = Item;
export default Dropdown;
