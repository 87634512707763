function Message() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="currentColor"
      clipRule="evenodd"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      viewBox="0 0 1004.09 958.8"
    >
      <path
        fill="currentColor"
        d="M652.58 418.91c0 25.85 20.96 46.81 46.81 46.81s46.81-20.96 46.81-46.81-20.96-46.83-46.81-46.83-46.81 20.98-46.81 46.83zM465.31 418.91c0 25.85 20.96 46.81 46.81 46.81 25.87 0 46.82-20.96 46.82-46.81s-20.96-46.83-46.82-46.83c-25.85 0-46.81 20.98-46.81 46.83zM278.06 418.91c0 25.85 20.96 46.81 46.81 46.81s46.81-20.96 46.81-46.81-20.96-46.83-46.81-46.83-46.81 20.98-46.81 46.83z"
        className="fil0"
      ></path>

      <path
        d="M252.42 3.29C292.78 0 343.02 0 407.07 0h189.96c64.05 0 114.28 0 154.65 3.29 41.1 3.36 75.05 10.32 105.79 25.98a268.494 268.494 0 01117.35 117.35c15.66 30.74 22.62 64.69 25.98 105.79 3.29 40.36 3.29 90.6 3.29 154.65v199.05c0 116.63-94.55 211.17-211.17 211.17h-28.1c-11.59 0-19.54 11.71-15.23 22.5 32.07 80.16-60.26 152.65-130.51 102.47l-121.94-87.1-2.29-1.64a198.543 198.543 0 00-113.06-36.23H346.5c-72.33 0-116.45.02-153.51-10.85-87.77-25.74-156.39-94.37-182.12-182.14C0 587.23 0 543.13.02 470.8v-63.74c0-64.05 0-114.28 3.29-154.65 3.36-41.1 10.32-75.05 25.98-105.79A268.442 268.442 0 01146.64 29.27c30.74-15.66 64.69-22.62 105.79-25.98zm573.25 88.39c-19.22-9.8-42.94-15.56-79.71-18.56-37.17-3.05-84.54-3.07-150.51-3.07H408.63c-65.96 0-113.33.02-150.5 3.07-36.78 3-60.5 8.76-79.71 18.56a198.465 198.465 0 00-86.73 86.73c-9.78 19.22-15.56 42.93-18.56 79.71-3.03 37.17-3.07 84.54-3.07 150.5v55.3c0 81.35.36 114.52 8.02 140.66 19.01 64.86 69.74 115.59 134.62 134.62 26.13 7.66 59.29 8.02 140.64 8.02h28.89a268.52 268.52 0 01152.97 49.03l2.65 1.88 121.92 87.1c13.33 9.52 30.84-4.22 24.77-19.44-22.72-56.78 19.1-118.56 80.26-118.56h28.1c77.93 0 141.12-63.19 141.12-141.12V408.63c0-65.96-.03-113.33-3.05-150.5-3.02-36.78-8.8-60.5-18.58-79.71a198.465 198.465 0 00-86.73-86.73z"
        className="fil0"
      ></path>
    </svg>
  );
}

export default Message;
