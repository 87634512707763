import React from "react";
import { environment } from "../../config/environment";
import { GoogleLogin } from "react-google-login";
import GoogleButton from 'react-google-button'
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext/";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FormattedMessage } from "react-intl";

function GoogleLogInButton() {
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuthContext();

  const responseGoogleOK = async (response) => {

    // Logging in throughout context.
    await auth.googleLogin( response.tokenId );

    // Redirecting.
    const from = location.state?.from?.pathname || "/";
    navigate(from, { replace: true });
  
  };

  const responseGoogleFailure = async (response) => {

    // Sending an error message.
    MySwal.fire({
      title: "Couldn't log in",
      text: "Something happened, please try again",
      icon: "error",
      timer: 3000
    });

  };

  return (
    <GoogleLogin
      clientId= {environment.CLIENT_ID}
      hostedDomain= {"bluetrailsoft.com"}
      buttonText= {""}
      onSuccess={responseGoogleOK}
      onFailure={responseGoogleFailure}
      cookiePolicy={"single_host_origin"}
      render={renderProps => (
        <GoogleButton type="light" onClick={renderProps.onClick} disabled={renderProps.disabled}>{/*<FormattedMessage id="login.google.sign.button" defaultMessage=" Sign in with Google "/>*/}Sign in with Google</GoogleButton>
      )}
    />
  );
};

export default GoogleLogInButton;
