import jwt_decode from "jwt-decode";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useAuthContext } from ".";
import { useState } from "react";
import Swal from "sweetalert2";

export function SessionValidator({ children }) {
    const intl = useIntl();
    const auth = useAuthContext();

    const [expirationMessages, setExpirationMessages] = useState({
        hour: false,
        halfAnHour: false,
        tenMinutes: false,
        minute: false,
    });

    const [recentlyOpened, setRecentlyOpened] = useState(true);

    const parseSecondsForExpirationMessages = (seconds) => {
        if (seconds <= 0) return "expired";
        else if (seconds > 0 && seconds <= 60) return "minute";
        else if (seconds > 60 && seconds <= 60 * 10) return "tenMinutes";
        else if (seconds > 60 * 10 && seconds <= 60 * 30) return "halfAnHour";
        else if (seconds > 60 * 30 && seconds <= 60 * 60) return "hour";
    };

    const tokenExpirationMessages = (period, intl) => {
        switch (period) {
            case "hour":
                return {
                    icon: "info",
                    title: intl.formatMessage({
                        id: "hour.token",
                        defaultMessage: "Your session will expire in an hour",
                    }),
                };
            case "halfAnHour":
                return {
                    icon: "info",
                    title: intl.formatMessage({
                        id: "half.an.hour.token",
                        defaultMessage:
                            "Your session will expire in half an hour",
                    }),
                };
            case "tenMinutes":
                return {
                    icon: "warning",
                    title: intl.formatMessage({
                        id: "ten.minutes.token",
                        defaultMessage:
                            "Your session will expire in ten minutes",
                    }),
                };
            case "minute":
                return {
                    icon: "warning",
                    title: intl.formatMessage({
                        id: "minute.token",
                        defaultMessage: "Your session will expire in a minute",
                    }),
                };
            case "expired":
                return {
                    icon: "error",
                    title: intl.formatMessage({
                        id: "invalid.token",
                        defaultMessage: "Your session has expired",
                    }),
                    text: intl.formatMessage({
                        id: "invalid.token.footer",
                        defaultMessage: "Please, log in perro",
                    }),
                };
        }
    };

    const checkForTokenExpiration = (
        token,
        logout,
        intl,
        Swal,
        expirationMessages,
        setExpirationMessages
    ) => {
        const decodedToken = jwt_decode(token);

        let secondUntilExpires = 0;
        const expirationDate = new Date(0);
        expirationDate.setUTCSeconds(decodedToken.exp);
        secondUntilExpires =
            (expirationDate.valueOf() - new Date().valueOf()) / 1000;

        const expirationPeriod =
            parseSecondsForExpirationMessages(secondUntilExpires);

        if (
            expirationPeriod !== parseSecondsForExpirationMessages(0) &&
            !expirationMessages[expirationPeriod] && expirationMessages[expirationPeriod] !== undefined
        ) {
            const newKey = {};
            newKey[expirationPeriod] = true;
            setExpirationMessages((expirationMessages) => ({
                ...expirationMessages,
                ...newKey,
            }));
            Swal.fire({
                ...tokenExpirationMessages(expirationPeriod, intl),
                position: "top-end",
                toast: true,
                timer: 3500,
                showConfirmButton: false,
            });
        } else if (expirationPeriod === parseSecondsForExpirationMessages(0)) {
            logout(false)
                .then(() => {
                    Swal.fire({
                        ...tokenExpirationMessages(expirationPeriod, intl),
                        position: "center",
                    });
                })
                .then(() => {
                    setExpirationMessages({
                        hour: false,
                        halfAnHour: false,
                        tenMinutes: false,
                        minute: false,
                    });
                });
        }
    };

    useEffect(() => {
        let checkingInterval;
        if (auth?.user?.token && !recentlyOpened)
            checkingInterval = setInterval(
                checkForTokenExpiration,
                1000 * 5,
                auth?.user?.token,
                auth?.logout,
                intl,
                Swal,
                expirationMessages,
                setExpirationMessages
            )
        else if (auth?.user?.token) {
            checkForTokenExpiration(
                auth?.user?.token,
                auth?.logout,
                intl,
                Swal,
                expirationMessages,
                setExpirationMessages);
                setRecentlyOpened(false)
        };
        return () => {
            clearInterval(checkingInterval);
        };
    }, [auth.user, expirationMessages, recentlyOpened]);

    return (
        <>
        { children }
        </>
    );
};
