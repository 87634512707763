import React, { useState, useEffect } from "react";
import scss from "./SingleNews.module.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import css from "../../components/Core/WhatIsGoingOn/WhatIsGoingOn.module.scss";
import { environment } from "../../config/environment";
import Menu from "../../components/Icons/ElipsisButtonIcon";
import Dropdown from "../../components/shared/Dropdown";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { deleteNew } from "../../components/Core/services/news";
import PropTypes from "prop-types";
import { getImage } from "../../components/Core/services/images";
import { useAuthContext } from "../../context/AuthContext";
import { useIntl } from "react-intl";

/**
 * @description This function creates a single component for each News on a given array
 * from the component "News"
 * @param {e} Object The object that contains all the News information
 * @param {String} title Title of the Notice
 * @param {String} resume brief description of the Notice
 * @param {String} img Link to the main portrait image of the notice
 * @param {String} authorPhoto Link to the image of the author of the notice
 * @param {String} date Creation date of the notice
 * @param {String} description Whole description of the notice
 * @param {String} author User that created the notice
 * @param {number} noticeId id of the notice
 * @param {String} region Region that the notice belongs to
 *
 * @returns {JSX} SingleNews
 */

function SingleNews({
  e,
  title,
  resume,
  authorPhoto,
  date,
  description,
  author,
  noticeId,
  region,
}) {
  // Checking for server-side photos or others.
  let notAWSPicture = authorPhoto;
  let userPhoto = `${environment.S3_ROOT}${environment.S3_BUCKET}/${environment.S3_FOLDER}/${authorPhoto}`;
  if (authorPhoto?.slice(0, 4) === "http") {
    userPhoto = notAWSPicture;
  }
  authorPhoto = userPhoto;
  const MySwal = withReactContent(Swal);
  const [showDropdown, setShowDropdown] = useState(false);
  const [outsideClick, setOutsideClick] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const auth = useAuthContext();
  const intl = useIntl();
  const { user } = useAuthContext();
  const [permissions, setPermissions] = useState([]);
  let navigate = useNavigate();
  const hideDropdown = () => {
    setShowDropdown(false);
    setOutsideClick(true);
  };
  let location = useLocation();

  useEffect(() => {
    const myTimer = setTimeout(() => {
      setOutsideClick(false);
    }, 100);
    return () => {
      clearTimeout(myTimer);
    };
  }, [outsideClick]);

  useEffect(()=> {getImageUrl()}, []);

  async function getImageUrl() {
    try {
      let photo = await getImage(noticeId, auth?.user?.token);
      photo = photo.replace('/assets/newsPhotos', '/newsFiles')
      photo = photo.split("?")[0]
      setImageUrl(photo);
    }
    catch (error) {
    }
  }
  /**
   * @description This function calls a service in order to delete a selected notice
   * and then reloads the page.
   * @param {number} noticeId id of the selected notice
   *
   */

  const deleteNotice = async (noticeId) => {
    try {
      const request = await deleteNew(noticeId, auth.user.token);
      navigate(location.pathname);
    } catch (error) {
      throw error;
    }
  };

  /**
   * @description this function opens a modal to confirm if the user really wants
   * to delete a selected notice and if thats the case the other function
   * "deleteNotice" is executed
   * @param {number} noticeId
   */

  const deleteConfirmation = async (noticeId) => {
    try {
      MySwal.fire({
        title: "Are you sure you want to delete this entry?",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: `${intl.formatMessage({ id: "button.confirm", defaultMessage: "Yes, I want" })}`,
        customClass: {
          title: "main-dialog",
          htmlContainer: "secondary-dialog",
          actions: "actions-container",
        },
        confirmButtonColor: "#E42B02",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteNotice(noticeId);
        }
      });
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    let permissionsAr = user.permissions.userPermissions;
    let permissionsArray = [];
    for (const permissionElement of permissionsAr) {
      if (permissionElement.locations && permissionElement.locations.name === auth.userInfo.location) {
        permissionsArray.push(permissionElement.permission.name);
      }
    }
    setPermissions([...permissionsArray])
  }, [])

  return (
    <div className={scss.container}>

      <div className={scss.img}>
        <img src={imageUrl} alt="" />
      </div>
      <div className={scss.titleAndResume}>
        <div className={scss.title}>{title}</div>
        <div className={scss.resume}>
          {resume}
          <Link
            state={{ ...e, type: region, isEdit: false }}
            className={css.articleReadMore}
            to={`/news/${noticeId}`}
          >
            Read More...
          </Link>
        </div>
      </div>

      <div className={scss.date}>{date}</div>
      <div className={scss.author}>
        <img className={scss.authorPhoto} src={authorPhoto} alt="Author" />
        {author}
      </div>
      {permissions.includes('update-news') || permissions.includes('delete-news') ?
        <div
          className={scss.moreIcon}
          onClick={!outsideClick ? () => setShowDropdown(!showDropdown) : null}
        >
          <Menu />
          {showDropdown ? (
            <div className={scss.drop}>
              <Dropdown hideDropdown={hideDropdown} showDropdown={showDropdown}>
                {permissions.includes('update-news') ?
                  <Dropdown.Item>

                    <Link
                      state={{ ...e, type: region, isEdit: true }}
                      className={css.articleReadMore}
                      to={`/news/${noticeId}`}
                    >
                      Edit
                    </Link>
                  </Dropdown.Item> : null
                }
                <hr />
                {permissions.includes('delete-news') ?

                  <Dropdown.Item>
                    <div onClick={() => deleteConfirmation(noticeId)}>Delete</div>
                  </Dropdown.Item> : null
                }
              </Dropdown>
            </div>
          ) : (
            <></>
          )}
        </div> : null}
      <div className={scss.moreIcon}></div>
    </div>
  );
}

SingleNews.propTypes = {
  e: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  resume: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  authorPhoto: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  noticeId: PropTypes.number.isRequired,
  region: PropTypes.string.isRequired,
};

export default SingleNews;
