import { useEffect, useState } from "react";
import "./RolesAndPermissions.scss";
import { useAuthContext } from "../../context/AuthContext";
import getEmployees from "../../services/RoleAccessControl/getEmployees";
import RoleAccessControl from "../../components/RolesAndPermissions/RoleAccessControl";

/**
 * @description Contain the function to send the data through the project list and make the main structure
 * @returns {JSX} ProjectById
 */

function RolesAndPermissions() {

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState("");
  const [userList, setUserList] = useState([]);
  const auth = useAuthContext();

  useEffect( async () => {

    let res = [];
    const allUser = await getEmployees(auth);

    for (const user of allUser) {
      if (!res.find((elem) => elem.id === user.id)) {
        if (user.id !== auth.user.id) res.push(user);
      }
    }
    setUserList(res);
    setLoading(false);

  }, []);

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border text-secondary" style={{'width': '5rem', 'height': '5rem', 'marginTop':'25%'}} role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div>
          <div>
            <div>
              <RoleAccessControl
                name={"user"}
                currentValue={user}
                userList={userList}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );

};

export default RolesAndPermissions;