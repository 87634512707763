import React from "react";

/**
 * @description this function creates the Calendar Icon as a component
 * @returns {JSX} CalendarWithDateIcon
 */

const CalendarWithDateIcon = (date) => (
  <div style={{ position: "relative" }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="43"
      fill="none"
      viewBox="0 0 38 43"
    >
      <rect
        width="35"
        height="37"
        x="1.5"
        y="4.5"
        stroke="#313131"
        strokeWidth="3"
        rx="10.5"
      ></rect>
      <rect width="3" height="9" x="12" fill="#313131" rx="1.5"></rect>
      <rect width="3" height="9" x="23" fill="#313131" rx="1.5"></rect>
    </svg>
    <span
      style={{
        position: "absolute",
        left: "1px",
        padding: "10%",
        fontSize: "13px",
        fontWeight: "bold",
        textAlign: "center",
        lineHeight: "1",
        top: "15%",
      }}
    >
      {date.date}
    </span>
  </div>
);

export default CalendarWithDateIcon;
