import axios from 'axios';
import moment from 'moment';
import { environment } from '../../../config/environment';

const eventColor = [
  '#8F9DCE', //Paid time
  '#8d2ca7', // Illness
  '#FFB5A7', // Maternity leave
  '#4B5D83', // Paternity leave
  '#b0b0b0', // Others
];

/**
 *
 * @param {Object[]} daysOffList
 * @returns {Object[]}
 */

const CreateArrayDaysOffEvents =  (daysOffList, intl) => {

  const currentLang = localStorage.getItem('lang');

  let daysOffEvents = [];
  if (daysOffList.length) {
    daysOffList.forEach((event) => {
      let endDate = moment.utc(event.toDate)
        .add(1, 'day');
      let date1 = moment.utc(event.fromDate);
      let date2 = moment.utc(event.toDate);
      let difDate = date2.diff(date1, 'days');
      daysOffEvents.push({
        id: event.id,
        title: event.name,
        start: date1.format('YYYY-MM-DD'),
        end: difDate < 1 ? null : moment.utc(endDate).format('YYYY-MM-DD'),
        borderColor: eventColor[event.daysOffTypeId - 1],
        textColor: eventColor[event.daysOffTypeId - 1],
        allDay: true,
        type: 'Day off',
        status: event.status,
        reason:
          event.status === 'accepted'
            ? `${intl.formatMessage({
              id: "tickler.enjoy.your",
              defaultMessage: "Enjoy your ",
            })} ${event.name} ${intl.formatMessage({
              id: "tickler.day.off",
              defaultMessage: " day off",
            })}}`
            : `Day off for ${event.name} pending approval`, // translation pending
        className:
          event.status === 'accepted' ? 'dayOff' : 'dayOff dashedBorder',
      });
    });
  }
  return daysOffEvents;
}

const getUserDaysOff =  {
  approved: async (startDate, endDate, userId, tokenUser, intl) => {
    try {
      const {
        data: { data: daysOff },
      } = await axios.get(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/historicDaysOff/${userId}?startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            authorization: tokenUser,
          },
        }
      );
      return CreateArrayDaysOffEvents(daysOff, intl);
    } catch (err) {
      throw (
        err.response || err.toJSON() || err
      );
    }
  },
  pending: async (startDate, endDate, userId, tokenUser, intl) => {
    try {
      const {
        data: { data: daysOff },
      } = await axios.get(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/historicDaysOff/pending/${userId}?startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            authorization: tokenUser,
          },
        }
      );
      return CreateArrayDaysOffEvents(daysOff, intl);
    } catch (err) {
      throw (
        err.response || err.toJSON() || err
      );
    }
  },
};

export default getUserDaysOff;
