import React, { useState, useEffect } from "react";

import Modal from "react-bootstrap/Modal";
import DataTable, { createTheme } from "react-data-table-component";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "../SchedulerLayout/scheduler.scss";
import "../PopOver/popOver.scss";
import "../Modal/VacationsModal.css";
import { InformationIcon } from "../../../Icons";
import { BsFillTrashFill } from "react-icons/bs";

// language switcher feature
import { FormattedMessage, useIntl } from "react-intl";
import {useAuthContext} from "../../../context/AuthContext";
import {ActionButton} from "../../shared";
import getUserVacations from "../services/getUserVacations";
import moment from "moment";
import AddVacationsModal from "./AddVacationsModal";
import {editVacations} from "../services/editVacations";
import getUserByName from "../services/getUserByName";
import {getLocationsAndRegions} from "../services/getLocationsAndRegions";
import getUserPermission from "../services/get.user.permission";

/**
 * @module
 * @description Modal container that displays the selected form
 * @param {Object} obj - An Object
 * @param {Object[]} obj.refreshTask - Function that increases the value of the timesheet counter by one
 * @param {Function} obj.count - Timesheet counter
 * @param {} obj.myOnClose
 * @param {string} obj.selectedDate
 * @param {} obj.buttonText
 * @returns {JSX} ModalContainer
 */
function VacationsModal({
  myOnClose,
  type,
  buttonText,
  modalDisplayed,
  popOn,
  context,
  name,
  selectedUser,
  availableDays,
  setAvailableDays,
  totalDays,
  setTotalDays,
  usedDays,
  setUsedDays
}) {
  const [show, setShow] = useState(false);
  const [entryType, setEntryType] = useState("1");
  const auth = useAuthContext();
  const [player, setPlayer] = useState(auth.userInfo.firstName + " " + auth.userInfo.lastName);
  const intl = useIntl();
  let initialValue=0;
  const { user } = useAuthContext();
  const [permissions,setPermissions] = useState([]);

  useEffect(async ()=>{
    let permissionsArray = [];
    let permission = user.permissions.userPermissions;
    if(context === 'Personal') {
      for (const permissionElement of permission) {
        if(permissionElement.locations && permissionElement.locations.name === auth.userInfo.location) {
          permissionsArray.push(permissionElement.permission.name);
        }
      }
      setPermissions([...permissionsArray]);
    }else {
      let locationFound =[];
      let regionFound;
      let permissionsByLocation = [];
      const selectedUser = await getUserByName(name, auth.user.token);
      if(selectedUser) {
        const locationsAndRegions = await getLocationsAndRegions(auth.user.token);
        locationsAndRegions.forEach((region) => {
          const tempLocationFound = region.locations.filter((location) => location.id === selectedUser.companyLocationId)
          if(tempLocationFound.length > 0) {
            locationFound = [...tempLocationFound];
            regionFound = region;
          }
        });
        for (const permissionElement of permission) {
          if(permissionElement.locations && permissionElement.locations.name === locationFound[0].name) {
            permissionsByLocation.push(permissionElement.permission.name);
          }
        }
        if(permissionsByLocation.length === 0) {
          for (const permissionElement of permission) {
            if(permissionElement.regions && permissionElement.regions && permissionElement.regions.name === regionFound.name) {
              permissionsByLocation.push(permissionElement.permission.name);
            }
          }
        }
        setPermissions([...permissionsByLocation]);
      }
    }
  },[show])

  const handleClose = () => {
    if (popOn !== undefined) {
      modalDisplayed();
      popOn(false);
    }
    setShow(false);
    if (myOnClose) myOnClose();
  };

  const handleShow = () => {
    if (popOn !== undefined) {
      modalDisplayed();
    }
    setShow(true);
    if (type) {
      setEntryType(type);
    } else {
      setEntryType("1");
    }
  };

  //1- Configurar los hooks
  const [records, setRecords] = useState([]);

  //2- Función para mostrar los datos
  const showData = async () => {
    let response = await getUserVacations(selectedUser, auth.user.token);

    let disableVacations = response.vacations.filter(item =>{
      let expiration = moment(item.expirationDate).format('DD-MM-YYYY').split('-')
      expiration = expiration[2]+''+expiration[1]+''+expiration[0];
      expiration = parseInt(expiration);

     let now = moment().format('DD-MM-YYYY').split('-');
      now = now[2]+''+now[1]+''+now[0];
      now = parseInt(now);
      return expiration < now;
    });

    if(disableVacations.length>0){
      disableVacations.forEach( async item => deleteDays(item))
    }else{
      setRecords(response.vacations);
    }
  };

  useEffect(()=>{
    setTotalDays(records.map(item => item.numberDaysOff).reduce((count, next)=> count+next,initialValue))
    setUsedDays(records.filter(item => !item.available).map(item => item.numberDaysOff).reduce((count, next)=> count+next,initialValue))
  }, [records])

  useEffect(()=>{
    if((totalDays-usedDays) < 0 && totalDays === 0) {
      setAvailableDays(0);
    } else if((totalDays-usedDays) < 0 && totalDays !== 0) {
      setAvailableDays(totalDays);
    }else {
      setAvailableDays(totalDays-usedDays);
    }
  });

  useEffect(async () => {
    showData();
  }, [show]);

  const usedRowStyles = [
    {
      when: row => !row.available,
      style:{right:'10%', background:'lightGrey'}
    }
    ]
  //3- Configuramos las columnas para DataTable
  const columns = [
    { name: "Granted On", selector: (row) => moment(row.grantedDate).format("DD/MM/YYYY"), allowOverflow: true },
    { name: "Expires On", selector: (row) => moment(row.expirationDate).format("DD/MM/YYYY"), allowOverflow: true },
    { name: "Type", selector: (row) => row.type },
    { name: "Notes", selector: (row) => row.notes },
    { name: "Days", selector: (row) => row.numberDaysOff },
    { name: '', selector: (row) =>  <BsFillTrashFill onClick={()=>deleteDays(row)}/>,style:{right:'10%'}, },
  ];

  const deleteDays = async (row) =>{
    const response = await editVacations(selectedUser, auth.user.token, row);
    if (response.status === 200){
      const response = await getUserVacations(selectedUser, auth.user.token);
      setRecords(response.vacations);
    }
  }

  useEffect(() => {
    if(context !== 'Personal') {
      setPlayer(name);
    }
    if(context === 'Personal') {
      setPlayer(auth.userInfo.firstName + " " + auth.userInfo.lastName);
    }
  }, [context]);

  return (
    <>
      <>
        <button className="btn-addTime" onClick={handleShow}>
          {buttonText}
        </button>
        <Modal
          show={show}
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="timesheetModal"
        >
          <Modal.Header closeButton className="modalHeader">
            <h1>Days off history</h1>
          </Modal.Header>
          <div>
            <div className="playerContainer">
              <h2><b>Player:</b> {player}</h2>
              <div className='btn-container'  style={{'width':'160px', 'marginRight':'3%'}}>
                {permissions.includes('add-days-off') ?
                  <AddVacationsModal
                    selectedUser={selectedUser}
                    showDataUpdates={showData}
                    buttonText={
                      <span
                        style={{
                          color: "#16448a",
                          textDecoration: "underline",
                        }}
                      >
                          {/*<FormattedMessage
                            id="scheduler.modal.vacation.footer"
                            defaultMessage="Add days off"
                          />*/}
                          Add days off
                        </span>
                    }
                  /> : null
                }
              </div>
            </div>
          </div>
          <hr />
          <div className="tableVacations">
            <DataTable
              columns={columns}
              data={records}
              dense
              border
              fixedHeader={true}
              conditionalRowStyles={usedRowStyles}
            />
          </div>
          <div>
            <div className="informationMessage">
              <InformationIcon />
              <p>
                If you think this information is wrong, please contact your
                local manager.
              </p>
            </div>
            <div className="numberDaysOff">
              <p>Total granted {totalDays}</p>
              <p>Used -{usedDays}</p>
              <hr id= "hr1"/>
              <p>Total available {availableDays}</p>
            </div>
          </div>

          <hr />

          <div className="botContainer">
            <button className="closeVacationsModal" onClick={handleClose}>
              Close
            </button>
          </div>
        </Modal>
      </>
    </>
  );
}

export default VacationsModal;
