import React, {useEffect, useState} from "react";


export default function CheckBoox({name, value, handleChangePermission, id}){
  const [checkValue, setCheckValue] = useState(value);

  useEffect(()=>{
    setCheckValue(value);
    },[value]);

  useEffect(()=>{
    const item = {
      name:name,
      value:checkValue
    }
    handleChangePermission(item);
  },[checkValue])

  return(
    <div className="custom-control custom-checkbox" style={{'marginBottom':'10px'}}>
      <input type="checkbox" className="custom-control-input" id={id} checked={checkValue} onChange={()=>setCheckValue(!checkValue)}/>
      <label className="custom-control-label" htmlFor={id} style={{'marginLeft':'5px','marginRight':'35px'}} >{name}</label>
    </div>
  );
}