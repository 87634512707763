import React from "react";
/**
 * @description Function to create the danger flag icon as a component
 * @returns {JSX} DangerFlagIcon
 */
function DangerFlagIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      version="1"
      viewBox="0 0 73 92"
      fill="#ff0000"
    >
      <path
        d="M80 873c-45-18-45-16-48-420L29 70h61v135c0 121 2 135 18 136 9 1 123 3 252 4 156 2 236 7 238 14 2 6 19 11 38 11 23 0 34 5 34 14 0 8 5 16 11 18 5 2 13 22 16 46 5 34 3 42-11 42-11 0-16 9-16 30 0 28-3 30-35 30h-35v60c0 53 2 60 20 60 11 0 20 7 20 15s7 15 15 15 15 7 15 15 7 15 15 15c12 0 15 10 13 41-1 23-8 43-15 46s-13 11-13 19-7 14-15 14-15 7-15 15c0 13-37 15-272 14-150 0-280-3-288-6zm560-98c0-38-3-45-20-45-11 0-20-7-20-15s-7-15-15-15-15-7-15-15-7-15-15-15c-12 0-15-13-15-60s3-60 15-60c8 0 15-7 15-15s7-15 15-15 15-7 15-15 9-15 20-15c16 0 20-7 20-30s-4-30-20-30c-11 0-20-7-20-15 0-13-35-15-255-15H90v420h550v-45z"
        transform="matrix(.1 0 0 -.1 0 92)"
      ></path>
    </svg>
  );
}

export default DangerFlagIcon;
