import scss from "./WelcomeSignUp.module.scss";
import PropTypes from "prop-types";

/**
 * @module
 * @description This function creates the welcome message of the local page , depending on the user information the message may vary.
 * @param {String} props.name: User to be signed up name.
 * @returns {JSX} A welcoming message for the signUp page, with the user's name.
 */

function WelcomeSignUp(props) {
  return (
    <div className={scss.content_card}>
      <h1 className={scss.welcome_primary}>
        Welcome to the Blue Trail Family, {props?.name}!
      </h1>
      <h2 className={scss.welcome_secondary}>
        To get started, please follow the 2 steps below...
      </h2>
    </div>
  );
}

WelcomeSignUp.propTypes = {
  name: PropTypes.string,
};

export default WelcomeSignUp;
