import React, {useEffect, useState} from "react";
import SelectCountry from "./SelectCountry";
import PermissionsItems from "./PermissionsItems";
import {getPermissions} from "../../services/RoleAccessControl/getPermissions";
import {getLocationsByRegionName, getUserLocations} from "../../services/RoleAccessControl/getUserLocation";
import {useAuthContext} from "../../context";
import {getLocations, getLocationsAndRegions, getRegions} from "../MyCalendar/services/getLocationsAndRegions";
import {roleService} from "../../services/RoleAccessControl/roleService";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useIntl } from "react-intl";
import {useNavigate} from "react-router-dom";
import {
  permissionsByUserRoleLocation,
  permissionsByUserRoleLocationRegion
} from "../../services/RoleAccessControl/savePermissions";

export default function Permissions({countriesPermissions, role, locationsBadge, selectedEmployee, defaultLocation}){
  const [count, setCount] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState();
  const [showDefaultPermissions, setShowDefaultPermissions] = useState(false); //poner en false si se habilitar el boton de shoe default permissions
  const [permissionItems, setPermissionItems] = useState([])
  const [selectAllCategories, setSelectAllCategories] = useState({
    'Projects':false,
    'Events and Holidays':false,
    'Players':false,
    'Scheduler':false,
    'News':false,
    'Reports':false
  });

  const auth = useAuthContext();
  const MySwal = withReactContent(Swal);
  const intl = useIntl();
  const [resetPermissions, setResetPermissions] = useState(false);
  const navigate = useNavigate();
  // useEffect(()=>{
  //   console.log(selectedCountry)
  //   if(selectedCountry === null || selectedCountry===undefined){
  //
  //   }
  //   console.log(permissionItems)
  // }, [selectedCountry])

  useEffect(()=>{
    setSelectedCountry(null)
  }, [selectedEmployee])

  useEffect(async ()=>{
    const permissions = await getPermissions.getAllPermissions(auth.user.token);
    let allPermissions = permissions.data;
    const permissionCategories = await getPermissions.permissionCategories(auth.user.token);
    let permItemTemp = permissionCategories.data;
    permItemTemp = permissionCategories.data.map(ele => {
      return {
        id: ele.id,
        title: ele.name,
        items: []
      }
    })

    permItemTemp = permItemTemp.map(ele => {
      for (const perm of allPermissions) {
        if (ele.id === perm.categoryId) {
          ele.items = [...ele.items, {name: perm.description, id: perm.id}]
        }
      }
      return ele;
    })
    permItemTemp = permItemTemp.map(ele => {

      return {
        id: ele.id,
        title: ele.title,
        items: ele.items.map(el => {
          return {
            name: el.name,
            value: false,
            id: el.id
          }
        })
      }
    })

    setPermissionItems(permItemTemp)
  },[])

  useEffect(()=>{
    if(permissionItems.length>0){
      const temp= permissionItems.map(per =>{
        per.items = per.items.map(item =>{
          item.value=false;
          return item
        })
        return per;
      });
      setPermissionItems(temp)
    }
  }, [locationsBadge]);

  useEffect(()=> {
    setSelectedCountry(null);
  }, [countriesPermissions])

  useEffect(async ()=>{

    try {
      if(showDefaultPermissions){
        const permissions = await getPermissions.getAllPermissions(auth.user.token);
        let allPermissions = permissions.data;
        const permissionCategories = await getPermissions.permissionCategories(auth.user.token);
        let permItemTemp = permissionCategories.data;
        permItemTemp = permissionCategories.data.map(ele => {
          return {
            id: ele.id,
            title: ele.name,
            items: []
          }
        })

        permItemTemp = permItemTemp.map(ele => {
          for (const perm of allPermissions) {
            if (ele.id === perm.categoryId) {
              ele.items = [...ele.items, {name: perm.description, id: perm.id}]
            }
          }
          return ele;
        })

        let userPermissionRoleLocation;

        const country = selectedCountry;
        if (selectedCountry && selectedCountry.id) {

          let userPermissions;
          userPermissions = await getPermissions.getDefaultPermissionsByRoleName(auth.user.token, role.name);
          userPermissionRoleLocation = userPermissions;

        } else {
          userPermissionRoleLocation = [];
        }
        if ( selectedCountry && selectedCountry.id) {

          if (userPermissionRoleLocation.length > 0) {

            permItemTemp = permItemTemp.map(ele => {

              return {
                id: ele.id,
                title: ele.title,
                items: ele.items.map(el => {
                  let find = false;
                  for (const per of userPermissionRoleLocation) {
                    if (el.id === per.permissionId) {
                      find = true;
                      return {
                        name: el.name,
                        value: true,
                        id: el.id
                      }
                    }
                  }
                  return {
                    name: el.name,
                    value: false,
                    id: el.id
                  }
                })
              }
            })

          } else {

            const permissionsByDefault = await getPermissions.getPermissionsByDefault(auth.user.token);
            permItemTemp = permItemTemp.map(ele => {
              const items = ele.items.map(el => {
                return {
                  name: el.name,
                  value: false,
                  id: el.id
                }
              })
              ele.items = items;
              return ele;
            })
            for (let i = 0; i < permItemTemp.length; i++) {

              for (let j = 0; j < permItemTemp[i].items.length; j++) {

                for (let k = 0; k < permissionsByDefault.length; k++) {

                  if (role.id === permissionsByDefault[k].companyRoleId &&
                    permItemTemp[i].items[j].id === permissionsByDefault[k].permissionId) {
                    permItemTemp[i].items[j].value = true;
                    break;
                  }
                }
              }
            }
          }
        } else if (selectedCountry!== null && selectedCountry.name !== null &&  selectedCountry.name !== undefined) {

          const permissionsByDefault = await getPermissions.getPermissionsByDefault(auth.user.token);

          if (countriesPermissions.employee.position.includes(role.name)) {
            permItemTemp = permItemTemp.map(ele => {

              const items = ele.items.map(el => {
                return {
                  name: el.name,
                  value: false,
                  id: el.id
                }
              })
              ele.items = items;
              return ele;
            });
            for (let i = 0; i < permItemTemp.length; i++) {

              for (let j = 0; j < permItemTemp[i].items.length; j++) {

                for (let k = 0; k < permissionsByDefault.length; k++) {

                  if (role.id === permissionsByDefault[k].companyRoleId &&
                    permItemTemp[i].items[j].id === permissionsByDefault[k].permissionId) {
                    permItemTemp[i].items[j].value = true;
                    break;
                  }
                }
              }
            }
          } else {
            let userPermissions = await getPermissions.getDefaultPermissionsByRoleName(auth.user.token, role.name);
            permItemTemp = permItemTemp.map(ele => {

              return {
                id: ele.id,
                title: ele.title,
                items: ele.items.map(el => {
                  for (const per of userPermissions) {

                    if (el.id === per.permissionId) {
                      return {
                        name: el.name,
                        value: true,
                        id: el.id
                      }
                    }
                  }
                  return {
                    name: el.name,
                    value: false,
                    id: el.id
                  }
                })
              }
            })
          }
        }

        if(selectedCountry === null){
          permItemTemp = permItemTemp.map(item =>{
            return {
              id:item.id,
              title:item.title,
              items: item.items.map(per =>{
                return {
                  name:per.name,
                  id:per.id,
                  value:false
                }
              })
            }
          });
        }
        setPermissionItems(permItemTemp)
      }else{

        let allPermissions;
        let permissionsByUserRoleLocation;
        let permissionCategories;
        let permItemTemp;

        allPermissions = await getPermissions.getAllPermissions(auth.user.token);
        allPermissions = allPermissions.data;
        permissionCategories = await getPermissions.permissionCategories(auth.user.token);
        permItemTemp = permissionCategories.data;

        let roleData = await roleService.getRoleByName(auth.user.token, role.name);

        if(selectedCountry.id){
          permissionsByUserRoleLocation = await getPermissions.getUserRoleLocationPermissions(auth.user.token, countriesPermissions.employee.id, roleData.id, selectedCountry.name);
        }else{
          permissionsByUserRoleLocation = await getPermissions.getUserRoleLocationRegionPermissions(auth.user.token, countriesPermissions.employee.id, roleData.id, selectedCountry.name);
        }
        permissionsByUserRoleLocation = permissionsByUserRoleLocation.length===0 ? []: permissionsByUserRoleLocation;
        permItemTemp = permissionCategories.data.map(ele => {
          return {
            id: ele.id,
            title: ele.name,
            items: []
          }
        })

        permItemTemp = permItemTemp.map(ele => {
          for (const perm of allPermissions) {
            if (ele.id === perm.categoryId) {
              ele.items = [...ele.items, {name: perm.description, id: perm.id, value: false}]
            }
          }
          return ele;
        })
        permItemTemp = permItemTemp.map(ele => {
           ele.items = ele.items.map(item =>{
             item.value = false;
             let itemTemp = item;
             for (const permByURL of permissionsByUserRoleLocation) {
                if(permByURL.permissionId === item.id){
                  itemTemp.value = true;
                }
              }

             return itemTemp;
            })
          return ele;
        })
        setPermissionItems(permItemTemp)
      }
    }catch (e){
      console.log(e);
    }

  }, [countriesPermissions, selectedCountry,role,showDefaultPermissions, count, selectedEmployee, resetPermissions]);

  const onChange = (permissionsCategorie)=>{

    let temp =  permissionItems.map(item => item.id === permissionsCategorie.id ? permissionsCategorie: item);

    setPermissionItems(temp);
  }


  const savePermissions = async ()=>{
    try {
      MySwal.fire({
        title: "Are you sure you want to save changes?",
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: `${intl.formatMessage({ id: "button.confirm", defaultMessage: "Yes, I want" })}`,
        customClass: {
          title: "main-dialog",
          htmlContainer: "secondary-dialog",
          actions: "actions-container",
        },
        confirmButtonColor:"#38b872",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const items = permissionItems.filter(permItem=> {
            if(selectAllCategories[permItem.title]===true){
              return true
            }
            return false;
          })

          let permissionToSave = permissionItems.filter(permItem=> {
            if(selectAllCategories[permItem.title]===false){
              return true
            }
            return false;
          })

          const permissionValueTrue = items.map(it =>{
            it.items = it.items.map(per =>{
              per.value=true;
              return per;
            })
            return it;
          })

          permissionToSave = [...permissionToSave, ...permissionValueTrue];

          const filterSelectedPermissionsToSave = filterSelectedPermissions(permissionToSave);
          const filterNoSelectedPermissionsToDelete = filterNoSelectedPermissions(permissionToSave);

          // if(showDefaultPermissions){
          if(selectedCountry.id !== undefined){
            let roleData = await roleService.getRoleByName(auth.user.token, role.name);
            const locations = await getLocations(auth.user.token)
            const location = locations.filter(item => item.name === selectedCountry.name);
            const save = await permissionsByUserRoleLocation(auth.user.token, countriesPermissions.employee.id, location[0].id, roleData.id, filterSelectedPermissionsToSave, locationsBadge);
          }else{
            let roleData = await roleService.getRoleByName(auth.user.token, role.name);
            const locations = await getLocationsByRegionName(auth.user.token, selectedCountry.name);
            let region = await getRegions(auth.user.token);
            region = region.filter(item => item.name === selectedCountry.name);
            region = region[0]
            const save = await permissionsByUserRoleLocationRegion(auth.user.token, countriesPermissions.employee.id, locations, region.id,  roleData.id, filterSelectedPermissionsToSave, locationsBadge);
          }
          Swal.fire(`Permissions saved`, "", "success");
        }
      });
    } catch (error) {
      throw error;
    }
  }

  const cancelPermissions = async () => {
    setResetPermissions(true);
  }


  const filterSelectedPermissions = (selectedPermissions) =>{
    let permissionsToSave = selectedPermissions.map(item =>{
      let itemsFiltered = item.items.filter(itemKey =>{
        if(itemKey.value === true){
          return true;
        }
        return false;
      });
      return itemsFiltered
    });
    let temp = []
    for (const permissionsToSaveElement of permissionsToSave) {
      temp =[...temp, ...permissionsToSaveElement];
    }

    return temp;
  }

  const filterNoSelectedPermissions = (selectedPermissions) =>{
    let permissionsToSave = selectedPermissions.map(item =>{
      let itemsFiltered = item.items.filter(itemKey =>{
        if(itemKey.value === false){
          return true;
        }
        return false;
      });
      return itemsFiltered
    });
    let temp = []
    for (const permissionsToSaveElement of permissionsToSave) {
      temp =[...temp, ...permissionsToSaveElement];
    }

    return temp;
  }

  useEffect(() => {
    setResetPermissions(false);
  }, [permissionItems])

  useEffect(()=>{
    setSelectAllCategories({
      'Projects':false,
      'Events and Holidays':false,
      'Players':false,
      'Scheduler':false,
      'News':false,
      'Reports':false
    })
  },[role, selectedEmployee]);

  return (
    <>
      <div className="projects-list">
        <div className="select-role-container">

          <SelectCountry selectedCountry={defaultLocation} countries={locationsBadge} setSelectedCountry={setSelectedCountry} setShowDefaultPermissions={setShowDefaultPermissions} />
          <div style={{'display':'flex', 'flexWrap':'wrap', 'marginLeft':'5px', 'marginTop':'20px'}} className='permissions-container'>
            {
              permissionItems.map(per =>{
                return <PermissionsItems permissions={per} onChange={onChange} setSelectAllCategories={setSelectAllCategories}  selectAllCategories={selectAllCategories}  setCount={setCount} count={count}/>
              })
            }
          </div>
        </div>
      </div>
      {permissionItems.length>0?
      <div className="btn-container">
        <button className="btn-cancel" onClick={cancelPermissions}>Cancel</button>
        <button className="btn-save" onClick={savePermissions}>Save</button>
      </div>:null}
    </>
  );
}