import axios from "axios";
import { environment } from "../../../config/environment";

const eventService = {
  save: async (eventData, userId, token) => {
    try {
      const data = await axios
        .post(`${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/company/events`,
          eventData,
          {
            headers: {
              authorization: token,
            },
          }
        );
      return data;
    } catch (err) {
      throw (
        err.response || err.toJSON() || err
      );
    }
  },
}

const eventServiceDataFormat = (data, userId, token)=>{

  const newData = {
    "regionId": data.context,
    "date": data.date,
    "name": data.title,
    "description": data.description,
    "start_hour": data.workTimeStart,
    "end_hour": data.workTimeEnd,
    "globally_viewable": data.globalVisible,
    "all_day": data.allDay,

  }
  return eventService.save(newData, userId, token)
}


export { eventServiceDataFormat };