/**
 * @module
 * @description This function create the logic of the Link
 * @param {any} url get url
 * @param {string} text get data text
 * @param {string} blank
 * @returns {JSX} Link Externo
 */

const LinkExterno = ({ url, text, blank }) => {
  return (
    <a href={url} target={blank ? '_blank' : '_self'} rel="noreferrer">
      {text}
    </a>
  );
};

export default LinkExterno;
