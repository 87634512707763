import "./ActionButton.scss";

const ActionButton = ({
  type,
  onClick,
  children,
  width,
  disabled,
  secondary,
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`${secondary ? (secondary === "tertiary" ? "tertiary-action-button" : "secondary-action-button") : "action-button"} `}
      style={{ width: isNaN(width) ? `${width}` : `${width}%` }} // if prop "width" is a string i.e. "100px" it will have an absolute width size. In the another case it'll be relative
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default ActionButton;
