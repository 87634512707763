import { Modal, Form, Spinner } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useContext, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { CalendarIcon } from '../../Icons';
import './AddPlayerModal.scss';
import 'react-datepicker/dist/react-datepicker.css';
import AllocationTable from './AllocationTable';
import { ActionButton } from '../shared';
import ProjectsContext from '../../context/ProjectsContext/ProjectsContext';
import moment, { max } from 'moment';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

/**
 * @module
 * @description This function creates the structure and the logic of the add/edit a player
 * @param {any} show to see if the modal is open or not
 * @param {any} handlePlayerModal to control the modal
 * @param {String} type to see what type of modal is
 * @param {Number} playerId get Id player
 * @param {Number} projectId get Id project
 * @param {Function} callback function to execute after get the response
 * @returns {JSX} AddPlayerModal
 */

const AddPlayerModal = ({
  show,
  handlePlayerModal,
  type,
  playerId,
  projectId,
  callback,
  players,
}) => {
  const {
    addNewPlayer,
    getAllPlayers,
    getPlayerData,
    getProjectRoles,
    editPlayerData,
    removeAllocations,
    auth
  } = useContext(ProjectsContext);

  const intl = useIntl();

  const [initialValues, setInitialValues] = useState({
    playerName: '',
    projectRole: '',
    allocationType: '',
    allocation: '',
    startDate: '',
    releaseDate: '',
    billableResource: false,
    temporarySupport: false,
  });
  const [loading, setLoading] = useState(false);
  const [playerChange, setPlayerChange] = useState(null);

  // states to store the options to fill out the form's dropdown
  const [playersList, setPlayersList] = useState([]);
  const [rolesList, setRolesList] = useState([]);

  const [playerAllocations, setPlayerAllocations] = useState([]);
  const [selectedAllocations, setSelectedAllocations] = useState([]);

  useEffect(() => {
    // projectPlayers();
    setPlayerAllocations([]);
  }, [playerChange]);

  useEffect(() => {
    //get player list to fill the datalist
    if (show) {
      getPlayerList();
      getRoles();
    }
    //get all project roles to fill the dropdown
    // getRoles();
  }, [show]);

  useEffect(() => {
    if (type === 'edit' || type === 'relocate') getUserData();
    if (type === 'add') {
      playerAllocations.length = 0;
    }
  }, [show]);

  /**
   * @description Execute the function from the context and receive the player data
   * @param {String} id
   */

  const getUserData = async (id) => {
    try {
      let response = await getPlayerData(playerId || id);
      response = response?.data?.data;
      const playerProject = response.find((pr) => pr.id === parseInt(projectId));
      if (type !== 'add') {
        setInitialValues({
          playerName: `${playerProject.firstName} ${playerProject.lastName}`,
          projectRole: playerProject.projectRoleName,
          allocationType: playerProject.allocationType,
          allocation: playerProject.allocationValue,
          startDate: new Date(playerProject.startDate),
          releaseDate:
            playerProject.releaseDate === null
              ? null
              : new Date(playerProject.releaseDate),
          billableResource: playerProject.billableAllocation,
          temporarySupport: playerProject.temporarySupport,
        });
      }

      setPlayerAllocations(response.filter((pr) => pr.id !== projectId));
    } catch (error) {
      Swal.fire(
        'Something went wrong',
        "We couldn't get the player data",
        'error'
      );
    }
  };

  /**
   * @description Execute the function from the context and get the player data
   * @param {String} playerName
   */

  const getAllocations = async (playerName) => {
    setPlayerChange(playerName);
    const id = getPlayerId(playerName);
    if (!id || type !== 'add') return;
    try {
      let response = await getPlayerData(id);
      response = response?.data?.data;
      setPlayerAllocations(response.filter((pr) => pr.id !== projectId));
    } catch (error) {
      console.error(
        error,
        'Something went wrong trying to get the player allocations'
      );
    }
  };

  /**
   * @description Modal schema
   */

  const AddPlayerSchema = Yup.object().shape({
    playerName: Yup.string()
      .required('Please enter a player')
      .test(
        'playerExist',
        'You must select a player from the list',
        (playerName) => {
          return (
            playersList.find((player) => player.fullName === playerName) ||
            type === 'relocate'
          );
        }
      ),
    projectRole: Yup.string()
      .required('Please select a role')
      .test(
        'projectRoleExist',
        'You must select a role from the list',
        (role) => {
          return rolesList.find((rol) => rol.name === role);
        }
      ),
    allocationType: Yup.string().required(
      intl.formatMessage({
        id: "projects.options.alloocation.type",
        defaultMessage: "Please, select an allocation type",
      })
    ),
    allocation: Yup.number()
      .min(1, 'The minimum valid value is 1')
      .when('allocationType', {
        is: 'Partial-time',
        then: Yup.number()
          .max(99, 'The maximum valid value is 99')
          .required(
            intl.formatMessage({
              id: "projects.options.alloocation.percentage",
              defaultMessage: "Please, enter an allocation percentage",
            })
          ),
      })
      .when('allocationType', {
        is: 'Partial-time-hs',
        then: Yup.number()
          .max(56, 'The maximum valid value is 56')
          .required(
            intl.formatMessage({
              id: "projects.options.allocation.hours",
              defaultMessage: "Please, enter an hour allocation",
            })
          ),
      })
      .nullable(),
    startDate: Yup.date().nullable().required('Please enter a start date'),
    releaseDate: Yup.date()
      .nullable()
      .min(Yup.ref('startDate'), 'End date must be later than start date'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: AddPlayerSchema,
    onSubmit: (values) => {
      setLoading(true);
      if (type === 'add') addPlayer();
      if (type === 'relocate') editPlayer();
      // let's add put to update a player (relocate, edit project role and so on)
      handlePlayerModal();
    },
  });

  /**
   * @description Actions when the modal has been closed
   */

  const onHide = () => {
    formik.resetForm();
    handlePlayerModal();
  };

  var {
    playerName,
    projectRole,
    allocationType,
    allocation,
    startDate,
    releaseDate,
    billableResource,
    temporarySupport,
  } = formik.values;

  /**
   * @description Execute the functions from the context and get the response
   */

  const addPlayer = async () => {
    if (allocationType === 'Full-time') {
      allocation = 100;
    } else if (allocationType === 'On-demand') {
      allocation = null;
    }
    const payload = {
      userID: getPlayerId(playerName),
      projectID: projectId,
      roleID: getRoleId(projectRole),
      leader: false,
      startDate: moment(startDate).format('YYYY-MM-DD'),
      weeklyHours: 40,
      variability: 'Fixed',
      billableAllocation: billableResource,
      temporarySupport,
      allocationType,
      allocationValue: allocation,
    };

    if (releaseDate) {
      payload.releaseDate = moment(releaseDate).format('YYYY-MM-DD');
    }
    try {
      const response = await addNewPlayer(payload);
      if (selectedAllocations.length) {
        removeAllocations(
          playerId || getPlayerId(playerName),
          selectedAllocations
        );
      }
      callback();
      setLoading(false);
      Swal.fire(
        `The player was ${type === 'add' ? 'added' : 'edited'}  successfully!`,
        '',
        'success'
      );
      onHide();
    } catch (error) {
      setLoading(false);

      Swal.fire(
        'Something went wrong',
        error.data?.message || 'Try again later',
        'error'
      );

      console.error(
        error.data?.message,
        'An error occurs while trying add a new player'
      );
    }
  };

  /**
   * @description Execute the functions from the context and get the response
   */

  const editPlayer = async () => {
    if (allocationType === 'Full-time') {
      allocation = 100;
    } else if (allocationType === 'On-demand') {
      allocation = null;
    }
    const payload = {
      projectID: projectId,
      roleID: getRoleId(projectRole),
      leader: false,
      startDate: moment(startDate).format('YYYY-MM-DD'),
      billableAllocation: billableResource,
      temporarySupport,
      allocationType,
      allocationValue: allocation,
      releaseDate,
    };

    if (releaseDate) {
      payload.releaseDate = moment(releaseDate).format('YYYY-MM-DD');
    }

    try {
      const response = await editPlayerData(playerId, payload);

      if (selectedAllocations.length) {
        removeAllocations(
          playerId || getPlayerId(playerName),
          selectedAllocations
        );
      }
      callback();
      setLoading(false);
      Swal.fire(
        `The player was ${
          type === 'relocate' ? 'edited' : 'added'
        }  successfully!`,
        '',
        'success'
      );
      onHide();
    } catch (error) {
      setLoading(false);

      Swal.fire(
        'Something went wrong',
        error.data?.message || 'Try again later',
        'error'
      );

      console.error(
        error.data?.message,
        'An error occurs while trying edit a player'
      );
    }
  };

  /**
   * @description Execute the function from the context and get the response
   */

  const getPlayerList = async () => {
    //console.log(name, playersBody)
    try {
      const response = await getAllPlayers(projectId);
      const {
        data: {
          data: { items },
        },
      } = response;
      setPlayersList(items);
    } catch (error) {
      console.error(
        error,
        'An error occurs while trying to get the players list'
      );
    }
  };

  /**
   * @description Execute the function from the context and get the response
   */

  const getRoles = async () => {
    try {
      const response = await getProjectRoles();
      const {
        data: { data },
      } = response;
      setRolesList(data);
    } catch (error) {
      console.error(
        error,
        'An error occurs while trying to get the project roles'
      );
    }
  };

  /**
   * @description Make the title for the modal
   * @returns {Object} titles
   */

  const getTitle = () => {
    const titles = {
      edit: `Edit ${playerName}`,
      add: 'Add a player',
      relocate: `Reallocate ${playerName}`,
    };

    return titles[type];
  };

  // get player id by name
  /**
   * @description get player id by name
   * @returns {Number} playerId
   */

  const getPlayerId = (playerName) => {
    const playerId = playersList.find(
      (player) => player.fullName === playerName
    )?.id;
    return Number(playerId);
  };

  // get player id by name
  /**
   * @description get role id by name
   * @returns {Number} roleId
   */

  const getRoleId = (roleName) => {
    const roleId = rolesList.find((role) => role.name === roleName).id;
    return Number(roleId);
  };

  return (
    <Modal
      className="add-player-modal"
      show={show}
      onHide={onHide}
      centered
      size="lg"
    >
      <Modal.Header>
        <h4>{getTitle()}</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="first-section">
          <form className="addPlayerForm">
            <div className="formField">
              <label htmlFor="">Player*</label>
              <input
                type="text"
                disabled={type !== 'add'}
                id="playerName"
                value={playerName}
                name="playerName"
                onFocus={() => {}}
                onChange={(e) => {
                  formik.handleChange(e);
                  getAllocations(e.target.value);
                }}
                onBlur={formik.handleBlur}
                list="playersList"
                className="form-control"
                placeholder="Search by name or email..."
              />

              <datalist id="playersList">
                {playersList.map((player) => (
                  <option key={player.id} value={player.fullName} />
                ))}
              </datalist>

              {formik.touched.playerName && formik.errors.playerName ? (
                <div className="formikError">
                  <small>{formik.errors.playerName}</small>
                </div>
              ) : null}
            </div>
            <div className="formField">
              <label htmlFor="projectRole">Project Role*</label>
              <input
                type="text"
                id="projectRole"
                value={projectRole}
                name="projectRole"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                list="roleList"
                className="form-control"
                placeholder="Search by name..."
              />

              <datalist id="roleList">
                {rolesList.map((role) => (
                  <option key={role.id} value={role.name} />
                ))}
              </datalist>
              {formik.touched.projectRole && formik.errors.projectRole ? (
                <div className="formikError">
                  <small>{formik.errors.projectRole}</small>
                </div>
              ) : null}
            </div>
            <div className="formField">
              <label htmlFor="allocationType">Allocation type*</label>
              <Form.Select
                type="select"
                className="form-control"
                id="allocationType"
                value={allocationType}
                name="allocationType"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">Select type</option>
                <option value="Partial-time">Partial-Time %</option>
                <option value="Full-time">Full-time</option>
                <option value="Partial-time-hs">Partial-Time Hs</option>
                <option value="On-demand">On Demand</option>
              </Form.Select>
              {formik.touched.allocationType && formik.errors.allocationType ? (
                <div className="formikError">
                  <small>{formik.errors.allocationType}</small>
                </div>
              ) : null}
            </div>
            <div
              className={`formField ${
                (allocationType === 'Full-time' ||
                  allocationType === 'On-demand') &&
                'allocation'
              }`}
            >
              <label htmlFor="allocation">Allocation*</label>
              <div className="icon-outside-input">
                <input
                  type="number"
                  id="allocation"
                  min="1"
                  max="99"
                  value={allocation}
                  name="allocation"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-control"
                  placeholder="1"
                />
                <span>
                  {allocationType === 'Partial-time-hs' ? 'Hrs' : '%'}
                </span>
              </div>
              {formik.touched.allocation && formik.errors.allocation ? (
                <div className="formikError">
                  <small>{formik.errors.allocation}</small>
                </div>
              ) : null}
            </div>
            {/* </div> */}
            <div className="formField">
              <label htmlFor="startDate">Start Date*</label>
              <div className="inputWithIcon">
                <label>
                  <DatePicker
                    selected={startDate}
                    name="startDate"
                    onBlur={formik.handleBlur}
                    id="startDate"
                    placeholderText="Enter the start date"
                    onChange={(date) => formik.setFieldValue('startDate', date)}
                    className="form-control"
                  />
                  <CalendarIcon />
                </label>
              </div>
              {formik.touched.startDate && formik.errors.startDate ? (
                <div className="formikError">
                  <small>{formik.errors.startDate}</small>
                </div>
              ) : null}
            </div>
            <div className="formField">
              <label htmlFor="startDate">End Date</label>
              <div className="inputWithIcon">
                <label>
                  <DatePicker
                    selected={releaseDate}
                    name="releaseDate"
                    onBlur={formik.handleBlur}
                    id="releaseDate"
                    placeholderText="Enter the release date"
                    onChange={(date) => formik.setFieldValue('releaseDate', date)}
                    className="form-control"
                  />
                  <CalendarIcon />
                </label>
              </div>

              {formik.touched.releaseDate && formik.errors.releaseDate ? (
                <div className="formikError">
                  <small>{formik.errors.releaseDate}</small>
                </div>
              ) : null}
            </div>
          </form>
          <AllocationTable
            playerId={playerId || getPlayerId(playerName)}
            getUserData={getUserData}
            callback={callback}
            playerAllocations={playerAllocations}
            currentAllocation={
              allocationType === 'Partial-time-hs'
                ? Math.round((allocation * 100) / 56)
                : allocation
            }
            type={type}
            setSelectedAllocations={setSelectedAllocations}
            selectedAllocations={selectedAllocations}
          />{' '}
        </div>

        <div className="moreOptions">
          <h5>More options</h5>
          <div className="moreOptionsField">
            <input
              type="checkbox"
              id="billableResource"
              name="billableResource"
              checked={billableResource}
              onChange={formik.handleChange}
            />
            <label htmlFor="billableResource">Billable resource</label>
          </div>
          <div className="moreOptionsField">
            <input
              type="checkbox"
              id="temporarySupport"
              name="temporarySupport"
              checked={temporarySupport}
              onChange={formik.handleChange}
            />
            <label htmlFor="temporarySupport">Temporary support</label>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <ActionButton secondary onClick={onHide}>
            Cancel
          </ActionButton>
          <ActionButton disabled={loading} onClick={formik.submitForm}>
            {' '}
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <>{type === 'add' ? 'Add' : 'Confirm'}</>
            )}{' '}
          </ActionButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

AddPlayerModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handlePlayerModal: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  projectId: PropTypes.number.isRequired,
  callback: PropTypes.func.isRequired,
};

export default AddPlayerModal;
