import React, {useEffect, useState} from "react";
import Checkbox from "./CheckBoox";
import getProjectPermissions from "../../services/RoleAccessControl/getProjectPermissions";
import {getPermissions} from "../../services/RoleAccessControl/getPermissions";


export default function PermissionsItems({permissions, onChange, setSelectAllCategories, selectAllCategories, count, setCount}){

  const [allPermissionsActive, setAllPermissionsActive] = useState();
  const [all, setAll] = useState(selectAllCategories[permissions.title]);

  useEffect(()=>{
    if(permissions.items.length > 0  && !selectAllCategories){
      const tempPer = permissions;
      let temp = tempPer;
      temp = temp.items.map(it =>{
        it.value=true;
        return it;
      });
      setAllPermissionsActive({
        title: permissions.title,
        items: temp,
        id: permissions.id
      });
    }
  }, [permissions]);

  useEffect(()=>{
    setAll((selectAllCategories[permissions.title]))
  }, [selectAllCategories]);

  function handleChangePermission(permission) {
    let temPermission =permissions;

    if(!selectAllCategories[permissions.title]){
      temPermission.items = permissions.items.map(item =>{
        let tempItem = item;
        if(permission.name === item.name){
          tempItem.value = permission.value;
        }
        return tempItem;
      });
    }

    onChange(temPermission);
  }

  function onChangeSelectAll(){
    let temp = selectAllCategories;
    let update;
    const title = permissions.title;

    if(permissions.items.length > 0  && !selectAllCategories[permissions.title]){
      const tempPer = permissions;
      let temp = tempPer;
      temp = temp.items.map(it =>{
        it.value=true;
        return it;
      });
      setAllPermissionsActive({
        title: permissions.title,
        items: temp,
        id: permissions.id
      });
     update= {
      title : true
     };

    }else{
      update= {
        title : false
    };
    }

    let selectAll = selectAllCategories;
    selectAll[title] = update.title;
    setSelectAllCategories(selectAll);
    setAll(!all)
    setCount(count + 1)
  }

  useEffect(()=>{

  }, [all])
  return (
    <div className="v-line line-color" style={{'marginLeft':'0px', 'width':'45%','marginRight':'10px','marginTop':'10px', 'marginBottom':'10px'}}>

      <div className="custom-control custom-checkbox" style={{'display':'flex'}}>
        <h6 style={{'marginRight':'15px','paddingLeft':'15px'}}><strong>{permissions.title}</strong></h6>
        <input type="checkbox" className="custom-control-input" id={permissions.title} style={{'marginTop':'-5px'}} checked={all} onClick={onChangeSelectAll}/>
        <label className="custom-control-label" htmlFor={permissions.title} style={{'marginLeft':'10px' , 'fontSize':'13px'}} >Grant all</label>
      </div>

      <div className="badge-location" style={{'display':'flex', 'flexWrap':'wrap'}}>
        {!all ? permissions.items.map(per=> <Checkbox id={per.id} name={per.name} value={per.value} handleChangePermission={handleChangePermission}/>) : null}
        {all ? allPermissionsActive.items.map(per=> <Checkbox id={per.id} name={per.name} value={per.value} handleChangePermission={handleChangePermission}/>) : null}
      </div>

    </div>);
}

