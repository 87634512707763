import './InfoStatus.scss';
import moment from 'moment';
import { getStatusIcon } from '../../utils/getProjectStatusIcon';
import { getMoodsIcon } from '../../utils/getMoodsIcon';
import { getSatisfactionIcon } from '../../utils/getSatisfactionIcon';
import { convertDateToLocale } from '../../utils/convertDateToLocale';
import { ArchivedIcon } from '../Icons';
import ReactTooltip from "react-tooltip";
import React from "react";

/**
 * @module
 * @description This function create the structure and the logic of the Information of the project Status
 * @param {Function} project get data project status
 * @returns {JSX} Info Project Status
 */

const InfoStatus = ({
  project: {
    statusId,
    statusUpdatedAt,
    moodId,
    satisfactionId,
    projectIsArchived,
  },
}) => {
  // this func transform the utc date to locale
  const localeDate = convertDateToLocale(statusUpdatedAt).split('T')[0];

  const lastUpdate = moment(localeDate).format(`MMM DD 'YY`);
  if (projectIsArchived) {
    return (
      <>
        <div className="status-project-archived">
          <div className="info-status-archived">
            <ArchivedIcon />
          </div>
        </div>
        <p className="date">{lastUpdate}</p>
      </>
    );
  }
  return (
    <>
      <div className="status-project">
        <button className="info-status" data-tip="Status" data-for="status">
          {getStatusIcon(statusId)}
        </button>
        <ReactTooltip id="status" place="bottom" backgroundColor="#3FAA58"/>

        <button className="info-status-mood" data-tip="Mood" data-for="status-mood">
          {getMoodsIcon(moodId)}
        </button>
        <ReactTooltip id="status-mood" place="bottom" backgroundColor="#3FAA58"/>

        <button className="info-status-satisfaction" data-tip="Satisfaction" data-for="status-satisfaction">
          {getSatisfactionIcon(satisfactionId)}
        </button>
        <ReactTooltip id="status-satisfaction" place="bottom" backgroundColor="#3FAA58"/>
      </div>
      <p className="date">{lastUpdate}</p>
    </>
  );
};

export default InfoStatus;
