import Swal from "sweetalert2";
import "./DialogBox.scss";

const DialogBox = (dialog, actionToDo) => {
  Swal.fire({
    title: `${dialog?.principalText}`,
    text: `${dialog?.secondaryText}`,
    reverseButtons: true,
    showCancelButton: true,
    confirmButtonText: `${dialog?.confirmButtonText}`,
    customClass: {
      title: "main-dialog",
      htmlContainer: "secondary-dialog",
      actions: "actions-container",
      confirmButton: "action-button",
      cancelButton: "secondary-action-button",
    },
  }).then((result) => {
    if (result.isConfirmed) {
      actionToDo();
    }
  });
};

export { DialogBox };
