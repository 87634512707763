import axios from 'axios';
import PropTypes from 'prop-types';
import { environment } from '../../../config/environment';

/**
 *@function
 *@description This service calls the api in order to retrieve all the tutorials that a 
 user has  completed
 * @param {number} id id of the user that we want to know which tutorials he/she has done
 * @return {object} response.data.data Array that contains all the information of
 * which tutorials the user has completed
 */
async function getTutorialsCompleted(id, token) {
  try {
    const response = await axios.get(
      `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module0/tutorials/${id}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data.data;
  } catch (error) {
    throw error.response || error.toJSON() || error;
  }
}

/**
 *@function
 *@description This service calls the api in order to post a new entry when a user completes a tutorial
 * @param {number} userId id of the user that did the tutorial.
 * @param {number} tutorialId id of the tutorial that the user just completed
 * @return {object} request response from the API
 */

async function postTutorialCompleted(userId, tutorialId, token) {
  try {
    const request = await axios.post(
      `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module0/tutorials/`,
      {
        user_id: userId,
        tutorial_id: tutorialId,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return request;
  } catch (error) {
    throw error.response || error.toJSON() || error;
  }
}

export { getTutorialsCompleted, postTutorialCompleted };
