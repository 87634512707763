/**
 * @description Function to create the MyProjects icon as a JSX component
 * @returns {JSX} MyProjects
 */
function MyProjects() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="currentColor"
        d="M10.5 8.75a.75.75 0 00-1.5 0V11H6.75a.75.75 0 000 1.5H9v2.25a.75.75 0 001.5 0V12.5h2.25a.75.75 0 000-1.5H10.5V8.75z"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.005 0c-.361 0-.682 0-.948.024a2.064 2.064 0 00-.854.244C.744.528.4.948.2 1.437c-.116.283-.16.577-.18.885C0 2.62 0 2.983 0 3.42v6.336c0 .928 0 2.134.03 3.205.016.535.04 1.046.076 1.475.034.407.084.811.175 1.092a5.75 5.75 0 003.692 3.692c.868.281 1.909.281 3.615.281h4.324c1.706 0 2.747 0 3.615-.281a5.75 5.75 0 003.692-3.692c.281-.868.281-1.909.281-3.615v-.324c0-1.706 0-2.747-.281-3.615a5.75 5.75 0 00-3.692-3.692c-.235-.076-.574-.118-.906-.148-.357-.033-.79-.056-1.256-.075a95.387 95.387 0 00-3.01-.055c-.471-.002-.982-.403-1.261-1.087l-.01-.023C8.67 1.88 7.966 1.035 7.062.523 6.558.236 6.01.113 5.379.055 4.766 0 4.01 0 3.072 0h-.067zM1.944 1.572c.02-.011.074-.038.25-.054.19-.017.44-.018.842-.018.983 0 1.67 0 2.206.05.523.048.835.138 1.08.277.58.329 1.073.896 1.374 1.633l.01.023c.398.976 1.317 2.013 2.64 2.02.97.005 2.053.02 2.961.054.455.018.858.04 1.179.07.345.03.524.064.577.081a4.25 4.25 0 012.729 2.729c.199.611.208 1.393.208 3.313 0 1.92-.01 2.702-.208 3.313a4.25 4.25 0 01-2.729 2.729c-.611.199-1.393.208-3.313.208h-4c-1.92 0-2.702-.01-3.313-.208a4.25 4.25 0 01-2.729-2.729c-.031-.096-.073-.34-.107-.753-.033-.39-.056-.87-.07-1.394C1.5 11.87 1.5 10.683 1.5 9.75V3.444c0-.467 0-.78.016-1.023.016-.236.044-.348.072-.417a.862.862 0 01.356-.432z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default MyProjects;
