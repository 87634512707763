import { Link } from "react-router-dom";
import css from "./WhatIsGoingOn.module.scss";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { getImage } from "../services/images";
import {FormattedMessage, FormattedDate, useIntl} from "react-intl";
import { useAuthContext } from "../../../context/AuthContext";

/**
 * @module
 * @description This function creates an individual manager card and  can be different depending on what type of manager it is
 * @param {Object} item What is going on article
 * @param {String} region Can be name of a region or global
 * @returns {JSX} Individual element of what is going on
 */
const WhatIsGoingOnElement = ({ item, region, id }) => {
  const [imageUrl, setImageUrl] = useState("");
  const { noticeId, title, shortDescription, portrait, datePublished } = item;
  const auth = useAuthContext();
  const intl = useIntl();

  useEffect(async () => {
    try {
      let photo = await getImage(noticeId, auth?.user?.token);
      photo=photo.replace('/assets/newsPhotos', '/newsFiles')
      photo=photo.split("?")[0]
      setImageUrl(photo);
    }
    catch (error) {
    }
  });
  return (
    <div className={css.article}>
      <div className={css.section1}>
        <div className={css.articlePicture}>
          <figure>
            <img src={imageUrl} alt="Event" />
          </figure>
        </div>
        <div className={css.postedOn}>
          {/*<FormattedMessage id = "going.on.posted.on"
           defaultMessage = "Posted on"/>*/}
           Posted on
          <br />
          <FormattedDate 
          year = "numeric"
          month = "short"
          day = "numeric"/>
        </div>
      </div>
      <div className={css.section2}>
        <h3 className={css.articleTitle}>{title}</h3>
        <span className={css.articleText}>
          {shortDescription.slice(0, 100)}
        </span>
        <Link
          state={{ ...item, type: region }}
          className={css.articleReadMore}
          to={`/news/${noticeId}`}
        >
          {/*<FormattedMessage id = "going.on.read.more"
          defaultMessage = "Read More..."/>*/}
          Read More...
        </Link>
      </div>
    </div>
  );
};

WhatIsGoingOnElement.propTypes = {
  item: PropTypes.object.isRequired,
  region: PropTypes.string.isRequired,
};

export default WhatIsGoingOnElement;
