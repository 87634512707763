import { ActionButton } from "../../../components/shared";
import { Link, useNavigate, useParams } from "react-router-dom";
import css from "../NewsById.module.scss";
import css2 from "./AddNews.module.scss";
import { useAuthContext } from "../../../context/AuthContext";
import { useEffect, useState } from "react";
import axios from "axios";
import { environment } from "../../../config/environment";
import { Breadcrumb } from "react-bootstrap";
import moment from "moment";
import EditComponent from "../../../components/Icons/EditComponent";
import data from "../../../version.json";
import FilesDropzone from "../FilesDropzone";
import Swal from "sweetalert2";
import { useIntl } from "react-intl";

const AddNews = () => {
  const [newsId, setNewsId] = useState();
  const navigate = useNavigate();
  const context = useAuthContext();
  const { user } = useAuthContext();
  const intl = useIntl();
  let version = data;
  let currentVersion = version[version.length - 1];
  const [imagePreview, setImagePreview] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [file, setFile] = useState(null);
  const info = context?.userInfo?.photo;
  const datePublished = Date.now();
  let userPhoto = `${environment.S3_ROOT}${environment.S3_BUCKET}/${environment.S3_FOLDER}/${info}`;
  if (info?.slice(0, 4) === "http") {
    userPhoto = info;
  }

  let { region } = useParams();

  useEffect(() => {
    setAuthorName(context?.userInfo?.firstName + " " + context?.userInfo?.lastName);
  });

  const setImage = (e) =>{
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  }

  const postFile = async (bodyFormData) => {
    const response = await axios
      .post(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module0/news/image`,
        bodyFormData,
        {
          headers: {
            Authorization: context?.user?.token
          },
        }
      )

      .catch((err) => {
        throw err.response || err.toJSON() || err
      });
    return response;
  };

  const uploadNewsImg = async () => {
    let url = "";
    if(file){
      let bodyFormData = new FormData();
      bodyFormData.append("file", file)
      await postFile(bodyFormData).then(() => {
      })
      url = `dev/assets/newsPhotos/${file.name}`
    }
    return url;
  }

  const postNews = async () => {
    const url = await uploadNewsImg();
    const body = {
      author_id: context?.user?.id,
      title: title,
      short_description: description.length > 300? description.substring(0, 296) : description,
      description: description,
      portrait: url,
      imgs: {
        img: url,
      },
      is_global: region === 'global' ? true : false,
      urls: null,
    };

    try {
      let path = `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module0/news`;

      if (region === 'local') {
        path = `${path}/local`;
      }
      const response = await axios.post(
        path,
        body,
        {
          headers: {
            Authorization: context?.user?.token,
          },
        }
      ).catch((e) => {
        Swal.fire(
          intl.formatMessage({
            id: "modal.holidays.swal.error.description",
            defaultMessage: "Something went wrong: ",
          }) + e.response?.data?.message,
          intl.formatMessage({
            id: "modal.error.footer",
            defaultMessage: "Please, try again later",
          }),
          "error"
        );
        console.log(e);
      });
      setTitle("");
      setDescription("");
      
      if(response) {
        if (response.status !== 201) {
          if (response.status === 401) {
            throw Object.assign(new Error(), {
              code: 401,
              message: "Unauthorized",
            });
          } else {
            throw Object.assign(new Error(), {
              code: response.status,
              message:
                "Unexpected error, please try again or contact an administrator",
            });
          }
        }else {
          setNewsId(response.data.data[0].id);
          Swal.fire(
            intl.formatMessage({
              id: "news.swal.success",
              defaultMessage: "News added successfully!",
            }),
            "",
            "success"
          );

        }
      }
    } catch (error) {
      console.log(error)
      throw error;
    }
  };

  useEffect(() => {
    let permissions = user.permissions.userPermissions;
    let redirect = true;
    for (const permission of permissions) {
      if (permission.permission.name === 'create-global-news' || permission.permission.name === 'create-local-news' +
        '') {
        redirect = false;
      }
    }
    if (redirect) {
      navigate("/")
    }
  }, [])

  const upload = (e) => {
    setImage(e);
  };

  return (
    <>
      <div className={css.container}>
        <div className={css.top}>
          <Breadcrumb className={css.breadCrumb}>
            <Breadcrumb.Item>
              <div className={css.global}>
                <Link
                  className={css.articleReadMore}
                  to={"/news/region/global"}
                >
                  {region[0].toUpperCase() + region.substring(1)} news
                </Link>
              </div>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <input onChange={(e) => setTitle(e.target.value)}></input>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className={css.bottom}>
          <div className={css.section1}>
            <div className={css.articlePicture}>
              <figure>
                <div className={css2.input}>

                  <div className="element">
                    {imagePreview != null ? <img src ={imagePreview} style={ {marginTop:'-70px', height:'150px', width:'120px'}}/>
                      : null}

                    {imagePreview != null ? <EditComponent style={{position:'sticky', zIndex:'5', marginTop:'-80px',marginRight:'45px'}}/>
                      : <EditComponent style={{position:'sticky', zIndex:'5'}}/>}
                  </div>

                  <input
                    style={{height:'20%'}}
                    type="file"
                    className={css2.inputfiles}
                    onInput={(e) => {
                      setFile(e.target.files[0])
                    }}
                    onChange={e=>{
                      upload(e)
                    }}
                  ></input>
                </div>
              </figure>
            </div>
          </div>
          <div className={css.section2}>
            <div className={css.postedBy}>
              Creator: {authorName} &nbsp;
              <svg
                className={css.profilePicture}
                alt="profilePic"
                src={userPhoto}
                style={{ backgroundImage: `url(${userPhoto})` }}
              ></svg>
            </div>
            <div className={css.postedOn}>
              {moment(datePublished).format("MMMM DD Y")}
            </div>

            <div className={css.articleText}>
              <textarea
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>
            <FilesDropzone id={newsId} save={newsId} region={region}/>
            <div className={css2.buttons}>
              <ActionButton
                secondary="tertiary"
                type="submit"
                onClick={() => postNews()}
              >
                Create
              </ActionButton>
              <ActionButton
                secondary="tertiary"
                type="submit"
                onClick={() => {
                  navigate(`/news/region/${region}`, { replace: true });
                }}
              >
                Cancel
              </ActionButton>
            </div>
          </div>
        </div>
      </div>
      <div className={css.version}>
        <p className={css.versionText}>{currentVersion["Current version"]}</p>
      </div>
    </>
  );
};

export default AddNews;
