import React, {useEffect} from "react";
import scss from "../../Core/FirstTutorial/FirstTutorial.module.scss";
import {Link} from "react-router-dom";

/**
 * @module
 * @description This function creates a pop-up to show terms and conditions
 * @param {object} props This object contains the array with all the steps of a specific
 * tutorial , and also the id of the tutorial.
 * @return {element} Return the pop-up itself.
 */
const TermsAndConditions = ({ termsAndConditionsText, close }) => {

  const terms = termsAndConditionsText.text;

  return (
        <div className={scss.cover}>
          <div
            className={`${scss.guideInformation} ${
              scss['center']
            }`}
          >
            <h4 className={scss.title}>MyBTS Terms and Conditions</h4>
            <p className={scss.description} style={{fontSize:'1rem'}}>{terms}</p>
            <br/>
            <br/>
            <div id={scss.btnContainer}>
              <button
                className={scss.continueButton}
                onClick={close}
              >
                Accept
              </button>
            </div>
          </div>
        </div>
  );
};

export default TermsAndConditions;
