import axios from 'axios';
import PropTypes from 'prop-types';
import { environment } from '../../../config/environment';

/**
 * @module
 * @description This service calls the API in order to a motivational phrase from the BE.
 * @param {Object} context: user's context.
 * @returns {Object} Full list of motivational phrases.
 */

async function getMotivationalPhrase(context) {
  try {
    const response = await axios.get(
      `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module0/motivationalPhrase/`,
      {
        headers: {
          Authorization: `${context.user.token}`,
        },
      }
    );

    return response;
  } catch (error) {
    throw error.response || error.toJSON() || error;
  }
}

getMotivationalPhrase.propTypes = {
  context: PropTypes.object.isRequired,
};

export default getMotivationalPhrase;
