import {
  ArchivedIcon,
  CheckedIcon,
  DangerFlagIcon,
  OutdatedIcon,
  WarningIcon,
} from "../components/Icons";

/**
 * Get the icon status by a status id
 * @param  {Number} statusId Status id
 */
export const getStatusIcon = (statusId) => {
  const iconMatrix = {
    3: <WarningIcon />,
    2: <DangerFlagIcon />,
    1: <CheckedIcon />,
    4: <OutdatedIcon />,
    5: <ArchivedIcon />,
  };
  // const iconMatrix = [
  //     {
  //       id:3,
  //       icon: <WarningIcon />,
  //       title: "Warning",
  //     },
  //     {
  //       id:2,
  //       icon: <DangerFlagIcon />,
  //       title:"Danger",
  //     },
  //     {
  //       id:1,
  //       icon: <CheckedIcon />,
  //       title:"Check",
  //     },
  //     {
  //       id:4,
  //       icon: <OutdatedIcon />,
  //       title:"out",
  //     },
  //     {
  //       id:5,
  //       icon: <ArchivedIcon />,
  //       title:"Archive",
  //     },
  //   ]

  return iconMatrix[statusId] || <ArchivedIcon />;
};
