import React from "react";
/**
 * @description Function to create the log out icon as a JSX component
 * @returns {JSX} LogOutIcon
 */
function LogOutIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.5"
      height="13"
      fill="currentcolor"
      viewBox="1.8 0 13 16"
    >
      <path
        //fill=""
        d="M6.953.453a.453.453 0 10-.907 0v4.233a.453.453 0 00.907 0V.453z"
      ></path>
      <path
        //fill="#9C9C9C"
        d="M3.144 2.025A.453.453 0 102.6 1.3 6.5 6.5 0 1013 6.5c0-2.111-1.047-4.013-2.597-5.197a.454.454 0 00-.55.72c1.34 1.025 2.24 2.669 2.24 4.477a5.593 5.593 0 11-8.949-4.475z"
      ></path>
    </svg>
  );
}

export default LogOutIcon;