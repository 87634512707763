import {BsFillTrashFill} from "react-icons/bs";
import ReactTooltip from "react-tooltip";
import React from "react";
import {useIntl} from "react-intl";


export default function Badge({region, deleteBadge}){

  const intl = useIntl();
  return (
    <div style={{'display':'flex', 'marginRight':'20px', 'width':'127px'}}>
      <span data-tip={region} data-for='badge' className="badge badge-success" style={{'width':'120px', 'paddingLeft':'5px', 'paddingRight':'5px'}}>{region}</span>
      <button onClick={()=>deleteBadge(region)} className="trash-icon" data-tip={intl.formatMessage({ id: "delete.task", defaultMessage: "Delete" })} data-for="deleteTask">
        <BsFillTrashFill />
      </button>
      <ReactTooltip id="badge" place="bottom" backgroundColor="#3FAA58" />
      <ReactTooltip id="deleteTask" place="bottom" backgroundColor="#3FAA58" />
    </div>
  );
}