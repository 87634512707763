import React, { useLayoutEffect, useState } from 'react';
import scss from './Motivational.module.scss';
import { useAuthContext } from '../../../context/AuthContext/AuthContext';
import getMotivationalPhrase from '../services/motivationalPhrase';
import { useIntl } from 'react-intl';

/**
 * @module
 *@descriptiondescription defining the Motivation phrase component , the component will save on local storage
 *the response of the API CALL , and the component only make calls to the API if
 *the localstorage its null.
 *@returns {JSX} Motivational
 */
const Motivational = () => {
  const [phrase, setPhrase] = useState('');
  const [author, setAuthor] = useState('');
  const [err, setErr] = useState(false);
  const context = useAuthContext();
  const intl = useIntl();
  useLayoutEffect(() => {
    let isMounted = true;

    (async function () {
      try {
        if (context.user) {
          const motivationalPhrases = await getMotivationalPhrase(context);

          if (isMounted) {
            setPhrase(motivationalPhrases.data.data[0].q);
            setAuthor(motivationalPhrases.data.data[0].a);
          }
        }
      } catch (error) {
        setErr(error?.message);
      }
    })();

    return () => (isMounted = false);
  }, [context]);

  return (
    <div className={scss.containerMotivational}>
      <div className={scss.motivational}>
        "{phrase || 'We bring your vision to life'}"
        <br />
        <div className={scss.author}>{author ? `By ${author}` : ''}</div>
      </div>
    </div>
  );
};

export default Motivational;
