import React from "react";
/**
 * @description Function to create the Profile icon as a JSX component
 * @returns {JSX} ProfileIcon
 */
function ProfileIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="15"
      fill="currentcolor"
      viewBox="3.5 0 7 15"
    >
      <path
        //fill=""
        fillRule="evenodd"
        d="M5.167 0a3.167 3.167 0 100 6.333 3.167 3.167 0 000-6.333zM3 3.167a2.167 2.167 0 114.333 0 2.167 2.167 0 01-4.333 0zm.167 4.166a3.167 3.167 0 000 6.334h4a3.167 3.167 0 000-6.334h-4zM1 10.5c0-1.197.97-2.167 2.167-2.167h4a2.167 2.167 0 010 4.334h-4A2.167 2.167 0 011 10.5z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default ProfileIcon;