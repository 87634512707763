import scss from "./Apps.module.scss";
import React, { useState, useEffect } from "react";
import Dropdown from "../../shared/Dropdown";
import Link from "../../shared/Link";
import {
  AppsIcon,
  CalendarIcon,
  MailIcon,
  PlatformIcon,
  RecruitmentIcon,
  WikiIcon,
} from "../../../Icons";

import ToggleButton from "react-bootstrap/ToggleButton";
import ReactTooltip from "react-tooltip";
import Library from "../../../Icons/Library";
import {FormattedMessage, useIntl} from "react-intl";
import { environment } from "../../../config/environment";

/**
 * @module
 * @description This function creates a component called "App", this component is used inside the top toolbar, and the functionality of
 * the component itself is to display a menu with different links to different pages that can be useful for the user..."BTSApps"
 * @returns {JSX} App
 */
const Apps = () => {
  let intl = useIntl();
  const [showDropdown, setShowDropdown] = useState(false);
  const [outsideClick, setOutsideClick] = useState(false);
  // If is checked, the menu Apps is shown
  const handleChecked = (event) => {
    setShowDropdown(event.currentTarget.checked);
  };



  // Close menu Apps
  const hideDropdown = () => {
    setShowDropdown(false);
    setOutsideClick(true);
  };

  useEffect(() => {
    const myTimer = setTimeout(() => {
      setOutsideClick(false);
    }, 100);
    return () => {
      clearTimeout(myTimer);
    };
  }, [outsideClick]);

  return (
    <div className={scss.appsContainer}>
      <ToggleButton
        id="toggle-check"
        type="checkbox"
        checked={showDropdown}
        value="1"
        className={scss.appsButton}
        onChange={!outsideClick ? handleChecked : null}
      >
        <span className="appsButton" data-tip={intl.formatMessage({id : "topbar.apps", defaultMessage: "Apps"})} data-for="apps">
          <AppsIcon color={showDropdown ? "#5AC775" : ""} />
        </span>
        <ReactTooltip id="apps" place="bottom" backgroundColor="#3FAA58" />
      </ToggleButton>
      {showDropdown && (
        <Dropdown hideDropdown={hideDropdown} showDropdown={showDropdown}>
          <Dropdown.Item>
            <Link to={environment.LEGACY_FE}>
               <PlatformIcon /> <h7 className={scss.platformIcon}>{/*<FormattedMessage id="topbar.apps.mybts.legacy" defaultMessage="MyBTS Legacy"/>*/}MyBTS Legacy</h7>
            </Link>
          </Dropdown.Item>
          <Dropdown.Item>
            <Link to="https://mail.google.com">
              <MailIcon /> <h7 className={scss.mailIcon}>{/*<FormattedMessage id="topbar.apps.mail" defaultMessage="Mail" />*/}Mail</h7>
            </Link>
          </Dropdown.Item>
          <Dropdown.Item>
            <Link to="https://calendar.google.com/">
              <CalendarIcon /> <h7 className={scss.calendarIcon}>{/*<FormattedMessage id="topbar.apps.calendar" defaultMessage="Calendar" />*/}Calendar</h7>
            </Link>
          </Dropdown.Item>
          <Dropdown.Item>
            <Link to="https://bluetrailsoft.atlassian.net/wiki/spaces/BTS/overview">
              <WikiIcon /> <h7 className={scss.wikiIcon}>{/*<FormattedMessage id="topbar.apps.company.wiki" defaultMessage="Company wiki" />*/}Company wiki</h7>
            </Link>
          </Dropdown.Item>

          <Dropdown.Item>
            <Link to="https://bluetrailsoftware.atlassian.net/">
              <RecruitmentIcon /> <h7 className={scss.recruitmentIcon}>{/*<FormattedMessage id="topbar.apps.recruiment" defaultMessage="Recruitment" />*/}Recruitment</h7>
            </Link>
          </Dropdown.Item>
          <Dropdown.Item>
            <Link to="https://bluetrailsoft.atlassian.net/wiki/spaces/BTS/pages/673841234/Material+of+Interest+-+by+Topic">
              <Library /> <h7 className={scss.libraryIcon}>{/*<FormattedMessage id="topbar.apps.knowledge.base" defaultMessage="Knowledge Base" />*/}Knowledge Base</h7>
            </Link>
          </Dropdown.Item>
          <hr/>
          <Dropdown.Item>
            <Link to="https://bluetrailsoft.atlassian.net/wiki/spaces/BTS/pages/208339/Getting+Started+in+BTS">
              <h7 className={scss.newBTS}>{/*<FormattedMessage id="topbar.apps.new.in.bts" defaultMessage="New to Blue Trail?" />*/}New to Blue Trail?</h7>
            </Link>
          </Dropdown.Item>
        </Dropdown>
      )}
    </div>
  );
};

export default Apps;
