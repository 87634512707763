import axios from "axios";
import { environment } from "../../../config/environment";

const postRequestDaysOff = async (userId, userToken, startDate, endDate, dayOffType, totalWorkDays, notes) => {
  const data = {
    'startDate': startDate,
    'endDate': endDate,
    'dayOffType': dayOffType,
    'totalWorkDays': totalWorkDays,
    'notes': notes 
  };
  
  let daysOffRequest = await axios
    .post(`${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/users/${userId}/dayOff`, data, {
      headers: {
        authorization: userToken,
      },  
    })
    .then(result => result)
    .catch(error => {throw error.response || error.toJSON() || error});
  return daysOffRequest.data;
};

export default postRequestDaysOff;
