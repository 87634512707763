import axios from "axios";
import { environment } from "../../../config/environment";
import postUserTags from "./postUserTags";
const moment = require("moment");

const userTimesheetsService = {
  save: async (userTimesheetsData, userId, token) => {
    try {
      const data = await axios.post(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/users/${userId}/timesheet`,
        userTimesheetsData,
        {
          headers: {
            authorization: token,
          },
        }
      );
      return data;
    } catch (err) {
      throw (
        err.response || err.toJSON() || err
      );
    }
  },
};

const userTimesheetsServiceDataFormat = async (data, userId, token, page) => {
  let tags = undefined;

  if (data.tags?.length) {
    tags = data.tags.join(",");
  };

  if (tags) await postUserTags(userId, token, { tags })
    .then((r) => console.log(r))
    .catch((error) => {
      throw error.response || error.toJSON() || error
    });

  let time = null;
  if (page === "Modal") {
    time = moment.utc(data.totalTime, "HH:mm:ss: A").diff(
      moment.utc().startOf("day"),
      "seconds"
    );
  } else {
    time = parseInt(data.totalTime) / 1000;
  }

  const toBePushedObject = {
    projectId: parseInt(data.project),
    dedicatedHours:
      parseInt(data.workTimeEnd.slice(0, 2)) -
      parseInt(data.workTimeStart.slice(0, 2)),
    task: data.task,
    isHappy: data.feeling === "true" ? true : false,
    note: data.note || "No Comments",
    startHour: data.workTimeStart + ":00",
    endHour: data.workTimeEnd + ":00",
    isFinished: data.isFinished === "false" ? false : true,
    dedicatedTime: parseInt(time),
  }

  if (tags) toBePushedObject.tags = tags;

  return userTimesheetsService.save({date: moment(data.date).format("YYYY-MM-DD"), descriptions: [toBePushedObject]}, userId, token);
};

export { userTimesheetsServiceDataFormat };
