function MefFaceIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
      >
        <path
          fill="#F9A526"
          d="M7.5 6.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm4.5 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm-6 4.688a.562.562 0 100 1.124h6a.562.562 0 100-1.124H6z"
        ></path>
        <path
          fill="#F9A526"
          fillRule="evenodd"
          d="M9 .938a8.062 8.062 0 100 16.124A8.062 8.062 0 009 .937zM2.062 9a6.937 6.937 0 1113.875 0A6.937 6.937 0 012.063 9z"
          clipRule="evenodd"
        ></path>
      </svg>
    );
  }
  
  export default MefFaceIcon;
  