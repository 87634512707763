import axios from "axios";
import { environment } from "../../../config/environment";
import { getLocationsAndRegions } from "../../MyCalendar/services/getLocationsAndRegions";

const updateNews = async (
  updateText,
  updateTitle,
  context,
  id,
  type,
  photo,
  userId,
  token
) => {
  let regions = "";
  let position, locationCity;
  let locations = await getLocationsAndRegions();

  for (let x in locations) {
    if (locations[x].name === context) {
      regions = locations[x].id;
      position = x;
    }
  }

  for (let y in locations[position].locations) {
    if (locations[position].locations[y].name === context) {
      locationCity = locations[position].locations[y].id;
    }
  }

  let aux = true;
  if (type === null) {
    aux = false;
  }

  const body = {
    company_region_id: regions,
    title: updateTitle,
    short_description: updateText.length > 300? updateText.substring(0, 296) : updateText,
    description: updateText,
    portrait: photo,
    imgs: {
      img: photo,
    },
    is_global: aux,
    urls: null,
    updatedBy_id: userId,
  };

  try {
    const response = await axios.put(
      `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module0/news/article/${id}`,
      body,
      {
        headers: {
          authorization: token,
        },
      }
    );
    return response;
  } catch (err) {
    throw err.response || err.toJSON() || err;
  }
};

export default updateNews;
