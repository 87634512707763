import "./Tags.scss";
import { useState, useEffect } from "react";

function TagsInput(props) {
  const [tags, setTags] = useState([]);
  const [tagsInvalidNumber, setTagsInvalidNumber] = useState(false);
  const [userTags, setUserTags] = useState([]);
  const { allTags, setAllTags } = props;

  useEffect(() => {
    let countTags = userTags.length;
    if (countTags > 9) {
      setTagsInvalidNumber(true);
    } else {
      setTagsInvalidNumber(false);
    }
  }, [tags, setTags]);

  useEffect(() => {
    setUserTags(userTags);
    setAllTags(userTags);
  }, []);

  function handleKeyDown(e) {
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim()) return;
    setTags([...tags, value]);
    e.target.value = "";
    e.preventDefault();
  }

  function removeTag(index) {
    setAllTags([...allTags.filter((tag) => allTags.indexOf(tag) !== index)]);
    setUserTags([...userTags.filter((tag) => userTags.indexOf(tag) !== index)]);
    setTags([...tags.filter((tag) => tags.indexOf(tag) !== index)]);
  }

  tags.forEach((item) => {
    if (!userTags.includes(item) && item.length > 1 && item.length < 11) {
      userTags.push(item);
    } else if (!allTags.includes(item) && item.length > 1 && item.length < 11) {
      allTags.push(item);
    } else {
      tags.pop();
    }
  });

  return (
    <>
      <div className="tags-input-container">
        {allTags.map((tag, index) => (
          <div className="tag-item" key={index}>
            <span className="text">{tag}</span>
            <span className="close" onClick={() => removeTag(index)}>
              &times;
            </span>
          </div>
        ))}
        {!tagsInvalidNumber ? (
          <input
            type="text"
            className="tag-input"
            onKeyDown={handleKeyDown}
            placeholder
            minLength={"2"}
            maxLength={"10"}
          />
        ) : (
          <div></div>
        )}
        {tagsInvalidNumber ? (
          <>
            <input
              type="text"
              className="tag-input"
              onKeyDown={handleKeyDown}
              placeholder={""}
              disabled
            />
          </>
        ) : (
          <div></div>
        )}
      </div>
    </>
  );
}

export default TagsInput;
