import React from "react";
import Select from "react-select";


export default function SelectRole({roles, selectedPosition, actualRole}){
  const handleChangePosition = (role)=>{
    selectedPosition(role)
  }
  return (
    <div className="projects-list" style={{'width':'72%'}}>
      <div className="select-role-container">
        <div className="select-role">
          <h3 className="title-container actual-role">
            The actual role is
          </h3>
          <div className="employee-list select-role" style={{'width':'250px', 'marginBottom': '30px'}}>

            <Select
              id="select"
              value={actualRole}
              options={roles}
              getOptionLabel={(roles) => roles.name}
              getOptionValue={(roles) => roles.id}
              onChange={handleChangePosition}
              placeholder={"Search role"}
              maxMenuHeight={190}
              width={253}
              styles={{
                control:(baseStyles, state)=>({
                  ...baseStyles,
                  marginTop:'15px',
                  minWidth: '253px',
                }),
                menu:(baseStyles, )=>({
                  ...baseStyles,
                  minWidth: '253px'
                })
              }}
            />
          </div>
          <div className="info">
            &#8505; Changing the role, the default permissions for each role will be loaded. All user-permissions will be lost.
          </div>
        </div>
        <div className="position">
          <div className="project-button">

          </div>
        </div>
      </div>
    </div>
  );
}