import scss from "./Logo.module.scss";
import BTSLogo from "./BTSLogo";
import BTSLogoText from "./BTSLogoText";
import { Link } from "react-router-dom";

/**
 *@module
 *@description This function creates the BlueTrail Logo as a react component. Rendering only the logo for
 *mobile resolutions or With the Blue Trail Software text for Laptop/Desktop resolutions
 * @returns {JSX} Logo
 */
const Logo = () => {
  return (
    <Link className={scss.LogoContainer} to={`/`}>
      <div className={scss.mobileLogo}>
        <BTSLogo />
      </div>
      <div className={scss.textLogo}>
        <BTSLogoText />
      </div>
    </Link>
  );
};

export default Logo;
