import React, { useState, useEffect } from "react";
import { BsFillPlayCircleFill } from "react-icons/bs";
import DayCard from "./DayCard";
import { useAuthContext } from "../../../context/AuthContext";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../SchedulerLayout/scheduler.scss";
import PropTypes from "prop-types";
import { useSchedulerContext } from "../../../context/SchedulerContext";
import { DangerTriangleIcon } from "../../Icons";
import Swal from "sweetalert2";

// language switcher feature
import { FormattedMessage, useIntl } from "react-intl";
import { postTicklerTask } from "../services/userTicklerServices";
import { Link } from "react-router-dom";
import moment from "moment";
import {projectService} from "../services/get.user.projects";

/**
 * @module
 * @description this component displays the form to add a new timesheet
 * and also shows the current five timesheets the user has been working on
 * @param {Object} timesheets
 * @param {Function} changeTickler
 * @param {Function} handleStartTimer
 * @returns {JSX} MyTickler
 */

const MyTickler = ({
  timesheets,
  changeTickler,
  handleStartTimer,
  changed,
  handleStartHourChange: setStartHour,
  projects,
  currentDate,
  setLocalStorageChanged,
  setShowDropdown,
  projectsDB,
}) => {
  const intl = useIntl();
  const moment = require("moment");
  let quantity = 0;

  const [hours, setHours] = useState([]);
  const auth = useAuthContext();
  const schedulerContext = useSchedulerContext();

  // validation schema
  const validationSchema = Yup.object().shape({
    task: Yup.string().required("Required field"),
    workTimeStart: Yup.string().required("Required field"),
    project: Yup.string().required("Required field"),
  });

  // Array hour quarters
  useEffect(() => {
    let actualHour = parseInt(moment().format("HH"));
    let actualMinutes = parseInt(moment().format("mm"));
    // array hour quarters [23:45, 23:30, 23:15, ... 00:00]
    // Add the actual hour
    hours.push(
      actualHour +
        ":" +
        (actualMinutes < 10 ? "0" + actualMinutes : actualMinutes)
    );

    for (var i = actualHour; i >= 0; i--) {
      for (var j = 3; j >= 0; j--) {
        hours.push(i + ":" + (j === 0 ? "00" : 15 * j)); // Put loop counter into array with "00" next to it
        if (i === actualHour && actualMinutes < 15 * j) {
          hours.pop();
        }
      }
    }
  }, [hours]);

  const projectsOptions = projects.map((project, key) => (
    <option value={project.id + "," + project.name} key={project.id}>
      {project.name}
    </option>
  ));

  const saveTickler = async (values, { resetForm }) => {
    setStartHour(values.workTimeStart);

    let startHour = values.workTimeStart;

    // Checking start hours like 9:00, 7:00 and padding a zero in front.
    // Numbers like "15:00" are left the same.
    if (
      parseInt(startHour[0]) >= 0 &&
      parseInt(startHour[0]) <= 9 &&
      isNaN(parseInt(startHour[1]))
    )
      startHour = "0" + startHour;

    let data = {
      date: currentDate,
      descriptions: [
        {
          projectId: parseInt(values.project.split(",")[0]),
          task: values.task,
          isHappy: true,
          startHour: startHour + ":00",
        },
      ],
    };
    try {
      const response = await postTicklerTask(
        data,
        auth.user.id,
        auth.user.token
      );
      if (response.status === 201) {
        changed(schedulerContext.taskCounter + 1);
        localStorage.setItem(
          "tickler",
          JSON.stringify({
            id: response.data.data.timesheet.descriptions[0].id,
            task: response.data.data.timesheet.descriptions[0].task,
            startHour: response.data.data.timesheet.descriptions[0].startHour,
            projectId: response.data.data.timesheet.descriptions[0].projectId,
            projectName:
              response.data.data.timesheet.descriptions[0].projectName,
            tags: response.data.data.timesheet.descriptions[0]?.tags,
          })
        );
        Swal.fire({
          toast: true,
          icon: "success",
          title: intl.formatMessage({
            id: "tickler.task.start",
            defaultMessage: "New task started",
          }),
          timer: 2000,
          position: "top",
          showConfirmButton: false,
        });
        resetForm();
        setLocalStorageChanged(true);
        setShowDropdown(false);
        handleStartTimer();
        changeTickler();
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        toast: true,
        icon: "error",
        title: `${intl.formatMessage({
          id: "tickler.task.error",
          defaultMessage: "Something happened: ",
        })}${error?.data.message}`,
        timer: 2000,
        position: "top",
        showConfirmButton: false,
      });
    }
  };

  const handleUpdate = () => {
    schedulerContext.setTaskCounter(schedulerContext.taskCounter + 1);
  };

  // Create alerts
  let redAlerts = schedulerContext.alerts.filter(
    (event) => event.title === "⚠"
  );
  let yellowAlerts = schedulerContext.alerts.filter(
    (event) => event.title !== "⚠"
  );

  const timesheetCard = timesheets ? (
    timesheets.map((timesheet, key) => {
      if (
        timesheet.descriptions.length === 1 &&
        timesheet.descriptions[0].isFinished !== true
      ) {
        return null;
      } else {
        if (
          quantity < 5 &&
          moment.utc(timesheet.date).format("YYYY-MM-DD") <=
            moment.utc().format("YYYY-MM-DD")
        ) {
          quantity += 1;
          return (
            <DayCard
              item={timesheet}
              key={timesheet.id}
              yellowAlerts={yellowAlerts}
              refreshTask={function () {
                handleUpdate();
              }}
              isTopTickler={true}
            />
          );
        }
      }
    })
  ) : (
    <p>
      {/*<FormattedMessage id="no_timesheets_found" />*/}
      No timesheets found
    </p>
  );

  function validateProject(value) {
    let error;
    const currentDate = new Date()
    const projId = parseInt(value.split(",")[0]);

    for (let i = 0; i < projectsDB.length; i++) {
      if(projId === projectsDB[i].projectId){
        const startDate = (new Date(moment.utc(projectsDB[i].startDate).format("YYYY-MM-DD"+"T00:00:00")));
        if(currentDate < new Date(moment.utc(startDate).format("YYYY-MM-DD"))) {
          console.log('error')
          error = "Invalid date";
        } else {
          error = null;
        }
        return error;
      }
    }
  }

  return (
    <>
      <h3 className="top-tickler-title">
        {/*<FormattedMessage
          id="topbar.tickler.my.task"
          defaultMessage="My Task"
        />*/}
        My Task
      </h3>
      <Formik
        initialValues={{
          task: "",
          workTimeStart: "",
          project: "",
        }}
        validationSchema={validationSchema}
        onSubmit={saveTickler}
      >
        {({ errors, touched, isValidating }) => (
          <Form className="top-tickler-form">
            <Row>
              <Col md={12}>
                <Field
                  type="text"
                  name="task"
                  placeholder={intl.formatMessage({
                    id: "topbar.tickler.what.are.working",
                    defaultMessage: "What are you working on?",
                  })}
                  className={`form-control ${errors.task ? "is-invalid" : ""}`}
                />

                <ErrorMessage
                  component="div"
                  name="task"
                  className="invalid-feedback"
                />
              </Col>
            </Row>

            <Row>
              <Col md={5}>
                <Field
                  name="workTimeStart"
                  as="select"
                  className={`form-select top-tickler-input-2 ${
                    errors.workTimeStart ? "is-invalid" : ""
                  }`}
                  // onChange={(e)=> setStartHour(e.target.value)}
                >
                  <option>
                    {intl.formatMessage({
                      id: "topbar.tickler.when.start",
                      defaultMessage: "When you have started?",
                    })}
                  </option>
                  {hours.map((hour, key) => (
                    <>
                      {key === 0 ? (
                        <option value={hour} key={key} selected>
                          {intl.formatMessage({
                            id: "topbar.tickler.start.now",
                            defaultMessage: "Start now",
                          })}
                        </option>
                      ) : (
                        <option value={hour} key={key}>
                          {hour}
                        </option>
                      )}
                    </>
                  ))}
                </Field>

                <ErrorMessage
                  component="div"
                  name="workTimeStart"
                  className="invalid-feedback"
                />
              </Col>

              <Col md={5}>
                <Field
                  name="project"
                  as="select"
                  validate={validateProject}
                  className={`form-select top-tickler-input-3 ${
                    errors.project ? "is-invalid" : ""
                  }`}
                >
                  <option>
                    {intl.formatMessage({
                      id: "topbar.tickler.wich.project",
                      defaultMessage: "In which project?",
                    })}
                  </option>
                  {projectsOptions}
                </Field>
                <ErrorMessage
                  component="div"
                  name="workTimeStart"
                  className="invalid-feedback"
                />
                {touched.project && errors.project? (
                  <small className="validity-tickler">
                    {/*<FormattedMessage
                      id="topbar.tickler.date.invalid"
                      defaultMessage="Current date is before allocation date"
                    />*/}
                    Current date is before allocation date
                  </small>) : null}
              </Col>

              <Col md={2}>
                <Button className="startButton" type="submit">
                  <BsFillPlayCircleFill />
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>

      {/* show timesheets*/}

      <div className="top-tickler-timesheets">
        <div className="red-alerts">
          {redAlerts ? (
            redAlerts.map((event) => {
              let elementDate = moment.utc(event.start).format("YYYY-MM-DD");
              return (
                <div className="day-card red-card">
                  <div className="day-header">
                    <div className="day-title">
                      {moment.utc(event.start).format("ddd, DD")}
                    </div>
                  </div>
                  <div>
                    <span
                      style={{
                        color: "red",
                        fontWeight: 900,
                        fontSize: "16px",
                      }}
                    >
                      &#124;
                    </span>
                    &nbsp;
                    <span style={{ fontSize: "16px" }}>
                      <DangerTriangleIcon fillColor="#ff0101" />{" "}
                    </span>
                    {/*<FormattedMessage
                      id="alert.noreport"
                      defaultMessage="Oops... you have not reported hours for this day, please fix ASAP."
                    />*/}
                    Oops... you have not reported hours for this day, please fix ASAP.
                    &nbsp;
                    {/* Do not show button to add hours on top tickler */}
                  </div>
                </div>
              );
            })
          ) : (
            <h3>No alerts</h3>
          )}
        </div>
        {timesheetCard}
        <div id="button_loger">
          <a href="/scheduler" id="scheduler_loger">
            {/*<FormattedMessage
              id="topbar.tickler.scheduler.link"
              defaultMessage="Full log in scheduler"
            />*/}
            Full log in scheduler
          </a>
        </div>
      </div>
    </>
  );
};

MyTickler.propTypes = {
  task: PropTypes.string,
  workTimeStart: PropTypes.string,
  project: PropTypes.string,
};

export default MyTickler;
