import HappeningNext from "./ProjectsHappeningNext";
import "./WhatIsNextProjects.scss";
import { FormattedMessage } from "react-intl";

const WhatIsNextProjects = (props) => {
  return (
    <>
      <div className="whatsNextContainer">
        <h2 className="whatsNextTitle">
          {/*<FormattedMessage id ="project.by.id.happening.next" defaultMessage = "Happening next..."/>*/}
          Happening next...
        </h2>
        <div className="whatsNextElements">
          <HappeningNext region={props.region} />
        </div>
      </div>
    </>
  );
};

export default WhatIsNextProjects;
