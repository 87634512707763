import "./RoleAccessControl.scss";
import React, {useEffect, useState} from "react";
import SearchEmployee from "./SearchEmployee";
import SelectRole from "./SelectRole";
import SelectAllowedLocation from "./SelectAllowedLocation";
import getRoles from "../../services/RoleAccessControl/getRoles";
import {useAuthContext} from "../../context";
import getCompanyInfo from "../Core/services/retrieveCompanyInfo";
import {userAuthServices} from "../../services/Login/userAuthServices";
import {getAllLocationsAndRegions} from "../../services/RoleAccessControl/getAllLocationAndRegions";
import Permissions from "./Permissions";
import {roleService} from "../../services/RoleAccessControl/roleService";
import {getAllLocation} from "../../services/RoleAccessControl/getAllLocation";
import {getLocationsAndRegions} from "../../services/RoleAccessControl/getUserLocation";
import scss from "./RoleAccessControl.scss";
import {getLocations} from "../MyCalendar/services/getLocationsAndRegions";

/**
 * @module
 * @description This function creates the structure and the logic of Project List Table
 * @param {Function} projects get id project
 * @param {Function} setFilterArchived  get set Filter Archived
 * @param {Function} handleFilterSort  get handle Filter Sort
 * @param {Function} filterSort  get filter Sort
 * @param {Function} setFilterSearch  get set Filter Search
 * @param {Function} fetchingData  get fetching Data
 * @returns {JSX} Project List Table
 */

const RoleAccessControl = ({userList }) => {

  const [aux, setAux] = useState(0);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectLocations, setSelectLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [countriesPermissions, setCountryPermissions] = useState();
  const [selectedPosition, setSelectedPosition] = useState();
  const [locationsBadge, setlocationsBadge] = useState([]);
  const auth = useAuthContext();
  const [defaultLocation, setDefaultLocation] = useState();
  const [employees, setEmployees] = useState([]);

  useEffect(()=>{
    let tempEmployees = userList.filter(item => {
      const result = (item.endDate === null || item.endDate === undefined )? true: false;
      return result;
    });
    setEmployees(tempEmployees);
  }, [userList])

  useEffect(async ()=>{
    const userInfo = await userAuthServices.userInfo(
      selectedEmployee.id, auth.user.token
    );

    if(userInfo.data.asignedLocations.length>0){
      let StrDefaultLocation
      StrDefaultLocation = await  userInfo.data.asignedLocations[0];
      let allLocations = await getAllLocation(auth.user.token);
      allLocations = allLocations.filter(item => StrDefaultLocation === item.name)
      allLocations = allLocations.map(item => {return{
        id:item.id,
        name:item.name
      }})
      setDefaultLocation(allLocations[0])
    }
    const name = userInfo.data.position;

    const companyPositions = await getCompanyInfo(
      auth,
      "positions"
    );

    const position = companyPositions.filter(e => {
      if(e.name === name) return e;
    });

    let selectedRole = await roleService.getRoleByName(auth.user.token,position[0].name)

    const regions = await getAllLocationsAndRegions(auth.user.token)

    let userLocations= await getLocationsAndRegions(auth.user.token, selectedEmployee.id);

    const employeesList = userList.map(e=>{
      return {
        "id":e.id,
        "name":e.firstName+' '+e.lastName
      }
    });

    const countryPermissionsArr = userList.map(el =>{

      return{
        "employee": {
          "id":el.id,
          "name":el.firstName+' '+el.lastName,
          'position': [userInfo.data.position]
        },
        "permissions": [
          {
            "country": {
              "id": null,
              "name": null
            },
            "permission": [
              {
                "title": null,
                "items": [
                  {
                    "name": null,
                    "value": null
                  }
                ]
              }
            ]
          }
        ]
      }
    })


    let countryPermissionByselectedEmployee;

    countryPermissionsArr.forEach(ele=>{

      if(parseInt(ele.employee.id) ===  selectedEmployee.id){
          countryPermissionByselectedEmployee = ele;
        }
    });
    const loc = userInfo.data.asignedLocations.map(e =>{
      return {
        "country": {
          "id": 1,
          "name": e,
        }
      }
    });

    const reg = userInfo.data.asignedRegions.map(e =>{
      return {
        "country": {
          "id": 1,
          "name": e,
        },
        "permission": [
          {
            "title": null,
            "items": [
              {
                "name": null,
                "value": null
              }
            ]
          }
        ]
      }
    });

    countryPermissionByselectedEmployee.permissions=[...loc, ...reg]
    if(userInfo.data.asignedLocations.length===0){
      countryPermissionByselectedEmployee.permissions.push(
        {"country":{
          "id": null,
          "name": userInfo.data.location
        }})
    }
    if(userInfo.data.asignedRegions.length===0){
      countryPermissionByselectedEmployee.permissions.push({"country":{
          "id": null,
          "name": userInfo.data.region
        }})
    }

    setCountryPermissions(countryPermissionByselectedEmployee);
    let allLocations = await getAllLocation(auth.user.token);
    let StrDefaultLocation
    if(userInfo.data.asignedLocations.length>0){
      StrDefaultLocation = await  userInfo.data.asignedLocations[0];
    }
    allLocations = allLocations.filter(item => StrDefaultLocation === item.name)
    allLocations = allLocations.map(item => {return{
      id:item.id,
      name:item.name
    }})
    setDefaultLocation(allLocations[0])

    setlocationsBadge(userLocations);
    setSelectLocations(regions)
    setRoles(companyPositions);
    setSelectedPosition(selectedRole);
  },[selectedEmployee])

  useEffect(async ()=>{
    const rolesArr = await getRoles();
    setRoles(rolesArr);
  },[])

  const addLocation = async (newLocation)=>{

    if(!locationsBadge.includes(newLocation)){

      const newLocations = [...locationsBadge];
      newLocations.push(newLocation);
      setlocationsBadge([...newLocations]);
      let allLocations = await getAllLocation(auth.user.token);
      const newRe=allLocations.map(item => item.name).includes(newLocation) ? {
        "country": {
          "id": 1,
          "name": newLocation,
        }
      }:{
        "country": {
          "id": 0,
          "name": newLocation,
        }
      }

      const location ={ 'location': selectedLocations};

      let items = countriesPermissions;
      items.permissions.push(newRe);
      setCountryPermissions(items)
    }
  }

  const deleteBadge = (deleteLocationBadge) => {
    const filterBadge = locationsBadge.filter(badge => {
      if(badge.name !== deleteLocationBadge) {
        return true;
      }
    });
    setlocationsBadge(filterBadge);

    let deleteCountry = countriesPermissions;

    deleteCountry.permissions = deleteCountry.permissions.filter(e => {
      if(e.country.name !== deleteLocationBadge){
        return true;
      }
    });

    setCountryPermissions(deleteCountry);
    setAux(aux+1);
  }

  return (
    <>
      <SearchEmployee employees={employees} selectEmployee={setSelectedEmployee} employee={null} />
      <SelectRole roles={roles} selectedPosition={setSelectedPosition} actualRole={selectedPosition}/>
      <SelectAllowedLocation deleteBadge={deleteBadge} selectLocations={selectLocations} addLocations={addLocation} locationsBadge={locationsBadge}/>
      <Permissions countriesPermissions={countriesPermissions} role={selectedPosition} locationsBadge={locationsBadge} selectedEmployee={selectedEmployee} defaultLocation={defaultLocation} />
    </>
  )

};

export default RoleAccessControl;