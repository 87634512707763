import moment from 'moment'

//Function to convert time from my actual time zone to UTC time
const timeToUTC = (time) => {
    let timeInUTC  = new moment(time, "HH:mm:ss").utc().format('HH:mm:ss')
    return timeInUTC;
}

//Function to convert from UTC time to my actual time zone
const timeFromUTCtoMyTimeZone = (UTCtime) => {
    let timeMyTimeZone = new moment.utc(UTCtime, 'HH:mm:ss').local().format('HH:mm:ss')
    return timeMyTimeZone;
}

//Exporting functions
export const hoursConverter = {
    timeToUTC,
    timeFromUTCtoMyTimeZone,
}