/**
 * @description Function to create Roles and permissions icon as a JSX component
 * @returns {JSX} Scheduler
 */
function RolesAndPermissions() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 30 30"
    >
      <g transform="matrix(0.047 0 0 0.05 0 0)">
        <path style={{'stroke': 'none', 'strokeWidth': '1', 'strokeDasharray': 'none', 'strokeLinecap': 'butt', 'strokeDashoffset': '0', 'strokeLinejoin': 'miter', 'strokeMiterlimit': '4', 'fillRule': 'nonzero', 'opacity': '1'}}
          fill="currentColor"
          d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0S96 57.3 96 128s57.3 128 128 128zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c1.8 0 3.5-.2 5.3-.5c-76.3-55.1-99.8-141-103.1-200.2c-16.1-4.8-33.1-7.3-50.7-7.3H178.3zm308.8-78.3l-120 48C358 277.4 352 286.2 352 296c0 63.3 25.9 168.8 134.8 214.2c5.9 2.5 12.6 2.5 18.5 0C614.1 464.8 640 359.3 640 296c0-9.8-6-18.6-15.1-22.3l-120-48c-5.7-2.3-12.1-2.3-17.8 0zM591.4 312c-3.9 50.7-27.2 116.7-95.4 149.7V273.8L591.4 312z"

        ></path>
      </g>
    </svg>
  );
}

export default RolesAndPermissions;