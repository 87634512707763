import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "bootstrap/dist/css/bootstrap.min.css";
import DatePicker, { registerLocale } from "react-datepicker";
import { enUS, es } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
import "../SchedulerLayout/scheduler.scss";
import "../PopOver/popOver.scss";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { default as BootstrapForm } from "react-bootstrap/Form";
import * as Yup from "yup";
import { useAuthContext } from "../../../context/AuthContext";
import postRequestDaysOff from "../services/postRequestDaysOff";
import { useSchedulerContext } from "../../../context/SchedulerContext/SchedulerContext";
import getDaysOffTypes from "../services/getDaysOffTypes";
import CustomDatePicker from "../../../utils/CustomDatePicker";
// language swichter feature
import { FormattedMessage, useIntl } from "react-intl";
import WarningIcon from "../../Icons/WarningIcon";


/**
 * @module
 * @description This component is the days off form
 * @param {string} selectedDate
 * @param {Function} handleClose
 * @returns {JSX} DayOffModal
 */
function DayOffModal({ selectedDate, handleClose, entryType, handleChangeModalType ,permissions}) {
  const auth = useAuthContext();
  const [startDate, setStartDate] = useState(moment(selectedDate).toDate());
  const [endDate, setEndDate] = useState(moment(selectedDate).toDate());
  const [dayInvalid, setDayInvalid] = useState(false);
  const [total, setTotal] = useState(0);
  const [rangeInvalid, setRangeInvalid] = useState(false);
  const [dayOffTypes, setDayOffTypes] = useState(null);
  const schedulerContext = useSchedulerContext();
  const intl = useIntl()
  const daysOff = schedulerContext.userDaysOff;

  /**
   * @function
   * @param {String} dayOffTypeName day off type name coming back from the database.
   * 
   * * i.e. Paid off time, Maternity Leave, Illness, etc.
   *  
   * @returns {String} a formatted string to match en-EN.json or es-ES.json (or others) files from the intl library.
   * 
   * * i.e. paid.off.time, maternity.leave, illness, etc.
   * 
   * If the incoming data is not a string, or an empty string, returns another empty string.
   */
  const formatDayOffTypeName = (dayOffTypeName) => {
    if (dayOffTypeName.length > 0 && typeof(dayOffTypeName) === "string") {
      return dayOffTypeName.toLowerCase().split(" ").join(".");
    }
    else return "";
  };
  
  /**
   * This useEffect queries the DB to search for day off types from the backend, to showcase them
   * in the modal's select day off.
   * If there were more queries later, ideally we should do a 
   * Promise.all to prevent the modal re-rendering every time a query
   * is succesfull.
   */
  useEffect(() => {
      if (auth?.user?.token)
          getDaysOffTypes(auth.user.token)
              .then((response) => setDayOffTypes(response))
              .catch((error) => {
                  Swal.fire(
                      intl.formatMessage({
                          id: "modal.time.off.get.day.off.types.error",
                          defaultMessage: "Couldn't load day off types: ",
                      }) + `${error.data?.message}`,
                      intl.formatMessage({
                          id: "modal.error.footer",
                          defaultMessage: "Please, try again later",
                      }),
                      "error"
                  );
              });
  }, []);

  useEffect(() => {
    if (startDate !== "" && endDate !== "") {
      if (moment(endDate).diff(startDate, "days") < 0) {
        setDayInvalid(true);
      } else {
        const overlap = daysOff.some((dayOff) => {
          if (dayOff.end) {
            return (
              moment(startDate, "YYYY-MM-DD").isSameOrBefore(
                dayOff.end,
                "day"
              ) &&
              moment(endDate, "YYYY-MM-DD").isSameOrAfter(dayOff.start, "day")
            );
          } else {
            return (
              moment(startDate, "YYYY-MM-DD").isSameOrBefore(
                dayOff.start,
                "day"
              ) &&
              moment(endDate, "YYYY-MM-DD").isSameOrAfter(dayOff.start, "day")
            );
          }
        });
        if (overlap) {
          setRangeInvalid(true);
          setDayInvalid(true);
        } else {
          setRangeInvalid(false);
          setDayInvalid(false);
        }
      }

      if (moment(endDate).diff(startDate, "days") === 0) {
        setTotal(1);
      } else {
        let dayCount = 0;
        let dayProgress = startDate;
        while (dayProgress <= endDate) {
          if (
            moment(dayProgress).format("ddd") !== "Sat" &&
            moment(dayProgress).format("ddd") !== "Sun"
          ) {
            dayCount++;
          }
          dayProgress = moment(dayProgress).add(1, "d");
        }
        setTotal(dayCount);
      }
    }
  }, [startDate, endDate]);

  const NewDayOffSchema = Yup.object().shape({

    date1DayOff: Yup.date()
      .min(moment().format("YYYY-MM-DD"))
      .max(moment().endOf("year").format("YYYY-MM-DD")),

    date2DayOff: Yup.date()
      .min(moment().format("YYYY-MM-DD"))
      .max(moment().endOf("year").format("YYYY-MM-DD")),

    typeDaysOffs: Yup.number().min(1).required(intl.formatMessage({
      id: "modal.required.field",
      defaultMessage: "This is a required field"
    })),

    note: Yup.string().required(intl.formatMessage({
      id: "modal.required.field",
      defaultMessage: "This is a required field"
    }))

  });

  return (
    <>
      <Modal.Header closeButton className="modalHeader">
        {/*<FormattedMessage
          id="scheduler.modal.entry.time.off"
          defaultMessage="Add time off entry"
        />*/}
        Add time off entry
      </Modal.Header>
      <Formik
        initialValues={{
          type: 3,
          note: "",
          tags: "",
          date1DayOff: "",
          date2DayOff: "",
          typeDaysOffs: "0",
        }}
        validationSchema={NewDayOffSchema}
        onSubmit={async (values, actions) => {
          values.workedDays = moment(endDate).diff(startDate, "days") + 1;
          values.date1DayOff = moment(startDate).format("YYYY-MM-DD");
          values.date2DayOff = moment(endDate).format("YYYY-MM-DD");
          try {
            const response = await postRequestDaysOff(
              auth.user.id,
              auth.user.token,
              values.date1DayOff,
              values.date2DayOff,
              parseInt(values.typeDaysOffs),
              values.workedDays,
              values.note
            );
            if (response) {
              Swal.fire(
                intl.formatMessage({
                  id: "modal.time.off.success",
                  defaultMessage: "Your day off request was added successfully!"
                }),
                "",
                "success"
              );
              handleClose();
              schedulerContext.getDaysOff();
            }
            actions.setSubmitting(false);
          } catch (error) {
            Swal.fire(
              intl.formatMessage({
                id: "modal.time.off.error",
                defaultMessage: "Couldn't add your day off request: "
              }) + error.data?.message,
              intl.formatMessage({
                id: "modal.time.off.error.footer",
                defaultMessage: "Please, try again later"
              }),
              "error"
            );
            console.log(error);
            return;
          }
        }}
      >
        {({ touched, errors, isSubmitting }) => (
          <Form>
            <>
              <div className="timesheetModalBody">
                <div className="form-group">
                  <label htmlFor="type">
                      {/*<FormattedMessage
                        id="scheduler.modal.entry.type"
                        defaultMessage="Entry type"
                      />*/}
                      Entry type
                  </label>
                  <label htmlFor="type" id="timeOffLabel">
                        {/*<FormattedMessage
                          id="modal.time.off.type"
                          defaultMessage="Time off type"
                        />*/}
                        Time off type
                  </label>
                  <Row>
                    <Col xs={6}>
                      <BootstrapForm.Select
                        size="sm"
                        className="timesheetSelect"
                        value={entryType}
                        onChange={handleChangeModalType}
                        id="entryTypeSelect"
                      >
                        {
                          permissions.includes('log-hours')? <option value={1}>
                          {intl.formatMessage({
                            id: "modal.entry.type.option1",
                            defaultMessage: "Time log",
                          })}
                        </option>: null
                        }
                        <option value={3}>
                          {intl.formatMessage({
                            id: "modal.entry.type.option3",
                            defaultMessage: "Time Off",
                          })}
                        </option>

                      </BootstrapForm.Select>
                    </Col>
                    <Col md={6}>
                      <Field
                        as="select"
                        component="select"
                        name="typeDaysOffs"
                        id="typeSelect"
                        className={`form-control form-select ${
                          errors.typeDaysOffs ? "is-invalid" : ""
                        }`}
                      >
                        <option disabled value="0">
                          {intl.formatMessage({
                            id: "modal.time.off.default.selection",
                            defaultMessage: "Day off type"
                          })}
                        </option>
                        {!dayOffTypes ? null : dayOffTypes.map((element) => {
                          return (
                            <option value={element.id}>
                              {intl.formatMessage({
                              id: `modal.time.off.type.${formatDayOffTypeName(element.name)}`,
                              defaultMessage: element.name
                              })}
                            </option>
                          )
                        })}
                      </Field>
                    </Col>
                    <small class="smallMessage">
                      <WarningIcon />
                      &nbsp;
                      This type of entry requires approval, it usually takes 24-48hs
                      {/*<FormattedMessage
                        id="modal.time.off.approval"
                        defaultMessage="This type of entry requires approval, it usually takes 24-48hs"
                      />*/}
                    </small>
                  </Row>
                </div>
              </div>
              <hr className="hrModal"></hr>
              <Modal.Body className="timesheetModalBody">
                <Row className="dayOffDatePicker">
                  <Col xs={2} id="dateDayOffLabel">
                    <label
                      htmlFor="workTimeStart"
                      className="labelTimeframesDayOff"
                    >
                      {/*<FormattedMessage
                        id="modal.time.off.first.day"
                        defaultMessage="First day out"
                      />*/}
                      First day out
                      &nbsp;→
                    </label>
                  </Col>
                  <Col xs={4} id="dateDayOff">
                    <DatePicker
                      name="date1DayOff"
                      wrapperClassName="datePicker"
                      dateFormat="MM/dd/yyyy"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      minDate={moment().subtract(30, "d").toDate()}
                      maxDate={moment().endOf("year").toDate()}
                      showDisabledMonthNavigation
                      customInput={<CustomDatePicker />}
                    />
                  </Col>
                  <Col xs={2} id="dateDayOffLabel">
                    <label
                      htmlFor="workTimeStart"
                      className="labelTimeframesDayOff"
                    >
                      {/*<FormattedMessage
                        id="modal.time.off.last.day"
                        defaultMessage="Last day out"
                      />*/}
                      Last day out
                      &nbsp;→
                    </label>
                  </Col>
                  <Col xs={4} id="dateDayOff">
                    <DatePicker
                      name="date2DayOff"
                      wrapperClassName="datePicker"
                      dateFormat="MM/dd/yyyy"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      minDate={startDate}
                      maxDate={moment().endOf("year").toDate()}
                      showDisabledMonthNavigation
                      customInput={<CustomDatePicker />}
                    />
                  </Col>

                  <div className="timesheetTotalDay">
                    <small>
                      {" "}
                      {dayInvalid ? (
                        rangeInvalid ? (
                          <div>
                            {/*<FormattedMessage
                              id="time_off_days_overlap"
                              defaultMessage="Days overlap"
                            />*/}
                            Days overlap
                          </div>
                        ) : (
                          <div>
                            {/*<FormattedMessage
                              id="time_off_invalid_interval"
                              defaultMessage="Invalid interval"
                            />*/}
                            Invalid interval
                          </div>
                        )
                      ) : (
                        <div>
                          <small>
                            {/*<FormattedMessage
                              id="time_off_total"
                              defaultMessage="Total: "
                            />*/}
                            Total: 
                            {total}
                          </small>
                        </div>
                      )}
                    </small>
                  </div>
                </Row>
                <hr></hr>
                <Row>
                  <Col>
                    <div className="form-group">
                      <label htmlFor="note">
                        {/*<FormattedMessage
                          id="modal.time.off.notes"
                          defaultMessage="Notes*"
                        />*/}
                        Notes*
                      </label>
                      <Field
                        as="textarea"
                        name="note"
                        id="noteField"
                        className={`form-control ${
                          touched.note && errors.note ? "is-invalid" : ""
                        }`}
                        placeholder={
                          intl.formatMessage({
                            id: "modal.time.off.notes.placeholder",
                            defaultMessage: "Additional notes"
                          })
                          }
                      />
                      <ErrorMessage
                        component="div"
                        name="note"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button className="closeButton" onClick={handleClose}>
                  {/*<FormattedMessage
                    id="button.cancel"
                    defaultMessage="Cancel"
                  />*/}
                  Cancel
                </Button>
                <Button className="submitButton" type="submit">
                  {/*<FormattedMessage
                    id="button.accept"
                    defaultMessage="Accept"
                  />*/}
                  Accept
                </Button>
              </Modal.Footer>
            </>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default DayOffModal;
