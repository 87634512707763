import "./Shortcuts.scss";
import { useContext, useEffect, useState } from "react";
import LinkExterno from "./LinkExterno";
import ProjectsContext from "../../context/ProjectsContext/ProjectsContext";
import { useIntl } from "react-intl";
import { BiEditAlt } from "react-icons/bi";
import { FormattedMessage } from "react-intl";
import ReactTooltip from "react-tooltip";
import { Button } from "react-bootstrap";
import {useAuthContext} from "../../context";

/**
 * @module
 * @description This function creates the structure and the logic of Short cuts
 * @param {number} id get id
 * @param {Function} getShortcuts get Short cuts
 * @returns {JSX} Shortcuts
 */
const Shortcuts = ({ id, getShortcuts, postShortcuts, archivedProject }) => {
  const [shortcuts, setShortcuts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [linkUrl, setLinkUrl] = useState("");
  const [linkName, setLinkName] = useState("");
  const { auth } = useContext(ProjectsContext);
  const intl = useIntl();
  const { user } = useAuthContext();
  const authContext = useAuthContext();
  const [permissions,setPermissions] = useState([]);

  useEffect(() => {
    getShortcuts(id)
      .then(
        (res) => setShortcuts(res.data.data) //get the res to validate the info
        //setLoading(false)
      )
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleEdit = () => {
    setIsEditing(true);
  };
  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleConfirm = async () => {
    setIsEditing(false);
    let data = {
      shortcutProjectId: id,
      shortcutName: linkName,
      shortcutLink: linkUrl,
    };

    try {
      const response = await postShortcuts(id, data);
      if (response) {
        setLoading(true);
        getShortcuts(id)
          .then((res) => {
            const shortcutData = res.data.data;
            setShortcuts(shortcutData);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
          })
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeUrl = (e) => {
    setLinkUrl(e.target.value);
  };

  const handleChangeName = (e) => {
    setLinkName(e.target.value);
  };

  useEffect(()=>{
    let permission =user.permissions.userPermissions;
    let permissionsArray = [];
    for (const permissionElement of permission) {
      if(permissionElement.locations && permissionElement.locations.name === authContext.userInfo.location) {
        permissionsArray.push(permissionElement.permission.name);
      }
    }
    setPermissions([...permissionsArray])
  },[])

  return (
    <div className="shortcuts-main">
      <h2>
        {/*<FormattedMessage
          id="project.by.id.shorcut.links"
          defaultMessage="Shortcut links"
        />*/}
        Shortcut links
        {permissions.includes('update-projects') && !archivedProject?
        <div className="edit">
          <button
            data-tip={intl.formatMessage({
              id: "edit.task",
              defaultMessage: "Edit",
            })}
            data-for="editProjectShortcuts"
            onClick={handleEdit}
          >
            <BiEditAlt />
          </button>
          <ReactTooltip
            id="editProjectShortcuts"
            place="bottom"
            backgroundColor="#3FAA58"
          />
        </div>:null}
      </h2>
      {isEditing ? (
        <>
          <ul>
            {shortcuts.map((shortcut) => (
              <li key={shortcut.shortcutName} className="shortcut-name">
                <LinkExterno
                  blank={true}
                  url={`https://${shortcut.shortcutLink}`}
                  text={shortcut.shortcutName}
                />
              </li>
            ))}
          </ul>
          <form>
            <div className="form-group">
              <label for="shortcutName">Name:</label>
              <input
                className="shortcutName"
                id="shortcutName"
                type="form-control"
                placeholder="Enter shortcut's name..."
                onChange={handleChangeName}
              />
            </div>
            <div className="form-group">
              <label for="shortcutUrl">URL:</label>
              <input
                className="shortcutUrl"
                id="shortcutUrl"
                type="form-control"
                placeholder="Enter shortcut's link..."
                onChange={handleChangeUrl}
              />
            </div>
            <div className="buttons">
              <button className="btn btn-outline-danger" id="cancelButton" onClick={handleCancel}>
                {/*<FormattedMessage id="button.cancel" defaultMessage="Cancel" />*/}
                Cancel
              </button>
              <button type="submit" className="btn btn-success" id="confirmButton" onClick={handleConfirm}>
                {/*<FormattedMessage id="button.accept" defaultMessage="Accept" />*/}
                Accept
              </button>
            </div>
          </form>
        </>
      ) : (
        <>
          <ul>
            {shortcuts.map((shortcut) => (
              <li key={shortcut.shortcutName} className="shortcut-name">
                <LinkExterno
                  blank={true}
                  url={`https://${shortcut.shortcutLink}`}
                  text={shortcut.shortcutName}
                />
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default Shortcuts;
