import { useEffect } from "react";

/**
 * useLocalStorage
 * Set up an event listener to set a state with localStorage data
 * @param { any } state - React state to set with localStorage
 * @param { React.Dispatch } setState - React state setter
 * @param {string} cacheName - localStorage item's Key

 */
function useLocalStorage(state, setState, cacheName, state2, setState2, cacheName2) {
  useEffect(() => {
    const onStorageUpdate = (event) => {
      const { key, newValue } = event;
      if (key === cacheName) {
        setState(JSON.parse(newValue));
      }
      if (key === cacheName2) {
        setState2(JSON.parse(newValue));
      }
    };

    // Only for setting null -> value
    if (!state) {
      // Check if info in localStorage
      if (localStorage.getItem(cacheName)) {
        setState(JSON.parse(localStorage.getItem(cacheName)));
      }
      
    }
    if (!state2) {
      if (localStorage.getItem(cacheName2)) {
        setState2(JSON.parse(localStorage.getItem(cacheName2)));
      }
    }

    window.addEventListener("storage", onStorageUpdate);
    return () => window.removeEventListener("storage", onStorageUpdate);
  }, [state, setState, cacheName, state2, setState2, cacheName2]);
}

export default useLocalStorage;
