import axios from "axios";
import { environment } from "../../../config/environment";

const getImage = async (data, token) => {
  let resp;
  const result = await axios
    .get(
      `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module0/news/image`,
      {
        headers: {
          data: data,
          Authorization: token
        },
      }
    )
    .then(function (response) {
      resp = response.data.data;
    })
    .catch(err => {
      throw err.response || err.toJSON() || err
    });
  return resp;
};
const ImagePath = async (data, token) => {
  let resp;

  const result = await axios
    .get(
      `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module0/news/image`,
      {
        headers: {
          data: data,
          Authorization: token
        },
      }
    )
    .then((response) => {
      resp = response.data.pfFileLink;
    })
    .catch(error => {
      throw error.response || error.toJSON() || error
    });

  return resp;
};

export { getImage, ImagePath };
