// Environment variables.

export const environment = {

  CLIENT_ID : process.env.REACT_APP_CLIENT_ID,
  S3_FOLDER : process.env.REACT_APP_S3_FOLDER,
  S3_BUCKET : process.env.REACT_APP_S3_BUCKET,
  S3_ROOT : process.env.REACT_APP_S3_ROOT,
  BACKEND : process.env.REACT_APP_BACKEND,
  PORT : process.env.REACT_APP_PORT,
  BACKEND_VERSION : process.env.REACT_APP_BACKEND_VERSION,
  LEGACY_FE: process.env.REACT_APP_FRONT_HOST_LEGACY,

};
