import React, { useState, useEffect } from 'react';
import Dropdown from '../../shared/Dropdown';
import Clock from './Clock';
import { ClockIcon } from '../../Icons';
import ReactTooltip from "react-tooltip";
import {useIntl} from "react-intl";
/**
 * @module
 * @description This component its used to store the Clock component inside the dropdown and also adding the icon of the clock.
 * @returns {JSX} ClockComponent
 */
const ClockComponent = () => {
  const intl = useIntl();
  const [showDropdown, setShowDropdown] = useState(false);
  const [outsideClick, setOutsideClick] = useState(false);

  //This Functios its used to change the showDropdown and outSideClick states , in order to hide or display the dropdown with the clock component inside
  const hideDropdown = () => {
    setShowDropdown(false);
    setOutsideClick(true);
  };

  useEffect(() => {
    const myTimer = setTimeout(() => {
      setOutsideClick(false);
    }, 100);
    return () => {
      clearTimeout(myTimer);
    };
  }, [outsideClick]);
  return (
    <div className="clock_container">
      <div
        onClick={!outsideClick ? () => setShowDropdown(!showDropdown) : null}
        className="clockIcon" data-tip={intl.formatMessage({id : "topbar.clock", defaultMessage : "World clock"})} data-for="clock"
      >
        <ClockIcon color={showDropdown ? '#5AC775' : ''} />
      </div>
      <ReactTooltip id="clock" place="bottom" backgroundColor="#3FAA58"/>

      {showDropdown ? (
        <div className="dropClock">
          <Dropdown id="clockDropdown" hideDropdown={hideDropdown} showDropdown={showDropdown}>
            <Clock />
          </Dropdown>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ClockComponent;
