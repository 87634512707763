import axios from "axios";
import { environment } from "../../../config/environment";

const postUserTags = (id, token, tags) => {
    return axios
      .post(
        `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/users/${id}/tags`,
        tags,
        {
          headers: {
            authorization: token,
          },
        }
      )
      .then((result) =>  {
        return result.data
      })
      .catch((err)=>{
        throw err.response || err.toJSON() || err;
    });
  };
  
  export default postUserTags;