const paintingBlack = (floatMenuOpen, player) => {
  let divChild = document.getElementById(player.index).children;
  if (!floatMenuOpen) {
    for (let i = 0; i <= divChild.length - 1; i++) {
      if (divChild[i].children[0])
        divChild[i].children[0].classList.remove("playerClosed");
      if (divChild[i].id && player.id.toString() !== divChild[i].id)
        divChild[i].children[0].classList.add("playerOpened");
    }
  } else {
    for (let i = 0; i <= divChild.length - 1; i++) {
      if (divChild[i].id) {
        divChild[i].children[0].classList.remove("playerOpened");
        divChild[i].children[0].classList.add("playerClosed");
      }
    }
  }
};

export { paintingBlack }