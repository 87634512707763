import React from "react";

/**
 * @description Function to create the chat icon as a JSX component
 * @returns {JSX} ChatIcon
 */

const ChatIcon = () => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.667 4.5a.5.5 0 0 1 .5-.5h2.666a.5.5 0 1 1 0 1H7.167a.5.5 0 0 1-.5-.5zM4 7.167a.5.5 0 0 1 .5-.5h5.333a.5.5 0 1 1 0 1H4.5a.5.5 0 0 1-.5-.5z"
        fill="#B0B0B0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.811 0h2.711c.915 0 1.632 0 2.208.047.587.048 1.072.147 1.51.37a3.833 3.833 0 0 1 1.675 1.676c.224.439.323.924.371 1.51.047.577.047 1.293.047 2.208v.91c0 1.032 0 1.662-.155 2.19a3.833 3.833 0 0 1-2.6 2.6c-.528.156-1.158.156-2.19.156h-.504a2.833 2.833 0 0 0-1.614.517l-.033.023-1.74 1.243c-1.004.717-2.322-.318-1.864-1.462a.234.234 0 0 0-.217-.321h-.402A3.014 3.014 0 0 1 0 8.652V5.811c0-.915 0-1.631.047-2.208.048-.586.147-1.071.37-1.51A3.833 3.833 0 0 1 2.094.418c.439-.224.924-.323 1.51-.37C4.18 0 4.896 0 5.811 0zM3.685 1.044c-.525.043-.864.125-1.138.265a2.833 2.833 0 0 0-1.238 1.238c-.14.274-.222.613-.265 1.138C1 4.215 1 4.892 1 5.833v2.82c0 1.112.902 2.014 2.014 2.014h.402c.873 0 1.47.882 1.146 1.692-.087.217.163.414.353.278l1.74-1.244.038-.027a3.834 3.834 0 0 1 2.184-.7h.412c1.162 0 1.635-.004 2.008-.114a2.834 2.834 0 0 0 1.922-1.921c.11-.373.114-.847.114-2.008v-.79c0-.941 0-1.618-.043-2.148-.043-.525-.126-.864-.265-1.138a2.833 2.833 0 0 0-1.239-1.238c-.274-.14-.612-.222-1.137-.265C10.118 1 9.442 1 8.5 1H5.833c-.941 0-1.618 0-2.148.044z"
        fill="#B0B0B0"
      />
    </svg>
  );
};

export default ChatIcon;
