import { useAuthContext } from "../../../context/AuthContext/AuthContext";
import Motivational from "../Motivational/Motivational";
import scss from "./Welcome.module.scss";
import { GlobalComponent } from "../../Icons/index";
import { FormattedMessage } from "react-intl";

/**
 * @module
 * @description This function creates the welcome message of the global homepage.
 * @returns {JSX} WelcomeGlobal
 */
function WelcomeGlobal() {
  const context = useAuthContext();
  return (
    <div className={scss.container}>
      <div className={scss.title}>
        <div className={scss.icon_title}>
          <div className={scss.icon}>
            <GlobalComponent />
          </div>
          <h1 className={scss.welcome_primary}>
            {/*<FormattedMessage
              id="welocome.glad.to.have.you.back"
              defaultMessage="Glad to have you back, {name}!"
              values={{ name: context?.userInfo?.firstName }}
            />*/}
            Glad to have you back, {context?.userInfo?.firstName}!
          </h1>
        </div>
        <h2 className={scss.welcome_secondary}>
          {/*<FormattedMessage
            id="welcome.take.a.moment"
            defaultMessage="Take a moment to check what is going on globally."
          />*/}
          Take a moment to check what is going on globally.
        </h2>
      </div>
      <h3 className={scss.motivational_phrase}>
        <Motivational />
      </h3>
    </div>
  );
}

export default WelcomeGlobal;
