import axios from 'axios';
import PropTypes from 'prop-types';
import { environment } from '../../../config/environment';

/**
 * @module
 * @description This service calls the API in order to get information from the BE.
 * @param {Object} context: user's context.
 * @param {Object} type: type of information to be retrieved
 * @returns {Object} Full list of items depending on the type.
 */

async function getCompanyInfo(context, type) {
  try {
    const response = await axios.get(
      `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/module0/company/${type}`,
      {
        headers: {
          Authorization: `${context.user.token}`,
        },
      }
    );

    return response.data.data;
  } catch (error) {
    throw error.response || error.toJSON() || error;
  }
}

getCompanyInfo.propTypes = {
  context: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default getCompanyInfo;
