import axios from "axios";
import { environment } from "../../../config/environment";

/**
 * Used to query the BE to retrieve all day off types available, to later show them in
 * Days Off Request modal.
 * @param {String} userToken: authorization token. 
 * @returns {Array} data from response object depending on if the result was successfull.
 * 
 * * i.e. [{id: 2, name: 'Paid time off'}, ..., {id: 6, name: 'Others'}]
 * 
 * If it were an unsuccessfull query, it will return the parsed error object form the backend as a throw to
 * Swal.fire() the message in the calling function / useEffect.
 */
const getDaysOffTypes = async (userToken) => {
  let daysOffTypes = await axios
    .get(`${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/historicDaysOff/daysOffTypes`, {
      headers: {
        authorization: userToken,
      },  
    })
    .then(result => result)
    .catch(error => {
      throw error.response || error.toJSON() || error});
  return daysOffTypes.data.data;
};

export default getDaysOffTypes;
