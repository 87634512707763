import axios from "axios";
import { environment } from "../../../config/environment";

const getLocationsAndRegions = async (userToken) => {
  let locations = await axios
    .get(`${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/company/locations`, {
      headers: {
        authorization: userToken,
      },
    })
    .then((result) => result)
    .catch((error) => {
      throw error.response || error.toJSON() || error
    });
  locations = locations.data.data;

  let regions = await axios
    .get(`${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/company/regions`, {
      headers: {
        authorization: userToken,
      },
    })
    .then((result) => result)
    .catch((error) => {
      throw error.response || error.toJSON() || error
    });

  regions = regions.data.data;

  return regions;
};

const getLocations = async (userToken) => {
  let locations = await axios
    .get(`${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/company/locations`, {
      headers: {
        authorization: userToken,
      },
    })
    .then((result) => result)
    .catch((error) => {
      throw error.response || error.toJSON() || error
    });
  locations = await locations.data.data;

  return locations;
};

const getLocationsByRegionName = async (regionId, userToken) => {
  let locations = await axios
    .get(`${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/company/locations/${regionId}`, {
      headers: {
        authorization: userToken,
      },
    })
    .then((result) => result)
    .catch((error) => {
      throw error.response || error.toJSON() || error
    });
  locations = locations.data.data;

  return locations;
};

const getRegions = async (userToken) => {

  let regions = await axios
    .get(`${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/company/regions`, {
      headers: {
        authorization: userToken,
      },
    })
    .then((result) => result)
    .catch((error) => {
      throw error.response || error.toJSON() || error
    });

  regions = regions.data.data;

  return regions;
};

export { getLocationsAndRegions, getLocations, getLocationsByRegionName, getRegions };
