import { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";

export const Mood = ({
  mood,
  handleClickMood,
  getMoodIcon,
  player,
  iconMood,
  playerId,
}) => {
  const [test, setTest] = useState(false);

  useEffect(() => {
    if (mood.moodId !== iconMood && (player.id || player.userId) === playerId) {
      setTest(false);
    }
  }, [test, iconMood, mood.moodId, playerId, player.id, player.userId]);

  return (
    <>
      <span
        data-tip
        data-for={mood.moodTitle}
        style={{ background: test ? `#C1CBCB` : "none", borderRadius: "50%" }}
        id={mood.moodId}
        onClick={(e) =>
          handleClickMood(
            mood.moodId,
            player.id || player.userId,
            setTest,
            test,
            e
          )
        }
      >
        {getMoodIcon(mood.moodId)}
      </span>
      <ReactTooltip
        id={mood.moodTitle}
        effect="solid"
        type="light"
        className="tooltip"
      >
        {mood.moodTitle}
      </ReactTooltip>
    </>
  );
};
