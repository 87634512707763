import React from "react";

function ElipsisButtonIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      x="0"
      y="0"
      enableBackground="new 0 0 990.9 990.9"
      version="1.1"
      viewBox="0 0 990.9 990.9"
      xmlSpace="preserve"
    >
      <path d="M144.2 639.6c79.6 0 144.1-64.5 144.1-144.1 0-79.601-64.5-144.1-144.1-144.1C64.5 351.3 0 415.8 0 495.4s64.5 144.2 144.2 144.2zm0-213.7c38.3 0 69.5 31.2 69.5 69.5 0 38.301-31.2 69.5-69.5 69.5s-69.5-31.199-69.5-69.5c0-38.3 31.1-69.5 69.5-69.5zM351.4 495.4c0 79.6 64.5 144.1 144.1 144.1S639.6 575 639.6 495.4s-64.5-144.1-144.1-144.1-144.1 64.5-144.1 144.1zm213.6 0c0 38.301-31.2 69.5-69.5 69.5S426 533.701 426 495.4c0-38.3 31.2-69.5 69.5-69.5s69.5 31.2 69.5 69.5zM846.8 351.3c-79.6 0-144.1 64.5-144.1 144.1s64.5 144.1 144.1 144.1S990.9 575 990.9 495.4c.1-79.6-64.4-144.1-144.1-144.1zm0 213.6c-38.3 0-69.5-31.199-69.5-69.5 0-38.3 31.2-69.5 69.5-69.5s69.5 31.2 69.5 69.5c0 38.301-31.1 69.5-69.5 69.5z"></path>
    </svg>
  );
}

export default ElipsisButtonIcon;