import scss from './Field.module.scss';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

/**
 * @module
 * @description This component renders a dropdown list to be used in the signUp page.
 * @param {String} props.name: HTML attribute to be put in this component.
 * @param {Function} props.liftState: callback function to lift up this component's state.
 * @param {String} props.currentValue: current value of the parent's state according to this component.
 * @param {Array} props.seniorityList: list of company seniorities retrieved from the API.
 * @returns {JSX} a dropdown list containing the company's seniority levels.
 */

const Seniority = (props) => {
  const [error, setError] = useState('');

  const handler = (event) => {
    // const value = event.target.value;git
    props.liftState(event);
    // console.log(value);
  };

  const seniorityFiltered = () => {
    let senFiltered = props.seniorityList
      .filter(item => item.id !== 31)
    return senFiltered;
  }

  return (
    <>
      {props.seniorityList.length === 0 ? (
        error ? (
          <p>{error}</p>
        ) : (
          <p>Loading...</p>
        )
      ) : (
        <div className={scss.field}>
          <label>Which is your seniority?</label>
          <select
            onChange={(event) => handler(event)}
            name={props.name}
            value={props.currentValue}
          >
            <>
              <option value="" hidden>
                Select your seniority
              </option>
              {seniorityFiltered().map((item) => (
                <option key={item.id} value={item.name}>
                  {item.name}
                </option>
              ))}
            </>
          </select>
        </div>
      )}
    </>
  );
};

Seniority.propTypes = {
  name: PropTypes.string,
  liftState: PropTypes.func,
  currentValue: PropTypes.string,
  fieldList: PropTypes.array,
};

export default Seniority;
