import EditIcon from "./EditIcon";
import "./EditIcon.scss";

const EditComponent = ({style}) => {
  return (
    <>
      <div className="component" style={style}>
        <EditIcon />
      </div>
    </>
  );
};

export default EditComponent;
