/**
 * @description Function to create the Location icon as a JSX component
 * @returns {JSX} MyLocation
 */
function MyLocation() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 20 25"
    >
      <path
        fill="currentColor"
        d="M13.128 21.476l-.584-.47.584.47zm-6.256 0l-.584.47.584-.47zM10 24v-.75.75zm3.953-14.857a.75.75 0 10-1.156-.957l1.156.957zM11.1 11.412l-.578-.479.578.479zm-3.89 0l-.577.478.578-.478zm-.008-1.187a.75.75 0 00-1.156.956l1.156-.956zm11.047.201c0 1.357-.726 3.165-1.86 5.116-1.116 1.92-2.558 3.865-3.846 5.464l1.168.94c1.311-1.626 2.805-3.637 3.975-5.65 1.152-1.983 2.063-4.088 2.063-5.87h-1.5zM7.456 21.006c-1.288-1.599-2.73-3.543-3.846-5.464-1.134-1.95-1.86-3.759-1.86-5.116H.25c0 1.782.91 3.887 2.063 5.87 1.17 2.013 2.664 4.024 3.975 5.65l1.168-.94zM1.75 10.426C1.75 5.601 5.476 1.75 10 1.75V.25C4.583.25.25 4.84.25 10.426h1.5zM10 1.75c4.524 0 8.25 3.851 8.25 8.676h1.5C19.75 4.84 15.417.25 10 .25v1.5zm2.544 19.256c-.694.861-1.157 1.432-1.574 1.801-.385.34-.658.443-.97.443v1.5c.783 0 1.396-.317 1.963-.82.535-.472 1.088-1.163 1.75-1.983l-1.169-.941zm-6.256.94c.66.821 1.214 1.512 1.749 1.985.567.502 1.18.819 1.963.819v-1.5c-.312 0-.586-.103-.97-.443-.417-.369-.88-.94-1.574-1.801l-1.168.94zm6.51-13.76l-2.275 2.747 1.155.957 2.275-2.747-1.156-.957zm-5.009 2.747l-.586-.708-1.156.956.587.709 1.155-.957zm2.734 0c-.465.563-.764.92-1.015 1.148-.235.214-.322.213-.352.213v1.5c.564 0 .998-.273 1.361-.603.349-.316.726-.775 1.162-1.301l-1.156-.957zm-3.889.957c.436.526.813.985 1.161 1.301.363.33.798.603 1.361.603v-1.5c-.03 0-.116.001-.352-.213-.25-.227-.55-.585-1.015-1.148l-1.155.957z"
      ></path>
    </svg>
  );
}

export default MyLocation;
