import axios from "axios";
import { environment } from "../../../config/environment";

const deleteTimesheet = async (userId, token, id) => {
  try {
    const data = await axios.delete(
      `${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/users/${userId}/timesheet?timesheetDescriptionId=${id}`,
      {
        headers: {
          authorization: token,
        },
      }
    );
    return data;
  } catch (err) {
    throw (
      err.response || err.toJSON() || {
        success: false,
        message: "An error has ocurred, please try again",
      }
    );
  }
};

export { deleteTimesheet };
