import React, { forwardRef } from "react";
import { CalendarIcon } from "../Icons";

const CustomDatePicker = forwardRef(({ value, onClick }, ref) => (
    <div className="custom-date-picker" onClick={onClick} ref={ref}>
      <span>{value}</span>
      <CalendarIcon onClick={onClick} aria-hidden="true" />
    </div>
));

export default CustomDatePicker;