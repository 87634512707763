import React from "react";

function TimeCircle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      fill="none"
      viewBox="0 0 18 19"
    >
      <path
        fill="#595959"
        d="M4.21 1.373A.616.616 0 103.44.41L1.388 2.054a.616.616 0 10.77.962L4.21 1.373zM14.893.41a.616.616 0 10-.77.963l2.054 1.643a.616.616 0 00.77-.962L14.893.41z"
      ></path>
      <path
        fill="#595959"
        fillRule="evenodd"
        d="M9.167.891a8.833 8.833 0 100 17.667 8.833 8.833 0 000-17.667zm.616 5.547a.616.616 0 00-1.232 0v2.62l-.592-.59a.616.616 0 00-.871.87l1.232 1.233c.54.54 1.463.158 1.463-.606V6.438z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default TimeCircle;
