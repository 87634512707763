import SchedulerLayout from "./SchedulerLayout/SchedulerLayout";

/**
 * @module
 * @description this component displays all the scheduler layout
 * @returns {JSX} MyCalendar
 */

const MyCalendar = () => {
  return <SchedulerLayout />;
};

export default MyCalendar;
