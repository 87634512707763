import React, { useEffect, useRef, useState } from "react";
import "./Slider.css";

import {
  CircularInput,
  CircularTrack,
  CircularProgress,
  CircularThumb,
  useCircularInputContext,
} from "react-circular-input";

/**
 * Slider export the function wich add the slider to the World clock hour
 */
function Slider({ changeHandler, restart, reset, time }) {
  const [value, setValue] = useState(time * 0.00139);
  const [count, setCount] = useState(0);
  const prevValue = usePreviousValue(value);
  const prevCount = usePreviousCount(count);
  const stepValue = (v) => Math.round(v / 10) * 10;

  /**
   * usePreviousValue store the last value of the const value to use it later
   * @param {value}
   * @returns {ref.current}
   */
  useEffect(() => {
    if (restart === true) {
      setValue(time * 0.00139);
      setCount(0);
      reset(false);
    }
  }, [restart, reset]);

  function usePreviousValue(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  /**
   * usePreviousCount store the last value of the const count to use it later
   * @param {count}
   * @returns {ref.current}
   */
  function usePreviousCount(count) {
    const ref = useRef();
    useEffect(() => {
      ref.current = count;
    });
    return ref.current;
  }

  /**
   * accumulatedCurrentValue is the current value of the slider min 0 max 2000
   */
  let accumulatedCurrentValue = stepValue(value * 1000) + count * 1000;

  /**
   * valueWithinLimits make the Limit of the slider and set count to the value needed.
   * @param {value}
   * @returns put the slider in the correct value and shows the correct image.
   */
  const valueWithinLimits = (value) => {
    if (count >= 2 && accumulatedCurrentValue > 2000) {
      setCount(0);
    } else if (count < 0 && accumulatedCurrentValue < 0) {
      setCount(1);
    } else {
      return value;
    }
  };

  const handleOnWheel = (value) => {
    if (count >= 2 && accumulatedCurrentValue > 2000) {
      setCount(0);
    } else if (count < 0 && accumulatedCurrentValue < 0) {
      setCount(1);
    } else {
      return value;
    }

    console.log('onWheel: scrolling the list...');
  };

  const percentage = (accumulatedCurrentValue / 1000) * 1;

  const size = 140;
  const CircularProgressWidth = size / 11;
  const circleSize = CircularProgressWidth * 2.5;
  const circleThumbRadius = size / 10;
  const angle = 360 * value;

  useEffect(() => {
    if (stepValue(value * 1000) !== stepValue(prevValue * 1000)) {
      if (
        stepValue(prevValue * 1000) < 100 &&
        stepValue(value * 1000) > 900 &&
        prevCount === count &&
        count >= 0
      ) {
        setCount(count - 1);
      } else if (
        stepValue(prevValue * 1000) > 900 &&
        stepValue(value * 1000) < 100 &&
        count <= 2 &&
        prevCount === count
      ) {
        setCount(count + 1);
      } else {
        setCount(count);
      }
    }
  }, [value, prevValue, count, prevCount, accumulatedCurrentValue]);

  /**
   * changeImage make the animation of changing the central image os the slider
   * @param {show}
   * @returns {show}
   */
  function changeImage(show) {
    if (count === 0) {
      show = `calc(calc(100% - ${
        CircularProgressWidth * 2.5
      }px) - calc(calc(100% - ${
        CircularProgressWidth * 1.5
      }px) * ${percentage}))`;

      return show;
    } else {
      show = `calc(calc(100% - ${
        CircularProgressWidth * 23.3
      }px) + calc(calc(95% + ${CircularProgressWidth * 0}px) * ${percentage}))`;

      return show;
    }
  }

  return (
    <div>
      <div
        className="circleContainer"
        style={{
          width: `${size}px`,
          height: `${size}px`,
        }}
      >
        <div>
          <div
            className="am"
            style={{
              height: `calc(100% - ${circleSize}px)`,
              width: `calc(100% - ${circleSize}px)`,
              left: `${CircularProgressWidth * 1.25}px`,
              top: `${CircularProgressWidth * 1.25}px`,
            }}
          />
          <div
            className="pm"
            style={{
              height: changeImage(),
              width: `calc(100% - ${circleSize}px)`,
              left: `${CircularProgressWidth * 1.25}px`,
              top: `${CircularProgressWidth * 1.25}px`,
            }}
          />

          <div
            className="witheBorder"
            style={{
              height: `calc(115% - ${circleSize}px)`,
              width: `calc(115% - ${circleSize}px)`,
              left: `${CircularProgressWidth * 0.4}px`,
              top: `${CircularProgressWidth * 0.4}px`,
              border: `solid white ${CircularProgressWidth * 0.9}px`,
            }}
          ></div>
        </div>

        <CircularInput 
          style={{position: "absolute", height: `${size}`, width: `${size}`}}
          value={valueWithinLimits(value)}
          onChange={(value) => {setValue(valueWithinLimits(value)); changeHandler(value + count); }}
          onWheel={(e) => {setValue((prev) => +prev + e.deltaY / 10000);
          changeHandler(value + count);
        } }
        >
          <circle
            strokeWidth="1.5"
            r="110"
            stroke="gray"
            fill="none"
            strokeLinecap="round"
            cx="100"
            cy="100"
          />
          <circle
            strokeWidth="1.5"
            r="90"
            stroke="gray"
            fill="none"
            strokeLinecap="round"
            cx="100"
            cy="100"
          />
          <CircularTrack strokeWidth={19} stroke="#eee" />

          <CircularProgress
            className="gradient-border"
            strokeLinecap="butt"
            stroke={`#91E672`}
          />

          <CircularThumb r={14} fill="#41EE1D" id="circularThumb"/>
          <CustomComponent
            accumulatedCurrentValue={accumulatedCurrentValue}
            angle={angle}
          />
        </CircularInput>
      </div>
    </div>
  );
}

/**
 * CustomComponent is for the icon inside thumbnail
 */
function CustomComponent() {
  const { getPointFromValue, value } = useCircularInputContext();
  const point = getPointFromValue();
  if (!point) return null;
  return (
    <text
      {...point}
      textAnchor="middle"
      dy="0.35em"
      fill="#0B2EE9"
      style={{ pointerEvents: "none", fontWeight: "bold" }}
    ></text>
  );
}

export default Slider;
