import React, { useState, useEffect, forwardRef } from "react";
import moment from "moment";
import { default as BootstrapForm } from "react-bootstrap/Form";
import { Modal, Row, Col, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./EventModal.scss";
import "../PopOver/popOver.scss";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useAuthContext } from "../../../context/AuthContext";
import { useSchedulerContext } from "../../../context/SchedulerContext/SchedulerContext";
// language swichter feature
import { FormattedMessage, useIntl } from "react-intl";
import ActionButton from "../../shared/ActionButton";
import CloseIcon from "../../Icons/CloseIcon";
import Swal from "sweetalert2";
import getUsersByAdmin from "../services/getUserByAdmin";
import { CalendarIcon } from "../../Icons";
import postUserVacations from "../services/postUserVacations";


/**
 * @module
 * @description This component displays the events modal
 * @param {string} selectedDate
 * @param {string} startHour
 * @param {string} endHour
 * @param {Function} handleClose
 * @returns {JSX} EventModal
 */

const {
  getLocationsAndRegions,
} = require("../services/getLocationsAndRegions");

const AddVacationsModal = ({
  selectedUser, contexts,
  selectedDate,
  showDataUpdates,
  count,
  refreshTask,
  startHour,
  endHour,
  entryType,
  handleChangeModalType,
}) => {
  const auth = useAuthContext();
  const [show, setShow] = useState(false);
  const [users, setUsers] = useState([]);
  const scheduler = useSchedulerContext();
  const maxDaysOff = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10',
    '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
    '21', '22', '23', '24', '25', '26', '27', '28', '29', '30',
    '31', '32', '33', '34', '35', '36', '37', '38', '39', '40',]
  const options = users;

  const [regions, setRegion] = useState([]);
  const [startDate, setStartDate] = useState(moment(selectedDate).toDate());
  const [endDate, setEndDate] = useState(moment(selectedDate).toDate());
  const [nroDaysOff, setNroDaysOff] = useState(1)
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const schedulerContext = useSchedulerContext();
  const [selectedEmployee, setSelectedEmployee] = useState();

  useEffect(async () => {
    await scheduler.getUsers();
    if (show) {
      let getUsers = await getUsersByAdmin(auth.user.token, auth.user.id);

      setUsers(getUsers);
    }
  }, [show]);

  const showData = () => {
    showDataUpdates();
  };

  useEffect(async () => {
    await scheduler.getUsers();
    if (show) {
      let getUsers = await getUsersByAdmin(auth.user.token, auth.user.id);

      setUsers(getUsers);
    }
  }, [show]);

  useEffect(() => {
    let mounted = true;
    getLocationsAndRegions(auth.user.token).then((items) => {
      if (mounted) {
        setRegion(items);
      }
    });
    return () => (mounted = false);
  }, []);

  const onChange = (option) => {
    setSelectedEmployee(option)
    handleClose();
  };

  const handleClose = () => {
    setStartDate(moment(selectedDate).toDate());
    setEndDate(moment(selectedDate).toDate());
    setNroDaysOff(1)
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  const handleStartDate = (date, errors) => {
    setStartDate(date);
    errors.date = undefined;
  }
  const handleEndDate = (date, errors) => {
    setEndDate(date);
    errors.date = undefined;
  }

  const CustomDatePicker2 = forwardRef(({ value, onClick }, ref) => (
    <div className="custom-date-picker" onClick={onClick} ref={ref} style={{ width: "100%" }}>
      <span>{value}</span>
      <CalendarIcon onClick={onClick} aria-hidden="true" />
    </div>
  ));

  return (
    <>
      <button className="btn-add-days" onClick={handleShow}>
        Add days off
      </button>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="timesheetModal"
      >
        <div className="event-modal">
          <Modal.Header>
            <Modal.Title closeButton className="modal-title">
              {/*<FormattedMessage
              id="scheduler.modal.add.days.off"
              defaultMessage="Days off history"
            />*/}
              Days off history
            </Modal.Title>
            <button
              type="button"
              className="close-icon"
              aria-label="Close"
              onClick={handleClose}
            >
              <CloseIcon />
            </button>
          </Modal.Header>

          <hr className="hrModal"></hr>
          <Formik
            initialValues={{
              grantedDate: selectedDate || moment.utc().format("YYYY-MM-DD" + "T00:00:00"),
              expirationDate: selectedDate || moment.utc().format("YYYY-MM-DD" + "T00:00:00"),
              type: 0,
              notes: "",
              numberDaysOff: nroDaysOff,
              player: null
            }}
            onSubmit={async (values, actions) => {
              let vacationObj = {
                userId: values.player,
                grantedDate: values.grantedDate,
                expirationDate: values.expirationDate,
                type: values.type,
                notes: values.notes,
                numberDaysOff: parseInt(values.numberDaysOff)
              }
              try {
                const response = await postUserVacations(
                  vacationObj.userId,
                  auth.user.token,
                  vacationObj
                );
                if (response.status === 'success') {
                  Swal.fire(
                    intl.formatMessage({
                      id: "modal.add.days.off.swal.success",
                      defaultMessage: "Vacations granted successfully",
                    }),
                    "",
                    "success"
                  );
                  showData();
                  handleClose();
                }
                actions.setSubmitting(false);
              } catch (error) {
                Swal.fire(
                  intl.formatMessage({
                    id: "modal.holidays.swal.error.description",
                    defaultMessage: "Something went wrong: ",
                  }) + error.data?.message,
                  intl.formatMessage({
                    id: "modal.error.footer",
                    defaultMessage: "Please, try again later",
                  }),
                  "error"
                );
                console.log(error);
              }
            }}
            validate={(values) => {
              let errors = {};

              const nroDay = parseInt(values.numberDaysOff)

              if (values.player === null || values.player === undefined || values.player === '' || values.player === '0') {
                errors.player = "Select a user"
              }

              if (values.type === 0 || values.type === null || values.type === undefined || values.type === '' || values.type === '0') {
                errors.type = "Select a type"
              }

              if (isNaN(values.numberDaysOff)) {
                errors.numberDaysOff = "number of days off only can be a number"
              } else if (!isNaN(values.numberDaysOff) && nroDay < 1) {
                errors.numberDaysOff = "number of days off can't be less than 1"
              }
              return errors;
            }}
          >
            {({ touched, errors, values }) => (
              <Form >
                <>
                  <Modal.Body>
                    <Row className="event-modal-body g-0" >
                      <Col md={6}>
                        <div className="form-group">
                          <label htmlFor="player">
                            {/*<FormattedMessage
                              id="modal.add.days.off.player"
                              defaultMessage="Player*"
                            />*/}
                            Player*
                          </label>
                          <div className="checkBoxAndComponentOnModal">
                            <Field
                              as="select"
                              component="select"
                              name="player"
                              className="timesheetSelect"
                            >
                              <option value={0}>
                                {intl.formatMessage({
                                  id: "modal.time.log.select.player",
                                  defaultMessage: "Select a player",
                                })}
                              </option>
                              {users.map((employee) => {
                                return (
                                  <option key={employee.id} value={employee.id} className="cntx">
                                    {`${employee.firstName} ${employee.lastName}`}
                                  </option>
                                );
                              })}
                            </Field>
                          </div>
                          {errors.player ?
                            <small className="validity">
                              {/*<FormattedMessage
                                id="modal.time.log.player.invalid"
                                defaultMessage='Select a player'
                              />*/}
                              Select a player
                            </small> : <p>{errors.player}</p>}
                        </div>
                      </Col>

                      <Col xs={6}>
                        <div className="form-group">
                          <label htmlFor="type">
                            {/*<FormattedMessage
                              id="modal.add.days.off.type"
                              defaultMessage="Type*"
                            />*/}
                            Type*
                          </label>
                          <Field
                            as="select"
                            component="select"
                            name="type"
                            className="timesheetSelect"
                          >
                            <option value={0}>
                              {intl.formatMessage({
                                id: "modal.time.log.select.type",
                                defaultMessage: "Select type",
                              })}
                            </option>
                            <option value={'Yearly'}>
                              {intl.formatMessage({
                                id: "modal.add.days.off.entry1",
                                defaultMessage: "Yearly",
                              })}
                            </option>
                            <option value={'Extra'}>
                              {intl.formatMessage({
                                id: "modal.add.days.off.entry2",
                                defaultMessage: "Extra",
                              })}
                            </option>
                          </Field>
                          {errors.type ?
                            <small className="validity">
                              {/*<FormattedMessage
                                id="modal.time.log.type.invalid"
                                defaultMessage='Select a type'
                              />*/}
                              Select a type
                            </small> : null}
                        </div>
                      </Col>
                    </Row>

                    <hr className="hrModal" />
                    <Row className="event-modal-body g-0">
                      <Col xs={6} id="datePickerDiv" style={{ width: '47%' }}>
                        <div className="form-group">
                          <label htmlFor="datePicker1">
                            {/*<FormattedMessage
                              id="modal.add.days.off.granted"
                              defaultMessage="Granted on*"
                            />*/}
                            Granted on*
                          </label>
                          <DatePicker
                            className="datepicker-vacation"
                            wrapperClassName="datePicker1"
                            dateFormat="MM/dd/yyyy"
                            selected={startDate}
                            onChange={(date, errors) => handleStartDate(date, errors)}
                            showDisabledMonthNavigation
                            customInput={<CustomDatePicker2 />}
                          />
                          {errors.date ?
                            <small className="validity">
                              {/*<FormattedMessage
                                id="modal.time.log.date.invalid"
                                defaultMessage="Selected date is before allocation date"
                            />*/}
                            Selected date is before allocation date
                            </small> : null}
                        </div>
                      </Col>


                      <Col xs={6} id="datePickerDiv" style={{ width: '47%', marginLeft: '15px' }}>
                        <div className="form-group">
                          <label htmlFor="datePicker2">
                            {/*<FormattedMessage
                              id="modal.add.days.off.expires"
                              defaultMessage="Expires on*"
                          />*/}
                            Expires on*
                          </label>
                        </div>
                        <DatePicker
                          wrapperClassName="datePicker2"
                          dateFormat="MM/dd/yyyy"
                          selected={endDate}
                          onChange={(date, errors) => handleEndDate(date, errors)}
                          showDisabledMonthNavigation
                          customInput={<CustomDatePicker2 />}
                        />
                        {errors.date ?
                          <small className="validity">
                            {/*<FormattedMessage
                              id="modal.time.log.date.invalid"
                              defaultMessage="Selected date is before allocation date"
                            />*/}
                            Selected date is before allocation date
                          </small> : null}
                      </Col>
                    </Row>

                    <hr className="hrModal" />
                    <Row className="descriptionField">
                      <Col xs={6} id="datePickerDiv">
                        <div className="form-group">
                          <label htmlFor="numberDaysOff" style={{ fontWeight: '600', fontSize: '15px' }}>
                            {/*<FormattedMessage
                              id="modal.event.numberDaysOff"
                              defaultMessage="Number of days off*"
                            />*/}
                            Number of days off*
                          </label>
                          <div className="icon-outside-input">
                            <Field
                              type="input"
                              name="numberDaysOff"
                              className="form-control"
                              placeholder="1"
                            >

                            </Field>
                          </div>
                          {errors.numberDaysOff ?
                            <small className="validity">
                              {/*<FormattedMessage
                                id="modal.time.log.numberDaysOff.invalid"
                                defaultMessage='Invalid entry'
                              />*/}
                              Invalid entry
                            </small> : null}
                        </div>
                      </Col>
                    </Row>

                    <hr className="hrModal" />
                    <Row className="descriptionField">
                      <Col>
                        <div>
                          <label htmlFor="notes">
                            {/*<FormattedMessage
                              id="modal.event.notes"
                              defaultMessage="Notes"
                            />*/}
                            Notes
                          </label>
                          <Field
                            as="textarea"
                            name="notes"
                            placeholder={intl.formatMessage({
                              id: "modal.event.notes.placeholder",
                              defaultMessage: "Additional notes",
                            })}
                            className={`form-control ${touched.description && errors.description
                              ? "is-invalid"
                              : ""
                              }`}
                            id="notes"
                            style={{ resize: "none", height: "150px" }}
                          />
                          <ErrorMessage
                            component="div"
                            name="notes"
                            className="invalid-feedback"
                          />
                        </div>
                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <div>
                      <ActionButton secondary onClick={handleClose}>
                        {/*<FormattedMessage
                          id="button.cancel"
                          defaultMessage="Cancel"
                        />*/}
                        Cancel
                      </ActionButton>
                      <ActionButton disabled={loading} onClick={Formik.submitForm} type="submit">
                        {loading ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          /*<FormattedMessage
                            id="button.add"
                            defaultMessage="Add"
                        />*/
                          "Add"
                        )}
                      </ActionButton>
                    </div>
                  </Modal.Footer>
                </>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
}

export default AddVacationsModal;