import React, {useEffect, useState} from "react";
import Select from "react-select";
import {getLocations} from "../MyCalendar/services/getLocationsAndRegions";
import {getAllLocation} from "../../services/RoleAccessControl/getAllLocation";


export default function SelectCountry({countries,selectedCountry, setSelectedCountry, setShowDefaultPermissions}){
  const [displayCountry, setDisplayCountry] = useState(countries);

  useEffect(()=>{

    if(selectedCountry !== null && selectedCountry !== undefined) {
      const tempDefault = countries.filter(item =>{
        return item.name === selectedCountry.name;
      })
      if(countries.length===1){
        setDisplayCountry(countries[0])
        setSelectedCountry(countries[0])
      }else{
        setDisplayCountry(tempDefault[0])
        setSelectedCountry(tempDefault[0])
      }
    }else if(countries.length > 0){
      setDisplayCountry(countries[0])
      setSelectedCountry(countries[0])
    }else {
      setDisplayCountry(null)
    }
  }, [countries])

  useEffect(()=>{
        setDisplayCountry(countries[0])
        setSelectedCountry(countries[0])
  }, [])

  const handleCountry = (permissions)=>{
    try{
      return permissions.name
    }catch (err){
      return permissions.name
    }
  }

  const handleChangeCountry = async (permissions)=>{
    let location = await getAllLocation();

    if(location.length > 0) {

      location = location.filter(locat => locat.name === permissions.name)

      if (location.length>0) {

      setSelectedCountry({name: permissions.name, id: location[0].id});
      }else{
        setSelectedCountry({name: permissions.name, id: undefined});
      }
    }else{
      setSelectedCountry(permissions);
    }
    setDisplayCountry(permissions)
  }
  return (
    <div className="select-role" style={{'width':'100%', 'marginTop':'25px'}}>
      <div style={{'display':'flex','width':'37%'}}>
        <h3 className="title-container actual-role">
          Permissions for
        </h3>
        <div className="employee-list select-role">

          <Select
            id="select"
            value={displayCountry}
            options={countries !== undefined ? countries : []}
            getOptionLabel={(permissions) => handleCountry(permissions)}
            getOptionValue={(permissions) => permissions}
            onChange={(permissions) => handleChangeCountry(permissions)}
            placeholder={"Search region/location"}
            maxMenuHeight={190}
            width={253}
            styles={{
              control:(baseStyles, state)=>({
                ...baseStyles,
                marginTop:'15px',
                minWidth: '253px',
              }),
              menu:(baseStyles, )=>({
                ...baseStyles,
                minWidth: '253px'
              })
            }}
          />
        </div>
      </div>

      {/*<div style={{'display':'flex', 'marginTop':'10px', 'marginLeft':'30px'}}>*/}
      {/*  <input  type="checkbox" style={{'marginRight':'10px'}} onChange={e=>setShowDefaultPermissions(e.target.checked)}/>*/}
      {/*  <label style={{'marginTop':'15px'}}>Show selected role's default permissions</label>*/}
      {/*</div>*/}

    </div>
  );
}