import React, { useState, useEffect } from "react";
import Dropdown from "../../shared/Dropdown";
import NotiIcon from "./NotiIcon";
import NewNotificationIcon from "./NewNotificationIcon";
import scss from "./Notification.module.scss";
import Notification from "./Notification";
import { BlueCircleIcon } from "../../Icons";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

//This array is hardcoded, simulating the response from the endpoint that we are going to create in a near future.

const notifications = [
  {
    id: 1,
    date: "Friday, 4 December",
    read: false,
    seen: false,
    type: "warning",
    message:
      "Warning:It seems that you forgot to stop your tickler yesterday,please review the details and take action",
  },
  {
    id: 2,
    date: "Monday, 7 December",
    read: false,
    seen: true,
    type: "informational",
    message:
      "Info:The requested time off starting on Mon,15 Jun has been approved, enjoy",
  },
  {
    id: 3,
    date: "Tuesday, 8 December",
    read: false,
    seen: false,
    type: "warning",
    message:
      "Warning:Reported hours do not complete your daily allocation",
  },
  {
    id: 4,
    date: "Tuesday, 8 December",
    read: false,
    seen: false,
    type: "critical",
    message:
      "Critical:Reported hours do not complete your daily allocation",
  },
];
//This is used to sort the array of notifications by the type of notification.
const priority = {
  critical: 1,
  warning: 2,
  informational: 3,
};

notifications.sort((a, b) => priority[a.type] - priority[b.type]);

/**
 * @description this is the whole component, for each element in the array that we are getting from the backend , the component "Notifications" generates a "Notification" component
 * that its a single notification
 * @returns {JSX} Notifications
 */
const Notifications = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [newNotifications, setNewNotifications] = useState(true);
  const [notificationsList, setNotificationsList] = useState(notifications);
  const [outsideClick, setOutsideClick] = useState(false);

  //this is the function that we are using to delete notifications of the list.
  const deleteNotification = (id) => {
    const newList = notificationsList.filter((noti) => noti.id !== id);
    if (newList.length === 0) {
      hideDropdown();
      setNotificationsList(newList);
      setNotificationsList([
        {
          id: 0,
          date: "You are up to date",
          read: false,
          seen: true,
          type: "informational",
          message:
            "There are not new notifications to show, if you want to see older notifications please click on 'View all notifications'",
        },
      ]);
    } else {
      setNotificationsList(newList);
    }
  };
  deleteNotification.propTypes = {
    id: PropTypes.number.isRequired,
  };
  /**
   * @description This functions its used to mark all the notifications as seen
   * once the user clicks on the "Mark all as read" text
   * @returns {Object} NewList this is a new array with  the value "seen" updated to true on all objects.
   */
  const markAllAsSeen = () => {
    let newList = notificationsList.map((noti, index) => {
      return (noti = {
        id: noti.id,
        date: noti.date,
        read: noti.read,
        seen: true,
        type: noti.type,
        message: noti.message,
      });
    });
    setNotificationsList(newList);
  };
  /**
   * @description This functions is used to mark a single notification as seen once the user pass the mouse over the notification
   * @param {int} id
   * @returns {Object} NewList Returns a new array with the value "seen" updated on a specific notification object
   */
  const markAsSeen = (id) => {
    const newList = notificationsList.map((noti) => {
      if (noti.id == id) {
        return { ...noti, seen: true };
      }
      return noti;
    });
    setNotificationsList(newList);
  };
  markAsSeen.propTypes = {
    id: PropTypes.func.isRequired,
  };
  //Function used to change the state of the Notifications and also open/close the dropdown with all the notifications inside.
  const openCloseNotification = () => {
    setNewNotifications(false);
    setShowDropdown(!showDropdown);
  };
  // Close menu
  const hideDropdown = () => {
    setShowDropdown(false);
    setOutsideClick(true);
  };

  useEffect(() => {
    const myTimer = setTimeout(() => {
      setOutsideClick(false);
    }, 100);
    return () => {
      clearTimeout(myTimer);
    };
  }, [outsideClick]);

  return (
    <div className={scss.notificationsContainer}>
      <div
        className={scss.notificationsIcon}
        onClick={!outsideClick ? () => openCloseNotification() : null}
        data-tip="Notifications" data-for="notifications"
      >
        {newNotifications ? (
          <>
            <div className={scss.newNotifications}>
              <BlueCircleIcon />
            </div>
            <NewNotificationIcon />
            <ReactTooltip id="notifications" place="bottom" backgroundColor="#3FAA58"/>
          </>
        ) : (
          <>
            <NotiIcon color={showDropdown ? "#5cc076" : ""} />
            <ReactTooltip id="notifications" place="bottom" backgroundColor="#3FAA58"/>
          </>
        )}
      </div>

      {showDropdown ? (
        <div className={scss.dropd}>
          <Dropdown hideDropdown={hideDropdown} showDropdown={showDropdown}>
            <div className={scss.notificationsTitle}>
              <h3>Notifications</h3>
              <h5 onClick={() => markAllAsSeen()}>Mark all as seen</h5>
            </div>
            <hr />
            {notificationsList.map((notification, index) => {
              return (
                <div key={notification.id}>
                  <Notification
                    notification={notification}
                    key={notification.id}
                    deleteNotification={deleteNotification}
                    markAsSeen={markAsSeen}
                  />
                  {index == notificationsList.length - 1 ? <></> : <hr />}
                </div>
              );
            })}
            <div className={scss.allNotifications}>View all notifications</div>
          </Dropdown>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Notifications;
