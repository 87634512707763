import moment from "moment";

/**
 * Convert the date from UTC to locale timezone
 * @param  {String} utcDate Date with UTC timezone
 */
export const convertDateToLocale = (utcDate) => {
  var stillUtc = moment.utc(utcDate).toDate();
  var local = moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");

  return local;
};
