/**
 * @description Function to create the warning icon as a JSX component
 * @returns {JSX} WarningIcon
 */
const WarningIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#FFB800"
        d="M8.57 5.114a.547.547 0 00-1.093 0V9.49a.547.547 0 101.094 0V5.114z"
      ></path>
      <path
        fill="#FFB800"
        fillRule="evenodd"
        d="M9.73 1.282a4.194 4.194 0 00-3.412 0c-.679.303-1.227.882-1.793 1.683-.565.8-1.197 1.894-2.013 3.308l-.03.052C1.665 7.739 1.033 8.834.624 9.722c-.411.891-.639 1.655-.561 2.395a4.194 4.194 0 001.706 2.954c.601.437 1.377.622 2.354.712.974.09 2.238.09 3.87.09h.06c1.634 0 2.898 0 3.872-.09.977-.09 1.753-.275 2.354-.712a4.194 4.194 0 001.706-2.954c.077-.74-.15-1.504-.561-2.395-.41-.888-1.042-1.983-1.858-3.397l-.03-.052c-.817-1.414-1.449-2.509-2.013-3.308-.566-.8-1.114-1.38-1.793-1.682zm-2.967 1a3.1 3.1 0 012.522 0c.403.18.81.56 1.344 1.315.532.753 1.14 1.804 1.974 3.25.835 1.445 1.441 2.496 1.827 3.333.388.84.513 1.383.467 1.822a3.1 3.1 0 01-1.261 2.184c-.358.26-.89.423-1.811.507-.918.085-2.132.085-3.801.085-1.67 0-2.883 0-3.801-.085-.921-.084-1.454-.247-1.811-.507a3.1 3.1 0 01-1.261-2.184c-.046-.44.079-.982.466-1.822.386-.837.993-1.888 1.827-3.334.835-1.445 1.442-2.496 1.974-3.25.534-.755.941-1.134 1.345-1.314z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#FFB800"
        d="M8.753 11.678a.73.73 0 11-1.458 0 .73.73 0 011.458 0z"
      ></path>
    </svg>
  );
};

export default WarningIcon;
