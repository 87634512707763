import axios from "axios";
import { environment } from "../../../config/environment";
//Service to get projects, for the moment it returns a console.log




const projectService = {
    getProjects: (userId, token) => {   
        return axios.get(`${environment.BACKEND}:${environment.PORT}${environment.BACKEND_VERSION}/users/${userId}/projects`,{
            headers: {
                'authorization': token,
            },
        })
        .then((response)=>{
            const userProjects = response.data.data;
            return userProjects;
        })
        .catch((err)=>{
            throw err.response || err.toJSON() || err;
        })
    }
}

export { projectService };