import {BsFillTrashFill} from "react-icons/bs";
import ReactTooltip from "react-tooltip";
import React, {useEffect, useState} from "react";
import Badge from "./Badge";


export default function LocationBadge({locations, deleteBadge}){
  const [regionsArray, setRegionsArray] = useState([]);

  useEffect(()=>{
    let middle = locations.length/2;
    const middleTrunc = Math.trunc(middle);
    let middleFixed = middle;
    if(middle === middleTrunc){
      middleFixed= middle -1;
    }


    if(middle >1){
      const firstArray = locations.slice(0, middle);
      const secondArray = locations.slice(middle, locations.length);
      setRegionsArray([firstArray, secondArray])
    }else if(middle === 1 && middle > 0) {
      const firstArray = [locations[0]];
      const secondArray =[locations[1]];
      setRegionsArray([firstArray, secondArray])
    }else if(middle < 1){
      const firstArray = [locations[0]];
      setRegionsArray([firstArray])
    }

  },[locations]);

  return (
    <>
    {locations !== undefined && locations.length > 0 ?
      <div className="v-line line-gray" style={{'marginLeft':'150px', 'marginRigth':'5px'}}>
        {regionsArray.map(arr =>{
          return (
            <div className="badge-location">
              {arr.map(reg =>{
                if(reg !== undefined) return <Badge region={reg.name} deleteBadge={deleteBadge}/>})}
            </div>
          );
        })}

      </div>
      :null}
    </>
  );
}